
import { defineComponent, reactive } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import { ITituloFinanceiroRepasse } from '@/models/Entidades/Financeiro/TitulosFinanceiros/ITituloFinanceiro';

export default defineComponent({
  name: 'TituloFinanceiroRepasse',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    repasse: {
      type: Object as () => ITituloFinanceiroRepasse,
      required: true,
    },
  },
  components: {
    Card,
    SelecionarPessoa,
  },
  setup(props) {
    const state = reactive({});

    return {
      props,
      state,
    };
  },
});
