import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-15" }
const _hoisted_4 = { class: "ant-col ant-col-xs-12 ant-col-xl-3" }
const _hoisted_5 = { class: "ant-col ant-col-xs-12 ant-col-xl-6" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  class: "ant-row",
  style: {"margin":"10px 0px"}
}
const _hoisted_8 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_9 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_10 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_11 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mensagem_sem_dados = _resolveComponent("mensagem-sem-dados")!
  const _component_selecionar_conta = _resolveComponent("selecionar-conta")!
  const _component_selecionar_data = _resolveComponent("selecionar-data")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_card = _resolveComponent("card")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_totalizador = _resolveComponent("totalizador")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!

  return (_openBlock(), _createBlock(_component_card, {
    titulo: _ctx.props.tituloApresentacao.toLocaleUpperCase() + 'S',
    class: "ss-alinhar-texto-esquerda"
  }, {
    AcoesExtra: _withCtx(() => [
      (!_ctx.props.baixaComDetalhamentos)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "ss-fonte-negrito ss-card-botao-acao-extra",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.adicionarLancamento()))
          }, "ADICIONAR " + _toDisplayString(_ctx.props.tituloApresentacao.toLocaleUpperCase()), 1))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_ctx.state.computedLancamentos.length <= 0)
        ? (_openBlock(), _createBlock(_component_mensagem_sem_dados, {
            key: 0,
            mensagem: "Nenhum lançamento foi adicionado ainda"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_a_form, { layout: "vertical" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.computedLancamentos, (recebimento, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              style: {"padding":"5px 0px"}
            }, [
              _createVNode(_component_card, {
                titulo: _ctx.montaTituloCard(index),
                class: "ss-alinhar-texto-esquerda"
              }, {
                AcoesExtra: _withCtx(() => [
                  (!_ctx.props.baixaComDetalhamentos)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        class: "ss-fonte-negrito ss-card-botao-acao-extra-secundaria",
                        title: "Remover",
                        onClick: ($event: any) => (_ctx.excluirLancamento(index))
                      }, "Remover ", 8, _hoisted_1))
                    : _createCommentVNode("", true)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_selecionar_conta, {
                        codigoSelecionado: recebimento.codigoConta,
                        "onUpdate:codigoSelecionado": ($event: any) => ((recebimento.codigoConta) = $event),
                        empresas: [_ctx.props.empresa],
                        descricaoSelecionado: recebimento.descricaoConta,
                        "onUpdate:descricaoSelecionado": ($event: any) => ((recebimento.descricaoConta) = $event),
                        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.atualizarTotalizadores()))
                      }, null, 8, ["codigoSelecionado", "onUpdate:codigoSelecionado", "empresas", "descricaoSelecionado", "onUpdate:descricaoSelecionado"])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_a_form_item, {
                        label: "Data Baixa",
                        class: "ss-margin-campos"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_selecionar_data, {
                            dataSelecionada: recebimento.dataBaixa,
                            "onUpdate:dataSelecionada": ($event: any) => ((recebimento.dataBaixa) = $event),
                            disabled: true
                          }, null, 8, ["dataSelecionada", "onUpdate:dataSelecionada"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_a_form_item, {
                        label: "Valor",
                        class: "ss-margin-campos"
                      }, {
                        default: _withCtx(() => [
                          ((_ctx.props.valorRestante > 0))
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                class: "ss-apresentar-superior-direito ss-fonte-negrito ss-botao-acima-campo",
                                onClick: ($event: any) => (_ctx.lancarValorRestante(index))
                              }, "Lançar Valor Restante", 8, _hoisted_6))
                            : _createCommentVNode("", true),
                          _createVNode(_component_campo_numerico, {
                            monetario: true,
                            quantidadeCasasDecimais: 2,
                            valor: recebimento.valor,
                            "onUpdate:valor": ($event: any) => ((recebimento.valor) = $event),
                            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.atualizarTotalizadores()))
                          }, null, 8, ["valor", "onUpdate:valor"])
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["titulo"])
            ]))
          }), 128))
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_totalizador, {
            titulo: 'QTDE ' + _ctx.props.tituloApresentacao.toLocaleUpperCase() +'S',
            monetario: false,
            valor: _ctx.state.quantidadeLancamentos,
            casasDecimais: 0
          }, null, 8, ["titulo", "valor"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_totalizador, {
            titulo: 'TOTAL ' + _ctx.props.tituloApresentacao.toLocaleUpperCase() +'S',
            monetario: true,
            valor: _ctx.state.totalLancamentos
          }, null, 8, ["titulo", "valor"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_totalizador, {
            titulo: "TOTAL BAIXA TÍTULOS",
            monetario: true,
            valor: _ctx.props.totalBaixa
          }, null, 8, ["valor"])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_a_tooltip, {
            placement: "top",
            title: '(Total Créditos Utilizados + Total Novos Títulos + Total ' + _ctx.props.tituloApresentacao +'s) - Total Baixa Títulos'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_totalizador, {
                titulo: "VALOR RESTANTE",
                monetario: true,
                valor: _ctx.props.valorRestante
              }, null, 8, ["valor"])
            ]),
            _: 1
          }, 8, ["title"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["titulo"]))
}