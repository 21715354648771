
import { defineComponent, reactive, computed } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import { ITituloFinanceiroBoleto } from '@/models/Entidades/Financeiro/TitulosFinanceiros/ITituloFinanceiro';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import { ETipoEmissaoBoleto } from '@/models/Enumeradores/Financeiro/ETipoEmissaoBoletos';
import SelecionarSituacaoBoleto from './SelecionarSituacaoBoleto.vue';
import RemessaBoletosModal from './RemessaBoletosModal.vue';
import { ESituacaoBoleto } from '@/models/Enumeradores/Financeiro/ESituacaoBoleto';

export default defineComponent({
  name: 'TituloFinanceiroBoleto',
  props: {
    codigoRegistro: {
      type: Number,
      required: true,
    },
    empresa: {
      type: Number,
      required: true,
    },
    tipoTitulo: {
      type: Number,
      required: true,
    },
    boleto: {
      type: Object as () => ITituloFinanceiroBoleto,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Card,
    SelecionarData,
    RemessaBoletosModal,
    SelecionarSituacaoBoleto,
  },
  emits: ['update:boleto'],
  setup(props, { emit }) {
    const state = reactive({
      exibirRemessa: false,
    });

    const computedBoleto = computed({
      get: () => props.boleto,
      set: (boleto: ITituloFinanceiroBoleto) => {
        emit('update:boleto', boleto);
      },
    });

    function abrirRemessa() {
      state.exibirRemessa = true;
    }

    return {
      props,
      state,
      computedBoleto,
      ETipoTituloFinanceiro,
      ETipoEmissaoBoleto,
      ESituacaoBoleto,
      abrirRemessa,
    };
  },
});
