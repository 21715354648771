import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-5" }
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-5" }
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-5" }
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_totalizador_decorado = _resolveComponent("totalizador-decorado")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, { titulo: "RESUMO" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_totalizador_decorado, {
            titulo: "SALDO ANTERIOR",
            cor: "#8ba2c7",
            iconePequeno: true,
            valor: _ctx.props.saldoAnterior,
            monetario: true
          }, null, 8, ["valor"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_totalizador_decorado, {
            titulo: "RECEBIMENTOS REALIZADOS",
            cor: "#33CC00",
            iconePequeno: true,
            valor: _ctx.props.totalRecebimentos,
            monetario: true
          }, null, 8, ["valor"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_totalizador_decorado, {
            titulo: "PAGAMENTOS REALIZADOS",
            cor: "#FF0000",
            iconePequeno: true,
            valor: _ctx.props.totalPagamentos,
            monetario: true
          }, null, 8, ["valor"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_totalizador_decorado, {
            titulo: "SALDO CONCILIADO",
            cor: "#3333FF",
            iconePequeno: true,
            valor: _ctx.props.totalConciliados,
            monetario: true
          }, null, 8, ["valor"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_totalizador_decorado, {
            titulo: "SALDO",
            cor: "#25207d",
            iconePequeno: true,
            valor: _ctx.props.saldo,
            monetario: true
          }, null, 8, ["valor"])
        ])
      ])
    ]),
    _: 1
  }))
}