export enum EFiltroPeriodoMovimentoConta {
    Hoje = 1,
    MesAtual = 2,
    AnoAtual = 3,
    Ultimos7Dias = 4,
    Ultimos15Dias = 5,
    Ultimos30Dias = 6,
    Ultimos60Dias = 7,
    Ultimos90dias = 8,
    Personalizado = 9,
}
