
import { computed, defineComponent, reactive } from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';
import { IArquivo } from '@/models/Entidades/MeuSistema/IArquivo';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IMovimentoFinanceiroAnexo } from '@/models/Entidades/Financeiro/MovimentosFinanceiros/IMovimentoFinanceiro';
import ServicoMovimentoFinanceiro from '@/servicos/Financeiro/ServicoMovimentoFinanceiro';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { ERelacaoArquivo } from '@/models/Enumeradores/MeuSistema/ERelacaoArquivo';
import { IDTOMovimentoFinanceiroAnexoInserir } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiroAnexoInserir';

export default defineComponent({
  name: 'MovimentoFinanceiroAnexo',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    codigoMovimentoFinanceiro: {
      type: Number,
      required: true,
    },
    anexos: {
      type: Array as () => IMovimentoFinanceiroAnexo[],
      required: true,
    },
  },
  components: {
    Icone,
    Card,
  },
  emits: ['update:anexos'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();
    const state = reactive({
      enviandoArquivos: false,
      excluindoArquivos: false,
    });

    const servicoMovimentoFinanceiro = new ServicoMovimentoFinanceiro();
    const servicoSistema = new ServicoSistema();

    const computedAnexosMovimentoFinanceiro = computed({
      get: () => props.anexos,
      set: (valor: IMovimentoFinanceiroAnexo[]) => {
        emit('update:anexos', valor);
      },
    });

    async function removerAnexo(codigoMovimentoFinanceiroAnexo: number, codigoArquivo: number) {
      state.excluindoArquivos = true;
      const retornoArquivo = await servicoMovimentoFinanceiro.removerAnexo(props.codigoMovimentoFinanceiro, codigoMovimentoFinanceiroAnexo, codigoArquivo);
      if (retornoArquivo.status === EStatusRetornoRequisicao.Sucesso) {
        const index = computedAnexosMovimentoFinanceiro.value.findIndex((c) => c.codigoArquivo === codigoArquivo);
        if (index !== -1) {
          computedAnexosMovimentoFinanceiro.value.splice(index, 1);
        }
      } else {
        apresentarMensagemAlerta(retornoArquivo.mensagem);
      }
      state.excluindoArquivos = false;
    }

    async function visualizarAnexo(arquivo:IArquivo) {
      if (!UtilitarioGeral.valorValido(arquivo.link)) {
        const retornoArquivo = await servicoSistema.gerarLinkArquivoPrivado(arquivo.pasta, arquivo.nome);
        if (retornoArquivo.status === EStatusRetornoRequisicao.Sucesso) {
          UtilitarioGeral.downloadArquivo(retornoArquivo.linkArquivo);
        } else {
          apresentarMensagemAlerta(retornoArquivo.mensagem);
        }
      } else {
        UtilitarioGeral.downloadArquivo(arquivo.link);
      }
    }

    function selecionarArquivoUpload() {
      const elemento = document.getElementById('selecionarAnexoMovimentoFinanceiro');
      if (elemento !== null) {
        elemento.click();
      }
    }
    function confirmaExclusao(movimentoFinanceiroAnexo: IMovimentoFinanceiroAnexo) {
      Modal.confirm({
        title: 'Você deseja remover o anexo:',
        content: `${movimentoFinanceiroAnexo.arquivo.nome} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        centered: true,
        onOk: () => { removerAnexo(movimentoFinanceiroAnexo.codigo, movimentoFinanceiroAnexo.codigoArquivo); },
      });
    }
    function novoAnexo(retorno: IRetornoArquivo): IMovimentoFinanceiroAnexo {
      const movimentoFinanceiroAnexo:IMovimentoFinanceiroAnexo = {} as IMovimentoFinanceiroAnexo;
      movimentoFinanceiroAnexo.codigo = 0;
      movimentoFinanceiroAnexo.codigoMovimentoFinanceiro = props.codigoMovimentoFinanceiro;
      movimentoFinanceiroAnexo.codigoArquivo = retorno.codigoArquivo;
      movimentoFinanceiroAnexo.arquivo = {} as IArquivo;
      movimentoFinanceiroAnexo.arquivo.nome = retorno.arquivo;
      movimentoFinanceiroAnexo.arquivo.link = retorno.linkArquivo;
      movimentoFinanceiroAnexo.arquivo.tamanhoApresentacao = retorno.tamanhoApresentacao;
      return movimentoFinanceiroAnexo;
    }

    async function verificaApresentacaoNovosArquivos(retornoAnexos: IRetornoArquivo[]) {
      if (UtilitarioGeral.validaLista(retornoAnexos)) {
        const novosAnexos: IMovimentoFinanceiroAnexo[] = [];
        retornoAnexos.forEach((retorno) => {
          if (retorno.status) {
            const movimentoFinanceiroAnexo = novoAnexo(retorno);
            novosAnexos.push(movimentoFinanceiroAnexo);
            // Inserindo anexos na inclusão exibe o arquivo na hora;
            if (props.codigoMovimentoFinanceiro === 0) {
              computedAnexosMovimentoFinanceiro.value.push(movimentoFinanceiroAnexo);
            }
          } else {
            apresentarMensagemAlerta(retorno.mensagem);
          }
        });
        // Inserindo anexos na Alteração cria o vínculo com o movimento da conta depois apresenta os arquivos;
        if (props.codigoMovimentoFinanceiro > 0 && UtilitarioGeral.validaLista(novosAnexos)) {
          const movimentoFinanceiroAnexos: IDTOMovimentoFinanceiroAnexoInserir = {} as IDTOMovimentoFinanceiroAnexoInserir;
          movimentoFinanceiroAnexos.anexos = novosAnexos;
          const retornoAnexos = await servicoMovimentoFinanceiro.inserirAnexos(props.codigoMovimentoFinanceiro, movimentoFinanceiroAnexos);
          if (retornoAnexos.status) {
            novosAnexos.forEach((movimentoFinanceiroAnexo) => {
              computedAnexosMovimentoFinanceiro.value.push(movimentoFinanceiroAnexo);
            });
          } else {
            apresentarMensagemAlerta(retornoAnexos.mensagem);
          }
        }
      } else {
        apresentarMensagemAlerta('Desculpe-nos! Não conseguimos enviar os anexos neste momento, tente novamente mais tarde.');
      }
    }
    async function enviarArquivos(event:any) {
      state.enviandoArquivos = true;
      const retornoAnexos = await servicoSistema.enviarArquivosPrivados(props.empresa, ERelacaoArquivo.Anexos, event.target.files);
      await verificaApresentacaoNovosArquivos(retornoAnexos);
      state.enviandoArquivos = false;
    }

    async function drop(event:any) {
      state.enviandoArquivos = true;
      const retornoAnexos = await servicoSistema.enviarArquivosPrivados(props.empresa, ERelacaoArquivo.Anexos, event.dataTransfer.files);
      await verificaApresentacaoNovosArquivos(retornoAnexos);
      state.enviandoArquivos = false;
    }

    return {
      props,
      computedAnexosMovimentoFinanceiro,
      state,
      confirmaExclusao,
      drop,
      enviarArquivos,
      visualizarAnexo,
      selecionarArquivoUpload,
    };
  },
});
