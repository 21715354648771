import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-5 ant-col-xl-5" }
const _hoisted_3 = { class: "ant-col ant-col-xs-3 ant-col-xl-3" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "ant-col ant-col-xs-2 ant-col-xl-2" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "ant-col ant-col-xs-3 ant-col-xl-3" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "ant-col ant-col-xs-2 ant-col-xl-2" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "ant-col ant-col-xs-4 ant-col-xl-4" }
const _hoisted_12 = { class: "ant-col ant-col-xs-5 ant-col-xl-5" }
const _hoisted_13 = { class: "ant-row" }
const _hoisted_14 = { class: "ant-col ant-col-xs-4 ant-col-xl-4" }
const _hoisted_15 = { class: "ant-col ant-col-xs-4 ant-col-xl-4" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = { class: "ant-col ant-col-xs-11 ant-col-xl-11" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = { class: "ant-col ant-col-xs-5 ant-col-xl-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selecionar_banco = _resolveComponent("selecionar-banco")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_situacao_cheque = _resolveComponent("selecionar-situacao-cheque")!
  const _component_selecionar_cidade = _resolveComponent("selecionar-cidade")!
  const _component_selecionar_sim_nao = _resolveComponent("selecionar-sim-nao")!
  const _component_campo_telefone = _resolveComponent("campo-telefone")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, { titulo: "DADOS DO CHEQUE" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_selecionar_banco, {
            codigoSelecionado: _ctx.computedCheque.codigoBanco,
            "onUpdate:codigoSelecionado": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedCheque.codigoBanco) = $event)),
            disabled: !_ctx.props.editavel,
            required: true
          }, null, 8, ["codigoSelecionado", "disabled"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_form_item, {
            label: "Agência",
            class: "ss-margin-campos",
            rules: [{ required: true }]
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "ant-input",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedCheque.agencia) = $event)),
                disabled: !_ctx.props.editavel
              }, null, 8, _hoisted_4), [
                [_vModelText, _ctx.computedCheque.agencia]
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_a_form_item, {
            label: "DV",
            class: "ss-margin-campos",
            rules: [{ required: true }]
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "ant-input",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedCheque.agenciaDv) = $event)),
                disabled: !_ctx.props.editavel
              }, null, 8, _hoisted_6), [
                [_vModelText, _ctx.computedCheque.agenciaDv]
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_a_form_item, {
            label: "Conta",
            class: "ss-margin-campos",
            rules: [{ required: true }]
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "ant-input",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedCheque.conta) = $event)),
                disabled: !_ctx.props.editavel
              }, null, 8, _hoisted_8), [
                [_vModelText, _ctx.computedCheque.conta]
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_a_form_item, {
            label: "DV",
            class: "ss-margin-campos",
            rules: [{ required: true }]
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "ant-input",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedCheque.contaDv) = $event)),
                disabled: !_ctx.props.editavel
              }, null, 8, _hoisted_10), [
                [_vModelText, _ctx.computedCheque.contaDv]
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_a_form_item, {
            label: "Situação",
            class: "ss-margin-campos",
            rules: [{ required: true }]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_selecionar_situacao_cheque, {
                valor: _ctx.computedCheque.situacao,
                "onUpdate:valor": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedCheque.situacao) = $event)),
                disabled: !_ctx.props.editavel
              }, null, 8, ["valor", "disabled"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_a_form_item, {
            label: "Cidade/Praça",
            class: "ss-margin-campos",
            rules: [{ required: true }]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_selecionar_cidade, {
                apresentarEstado: true,
                codigoSelecionado: _ctx.computedCheque.codigoCidade,
                "onUpdate:codigoSelecionado": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedCheque.codigoCidade) = $event)),
                disabled: !_ctx.props.editavel
              }, null, 8, ["codigoSelecionado", "disabled"])
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_a_form_item, {
            label: "Terceiros",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_selecionar_sim_nao, {
                radioButton: true,
                valor: _ctx.computedCheque.terceiros,
                "onUpdate:valor": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.computedCheque.terceiros) = $event)),
                disabled: !_ctx.props.editavel
              }, null, 8, ["valor", "disabled"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_a_form_item, {
            label: "CPF/CNPJ do Terceiro",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "ant-input",
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.computedCheque.cpfCnpjTerceiro) = $event)),
                disabled: !_ctx.computedCheque.terceiros || !_ctx.props.editavel
              }, null, 8, _hoisted_16), [
                [_vModelText, _ctx.computedCheque.cpfCnpjTerceiro]
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_a_form_item, {
            label: "Nome do Terceiro",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "ant-input",
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.computedCheque.nomeTerceiro) = $event)),
                disabled: !_ctx.computedCheque.terceiros || !_ctx.props.editavel
              }, null, 8, _hoisted_18), [
                [_vModelText, _ctx.computedCheque.nomeTerceiro]
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_a_form_item, {
            label: "Telefone do Terceiro",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_campo_telefone, {
                valor: _ctx.computedCheque.telefoneTerceiro,
                "onUpdate:valor": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.computedCheque.telefoneTerceiro) = $event)),
                disabled: !_ctx.computedCheque.terceiros || !_ctx.props.editavel
              }, null, 8, ["valor", "disabled"])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}