
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { EOperadorLogico } from '@/core/models/Enumeradores/EOperadorLogico';
import ServicoTituloFinanceiroReceber from '@/servicos/Financeiro/ServicoTituloFinanceiroReceber';
import ServicoTituloFinanceiroPagar from '@/servicos/Financeiro/ServicoTituloFinanceiroPagar';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { ECondicao } from '@/core/models/Enumeradores/ECondicao';
import { ETipoDado } from '@/core/models/Enumeradores/ETipoDado';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IParametrosConsultaTituloFinanceiro } from '@/models/ParametrosRequisicao/Financeiro/IParametrosConsultaTituloFinanceiro';

export default defineComponent({
  name: 'SelecionarTituloFinanceiroPorNumero',
  props: {
    tipoTitulo: {
      type: Number,
      required: true,
    },
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    empresas: {
      type: Array as () => number[],
      default: () => [],
    },
    apenasTitulosPendentes: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
    dispararEventoSelecionarTitulo: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'change', 'titulosSelecionados'],
  setup(props, { emit }) {
    const refSelecionarNumeroTituloFinanceiro = ref<HTMLElement | null>(null);
    let debounce = 0;
    const servicoTituloFinanceiroReceber = new ServicoTituloFinanceiroReceber();
    const servicoTituloFinanceiroPagar = new ServicoTituloFinanceiroPagar();

    const state = reactive({
      listaTituloFinanceiros: [] as IOption[],
      listaTitulosLocalizados: [] as IDTOTituloFinanceiro[],
      buscandoDados: false,
      emDigitacao: false,
      keySelect: 1,
    });

    async function obterTituloFinanceiroPorCodigo(valor: any) {
      if (state.emDigitacao) { return; }

      if (props.varios) {
        if (props.codigosSelecionados.length === 0) { return; }
      } else {
        if (!UtilitarioGeral.valorValido(valor)) { return; }
        if (!(valor > 0)) { return; }
        if (props.codigoSelecionado === 0) return;
      }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.listaTituloFinanceiros)) {
        if (props.varios) {
          const listaCodigosConsulta = state.listaTituloFinanceiros.map((c) => c.value);
          const diferencas = props.codigosSelecionados.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const existeLista = state.listaTituloFinanceiros.find((c) => c.value === props.codigoSelecionado);
          if (existeLista !== undefined) {
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.listaTituloFinanceiros = [];

      const parametrosConsulta = {} as IParametrosConsultaTituloFinanceiro;
      parametrosConsulta.apenasTitulosPendentes = props.apenasTitulosPendentes;
      if (UtilitarioGeral.validaLista(props.empresas)) {
        parametrosConsulta.empresas = props.empresas;
      } else {
        parametrosConsulta.empresas = storeSistema.getters.empresasUsuarioAutenticado();
      }

      if (props.varios) {
        parametrosConsulta.codigosSelecionados = valor;
      } else {
        parametrosConsulta.codigosSelecionados = [];
        parametrosConsulta.codigosSelecionados.push(valor);
      }

      let listaPaginada = {} as IListaPaginada;
      if (props.tipoTitulo === ETipoTituloFinanceiro.Receber) {
        listaPaginada = await servicoTituloFinanceiroReceber.buscarTitulos(parametrosConsulta);
      } else if (props.tipoTitulo === ETipoTituloFinanceiro.Pagar) {
        listaPaginada = await servicoTituloFinanceiroPagar.buscarTitulos(parametrosConsulta);
      }

      if (UtilitarioGeral.validaLista(listaPaginada.dados)) {
        state.listaTitulosLocalizados = listaPaginada.dados;
      }
    }

    async function pesquisarTituloFinanceiro(valorPesquisa: any) {
      state.emDigitacao = true;

      if (!UtilitarioGeral.valorValido(valorPesquisa)) { return; }
      state.buscandoDados = true;

      state.listaTituloFinanceiros = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsulta = {} as IParametrosConsultaTituloFinanceiro;
        parametrosConsulta.apenasTitulosPendentes = props.apenasTitulosPendentes;
        if (UtilitarioGeral.validaLista(props.empresas)) {
          parametrosConsulta.empresas = props.empresas;
        } else {
          parametrosConsulta.empresas = storeSistema.getters.empresasUsuarioAutenticado();
        }
        parametrosConsulta.qtdeRegistrosPagina = 10;
        parametrosConsulta.qtdeRegistrosTotal = 10;
        parametrosConsulta.ordenacao = Array<string>();
        parametrosConsulta.ordenacao.push('DataVencimento|ASC');

        const filtros = [] as IFiltroGenerico[];
        const filtroNumeroTitulo = {} as IFiltroGenerico;
        filtroNumeroTitulo.identificador = 'NumeroTitulo';
        filtroNumeroTitulo.tipoDado = ETipoDado.Texto;
        filtroNumeroTitulo.operador = EOperadorLogico.E;
        filtroNumeroTitulo.condicao = ECondicao.Contem;
        filtroNumeroTitulo.valores = [];
        filtroNumeroTitulo.valores.push(valorPesquisa);
        filtros.push(filtroNumeroTitulo);

        let listaPaginada = {} as IListaPaginada;
        if (props.tipoTitulo === ETipoTituloFinanceiro.Receber) {
          listaPaginada = await servicoTituloFinanceiroReceber.buscarTitulos(parametrosConsulta, filtros);
        } else if (props.tipoTitulo === ETipoTituloFinanceiro.Pagar) {
          listaPaginada = await servicoTituloFinanceiroPagar.buscarTitulos(parametrosConsulta, filtros);
        }
        if (UtilitarioGeral.validaLista(listaPaginada.dados)) {
          state.listaTitulosLocalizados = listaPaginada.dados;
        }

        state.buscandoDados = false;
      }, 600);
    }

    function obterTituloFinanceiro():any {
      obterTituloFinanceiroPorCodigo(props.codigoSelecionado);
      return (props.codigoSelecionado === 0 ? undefined : props.codigoSelecionado);
    }

    function disparaEventoSelecaoUnicoTitulo(codigo:number) {
      if (props.dispararEventoSelecionarTitulo) {
        const titulosSelecionados:IDTOTituloFinanceiro[] = [];

        const indexTituloFinanceiro = state.listaTitulosLocalizados.findIndex((c) => c.codigo === codigo);
        if (indexTituloFinanceiro >= 0) {
          titulosSelecionados.push(state.listaTitulosLocalizados[indexTituloFinanceiro]);
          emit('titulosSelecionados', titulosSelecionados);
          state.listaTitulosLocalizados.splice(indexTituloFinanceiro, 1);
          state.keySelect += 1;
        }
      } else {
        emit('update:codigoSelecionado', codigo);
      }
    }
    const computedCodigoSelecionado = computed({
      get: () => obterTituloFinanceiro(),
      set: (val: number) => {
        disparaEventoSelecaoUnicoTitulo(val);
      },
    });

    function obterTitulosFinanceiros():number[] {
      obterTituloFinanceiroPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    function disparaEventoSelecaoVariosCodigos(valor:number[]) {
      if (props.dispararEventoSelecionarTitulo) {
        const titulosSelecionados:IDTOTituloFinanceiro[] = [];

        valor.forEach((codigoTitulo:number) => {
          const tituloFinanceiro = state.listaTitulosLocalizados.find((c) => c.codigo === codigoTitulo);
          if (tituloFinanceiro !== undefined) {
            titulosSelecionados.push(tituloFinanceiro);
          }
        });

        emit('titulosSelecionados', titulosSelecionados);
        emit('update:codigosSelecionados', []);
      }
    }
    const computedCodigosSelecionados = computed({
      get: () => obterTitulosFinanceiros(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
        disparaEventoSelecaoVariosCodigos(val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    function focus() {
      if (refSelecionarNumeroTituloFinanceiro.value) {
        refSelecionarNumeroTituloFinanceiro.value.focus();
      }
    }

    return {
      props,
      state,
      refSelecionarNumeroTituloFinanceiro,
      pesquisarTituloFinanceiro,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      change,
      focus,
      UtilitarioMascara,
      UtilitarioData,
    };
  },
});
