
import { defineComponent, reactive, watch } from 'vue';
import storeSistema from '@/store/storeSistema';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import Totalizador from '@/core/components/Tela/Totalizador.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IDTOMovimentoFinanceiro } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiro';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { EOperadorLogico } from '@/core/models/Enumeradores/EOperadorLogico';
import { useGradeBase } from '@/core/composables/GradeBase';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { ECondicao } from '@/core/models/Enumeradores/ECondicao';
import { ETipoDado } from '@/core/models/Enumeradores/ETipoDado';
import { IParametrosConsultaMovimentoFinanceiro } from '@/models/ParametrosRequisicao/Financeiro/IParametrosConsultaMovimentoFinanceiro';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import { ETipoMovimentoFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoMovimentoFinanceiro';
import ServicoMovimentoFinanceiro from '@/servicos/Financeiro/ServicoMovimentoFinanceiro';
import { EFiltroPeriodoMovimentoConta } from '@/models/Enumeradores/Financeiro/EFiltroPeriodoMovimentoConta';
import { IMovimentoFinanceiro } from '@/models/Entidades/Financeiro/MovimentosFinanceiros/IMovimentoFinanceiro';

export default defineComponent({
  name: 'BuscarMovimentosFinanceirosModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    conta: {
      type: Number,
      default: 0,
    },
    tipoMovimento: {
      type: Number,
      required: true,
    },
    tituloTela: {
      type: String,
      default: 'Buscar Movimentos na Conta',
    },
    movimentoOfx: {
      type: Object as () => IMovimentoFinanceiro,
      default: null,
    },
    apenasUmMovimento: {
      type: Boolean,
      default: false,
    },
    apenasSemNumeroControle: {
      type: Boolean,
      default: false,
    },
    filtros: {
      type: Object as () => IFiltroGenerico[],
      default: [] as IFiltroGenerico[],
    },
    dataEspecifica: {
      type: String,
      default: '',
    },
    omitirMovimentos: {
      type: Object as () => number[],
      default: [] as number[],
    },
    obrigarSelecaoValorEspecifico: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Icone,
    Card,
    Totalizador,
    MensagemSemDados,
  },
  emits: ['confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, preencherEmpresasDisponiveis,
      filtrarPermissaoDadosUsuarioMultiEmpresas,
    } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const {
      gradeBase, defineAlturaScroll,
    } = useGradeBase();
    const servicoMovimentoFinanceiro = new ServicoMovimentoFinanceiro();
    servicoMovimentoFinanceiro.requisicaoSistema();

    const state = reactive({
      movimentosFinanceiros: [] as IDTOMovimentoFinanceiro[],
      buscaRapida: '',
      quantidadeMovimentos: 0,
      totalMovimentos: 0,
      quantidadeMovimentosSelecionados: 0,
      totalMovimentosSelecionados: 0,
      conciliarMovimentoOfx: false,
      apresentarTodosMovimentos: false,
    });
    telaBase.ordenacaoSelecionada = [];
    telaBase.ordenacaoSelecionada.push({ identificador: 'DataMovimento', descricao: 'Data de Movimento', ordem: 'ASC' } as IOrdenacao);
    telaBase.ordenacaoSelecionada.push({ identificador: 'Valor', descricao: 'Valor', ordem: 'ASC' } as IOrdenacao);
    telaBase.ordenacaoSelecionada.push({ identificador: 'CodigoBaixa', descricao: 'Baixa', ordem: 'ASC' } as IOrdenacao);
    function preencherColunas() {
      if (storeSistema.state.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Movimento', dataIndex: 'movimentoFinanceiro', key: 'MovimentoFinanceiro', position: 2, visible: true,
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false, ordering: true,
          },
          {
            title: 'N.Identificação', dataIndex: 'identificadorMovimento', key: 'IdentificadorMovimento', position: 2, visible: true, ordering: true, ellipsis: true, width: 75,
          },
          {
            title: 'Data', dataIndex: 'dataMovimento', key: 'DataMovimento', position: 3, visible: true, ordering: true, customRenderRow: ECustomRenderRow.MascararData, width: 60, align: 'center',
          },
          {
            title: 'Tipo Movimento', dataIndex: 'tipoMovimento', key: 'TipoMovimento', position: 4, visible: true, width: 60, align: 'left',
          },
          {
            title: 'T.Documento', dataIndex: 'descricaoTipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 5, visible: true, ordering: true, width: 40, ellipsis: true,
          },
          {
            title: 'Valor R$', dataIndex: 'valor', key: 'Valor', position: 6, visible: true, ordering: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 65,
          },
          {
            title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 7, visible: true, ordering: true, width: 150, ellipsis: true,
          },
          {
            title: 'Categoria', dataIndex: 'nomePlanoContaCategoria', key: 'NomePlanoContaCategoria', position: 8, visible: true, ellipsis: true, width: 100,
          },
        ];
      }
    }
    function atualizarTotalizadores() {
      state.quantidadeMovimentos = 0;
      state.totalMovimentos = 0;
      state.quantidadeMovimentosSelecionados = 0;
      state.totalMovimentosSelecionados = 0;

      state.movimentosFinanceiros.forEach((movimentoFinanceiro) => {
        state.quantidadeMovimentos += 1;
        state.totalMovimentos += movimentoFinanceiro.valor;

        const index = gradeBase.codigosSelecionados.findIndex((c) => c === movimentoFinanceiro.codigo);
        if (index >= 0) {
          if (props.tipoMovimento === ETipoMovimentoFinanceiro.Recebimento) {
            if (movimentoFinanceiro.tipoMovimento === ETipoMovimentoFinanceiro.Recebimento) {
              state.totalMovimentosSelecionados += movimentoFinanceiro.valor;
            } else {
              state.totalMovimentosSelecionados -= movimentoFinanceiro.valor;
            }
          } else if (props.tipoMovimento === ETipoMovimentoFinanceiro.Pagamento) {
            if (movimentoFinanceiro.tipoMovimento === ETipoMovimentoFinanceiro.Pagamento) {
              state.totalMovimentosSelecionados += movimentoFinanceiro.valor;
            } else {
              state.totalMovimentosSelecionados -= movimentoFinanceiro.valor;
            }
          }
          state.quantidadeMovimentosSelecionados += 1;
        }
      });
    }
    const mudarSelecao = (selectedRowKeys: any) => {
      if (props.apenasUmMovimento) {
        gradeBase.codigosSelecionados = [selectedRowKeys[selectedRowKeys.length - 1]];
      } else {
        gradeBase.codigosSelecionados = selectedRowKeys;
      }
      atualizarTotalizadores();
    };

    async function buscarMovimentos() {
      state.movimentosFinanceiros = [];
      gradeBase.filtrosAplicados = [];
      state.quantidadeMovimentos = 0;
      state.totalMovimentos = 0;
      state.quantidadeMovimentosSelecionados = 0;
      state.totalMovimentosSelecionados = 0;

      if (UtilitarioGeral.validaLista(props.omitirMovimentos)) {
        const filtroCodigoTitulo = {} as IFiltroGenerico;
        filtroCodigoTitulo.identificador = 'Codigo';
        filtroCodigoTitulo.tipoDado = ETipoDado.Longo;
        filtroCodigoTitulo.operador = EOperadorLogico.E;
        filtroCodigoTitulo.condicao = ECondicao.NaoEstaEntre;
        filtroCodigoTitulo.valores = [];
        props.omitirMovimentos.forEach((codigoTitulo) => {
          filtroCodigoTitulo.valores.push(String(codigoTitulo));
        });

        gradeBase.filtrosAplicados.push(filtroCodigoTitulo);
      }
      const parametrosConsulta = {} as IParametrosConsultaMovimentoFinanceiro;
      parametrosConsulta.empresa = props.empresa;
      if (UtilitarioGeral.validaCodigo(props.conta)) {
        parametrosConsulta.conta = props.conta;
      }
      if (UtilitarioGeral.validaCodigo(props.tipoMovimento)) {
        if (!state.apresentarTodosMovimentos) {
          parametrosConsulta.tipoMovimento = props.tipoMovimento;
        }
      }
      parametrosConsulta.buscaRapida = state.buscaRapida;
      parametrosConsulta.apenasSemNumeroControle = props.apenasSemNumeroControle;

      if (UtilitarioGeral.valorValido(props.dataEspecifica)) {
        parametrosConsulta.periodo = EFiltroPeriodoMovimentoConta.Personalizado;
        parametrosConsulta.dataInicial = props.dataEspecifica;
        parametrosConsulta.dataFinal = props.dataEspecifica;
      }
      parametrosConsulta.ordenacao = Array<string>();
      telaBase.ordenacaoSelecionada.forEach((ordenacao) => {
        parametrosConsulta.ordenacao.push(`${ordenacao.identificador}|${ordenacao.ordem}`);
      });

      telaBase.carregando = true;
      const extratoMovimentacoes = await servicoMovimentoFinanceiro.buscarMovimentos(parametrosConsulta, gradeBase.filtrosAplicados);

      if (UtilitarioGeral.validaLista(extratoMovimentacoes.movimentos)) {
        state.movimentosFinanceiros = extratoMovimentacoes.movimentos;
        atualizarTotalizadores();
      }

      telaBase.carregando = false;
    }

    function limparTela() {
      state.movimentosFinanceiros = [];
      state.apresentarTodosMovimentos = false;
      telaBase.identificadorRecurso = 'MOVIMENTACOES_CONTAS';
      telaBase.identificadorPermissao = 'PER_MOVIMENTACOES_CONTAS';
      preencherColunas();
      gradeBase.codigosSelecionados = [];
    }

    function confirmar() {
      state.buscaRapida = '';
      const selecionados = state.movimentosFinanceiros.filter((t) => gradeBase.codigosSelecionados.includes(t.codigo));
      emit('confirmacao', selecionados);
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        await preencherEmpresasDisponiveis();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas([props.empresa]);

        if (UtilitarioGeral.objetoValido(props.movimentoOfx)) {
          if (UtilitarioGeral.valorValido(props.movimentoOfx.identificadorMovimento)) {
            state.conciliarMovimentoOfx = true;
          }
        }
        if (UtilitarioGeral.valorValido(props.dataEspecifica)) {
          await buscarMovimentos();
        }
      }
      telaBase.carregando = false;
    });

    watch(() => storeSistema.state.layoutMobile, () => {
      preencherColunas();
    });

    return {
      props,
      storeSistema,
      state,
      telaBase,
      modalBase,
      gradeBase,
      ECustomRenderRow,
      ETipoMovimentoFinanceiro,
      window,
      UtilitarioGeral,
      UtilitarioMascara,
      UtilitarioData,
      buscarMovimentos,
      mudarSelecao,
      confirmar,
      defineAlturaScroll,
    };
  },
});
