import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOClienteFinanceiro } from '@/models/DTO/Cadastros/Pessoas/Clientes/IDTOClienteFinanceiro';
import { IDTOBaixaTitulosEstornoParcial } from '@/models/DTO/Financeiro/BaixasTitulosFinanceiros/IDTOBaixaTitulosEstornoParcial';
import { IDTOBaixaTitulosOrdenarVencimento } from '@/models/DTO/Financeiro/BaixasTitulosFinanceiros/IDTOBaixaTitulosOrdenarVencimento';
import { IDTOMovimentoFinanceiro } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiro';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { IDTOTituloFinanceiroBaixado } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroBaixado';
import { IDTOTituloFinanceiroPendenteBaixa } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroPendenteBaixa';
import { IBaixaTitulos } from '@/models/Entidades/Financeiro/BaixasTitulosFinanceiros/IBaixaTitulosFinanceiros';

/**
 * Serviço de Baixas de Títulos Financeiros
 * Fornece todas regras de negócios e lógicas relacionado a baixa de títulos financeiros.
 */

class ServicoBaixaTitulosReceber {
    endPoint = 'baixas-titulos-receber';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async baixarTitulos(baixa: IBaixaTitulos): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, baixa);
      return result.data;
    }

    public async lancarBaixasTitulos(baixas: IBaixaTitulos[]): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/baixas-titulos`, baixas);
      return result.data;
    }

    public async ordernarTitulosVencimento(baixaTitulosOrdenar: IDTOBaixaTitulosOrdenarVencimento): Promise<IDTOTituloFinanceiroPendenteBaixa[]> {
      const result: any = await this.apiERP.post(`${this.endPoint}/ordernar-vencimento`, baixaTitulosOrdenar);
      return result.data;
    }

    public async validarAlteracaoPercentuais(empresa: number): Promise<boolean> {
      const result: any = await this.apiERP.get(`${this.endPoint}/alteracao-percentuais/empresa/${empresa}`);
      return result.data;
    }

    public async obterDadosFinanceirosClientes(pessoas: number[], empresa: number): Promise<IDTOClienteFinanceiro[]> {
      let parametrosAdicionais = '';
      for (let contador = 0; contador < pessoas.length; contador += 1) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Pessoas=${pessoas[contador]}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/financeiro-cliente/empresa/${empresa}${parametrosAdicionais}`);
      return result.data;
    }

    public async estonarBaixa(baixa: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/estorno-baixa/${baixa}/empresa/${empresa}`);
      return result.data;
    }

    public async estonoParcialBaixa(estornoParcial: IDTOBaixaTitulosEstornoParcial): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/estorno-baixa/parcial`, estornoParcial);
      return result.data;
    }

    public async obterMovimentosFinanceiros(baixa: number, empresa: number): Promise<IDTOMovimentoFinanceiro[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/movimentos-financeiros/${baixa}/empresa/${empresa}`);
      return result.data;
    }

    public async obterTitulosLancados(baixa: number, empresa: number): Promise<IDTOTituloFinanceiro[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/titulos-lancados/${baixa}/empresa/${empresa}`);
      return result.data;
    }

    public async obterTitulosBaixados(baixa: number, empresa: number): Promise<IDTOTituloFinanceiroBaixado[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/titulos-baixados/${baixa}/empresa/${empresa}`);
      return result.data;
    }
}
export default ServicoBaixaTitulosReceber;
