import { reactive, readonly } from 'vue';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { IDTOTituloFinanceiroStore } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloStore';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTOComunicacaoFinanceiroStore } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOComunicacaoFinanceiroStore';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';

const state = reactive({
  titulos: [] as IDTOTituloFinanceiroStore[],
  comunicacoes: [] as IDTOComunicacaoFinanceiroStore[],
});

const getters = {
  obterTitulos(token: string):IDTOTituloFinanceiro[] {
    if (UtilitarioGeral.validaLista(state.titulos)) {
      const indexTitulos = state.titulos.findIndex((c) => c.token === token);
      if (indexTitulos >= 0) {
        return state.titulos[indexTitulos].titulos;
      }
    }

    return [] as IDTOTituloFinanceiro[];
  },
  obterTituloFinanceiroStore(token: string):IDTOTituloFinanceiroStore {
    if (UtilitarioGeral.validaLista(state.titulos)) {
      const dadosStore = state.titulos.find((c) => c.token === token);
      if (dadosStore !== undefined) {
        return dadosStore;
      }
    }

    return {} as IDTOTituloFinanceiroStore;
  },
  obterComunicacao(token: string):IDTOComunicacaoFinanceiroStore {
    if (UtilitarioGeral.validaLista(state.comunicacoes)) {
      const indexComunicacao = state.comunicacoes.findIndex((c) => c.token === token);
      if (indexComunicacao >= 0) {
        return state.comunicacoes[indexComunicacao];
      }
    }

    return {} as IDTOComunicacaoFinanceiroStore;
  },
};

const mutations = {
  adicionarComunicacao(empresa: number, conta: number, redirecionarAposConcluir: string):string {
    const comunicacaoFinanceiroStore: IDTOComunicacaoFinanceiroStore = {} as IDTOComunicacaoFinanceiroStore;
    comunicacaoFinanceiroStore.empresa = empresa;
    comunicacaoFinanceiroStore.conta = conta;
    comunicacaoFinanceiroStore.redirecionarAposConcluir = redirecionarAposConcluir;
    comunicacaoFinanceiroStore.token = UtilitarioGeral.gerarTokenUnico();
    state.comunicacoes.push(comunicacaoFinanceiroStore);
    return comunicacaoFinanceiroStore.token;
  },
  removerComunicacao(token: string):void {
    if (UtilitarioGeral.validaLista(state.comunicacoes)) {
      const indexComunicacao = state.comunicacoes.findIndex((c) => c.token === token);
      if (indexComunicacao >= 0) {
        state.comunicacoes.splice(indexComunicacao, 1);
      }
    }
  },
  adicionarTituloStore(tituloFinanceiroStore:IDTOTituloFinanceiroStore):void {
    state.titulos.push(tituloFinanceiroStore);
  },
  adicionarTitulosComFiltros(token: string, rotaRecursoOrigem: string, detalhes: string, titulos: IDTOTituloFinanceiro[], buscaRapida: string, buscaAvancada: IBuscaAvancada):void {
    const tituloFinanceiroStore: IDTOTituloFinanceiroStore = {} as IDTOTituloFinanceiroStore;
    tituloFinanceiroStore.token = token;
    tituloFinanceiroStore.rotaRecursoOrigem = rotaRecursoOrigem;
    tituloFinanceiroStore.buscaRapida = buscaRapida;
    tituloFinanceiroStore.buscaAvancada = buscaAvancada;
    tituloFinanceiroStore.detalhes = detalhes;
    tituloFinanceiroStore.titulos = titulos;
    state.titulos.push(tituloFinanceiroStore);
  },
  adicionarTitulos(token: string, rotaRecursoOrigem: string, detalhes: string, titulos: IDTOTituloFinanceiro[]):void {
    const tituloFinanceiroStore: IDTOTituloFinanceiroStore = {} as IDTOTituloFinanceiroStore;
    tituloFinanceiroStore.token = token;
    tituloFinanceiroStore.rotaRecursoOrigem = rotaRecursoOrigem;
    tituloFinanceiroStore.detalhes = detalhes;
    tituloFinanceiroStore.titulos = titulos;
    state.titulos.push(tituloFinanceiroStore);
  },
  removerTitulos(token: string):void {
    if (UtilitarioGeral.validaLista(state.titulos)) {
      const indexTitulos = state.titulos.findIndex((c) => c.token === token);
      if (indexTitulos >= 0) {
        state.titulos.splice(indexTitulos, 1);
      }
    }
  },
};

const actions = {
};

export default {
  state: readonly(state),
  getters,
  mutations,
  actions,
};
