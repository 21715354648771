
import { defineComponent, reactive, computed } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoTelefone from '@/core/components/Tela/CampoTelefone.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import SelecionarBanco from '@/components/Cadastros/Financeiro/SelecionarBanco.vue';
import SelecionarSituacaoCheque from '@/components/Financeiro/TitulosFinanceiros/SelecionarSituacaoCheque.vue';
import SelecionarCidade from '@/components/Cadastros/Localizacoes/Cidades/SelecionarCidade.vue';
import { ITituloFinanceiroCheque } from '@/models/Entidades/Financeiro/TitulosFinanceiros/ITituloFinanceiro';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';

export default defineComponent({
  name: 'TituloFinanceiroCheque',
  props: {
    codigoRegistro: {
      type: Number,
      required: true,
    },
    empresa: {
      type: Number,
      required: true,
    },
    cheque: {
      type: Object as () => ITituloFinanceiroCheque,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Card,
    CampoTelefone,
    SelecionarSimNao,
    SelecionarBanco,
    SelecionarSituacaoCheque,
    SelecionarCidade,
  },
  emits: ['update:cheque'],
  setup(props, { emit }) {
    const state = reactive({
    });

    const computedCheque = computed({
      get: () => props.cheque,
      set: (cheque: ITituloFinanceiroCheque) => {
        emit('update:cheque', cheque);
      },
    });

    return {
      props,
      state,
      computedCheque,
      ETipoTituloFinanceiro,
    };
  },
});
