
import { Modal } from 'ant-design-vue';
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Card from '@/core/components/Tela/Card.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import ServicoMovimentoFinanceiro from '@/servicos/Financeiro/ServicoMovimentoFinanceiro';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import { IDTOTituloFinanceiroBaixado } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroBaixado';
import SelecionarTipoValorFinanceiro from '@/components/Financeiro/SelecionarTipoValorFinanceiro.vue';
import SelecionarUsuario from '@/components/MeuSistema/Usuarios/SelecionarUsuario.vue';
import SelecionarTipoTituloFinanceiro from '@/components/Financeiro/TitulosFinanceiros/SelecionarTipoTituloFinanceiro.vue';
import { IBaixaTitulos } from '@/models/Entidades/Financeiro/BaixasTitulosFinanceiros/IBaixaTitulosFinanceiros';
import Totalizador from '@/core/components/Tela/Totalizador.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { IDTOMovimentoFinanceiro } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiro';
import ServicoBaixaTitulosPagar from '@/servicos/Financeiro/ServicoBaixaTitulosPagar';
import ServicoBaixaTitulosReceber from '@/servicos/Financeiro/ServicoBaixaTitulosReceber';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import ContaReceberModal from '@/views/Financeiro/ContasReceber/ContaReceberModal.vue';
import ContaPagarModal from '@/views/Financeiro/ContasPagar/ContaPagarModal.vue';
import MovimentoFinanceiroModal from '@/views/Financeiro/MovimentosFinanceiros/MovimentoFinanceiroModal.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { useGradeBase } from '@/core/composables/GradeBase';
import { ECustomRenderRow, IColumn } from '@/core/models/AntDesign/IColumn';
import { ETipoMovimentoFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoMovimentoFinanceiro';
import storeSistema from '@/store/storeSistema';
import { IDTOBaixaTitulosEstornoParcial } from '@/models/DTO/Financeiro/BaixasTitulosFinanceiros/IDTOBaixaTitulosEstornoParcial';

export default defineComponent({
  name: 'BaixaTitulosModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      default: 0,
      required: true,
    },
    movimento: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    SelecionarTipoValorFinanceiro,
    SelecionarUsuario,
    SelecionarTipoTituloFinanceiro,
    SelecionarData,
    CampoNumerico,
    Totalizador,
    MensagemSemDados,
    ContaReceberModal,
    ContaPagarModal,
    MovimentoFinanceiroModal,
    RequisicaoModal,
  },
  emits: ['update:operacao', 'update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis, defineEmpresasSelecionadasComPermissao, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const {
      gradeBase,
    } = useGradeBase();

    const servicoMovimentoFinanceiro = new ServicoMovimentoFinanceiro();
    const servicoBaixaTitulosPagar = new ServicoBaixaTitulosPagar();
    const servicoBaixaTitulosReceber = new ServicoBaixaTitulosReceber();
    telaBase.identificadorRecurso = 'MOVIMENTACOES_CONTAS';
    telaBase.identificadorPermissao = 'PER_MOVIMENTACOES_CONTAS';

    const state = reactive({
      baixa: {} as IBaixaTitulos,
      titulosBaixados: [] as IDTOTituloFinanceiroBaixado[],
      titulosLancados: [] as IDTOTituloFinanceiro[],
      movimentosLancados: [] as IDTOMovimentoFinanceiro[],
      contaSelecionada: 0,
      carregandoTitulosBaixados: false,
      carregandoTitulosLancados: false,
      carregandoMovimentosLancados: false,
      exibirContaReceber: false,
      exibirContaPagar: false,
      exibirMovimentoFinanceiro: false,
      exibirEstorno: false,
      operacaoLancamento: {} as ITelaOperacao,
      colunasLancados: [] as IColumn[],
      colunasMovimentos: [] as IColumn[],
      codigosTitulosFinanceirosEstorno: [] as number[],
      estornoParcialDisponivel: false,
    });

    function preencherColunas() {
      if (storeSistema.state.layoutMobile) {
        // Colunas - Títulos Baixados
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigoTituloFinanceiro', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Título', dataIndex: 'titulo', key: 'TituloFinanceiro', position: 2, visible: true,
          },
        ];
        // Colunas - Títulos Lançados
        state.colunasLancados = [
          {
            title: 'Titulo', dataIndex: 'titulo', key: 'Titulo', position: 1, visible: true,
          },
        ];
        // Colunas - Movimentos da Baixa
        state.colunasMovimentos = [
          {
            title: 'Movimento', dataIndex: 'movimento', key: 'MovimentoBaixa', position: 1, visible: true,
          },
        ];
      } else {
        // Colunas - Títulos Baixados
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigoTituloFinanceiro', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'PGTO', dataIndex: 'tipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 2, visible: true, width: 40, ellipsis: true, fixed: 'left',
          },
          {
            title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 3, visible: true, ellipsis: true, width: 75, fixed: 'left',
          },
          {
            title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 4, visible: true, ellipsis: true, width: 150, fixed: 'left',
          },
          {
            title: 'Vencimento', dataIndex: 'dataVencimento', key: 'DataVencimento', position: 5, visible: true, ordering: false, customRenderRow: ECustomRenderRow.MascararData, width: 60, align: 'center', fixed: 'left',
          },
          {
            title: 'Juros/Multa R$', dataIndex: 'valorJurosMulta', key: 'ValorJurosMulta', position: 6, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 75,
          },
          {
            title: 'Acréscimo R$', dataIndex: 'valorAcrescimo', key: 'ValorAcrescimo', position: 7, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 70,
          },
          {
            title: 'Desconto R$', dataIndex: 'valorDesconto', key: 'ValorDesconto', position: 8, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 65,
          },
          {
            title: 'Abatimento R$', dataIndex: 'valorAbatimento', key: 'ValorAbatimento', position: 9, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 65,
          },
          {
            title: 'Valor Pago R$', dataIndex: 'valorPago', key: 'ValorPago', position: 10, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 80,
          },
          {
            title: 'Taxas R$', dataIndex: 'valorTaxa', key: 'ValorTaxa', position: 11, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 65,
          },
          {
            title: 'Conta', dataIndex: 'descricaoConta', key: 'Conta', position: 12, visible: true, ellipsis: true, width: 150,
          },
          {
            title: 'Ações', dataIndex: 'acoes', key: 'Acoes', position: 13, visible: true, width: 43, fixed: 'right', align: 'center',
          },
        ];

        state.colunasLancados = [
          {
            title: 'Tipo', dataIndex: 'descricaoTipo', key: 'Tipo', position: 1, visible: true, width: 40, ellipsis: true,
          },
          {
            title: 'PGTO', dataIndex: 'descricaoTipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 2, visible: true, width: 40, ellipsis: true,
          },
          {
            title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 3, visible: true, ellipsis: true, width: 75,
          },
          {
            title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 4, visible: true, ellipsis: true, width: 150,
          },
          {
            title: 'Vencimento', dataIndex: 'dataVencimento', key: 'DataVencimento', position: 5, visible: true, ordering: false, customRenderRow: ECustomRenderRow.MascararData, width: 60, align: 'center',
          },
          {
            title: 'Valor R$', dataIndex: 'valorTotal', key: 'Valor', position: 6, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 75,
          },
          {
            title: 'Conta', dataIndex: 'descricaoConta', key: 'Conta', position: 7, visible: true, ellipsis: true, width: 150,
          },
          {
            title: 'Ações', dataIndex: 'acoes', key: 'Acoes', position: 8, visible: true, width: 27, fixed: 'right', align: 'center',
          },
        ];

        // Colunas - Movimentos da Baixa
        state.colunasMovimentos = [
          {
            title: 'Conta', dataIndex: 'descricaoConta', key: 'Conta', position: 1, visible: true, ellipsis: true, width: 100,
          },
          {
            title: 'Data', dataIndex: 'dataMovimento', key: 'DataMovimento', position: 2, visible: true, ordering: false, customRenderRow: ECustomRenderRow.MascararData, width: 35, align: 'center',
          },
          {
            title: 'Tipo', dataIndex: 'descricaoTipoMovimento', key: 'DescricaoTipoMovimento', position: 3, visible: true, width: 40, ellipsis: true,
          },
          {
            title: 'Identificador', dataIndex: 'identificadorMovimento', key: 'IdentificadorMovimento', position: 4, visible: true, ellipsis: true, width: 75,
          },
          {
            title: 'Categoria', dataIndex: 'categoria', key: 'CategoriaMovimento', position: 5, visible: true, ellipsis: true, width: 90,
          },
          {
            title: 'Valor R$', dataIndex: 'valor', key: 'ValorMovimento', position: 6, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 65,
          },
          {
            title: 'Influência Saldo', dataIndex: 'influenciaSaldo', key: 'InfluenciaSaldo', position: 7, visible: true, ordering: false, width: 45, align: 'center',
          },
          {
            title: 'Ações', dataIndex: 'acoes', key: 'Acoes', position: 8, visible: true, width: 25, fixed: 'right', align: 'center',
          },
        ];
      }
    }
    preencherColunas();
    function limparTela() {
      state.baixa = {} as IBaixaTitulos;
      state.titulosBaixados = [] as IDTOTituloFinanceiroBaixado[];
      state.titulosLancados = [] as IDTOTituloFinanceiro[];
      state.movimentosLancados = [] as IDTOMovimentoFinanceiro[];
      state.exibirContaReceber = false;
      state.exibirContaPagar = false;
      state.exibirEstorno = false;
      state.operacaoLancamento = {} as ITelaOperacao;
      state.codigosTitulosFinanceirosEstorno = [];
      state.estornoParcialDisponivel = false;
    }

    async function validarPermissaoEstorno() {
      state.exibirEstorno = await servicoMovimentoFinanceiro.validarPermissaoEstorno(props.empresa);
    }

    async function obterBaixa() {
      state.baixa = await servicoMovimentoFinanceiro.obterBaixaTitulos(props.movimento, props.empresa);
    }

    async function obterTitulosBaixados() {
      state.carregandoTitulosBaixados = true;
      state.titulosBaixados = [];
      let tituloBaixados:IDTOTituloFinanceiroBaixado[] = [];
      if (state.baixa.tipoTituloFinanceiro === ETipoTituloFinanceiro.Pagar) {
        tituloBaixados = await servicoBaixaTitulosPagar.obterTitulosBaixados(state.baixa.codigo, props.empresa);
      } else {
        tituloBaixados = await servicoBaixaTitulosReceber.obterTitulosBaixados(state.baixa.codigo, props.empresa);
      }

      state.titulosBaixados = tituloBaixados.filter((c) => c.movimentoAdicional === false);

      state.carregandoTitulosBaixados = false;
    }

    async function obterTitulosLancados() {
      state.carregandoTitulosLancados = true;
      if (state.baixa.tipoTituloFinanceiro === ETipoTituloFinanceiro.Pagar) {
        state.titulosLancados = await servicoBaixaTitulosPagar.obterTitulosLancados(state.baixa.codigo, props.empresa);
      } else {
        state.titulosLancados = await servicoBaixaTitulosReceber.obterTitulosLancados(state.baixa.codigo, props.empresa);
      }
      state.carregandoTitulosLancados = false;
    }

    async function obterMovimentosLancados() {
      state.carregandoMovimentosLancados = true;
      if (state.baixa.tipoTituloFinanceiro === ETipoTituloFinanceiro.Pagar) {
        state.movimentosLancados = await servicoBaixaTitulosPagar.obterMovimentosFinanceiros(state.baixa.codigo, props.empresa);
      } else {
        state.movimentosLancados = await servicoBaixaTitulosReceber.obterMovimentosFinanceiros(state.baixa.codigo, props.empresa);
      }
      state.carregandoMovimentosLancados = false;
    }

    function visualizarTituloLancado(titulo: IDTOTituloFinanceiro) {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Visualizar;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = titulo.codigo;
      state.operacaoLancamento.empresaSelecionada = props.empresa;
      if (titulo.tipo === ETipoTituloFinanceiro.Receber) {
        state.exibirContaReceber = true;
      } else {
        state.exibirContaPagar = true;
      }
    }

    function visualizarTituloBaixado(titulo: IDTOTituloFinanceiroBaixado) {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Visualizar;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = titulo.codigoTituloFinanceiro;
      state.operacaoLancamento.empresaSelecionada = props.empresa;
      if (titulo.tipo === ETipoTituloFinanceiro.Receber) {
        state.exibirContaReceber = true;
      } else {
        state.exibirContaPagar = true;
      }
    }

    function visualizarMovimento(movimento: IDTOMovimentoFinanceiro) {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Visualizar;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = movimento.codigo;
      state.operacaoLancamento.empresaSelecionada = props.empresa;
      state.exibirMovimentoFinanceiro = true;
    }

    async function estornarBaixa() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso();
      retorno = await servicoBaixaTitulosReceber.estonarBaixa(state.baixa.codigo, state.baixa.codigoEmpresa);
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        emit('confirmacao');
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function estornoParcialPorTituloEspecificoBaixa(codigoTituloFinanceiro: number) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso();
      const estornoParcial:IDTOBaixaTitulosEstornoParcial = {} as IDTOBaixaTitulosEstornoParcial;
      estornoParcial.codigoEmpresa = state.baixa.codigoEmpresa;
      estornoParcial.codigoBaixa = state.baixa.codigo;
      estornoParcial.codigosTitulosFinanceirosEstornar = [];
      estornoParcial.codigosTitulosFinanceirosEstornar.push(codigoTituloFinanceiro);
      retorno = await servicoBaixaTitulosReceber.estonoParcialBaixa(estornoParcial);
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        emit('confirmacao');
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function estornoParcialPorTitulosSelecionadosBaixa() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso();
      const estornoParcial:IDTOBaixaTitulosEstornoParcial = {} as IDTOBaixaTitulosEstornoParcial;
      estornoParcial.codigoEmpresa = state.baixa.codigoEmpresa;
      estornoParcial.codigoBaixa = state.baixa.codigo;
      estornoParcial.codigosTitulosFinanceirosEstornar = state.codigosTitulosFinanceirosEstorno;
      retorno = await servicoBaixaTitulosReceber.estonoParcialBaixa(estornoParcial);
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        emit('confirmacao');
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function confirmarBaixa() {
      Modal.confirm({
        title: 'Atenção',
        content: 'Você confirma o estorno da baixa?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        centered: true,
        autoFocusButton: null,
        onOk: async () => { await estornarBaixa(); },
      });
    }

    async function confirmarEstornoTituloBaixa(numeroTitulo:string, codigoTituloFinanceiro:number) {
      Modal.confirm({
        title: 'Atenção',
        content: `Você confirma o estorno do título: ${numeroTitulo} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        centered: true,
        autoFocusButton: null,
        onOk: async () => { await estornoParcialPorTituloEspecificoBaixa(codigoTituloFinanceiro); },
      });
    }

    async function confirmarEstornoTitulosSelecionados() {
      Modal.confirm({
        title: 'Atenção',
        content: 'Você confirma o estorno dos títulos selecionados?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        centered: true,
        autoFocusButton: null,
        onOk: async () => { await estornoParcialPorTitulosSelecionadosBaixa(); },
      });
    }

    function obterDescricaoTipoMovimento(tipo: ETipoMovimentoFinanceiro) {
      switch (tipo) {
        case ETipoMovimentoFinanceiro.Recebimento:
          return 'Recebimento';
        case ETipoMovimentoFinanceiro.Pagamento:
          return 'Pagamento';
        default:
          return '';
      }
    }

    function obterDescricaoTipoTituloFinanceiro(tipoTitulo: ETipoTituloFinanceiro) {
      switch (tipoTitulo) {
        case ETipoTituloFinanceiro.Receber:
          return 'Receber';
        case ETipoTituloFinanceiro.Pagar:
          return 'Pagar';
        default:
          return '';
      }
    }

    function obterDescricaoInfluenciaSaldo(influenciaSaldo:boolean) {
      return influenciaSaldo ? 'Sim' : 'Não';
    }

    function obterTotalPago(titulo:IDTOTituloFinanceiroBaixado): number {
      return ((titulo.valorPago + titulo.valorJurosMulta + titulo.valorAcrescimo) - titulo.valorDesconto);
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      if (modalBase.computedVisivel) {
        telaBase.carregando = true;
        await obterPermissoes(ETipoPermissao.Dados);
        await preencherEmpresasDisponiveis();
        await defineEmpresasSelecionadasComPermissao();
        validarPermissaoEstorno();
        await obterBaixa();
        await obterTitulosBaixados();
        await obterTitulosLancados();
        await obterMovimentosLancados();

        if (UtilitarioGeral.validaLista(state.titulosLancados)) {
          state.estornoParcialDisponivel = false;
        } else if (UtilitarioGeral.validaLista(state.baixa.totalTitulosRepassados > 0)) {
          state.estornoParcialDisponivel = false;
        } else if (UtilitarioGeral.validaLista(state.baixa.totalCreditosUtilizados > 0)) {
          state.estornoParcialDisponivel = false;
        } else if (UtilitarioGeral.validaLista(state.baixa.totalSobraValores > 0)) {
          state.estornoParcialDisponivel = false;
        } else if (state.titulosBaixados.length === 1) {
          state.estornoParcialDisponivel = false;
        } else if (state.titulosBaixados.some((c) => c.possibilitarEstornoParcial === false)) {
          state.estornoParcialDisponivel = false;
        } else {
          state.estornoParcialDisponivel = true;
        }

        telaBase.carregando = false;
      }
    });

    watch(() => storeSistema.state.layoutMobile, () => {
      preencherColunas();
    });

    const mudarSelecao = (selectedRowKeys: any) => {
      state.codigosTitulosFinanceirosEstorno = selectedRowKeys;
    };

    return {
      props,
      state,
      telaBase,
      gradeBase,
      modalBase,
      storeSistema,
      UtilitarioGeral,
      UtilitarioData,
      UtilitarioMascara,
      ETipoTituloFinanceiro,
      ECustomRenderRow,
      apresentarRetornoRequisicao,
      visualizarTituloLancado,
      visualizarTituloBaixado,
      visualizarMovimento,
      confirmarBaixa,
      obterDescricaoTipoMovimento,
      obterDescricaoInfluenciaSaldo,
      obterDescricaoTipoTituloFinanceiro,
      obterTotalPago,
      confirmarEstornoTituloBaixa,
      confirmarEstornoTitulosSelecionados,
      mudarSelecao,
    };
  },
});
