import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selecionar_tipo_documento_fiscal = _resolveComponent("selecionar-tipo-documento-fiscal")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_data = _resolveComponent("selecionar-data")!
  const _component_selecionar_tipo_movimento_comercial = _resolveComponent("selecionar-tipo-movimento-comercial")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, { titulo: " " }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_form_item, {
            label: "Tipo de Documento Fiscal",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_selecionar_tipo_documento_fiscal, {
                valor: _ctx.computedDocumentoFiscal.tipoDocumento,
                "onUpdate:valor": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedDocumentoFiscal.tipoDocumento) = $event)),
                disabled: !_ctx.props.editavel
              }, null, 8, ["valor", "disabled"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_form_item, {
            label: "Emissão",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_selecionar_data, {
                dataSelecionada: _ctx.computedDocumentoFiscal.dataEmissao,
                "onUpdate:dataSelecionada": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedDocumentoFiscal.dataEmissao) = $event)),
                disabled: !_ctx.props.editavel
              }, null, 8, ["dataSelecionada", "disabled"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_a_form_item, {
            label: _ctx.obtemApresentacaoNumeroDocumento(_ctx.computedDocumentoFiscal.tipoDocumento),
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("input", {
                type: "number",
                min: "1",
                style: {"text-align":"right"},
                class: "ant-input",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedDocumentoFiscal.numeroDocumentoFiscal) = $event)),
                disabled: !_ctx.props.editavel
              }, null, 8, _hoisted_5), [
                [_vModelText, _ctx.computedDocumentoFiscal.numeroDocumentoFiscal]
              ])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_a_form_item, {
            label: "Relacionado a",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_selecionar_tipo_movimento_comercial, {
                valor: _ctx.computedDocumentoFiscal.origem,
                "onUpdate:valor": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedDocumentoFiscal.origem) = $event)),
                disabled: !_ctx.props.editavel
              }, null, 8, ["valor", "disabled"])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}