
import { defineComponent, reactive, onBeforeMount } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import TotalizadorDecorado from '@/core/components/Tela/TotalizadorDecorado.vue';
import ServicoTituloFinanceiroPagar from '@/servicos/Financeiro/ServicoTituloFinanceiroPagar';
import ServicoTituloFinanceiroReceber from '@/servicos/Financeiro/ServicoTituloFinanceiroReceber';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';

export default defineComponent({
  name: 'TituloFinanceiroTotalizadores',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    tipo: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    TotalizadorDecorado,
  },
  emits: ['filtrarTitulos'],
  setup(props, { emit }) {
    const servicoTituloFinanceiroPagar = new ServicoTituloFinanceiroPagar();
    const servicoTituloFinanceiroReceber = new ServicoTituloFinanceiroReceber();
    const servicoSistema = new ServicoSistema();

    const state = reactive({
      dataAtual: '',
      vencerHoje: 0,
      vencerMes: 0,
      vencidos: 0,
      baixadosHoje: 0,
      baixadosMes: 0,
      baixadosVencidos: 0,
      carregando: false,
      filtros: [] as IFiltroGenerico[],
    });

    async function obterValores() {
      state.carregando = true;

      state.dataAtual = await servicoSistema.obterDataAtual();
      if (props.tipo === ETipoTituloFinanceiro.Pagar) {
        state.vencerHoje = await servicoTituloFinanceiroPagar.obterValorVencerHoje(props.empresa);
        state.vencerMes = await servicoTituloFinanceiroPagar.obterValorVencerEsseMes(props.empresa);
        state.vencidos = await servicoTituloFinanceiroPagar.obterValorVencidos(props.empresa);
        state.baixadosHoje = await servicoTituloFinanceiroPagar.obterValorBaixadosHoje(props.empresa);
        state.baixadosMes = await servicoTituloFinanceiroPagar.ObterValorBaixadosEsseMes(props.empresa);
        state.baixadosVencidos = await servicoTituloFinanceiroPagar.ObterValorBaixadosVencidos(props.empresa);
      } else {
        state.vencerHoje = await servicoTituloFinanceiroReceber.obterValorVencerHoje(props.empresa);
        state.vencerMes = await servicoTituloFinanceiroReceber.obterValorVencerEsseMes(props.empresa);
        state.vencidos = await servicoTituloFinanceiroReceber.obterValorVencidos(props.empresa);
        state.baixadosHoje = await servicoTituloFinanceiroReceber.obterValorBaixadosHoje(props.empresa);
        state.baixadosMes = await servicoTituloFinanceiroReceber.ObterValorBaixadosEsseMes(props.empresa);
        state.baixadosVencidos = await servicoTituloFinanceiroReceber.ObterValorBaixadosVencidos(props.empresa);
      }
      state.carregando = false;
    }

    onBeforeMount(async () => {
      obterValores();
    });

    function filtrarTitulos(conceito:string) {
      emit('filtrarTitulos', conceito);
    }

    return {
      props,
      state,
      ETipoTituloFinanceiro,
      obterValores,
      filtrarTitulos,
    };
  },
});
