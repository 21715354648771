
import { defineComponent, computed, reactive } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { ETipoLancamentoBaixa } from '@/models/Enumeradores/Financeiro/ETipoLancamentoBaixa';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import BaixaTitulosLancamentoCarteiraTitulosModal from './BaixaTitulosLancamentoCarteiraTitulosModal.vue';
import Totalizador from '@/core/components/Tela/Totalizador.vue';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import UtilitarioFinanceiro from '@/core/utilitarios/UtilitarioFinanceiro';

export default defineComponent({
  name: 'BaixaTitulosLancamentoCarteira',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    tipoTituloFinanceiro: {
      type: Number,
      required: true,
    },
    contaPadrao: {
      type: Number,
      default: 0,
    },
    titulosLancados: {
      type: Object as () => IDTOTituloFinanceiro[],
      required: true,
    },
    totalTitulosLancados: {
      type: Number,
      required: true,
    },
    totalBaixa: {
      type: Number,
      required: true,
    },
    valorRestante: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Card,
    Icone,
    MensagemSemDados,
    Totalizador,
    BaixaTitulosLancamentoCarteiraTitulosModal,
  },
  emits: ['update:titulosLancados', 'update:totalTitulosLancados', 'atualizarTotalizadores'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const { gradeBase } = useGradeBase();

    const state = reactive({
      computedTitulosLancados: computed({
        get: () => props.titulosLancados,
        set: (val: IDTOTituloFinanceiro[]) => {
          emit('update:titulosLancados', val);
        },
      }),
      totalTitulosLancados: 0,
      tituloSelecionado: {} as IDTOTituloFinanceiro,
      quantidadeTitulos: 0,
      exibirAdicionarTitulo: false,
      chaveTitulos: 1,
    });

    gradeBase.layoutMobile = UtilitarioDispositivo.larguraTelaMobile();

    function preencherColunas() {
      if (gradeBase.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Titulo', dataIndex: 'titulo', key: 'Titulo', position: 1, visible: true,
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Ações', dataIndex: 'acoes', key: 'Acoes', position: 1, visible: true, width: 40, ellipsis: true,
          },
          {
            title: 'Tipo', dataIndex: 'descricaoTipo', key: 'Tipo', position: 2, visible: true, width: 40, ellipsis: true,
          },
          {
            title: 'PGTO', dataIndex: 'descricaoTipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 3, visible: true, width: 40, ellipsis: true,
          },
          {
            title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 4, visible: true, ellipsis: true, width: 75,
          },
          {
            title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 5, visible: true, ellipsis: true, width: 150,
          },
          {
            title: 'Vencimento', dataIndex: 'dataVencimento', key: 'DataVencimento', position: 6, visible: true, ordering: false, customRenderRow: ECustomRenderRow.MascararData, width: 60, align: 'center',
          },
          {
            title: 'Valor R$', dataIndex: 'saldo', key: 'Valor', position: 7, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 75,
          },
          {
            title: 'Conta', dataIndex: 'descricaoConta', key: 'Conta', position: 8, visible: true, ellipsis: true, width: 150,
          },
        ];
      }
    }

    preencherColunas();

    function abrirAdicaoTitulos() {
      state.tituloSelecionado = {} as IDTOTituloFinanceiro;
      state.exibirAdicionarTitulo = true;
    }

    function atualizarTotalizadores() {
      state.totalTitulosLancados = 0;
      state.quantidadeTitulos = 0;
      state.computedTitulosLancados.forEach((t) => {
        if (t.tipo === props.tipoTituloFinanceiro) {
          state.totalTitulosLancados += t.valorTotal;
        }

        state.quantidadeTitulos += 1;
      });

      emit('update:totalTitulosLancados', state.totalTitulosLancados);
      emit('atualizarTotalizadores');
    }

    function adicionarTitulos(titulos: IDTOTituloFinanceiro[]) {
      telaBase.carregando = true;
      let totalTitulosLancados = 0;
      if (state.tituloSelecionado.numeroTitulo !== undefined && state.tituloSelecionado.numeroTitulo !== '') {
        const index = state.computedTitulosLancados.findIndex((t) => t.numeroTitulo === state.tituloSelecionado.numeroTitulo);
        state.computedTitulosLancados[index] = titulos[0];
        state.tituloSelecionado = {} as IDTOTituloFinanceiro;
      } else {
        const titulosGrade: IDTOTituloFinanceiro[] = state.computedTitulosLancados;
        titulos.forEach((t) => {
          totalTitulosLancados += t.valorTotal;
          titulosGrade.push(t);
        });

        if (totalTitulosLancados > props.totalBaixa) {
          const diferenca = UtilitarioFinanceiro.valorDecimal2Casas((totalTitulosLancados - props.totalBaixa));
          if (diferenca > 0 && diferenca <= 0.05) {
            titulosGrade[titulosGrade.length - 1].valorTotal -= diferenca;
            titulosGrade[titulosGrade.length - 1].saldo -= diferenca;
          }
        } else {
          const diferenca = UtilitarioFinanceiro.valorDecimal2Casas((props.totalBaixa - totalTitulosLancados));
          if (diferenca > 0 && diferenca <= 0.05) {
            titulosGrade[titulosGrade.length - 1].valorTotal += diferenca;
            titulosGrade[titulosGrade.length - 1].saldo += diferenca;
          }
        }
        state.computedTitulosLancados = titulosGrade;
      }
      state.chaveTitulos += 1;
      telaBase.carregando = false;
      atualizarTotalizadores();
    }

    function editarTitulo(titulo: IDTOTituloFinanceiro) {
      state.tituloSelecionado = titulo;
      state.exibirAdicionarTitulo = true;
    }

    function excluirTitulo(index: number) {
      state.computedTitulosLancados.splice(index, 1);
      atualizarTotalizadores();
    }

    function obterDescricaoTipoTitulos() {
      if (props.tipoTituloFinanceiro === ETipoTituloFinanceiro.Receber) { return 'Recebimentos'; }
      if (props.tipoTituloFinanceiro === ETipoTituloFinanceiro.Pagar) { return 'Pagamentos'; }

      return '';
    }

    return {
      props,
      state,
      telaBase,
      gradeBase,
      ETipoLancamentoBaixa,
      UtilitarioGeral,
      UtilitarioData,
      UtilitarioMascara,
      abrirAdicaoTitulos,
      adicionarTitulos,
      editarTitulo,
      excluirTitulo,
      obterDescricaoTipoTitulos,
      ECustomRenderRow,
    };
  },
});
