
import { defineComponent, reactive, watch } from 'vue';
import { Modal } from 'ant-design-vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Icone from '@/core/components/Icone.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IMovimentoFinanceiroFechamento } from '@/models/Entidades/Financeiro/MovimentosFinanceiros/IMovimentoFinanceiro';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import ServicoMovimentoFinanceiro from '@/servicos/Financeiro/ServicoMovimentoFinanceiro';

export default defineComponent({
  name: 'ControlePeriodoMovimentacaoContasModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      Default: 0,
    },
    conta: {
      type: Number,
      Default: 0,
    },
  },
  components: {
    Icone,
    Card,
    RequisicaoModal,
    MensagemSemDados,
    SelecionarEmpresa,
    SelecionarConta,
    SelecionarDataInicialFinal,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissao, verificaConceitoParaApresentarEmpresas, verificaAutorizacao,
      apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const servicoMovimentoFinanceiro = new ServicoMovimentoFinanceiro();

    telaBase.identificadorRecurso = 'MOVIMENTACOES_CONTAS_PERIODOS_FINANCEIROS';
    telaBase.identificadorPermissao = 'AUT_MOVIMENTACOES_CONTAS_PERIODOS_FINANCEIROS';
    telaBase.apresentarEmpresas = false;

    const state = reactive({
      fechamentoPeriodo: {} as IMovimentoFinanceiroFechamento,
      listaFechamentosPeriodos: [] as IMovimentoFinanceiroFechamento [],
    });

    async function limparTela() {
      state.fechamentoPeriodo.codigo = 0;
      state.fechamentoPeriodo.codigoConta = 0;
      state.fechamentoPeriodo.codigoEmpresa = 0;
      state.fechamentoPeriodo.codigoUsuario = 0;
      state.fechamentoPeriodo.dataInicial = '';
      state.fechamentoPeriodo.dataFinal = '';
      state.listaFechamentosPeriodos = [];
    }

    async function buscarFechamentosPeriodoConta() {
      telaBase.carregando = true;
      state.listaFechamentosPeriodos = await servicoMovimentoFinanceiro.obterFechamentosPeriodosMovimentacoesConta(state.fechamentoPeriodo.codigoConta, state.fechamentoPeriodo.codigoEmpresa);
      telaBase.carregando = false;
    }

    async function excluir(codigo: number) {
      const retorno = await servicoMovimentoFinanceiro.excluirFechamentoPeriodoFinanceiro(codigo);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        const index = state.listaFechamentosPeriodos.findIndex((c) => c.codigo === codigo);
        if (index >= 0) {
          state.listaFechamentosPeriodos.splice(index, 1);
        }
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retorno.mensagem);
      }
    }

    async function confirmarExclusao(objeto: IMovimentoFinanceiroFechamento) {
      Modal.confirm({
        title: 'Você confirma a exclusão do Fechamento no período:',
        content: `${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(objeto.dataInicial)} a ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(objeto.dataFinal)}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        centered: true,
        autoFocusButton: null,
        onOk: async () => { await excluir(objeto.codigo); },
      });
    }

    async function fecharPeriodoMovimento() {
      if (!UtilitarioGeral.validaCodigo(state.fechamentoPeriodo.codigoConta)) {
        apresentarMensagemAlerta('É necessário selecionar uma conta!');
        return;
      }

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso();
      retorno = await servicoMovimentoFinanceiro.fecharPeriodoFinanceiro(state.fechamentoPeriodo);
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        modalBase.computedVisivel = false;
        emit('confirmacao');
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;

      if (modalBase.computedVisivel) {
        limparTela();
        await obterPermissoes(ETipoPermissao.Autorizacoes);
        await preencherEmpresasComEstrategiaPermissao();
        verificaConceitoParaApresentarEmpresas();

        if (props.empresa !== undefined && props.empresa > 0) {
          state.fechamentoPeriodo.codigoEmpresa = props.empresa;
        } else {
          state.fechamentoPeriodo.codigoEmpresa = telaBase.empresasDisponiveis[0].value;
        }

        verificaAutorizacao(state.fechamentoPeriodo.codigoEmpresa, telaBase.identificadorPermissao, true);

        if (props.conta !== undefined && props.conta > 0) {
          state.fechamentoPeriodo.codigoConta = props.conta;
          await buscarFechamentosPeriodoConta();
        }
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      emit,
      telaBase,
      modalBase,
      EPermissaoDados,
      UtilitarioGeral,
      UtilitarioMascara,
      UtilitarioData,
      apresentarRetornoRequisicao,
      fecharPeriodoMovimento,
      buscarFechamentosPeriodoConta,
      confirmarExclusao,
    };
  },
});
