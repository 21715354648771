import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOBaixaTitulosEstornoParcial } from '@/models/DTO/Financeiro/BaixasTitulosFinanceiros/IDTOBaixaTitulosEstornoParcial';
import { IDTOMovimentoFinanceiro } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiro';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { IDTOTituloFinanceiroBaixado } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroBaixado';
import { IBaixaTitulos } from '@/models/Entidades/Financeiro/BaixasTitulosFinanceiros/IBaixaTitulosFinanceiros';

/**
 * Serviço de Baixas de Títulos Financeiros
 * Fornece todas regras de negócios e lógicas relacionado a baixa de títulos financeiros.
 */

class ServicoBaixaTitulosPagar {
    endPoint = 'baixas-titulos-pagar';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async baixarTitulos(baixa: IBaixaTitulos): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, baixa);
      return result.data;
    }

    public async estonarBaixa(baixa: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/estorno-baixa/${baixa}/empresa/${empresa}`);
      return result.data;
    }

    public async estonoParcialBaixa(estornoParcial: IDTOBaixaTitulosEstornoParcial): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/estorno-baixa/parcial`, estornoParcial);
      return result.data;
    }

    public async obterMovimentosFinanceiros(baixa: number, empresa: number): Promise<IDTOMovimentoFinanceiro[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/movimentos-financeiros/${baixa}/empresa/${empresa}`);
      return result.data;
    }

    public async obterTitulosLancados(baixa: number, empresa: number): Promise<IDTOTituloFinanceiro[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/titulos-lancados/${baixa}/empresa/${empresa}`);
      return result.data;
    }

    public async obterTitulosBaixados(baixa: number, empresa: number): Promise<IDTOTituloFinanceiroBaixado[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/titulos-baixados/${baixa}/empresa/${empresa}`);
      return result.data;
    }
}
export default ServicoBaixaTitulosPagar;
