
import { defineComponent, ref } from 'vue';
import TelaPadraoCrud from '@/core/components/TelaPadraoCrud.vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import FormaPagamentoModal from './FormaPagamentoModal.vue';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import ServicoFormaPagamento from '@/servicos/Cadastros/Financeiro/ServicoFormaPagamento';

export default defineComponent({
  name: 'FormaPagamento',
  components: {
    TelaPadraoCrud,
    FormaPagamentoModal,
  },
  setup() {
    const {
      crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud,
    } = useCrudBase();
    const servicoFormaPagamento = new ServicoFormaPagamento();
    const refCrud = ref<InstanceType<typeof TelaPadraoCrud>>();

    crudBase.estrutura = preencherEstrutura();
    crudBase.estrutura.apresentarEmpresas = true;
    crudBase.estrutura.verificarCadastroCompartilhado = true;
    crudBase.estrutura.botaoPrincipalAcao = 'Novo';
    crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão da forma de pagamento';
    crudBase.estrutura.colunasGrade = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false, ordering: true,
      },
      {
        title: 'Descrição', dataIndex: 'descricao', key: 'Descricao', position: 2, visible: true, ordering: true, customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Status', dataIndex: 'ativo', key: 'Ativo', position: 3, visible: true, align: 'center', fixed: 'right', customRenderRow: ECustomRenderRow.TagAtivo, width: 80,
      },
      {
        title: 'Ações', key: 'acoes', position: 4, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];
    crudBase.estrutura.ordenacaoPadraoGrade.push({ identificador: 'Codigo', ordem: 'DESC' } as IOrdenacao);

    async function sincronizarRegistro(acao: EPermissaoDados, codigoRegistro: number) {
      if (refCrud.value !== undefined) {
        await refCrud.value.sincronizarRegistro(acao, codigoRegistro);
      }
    }

    return {
      crudBase,
      servicoFormaPagamento,
      exibirModal,
      sincronizaTelaCrud,
      sincronizarRegistro,
      refCrud,
    };
  },
});
