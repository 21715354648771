
import { computed, defineComponent, reactive } from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';
import { IArquivo } from '@/models/Entidades/MeuSistema/IArquivo';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ITituloFinanceiroAnexo } from '@/models/Entidades/Financeiro/TitulosFinanceiros/ITituloFinanceiro';
import ServicoTituloFinanceiroReceber from '@/servicos/Financeiro/ServicoTituloFinanceiroReceber';
import ServicoTituloFinanceiroPagar from '@/servicos/Financeiro/ServicoTituloFinanceiroPagar';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { ERelacaoArquivo } from '@/models/Enumeradores/MeuSistema/ERelacaoArquivo';
import { IDTOTituloFinanceiroAnexoInserir } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroAnexoInserir';

export default defineComponent({
  name: 'TituloFinanceiroAnexo',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    codigoTituloFinanceiro: {
      type: Number,
      required: true,
    },
    tipo: {
      type: Number,
      required: true,
    },
    anexos: {
      type: Array as () => ITituloFinanceiroAnexo[],
      required: true,
    },
  },
  components: {
    Icone,
    Card,
  },
  emits: ['update:anexos'],
  setup(props, { emit }) {
    const servicoTituloFinanceiroPagar = new ServicoTituloFinanceiroPagar();
    const servicoTituloFinanceiroReceber = new ServicoTituloFinanceiroReceber();
    const servicoSistema = new ServicoSistema();
    const { apresentarMensagemAlerta } = useTelaBase();
    const state = reactive({
      enviandoArquivos: false,
      excluindoArquivos: false,
    });

    const computedAnexosTituloFinanceiro = computed({
      get: () => props.anexos,
      set: (valor: ITituloFinanceiroAnexo[]) => {
        emit('update:anexos', valor);
      },
    });

    async function removerAnexo(codigoTituloFinanceiroAnexo: number, codigoArquivo: number) {
      state.excluindoArquivos = true;
      let retornoArquivo:IRetornoArquivo = {} as IRetornoArquivo;
      if (props.tipo === ETipoTituloFinanceiro.Pagar) {
        retornoArquivo = await servicoTituloFinanceiroPagar.removerAnexo(props.codigoTituloFinanceiro, codigoTituloFinanceiroAnexo, codigoArquivo);
      } else {
        retornoArquivo = await servicoTituloFinanceiroReceber.removerAnexo(props.codigoTituloFinanceiro, codigoTituloFinanceiroAnexo, codigoArquivo);
      }
      if (retornoArquivo.status === EStatusRetornoRequisicao.Sucesso) {
        const index = computedAnexosTituloFinanceiro.value.findIndex((c) => c.codigoArquivo === codigoArquivo);
        if (index !== -1) {
          computedAnexosTituloFinanceiro.value.splice(index, 1);
        }
      } else {
        apresentarMensagemAlerta(retornoArquivo.mensagem);
      }
      state.excluindoArquivos = false;
    }

    async function visualizarAnexo(arquivo:IArquivo) {
      if (!UtilitarioGeral.valorValido(arquivo.link)) {
        let retornoArquivo:IRetornoArquivo = {} as IRetornoArquivo;
        retornoArquivo = await servicoSistema.gerarLinkArquivoPrivado(arquivo.pasta, arquivo.nome);

        if (retornoArquivo.status === EStatusRetornoRequisicao.Sucesso) {
          UtilitarioGeral.downloadArquivo(retornoArquivo.linkArquivo);
        } else {
          apresentarMensagemAlerta(retornoArquivo.mensagem);
        }
      } else {
        UtilitarioGeral.downloadArquivo(arquivo.link);
      }
    }

    function selecionarArquivoUpload() {
      const elemento = document.getElementById('selecionarAnexoTituloFinanceiro');
      if (elemento !== null) {
        elemento.click();
      }
    }
    function confirmaExclusao(tituloFinanceiroAnexo: ITituloFinanceiroAnexo) {
      Modal.confirm({
        title: 'Você deseja remover o anexo:',
        content: `${tituloFinanceiroAnexo.arquivo.nome} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        centered: true,
        onOk: () => { removerAnexo(tituloFinanceiroAnexo.codigo, tituloFinanceiroAnexo.codigoArquivo); },
      });
    }
    function novoAnexo(retorno: IRetornoArquivo): ITituloFinanceiroAnexo {
      const tituloFinanceiroAnexo:ITituloFinanceiroAnexo = {} as ITituloFinanceiroAnexo;
      tituloFinanceiroAnexo.codigo = 0;
      tituloFinanceiroAnexo.codigoTituloFinanceiro = props.codigoTituloFinanceiro;
      tituloFinanceiroAnexo.codigoArquivo = retorno.codigoArquivo;
      tituloFinanceiroAnexo.arquivo = {} as IArquivo;
      tituloFinanceiroAnexo.arquivo.nome = retorno.arquivo;
      tituloFinanceiroAnexo.arquivo.link = retorno.linkArquivo;
      tituloFinanceiroAnexo.arquivo.tamanhoApresentacao = retorno.tamanhoApresentacao;
      return tituloFinanceiroAnexo;
    }

    async function verificaApresentacaoNovosArquivos(retornoAnexos: IRetornoArquivo[]) {
      if (UtilitarioGeral.validaLista(retornoAnexos)) {
        const novosAnexos: ITituloFinanceiroAnexo[] = [];
        retornoAnexos.forEach((retorno) => {
          if (retorno.status) {
            const tituloFinanceiroAnexo = novoAnexo(retorno);
            novosAnexos.push(tituloFinanceiroAnexo);
            // Inserindo anexos na inclusão exibe o arquivo na hora;
            if (props.codigoTituloFinanceiro === 0) {
              computedAnexosTituloFinanceiro.value.push(tituloFinanceiroAnexo);
            }
          } else {
            apresentarMensagemAlerta(retorno.mensagem);
          }
        });
        // Inserindo anexos na Alteração cria o vínculo com o movimento da conta depois apresenta os arquivos;
        if (props.codigoTituloFinanceiro > 0 && UtilitarioGeral.validaLista(novosAnexos)) {
          const tituloFinanceiroAnexos: IDTOTituloFinanceiroAnexoInserir = {} as IDTOTituloFinanceiroAnexoInserir;
          tituloFinanceiroAnexos.anexos = novosAnexos;
          let retornoAnexos:IRetornoRequisicao = {} as IRetornoRequisicao;
          if (props.tipo === ETipoTituloFinanceiro.Pagar) {
            retornoAnexos = await servicoTituloFinanceiroPagar.inserirAnexos(props.codigoTituloFinanceiro, tituloFinanceiroAnexos);
          } else {
            retornoAnexos = await servicoTituloFinanceiroReceber.inserirAnexos(props.codigoTituloFinanceiro, tituloFinanceiroAnexos);
          }

          if (retornoAnexos.status) {
            novosAnexos.forEach((tituloFinanceiroAnexo) => {
              computedAnexosTituloFinanceiro.value.push(tituloFinanceiroAnexo);
            });
          } else {
            apresentarMensagemAlerta(retornoAnexos.mensagem);
          }
        }
      } else {
        apresentarMensagemAlerta('Desculpe-nos! Não conseguimos enviar os anexos neste momento, tente novamente mais tarde.');
      }
    }
    async function enviarArquivos(event:any) {
      state.enviandoArquivos = true;
      const retornoAnexos = await servicoSistema.enviarArquivosPrivados(props.empresa, ERelacaoArquivo.Anexos, event.target.files);
      await verificaApresentacaoNovosArquivos(retornoAnexos);
      state.enviandoArquivos = false;
    }

    async function drop(event:any) {
      state.enviandoArquivos = true;
      const retornoAnexos = await servicoSistema.enviarArquivosPrivados(props.empresa, ERelacaoArquivo.Anexos, event.dataTransfer.files);
      await verificaApresentacaoNovosArquivos(retornoAnexos);
      state.enviandoArquivos = false;
    }

    return {
      props,
      computedAnexosTituloFinanceiro,
      state,
      confirmaExclusao,
      drop,
      enviarArquivos,
      visualizarAnexo,
      selecionarArquivoUpload,
    };
  },
});
