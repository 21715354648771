import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOConciliarImportacaoOfx } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOConciliarImportacaoOfx';
import { IDTOConciliarImportacaoOfxVariosMovimentos } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOConciliarImportacaoOfxVariosMovimentos';
import { IDTODesconciliarImportacaoOfxVariosMovimentos } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTODesconciliarImportacaoOfxVariosMovimentos';
import { IDTOMovimentoFinanceiro } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiro';
import { IDTORetornoImportacaoOfx } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTORetornoImportacaoOfx';
import { IBaixaTitulos } from '@/models/Entidades/Financeiro/BaixasTitulosFinanceiros/IBaixaTitulosFinanceiros';

/**
 * Serviço de Importação extrato OFX
 * Fornece todas regras de negócios e lógicas relacionado a importação OFX
 */

class ServicoImportacaoOfx {
    endPoint = 'movimentacoes-contas/ofx';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async enviarArquivoOfx(conta:number, empresa: number, arquivo: any): Promise<IDTORetornoImportacaoOfx> {
      const data = new FormData();
      data.append('arquivo', arquivo);

      const result: any = await this.apiERP.upload(`${this.endPoint}/conta/${conta}/empresa/${empresa}`, data);
      return result.data;
    }

    public async baixarTitulos(baixa: IBaixaTitulos): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/baixar-titulos`, baixa);
      return result.data;
    }

    public async obterMovimentosConciliados(conta:number, empresa: number, numeroControle:string): Promise<IDTOMovimentoFinanceiro[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/conta/${conta}/empresa/${empresa}/movimentacoes/numero-controle/${numeroControle}`);
      return result.data;
    }

    public async conciliarMovimento(conciliar: IDTOConciliarImportacaoOfx): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/conciliar`, conciliar);
      return result.data;
    }

    public async conciliarVariosMovimentos(conciliar: IDTOConciliarImportacaoOfxVariosMovimentos): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/conciliar/varios`, conciliar);
      return result.data;
    }

    public async desconciliarVariosMovimentos(desconciliar: IDTODesconciliarImportacaoOfxVariosMovimentos): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/desconciliar/varios`, desconciliar);
      return result.data;
    }
}
export default ServicoImportacaoOfx;
