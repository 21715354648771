
import { defineComponent, reactive, computed } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { ITituloFinanceiroDetalhamento } from '@/models/Entidades/Financeiro/TitulosFinanceiros/ITituloFinanceiro';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import SelecionarTipoDetalhamentoTituloFinanceiro from './SelecionarTipoDetalhamentoTituloFinanceiro.vue';
import { ETipoDetalhamentoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoDetalhamentoTituloFinanceiro';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import UtilitarioFinanceiro from '@/core/utilitarios/UtilitarioFinanceiro';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';

export default defineComponent({
  name: 'TituloFinanceiroDetalhamento',
  props: {
    visivel: {
      type: Boolean,
      required: true,
    },
    valor: {
      type: Number,
      required: true,
    },
    empresa: {
      type: Number,
      required: true,
    },
    codigoRegistro: {
      type: Number,
      required: true,
    },
    codigoPlanoContaCategoria: {
      type: Number,
      required: true,
    },
    detalhamentos: {
      type: Array as () => ITituloFinanceiroDetalhamento[],
      required: true,
    },
    totalDetalhado: {
      type: Number,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    CampoNumerico,
    MensagemSemDados,
    SelecionarTipoDetalhamentoTituloFinanceiro,
    SelecionarCategoriaPlanoConta,
  },
  emits: ['update:detalhamentos', 'update:totalDetalhado', 'atualizarSaldoPeloDetalhamento'],
  setup(props, { emit }) {
    const state = reactive({});

    const computedDetalhamentos = computed({
      get: () => props.detalhamentos,
      set: (detalhamentos: ITituloFinanceiroDetalhamento[]) => {
        emit('update:detalhamentos', detalhamentos);
      },
    });

    const computedTotalDetalhado = computed({
      get: () => props.totalDetalhado,
      set: (totalDetalhado: number) => {
        emit('update:totalDetalhado', totalDetalhado);
      },
    });

    function obterTotalDetalhado():number {
      let total = 0;
      if (UtilitarioGeral.validaLista(props.detalhamentos)) {
        props.detalhamentos.forEach((d) => {
          if (d.tipo === ETipoDetalhamentoTituloFinanceiro.Informativo) {
            total += d.valor;
          } else {
            total -= d.valor;
          }
        });
        computedTotalDetalhado.value = total;
      }

      emit('atualizarSaldoPeloDetalhamento', total);
      return total;
    }

    function adicionarDetalhamento() {
      const detalhamento = {} as ITituloFinanceiroDetalhamento;
      detalhamento.codigoTituloFinanceiro = props.codigoRegistro;
      if (computedDetalhamentos.value.length === 0) {
        detalhamento.codigoPlanoContaCategoria = props.codigoPlanoContaCategoria;
        detalhamento.tipo = ETipoDetalhamentoTituloFinanceiro.Informativo;
      } else {
        detalhamento.codigoPlanoContaCategoria = 0;
        detalhamento.tipo = ETipoDetalhamentoTituloFinanceiro.Informativo;
      }
      detalhamento.ordem = computedDetalhamentos.value.length + 1;
      detalhamento.valor = 0;
      computedDetalhamentos.value.push(detalhamento);
    }

    function copiarDetalhamento(index: number) {
      const detalhamento = {} as ITituloFinanceiroDetalhamento;
      detalhamento.codigoTituloFinanceiro = computedDetalhamentos.value[index].codigoTituloFinanceiro;
      detalhamento.codigoPlanoContaCategoria = computedDetalhamentos.value[index].codigoPlanoContaCategoria;
      detalhamento.descricao = computedDetalhamentos.value[index].descricao;
      detalhamento.tipo = computedDetalhamentos.value[index].tipo;
      detalhamento.valor = computedDetalhamentos.value[index].valor;
      detalhamento.ordem = computedDetalhamentos.value.length + 1;
      computedDetalhamentos.value.push(detalhamento);
    }

    function removerDetalhamento(index: number) {
      computedTotalDetalhado.value -= computedDetalhamentos.value[index].valor;
      computedDetalhamentos.value.splice(index, 1);
    }

    function removerTodos() {
      computedDetalhamentos.value = [] as ITituloFinanceiroDetalhamento[];
      computedTotalDetalhado.value = 0;
    }

    function apresenaMensagemDiferencaDetalhamento(): string {
      let mensagem = 'O total dos detalhamentos difere com total do título, ';
      if (computedTotalDetalhado.value < props.valor) {
        const diferenca = UtilitarioFinanceiro.valorDecimal2Casas(props.valor - computedTotalDetalhado.value);
        mensagem += `restam R$ ${UtilitarioMascara.mascararValor(diferenca)}`;
      } else if (computedTotalDetalhado.value > props.valor) {
        const diferenca = UtilitarioFinanceiro.valorDecimal2Casas(computedTotalDetalhado.value - props.valor);
        mensagem += `ultrapassou R$ ${UtilitarioMascara.mascararValor(diferenca)}`;
      }
      return mensagem;
    }

    return {
      props,
      storeSistema,
      state,
      computedDetalhamentos,
      computedTotalDetalhado,
      obterTotalDetalhado,
      adicionarDetalhamento,
      copiarDetalhamento,
      removerDetalhamento,
      removerTodos,
      apresenaMensagemDiferencaDetalhamento,
    };
  },
});
