
import {
  defineComponent, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Card from '@/core/components/Tela/Card.vue';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import {
  ITituloFinanceiroMarcador,
} from '@/models/Entidades/Financeiro/TitulosFinanceiros/ITituloFinanceiro';
import SelecionarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiro.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import SelecionarSazonalidade from '@/core/components/Tela/SelecionarSazonalidade.vue';
import { ESazonalidade } from '@/core/models/Enumeradores/ESazonalidade';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import SelecionarMarcadorTituloFinanceiro from '@/components/Financeiro/TitulosFinanceiros/SelecionarMarcadorTituloFinanceiro.vue';
import { EMeioPagamento } from '@/models/Enumeradores/Cadastros/Financeiro/EMeioPagamento';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';

export default defineComponent({
  name: 'BaixaTitulosLancamentoCarteiraTitulosModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    recursoOrigem: {
      type: String,
      default: '',
    },
    tipoTituloFinanceiro: {
      type: Number,
      required: true,
    },
    contaPadrao: {
      type: Number,
      default: 0,
    },
    tituloFinanceiro: {
      type: Object as () => IDTOTituloFinanceiro,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    SelecionarTipoDocumentoFinanceiro,
    SelecionarData,
    CampoNumerico,
    SelecionarPessoa,
    SelecionarConta,
    SelecionarCategoriaPlanoConta,
    SelecionarSazonalidade,
    SelecionarMarcadorTituloFinanceiro,
  },
  emits: ['update:tituloFinanceiro', 'update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase, apresentarRetornoRequisicao } = useModalBase(props, emit);
    const servicoPessoa = new ServicoPessoa();

    const state = reactive({
      titulo: {} as IDTOTituloFinanceiro,
      marcadores: [] as string[],
      repetir: false,
      sazonalidade: ESazonalidade.mensal,
      sazonalidadeCustomizada: 30,
      repeticao: 0,
      ultimoVencimento: '',
    });

    async function limparTela() {
      state.titulo = {} as IDTOTituloFinanceiro;
      state.titulo.codigoEmpresa = props.empresa;
      state.titulo.codigoConta = props.contaPadrao;
      state.titulo.parcela = 0;
      state.titulo.saldo = 0;
      state.titulo.valorTotal = 0;
      telaBase.empresasSelecionadas = [];
      telaBase.empresasSelecionadas.push(state.titulo.codigoEmpresa);
      state.titulo.tipo = props.tipoTituloFinanceiro;
      state.marcadores = [] as string[];
      state.titulo.status = EStatusTituloFinanceiro.Pendente;
      state.repetir = false;
      state.repeticao = 0;
    }

    function obterSazonalidade() {
      switch (state.sazonalidade) {
        case ESazonalidade.semanal:
          return 7;
        case ESazonalidade.quinzenal:
          return 15;
        case ESazonalidade.mensal:
          return 30;
        case ESazonalidade.bimestral:
          return 60;
        case ESazonalidade.trimestral:
          return 90;
        case ESazonalidade.semestral:
          return 180;
        case ESazonalidade.anual:
          return 360;
        default:
          return state.sazonalidadeCustomizada;
      }
    }

    function obterDescricaoSazonalidade() {
      switch (state.sazonalidade) {
        case ESazonalidade.semanal:
          return 'semanas';
        case ESazonalidade.quinzenal:
          return 'quinzenas';
        case ESazonalidade.mensal:
          return 'meses';
        case ESazonalidade.bimestral:
          return 'bimestres';
        case ESazonalidade.trimestral:
          return 'trimestres';
        case ESazonalidade.semestral:
          return 'semestres';
        case ESazonalidade.anual:
          return 'anos';
        default:
          return 'vezes';
      }
    }

    function atualizarUltimoVencimento() {
      if (!UtilitarioGeral.valorValido(state.titulo.dataVencimento)) {
        state.ultimoVencimento = '';
        return;
      }
      const sazonalidade = obterSazonalidade();
      let ultimaData = UtilitarioData.converterDataParaDayJs(state.titulo.dataVencimento);
      for (let contador = 1; contador <= state.repeticao; contador += 1) {
        switch (sazonalidade) {
          case 7:
            ultimaData = UtilitarioData.adicionarDiaNaDataDayJs(ultimaData, 7);
            break;
          case 15:
            ultimaData = UtilitarioData.adicionarDiaNaDataDayJs(ultimaData, 15);
            break;
          case 30:
            ultimaData = UtilitarioData.adicionarMesNaDataDayJs(ultimaData, 1);
            break;
          case 60:
            ultimaData = UtilitarioData.adicionarMesNaDataDayJs(ultimaData, 2);
            break;
          case 90:
            ultimaData = UtilitarioData.adicionarMesNaDataDayJs(ultimaData, 3);
            break;
          case 180:
            ultimaData = UtilitarioData.adicionarMesNaDataDayJs(ultimaData, 4);
            break;
          case 365:
            ultimaData = UtilitarioData.adicionarAnoNaDataDayJs(ultimaData, 1);
            break;
          default:
            break;
        }
      }
      state.ultimoVencimento = UtilitarioData.aplicaFormatoData(ultimaData);
    }

    function atualizarSaldo() {
      state.titulo.saldo = state.titulo.valorTotal;
    }

    function obterMensagemRepeticao() {
      const valorTotal = UtilitarioMascara.mascararValor(state.titulo.valorTotal + (state.repeticao * state.titulo.valorTotal), 2, true);
      if (state.repeticao === 1) {
        return `Será criado 1 título adicional, totalizando ${valorTotal}.\nO último vencimento será: ${state.ultimoVencimento}.`;
      }
      return `Serão criados ${state.repeticao} títulos adicionais, totalizando ${valorTotal}.\nO último vencimento será: ${state.ultimoVencimento}.`;
    }

    function obterMarcadores() {
      const marcadores = [] as ITituloFinanceiroMarcador[];

      state.marcadores.forEach((m) => {
        const marcador = {} as ITituloFinanceiroMarcador;
        marcador.codigoTituloFinanceiro = state.titulo.codigo;
        marcador.marcador = m;
        marcador.ordem = marcadores.length + 1;
        marcadores.push(marcador);
      });
      return marcadores;
    }

    function checkRepetir() {
      if (state.repetir) {
        state.repeticao = 1;
        state.titulo.parcela = 1;
        atualizarUltimoVencimento();
      } else {
        state.repeticao = 0;
        state.titulo.parcela = 0;
      }
    }

    function validarCampos() {
      if (!UtilitarioGeral.valorValido(state.titulo.numeroTitulo)) {
        apresentarMensagemAlerta('O Número do Título deve ser informado!');
        return false;
      }
      if (!UtilitarioGeral.valorValido(state.titulo.dataVencimento)) {
        apresentarMensagemAlerta('A Data de Vencimento deve ser informada!');
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.titulo.dataCompetencia)) {
        apresentarMensagemAlerta('A Data de Competência deve ser informada!');
        return false;
      }

      if (state.titulo.valorTotal === 0 || state.titulo.valorTotal < 0) {
        apresentarMensagemAlerta('O Valor deve ser maior que 0!');
        return false;
      }
      if (!UtilitarioGeral.valorValido(state.titulo.codigoConta)) {
        apresentarMensagemAlerta('A Conta deve ser informada!');
        return false;
      }
      if (!UtilitarioGeral.valorValido(state.titulo.codigoTipoDocumentoFinanceiro)) {
        apresentarMensagemAlerta('O Tipo de Documento deve ser informado!');
        return false;
      }
      if (!UtilitarioGeral.valorValido(state.titulo.codigoPessoa)) {
        apresentarMensagemAlerta('A Pessoa deve ser informada!');
        return false;
      }
      if (!UtilitarioGeral.valorValido(state.titulo.codigoPlanoContaCategoria)) {
        apresentarMensagemAlerta('A Categoria do Plano de contas deve ser informada!');
        return false;
      }
      return true;
    }

    function duplicarTitulo(parcela: number, contador:number) {
      const tituloFinanceiro = {} as IDTOTituloFinanceiro;
      tituloFinanceiro.codigoEmpresa = state.titulo.codigoEmpresa;
      tituloFinanceiro.codigoConta = state.titulo.codigoConta;
      tituloFinanceiro.codigoPlanoContaCategoria = state.titulo.codigoPlanoContaCategoria;
      tituloFinanceiro.codigoPessoa = state.titulo.codigoPessoa;
      tituloFinanceiro.codigoTipoDocumentoFinanceiro = state.titulo.codigoTipoDocumentoFinanceiro;
      tituloFinanceiro.dataCriacao = state.titulo.dataCriacao;
      tituloFinanceiro.dataCompetencia = state.titulo.dataCompetencia;
      tituloFinanceiro.dataVencimento = UtilitarioData.adicionarDiaNaDataHoraMinutoSegundo(state.titulo.dataVencimento, contador * obterSazonalidade());
      tituloFinanceiro.descricaoConta = state.titulo.descricaoConta;
      tituloFinanceiro.descricaoTipo = state.titulo.descricaoTipo;
      tituloFinanceiro.descricaoTipoDocumentoFinanceiro = state.titulo.descricaoTipoDocumentoFinanceiro;
      tituloFinanceiro.siglaTipoDocumentoFinanceiro = state.titulo.siglaTipoDocumentoFinanceiro;
      tituloFinanceiro.marcadores = state.titulo.marcadores;
      tituloFinanceiro.nomePlanoContaCategoria = state.titulo.nomePlanoContaCategoria;
      tituloFinanceiro.nomePessoa = state.titulo.nomePessoa;
      tituloFinanceiro.numeroTitulo = `${state.titulo.numeroTitulo}/${parcela}`;
      tituloFinanceiro.status = state.titulo.status;
      tituloFinanceiro.tipo = state.titulo.tipo;
      tituloFinanceiro.valorTotal = state.titulo.valorTotal;
      tituloFinanceiro.saldo = state.titulo.valorTotal;
      tituloFinanceiro.parcela = parcela;
      return tituloFinanceiro;
    }

    function obtemDescricaoTipo():string {
      if (props.tipoTituloFinanceiro === ETipoTituloFinanceiro.Pagar) {
        return 'Pagar';
      }
      return 'Receber';
    }
    async function confirmar() {
      if (validarCampos()) {
        const titulosLancados = [] as IDTOTituloFinanceiro[];
        state.titulo.marcadores = obterMarcadores();
        state.titulo.descricaoTipo = obtemDescricaoTipo();
        state.titulo.nomePessoa = await servicoPessoa.obterNome(state.titulo.codigoPessoa, state.titulo.codigoEmpresa);
        titulosLancados.push(state.titulo);
        let parcela = state.titulo.parcela + 1;
        if (state.repetir) {
          for (let contador = 1; contador <= state.repeticao; contador += 1) {
            const titulo = duplicarTitulo(parcela, contador);
            titulosLancados.push(titulo);
            parcela += 1;
          }
          titulosLancados[0].numeroTitulo += '/1';
        }
        emit('confirmacao', titulosLancados);
        modalBase.computedVisivel = false;
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        if (props.tituloFinanceiro.numeroTitulo !== undefined && props.tituloFinanceiro.numeroTitulo !== '') {
          state.titulo = props.tituloFinanceiro;
        }
      }
      telaBase.carregando = false;
    });

    function verificaAlteracaoNumeroTitulo(): boolean {
      if (UtilitarioGeral.objetoValido(props.tituloFinanceiro)) {
        return (props.tituloFinanceiro.numeroTitulo !== undefined && props.tituloFinanceiro.numeroTitulo !== '');
      }
      return false;
    }
    return {
      props,
      state,
      telaBase,
      modalBase,
      ESazonalidade,
      EPermissaoDados,
      UtilitarioGeral,
      apresentarRetornoRequisicao,
      obterMensagemRepeticao,
      obterDescricaoSazonalidade,
      confirmar,
      atualizarUltimoVencimento,
      EMeioPagamento,
      ETipoTituloFinanceiro,
      atualizarSaldo,
      checkRepetir,
      obtemDescricaoTipo,
      verificaAlteracaoNumeroTitulo,
    };
  },
});
