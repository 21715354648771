import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46f0279a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }
const _hoisted_3 = { class: "ant-input-group-wrapper" }
const _hoisted_4 = { class: "ant-input-wrapper ant-input-group" }
const _hoisted_5 = { class: "ant-input-group-addon ss-addon" }
const _hoisted_6 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }
const _hoisted_7 = { class: "ant-input-group-wrapper" }
const _hoisted_8 = { class: "ant-input-wrapper ant-input-group" }
const _hoisted_9 = { class: "ant-input-group-addon ss-addon" }
const _hoisted_10 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }
const _hoisted_11 = { class: "ant-input-group-wrapper" }
const _hoisted_12 = { class: "ant-input-wrapper ant-input-group" }
const _hoisted_13 = { class: "ant-input-group-addon ss-addon" }
const _hoisted_14 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }
const _hoisted_15 = { class: "ant-input-group-wrapper" }
const _hoisted_16 = { class: "ant-input-wrapper ant-input-group" }
const _hoisted_17 = { class: "ant-input-group-addon ss-addon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_selecionar_caixa = _resolveComponent("selecionar-caixa")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_data = _resolveComponent("selecionar-data")!
  const _component_selecionar_usuario = _resolveComponent("selecionar-usuario")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, { titulo: "CAIXA EM OPERAÇÃO" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, { layout: "vertical" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_icone, {
                        class: "ss-icone",
                        nome: "mod-7"
                      })
                    ])
                  ]),
                  _createVNode(_component_a_form_item, {
                    label: "Caixa",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_caixa, {
                        empresas: [_ctx.props.empresa],
                        codigoSelecionado: _ctx.props.abertura.codigoCaixa,
                        disabled: ""
                      }, null, 8, ["empresas", "codigoSelecionado"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_icone, {
                        class: "ss-icone",
                        nome: "calendario-preenchido"
                      })
                    ])
                  ]),
                  _createVNode(_component_a_form_item, {
                    label: "Data Abertura",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_data, {
                        horaMinutoSegundo: true,
                        dataSelecionada: _ctx.props.abertura.dataAbertura,
                        disabled: ""
                      }, null, 8, ["dataSelecionada"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, [
                _createElementVNode("span", _hoisted_12, [
                  _createElementVNode("span", _hoisted_13, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_icone, {
                        class: "ss-icone",
                        nome: "usuario-preenchido"
                      })
                    ])
                  ]),
                  _createVNode(_component_a_form_item, {
                    label: "Responsável",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_usuario, {
                        empresas: [_ctx.props.empresa],
                        codigoSelecionado: _ctx.props.abertura.codigoResponsavel,
                        disabled: ""
                      }, null, 8, ["empresas", "codigoSelecionado"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("span", _hoisted_15, [
                _createElementVNode("span", _hoisted_16, [
                  _createElementVNode("span", _hoisted_17, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_icone, {
                        class: "ss-icone",
                        nome: "usuario-preenchido"
                      })
                    ])
                  ]),
                  _createVNode(_component_a_form_item, {
                    label: "Operador",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_usuario, {
                        empresas: [_ctx.props.empresa],
                        codigoSelecionado: _ctx.props.abertura.codigoOperador,
                        disabled: ""
                      }, null, 8, ["empresas", "codigoSelecionado"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}