
import {
  defineComponent, onBeforeMount, reactive, watch,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '@/core/components/Icone.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IListaPaginadaMetaData } from '@/core/models/Consulta/IListaPaginada';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IPagination } from '@/core/models/AntDesign/IPagination';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import Card from '@/core/components/Tela/Card.vue';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import SelecionarTipoTituloFinanceiro from '@/components/Financeiro/TitulosFinanceiros/SelecionarTipoTituloFinanceiro.vue';
import SelecionarTipoRelatorioTitulosFinanceiros from '@/components/Relatorios/Financeiro/SelecionarTipoRelatorioTitulosFinanceiros.vue';
import SelecionarTipoDataTituloFinanceiro from '@/components/Relatorios/Financeiro/SelecionarTipoDataTituloFinanceiro.vue';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiro.vue';
import SelecionarStatusTituloFinanceiro from '@/components/Financeiro/TitulosFinanceiros/SelecionarStatusTituloFinanceiro.vue';
import { IFiltrosRelatorioTitulosFinanceiros } from '@/models/Relatorios/Financeiro/TitulosFinanceiros/IFiltrosRelatorioTitulosFinanceiros';
import { ETipoRelatorioTitulosFinanceiros } from '@/models/Enumeradores/Relatorios/ETipoRelatorioTitulosFinanceiros';
import ServicoRelatorioFinanceiro from '@/servicos/Relatorios/ServicoRelatoriosFinanceiros';
import { ETipoDataTituloFinanceiro } from '@/models/Enumeradores/Relatorios/ETipoDataTituloFinanceiro';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { useTituloFinanceiroBase } from '@/composables/Financeiro/TituloFinanceiroBase';
import TotalizadorDecorado from '@/core/components/Tela/TotalizadorDecorado.vue';
import { IDadosRelatorioTitulosFinanceiros } from '@/models/Relatorios/Financeiro/TitulosFinanceiros/IDadosRelatorioTitulosFinanceiros';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { ISalvamentoAutomatico } from '@/models/Entidades/MeuSistema/ISalvamentoAutomatico';
import ServicoParametros from '@/servicos/MeuSistema/ServicoParametros';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';

export default defineComponent({
  name: 'RelatorioTitulosFinanceiros',
  components: {
    Icone,
    Card,
    MensagemSemDados,
    TituloPadrao,
    PreviewPdf,
    SelecionarTipoTituloFinanceiro,
    SelecionarTipoRelatorioTitulosFinanceiros,
    SelecionarTipoDataTituloFinanceiro,
    SelecionarDataInicialFinal,
    SelecionarPessoa,
    SelecionarConta,
    SelecionarTipoDocumentoFinanceiro,
    SelecionarStatusTituloFinanceiro,
    TotalizadorDecorado,
    SelecionarCategoriaPlanoConta,
  },
  setup(props) {
    const servicoSistema = new ServicoSistema();
    const servicoParametros = new ServicoParametros();
    const servicoRelatorioFinanceiro = new ServicoRelatorioFinanceiro();
    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis, verificaConceitoParaApresentarEmpresas, apresentarMensagemErro,
      defineEmpresasSelecionadasComPermissao, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemAlerta,
      adicionarAtalho, removerAtalho, preencherDadosRota,
    } = useTelaBase();

    const { gradeBase } = useGradeBase();
    const { obterClasseStatus } = useTituloFinanceiroBase(props);

    const state = reactive({
      mostrarPagar: false,
      mostrarReceber: false,
      dataInicial: '01/01/2001',
      dataFinal: '10/01/2001',
      filtros: {} as IFiltrosRelatorioTitulosFinanceiros,
      filtrosUtilizados: {} as IFiltrosRelatorioTitulosFinanceiros,
      resultado: {} as IDadosRelatorioTitulosFinanceiros,
      titulos: [] as IDTOTituloFinanceiro[],
      paginacao: {} as IPagination,
      paginacaoApi: { totalRegistros: 0, paginaAtual: 1 } as IListaPaginadaMetaData,
      timerRegistros: 0,
      totalRegistrosComMascara: '100',
      arquivosPdf: [] as IArquivoPdf[],
      mostrarFiltros: 0,
      mostrarFiltrosInteligentes: 1,
      mostrarTotalizadores: 0,
      mostrarTitulos: false,
      controleAlcada: false,
      apenasBaixados: false,
      cacheAutomatico: {} as ISalvamentoAutomatico,
    });

    telaBase.apresentarEmpresas = false;
    function preencherColunas() {
      if (storeSistema.state.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Título', dataIndex: 'titulo', key: 'TituloFinanceiro', position: 2, visible: true,
          },
        ];
      } else if (state.filtrosUtilizados.tipo === ETipoRelatorioTitulosFinanceiros.Sintetico) {
        gradeBase.colunas = [
          {
            title: 'Status', dataIndex: 'descricaoStatus', key: 'Status', position: 1, visible: true, align: 'left', width: 200,
          },
          {
            title: 'N. Título', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 2, visible: true, width: 150,
          },
          {
            title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 3, visible: true, ellipsis: true, width: 500,
          },
          {
            title: 'Vencimento', dataIndex: 'dataVencimentoFormatada', key: 'DataVencimento', position: 4, visible: true, align: 'center', width: 150,
          },
          {
            title: 'Competência', dataIndex: 'dataCompetenciaFormatada', key: 'DataCompetencia', position: 5, visible: true, align: 'center', width: 150,
          },
          {
            title: 'Baixa', dataIndex: 'dataBaixaFormatada', key: 'DataBaixa', position: 6, visible: true, align: 'center', width: 150,
          },
          {
            title: 'Valor Total R$', dataIndex: 'valorTotal', key: 'ValorTotal', position: 13, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 150,
          },
          {
            title: 'Juros/Multa R$', dataIndex: 'jurosMulta', key: 'JurosMulta', position: 9, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 150,
          },
          {
            title: 'Acréscimo R$', dataIndex: 'acrescimo', key: 'Acrescimo', position: 10, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 150,
          },
          {
            title: 'Desconto R$', dataIndex: 'desconto', key: 'Desconto', position: 11, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 150,
          },
          {
            title: 'Valor Pago R$', dataIndex: 'valorPago', key: 'ValorPago', position: 12, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 150,
          },
        ];
      } else if (state.filtrosUtilizados.tipo === ETipoRelatorioTitulosFinanceiros.ProjecaoRecebiveis) {
        gradeBase.colunas = [
          {
            title: 'Status', dataIndex: 'descricaoStatus', key: 'Status', position: 1, visible: true, align: 'left', width: 200,
          },
          {
            title: 'N. Título', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 2, visible: true, width: 150,
          },
          {
            title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 3, visible: true, ellipsis: true, width: 500,
          },
          {
            title: 'Tipo de Documento', dataIndex: 'descricaoTipoDocumentoFinanceiro', key: 'DescricaoTipoDocumentoFinanceiro', position: 4, visible: true, ellipsis: true, width: 250,
          },
          {
            title: 'Conta', dataIndex: 'descricaoConta', key: 'DescricaoConta', position: 5, visible: true, ellipsis: true, width: 250,
          },
          {
            title: 'Vencimento', dataIndex: 'dataVencimentoFormatada', key: 'DataVencimento', position: 6, visible: true, align: 'center', width: 150,
          },
          {
            title: 'Competência', dataIndex: 'dataCompetenciaFormatada', key: 'DataCompetencia', position: 7, visible: true, align: 'center', width: 150,
          },
          {
            title: 'Dias Atraso', dataIndex: 'diasAtraso', key: 'DiasAtraso', position: 8, visible: true, align: 'center', width: 150,
          },
          {
            title: 'Saldo R$', dataIndex: 'valorTotal', key: 'ValorTotal', position: 13, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 150,
          },
          {
            title: 'Juros/Multa R$', dataIndex: 'jurosMulta', key: 'JurosMulta', position: 9, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 150,
          },
          {
            title: 'Acréscimo R$', dataIndex: 'acrescimo', key: 'Acrescimo', position: 10, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 150,
          },
          {
            title: 'Desconto R$', dataIndex: 'desconto', key: 'Desconto', position: 11, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 150,
          },
          {
            title: 'Vlr a Receber R$', dataIndex: 'valorPago', key: 'ValorPago', position: 12, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 150,
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Status', dataIndex: 'descricaoStatus', key: 'Status', position: 1, visible: true, align: 'left', width: 200,
          },
          {
            title: 'N. Título', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 2, visible: true, width: 150,
          },
          {
            title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 3, visible: true, ellipsis: true, width: 500,
          },
          {
            title: 'Tipo de Documento', dataIndex: 'descricaoTipoDocumentoFinanceiro', key: 'DescricaoTipoDocumentoFinanceiro', position: 4, visible: true, ellipsis: true, width: 250,
          },
          {
            title: 'Conta', dataIndex: 'descricaoConta', key: 'DescricaoConta', position: 5, visible: true, ellipsis: true, width: 250,
          },
          {
            title: 'Vencimento', dataIndex: 'dataVencimentoFormatada', key: 'DataVencimento', position: 6, visible: true, align: 'center', width: 150,
          },
          {
            title: 'Competência', dataIndex: 'dataCompetenciaFormatada', key: 'DataCompetencia', position: 7, visible: true, align: 'center', width: 150,
          },
          {
            title: 'Baixa', dataIndex: 'dataBaixaFormatada', key: 'DataBaixa', position: 8, visible: true, align: 'center', width: 150,
          },
          {
            title: 'Valor Total R$', dataIndex: 'valorTotal', key: 'ValorTotal', position: 13, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 150,
          },
          {
            title: 'Juros/Multa R$', dataIndex: 'jurosMulta', key: 'JurosMulta', position: 9, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 150,
          },
          {
            title: 'Acréscimo R$', dataIndex: 'acrescimo', key: 'Acrescimo', position: 10, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 150,
          },
          {
            title: 'Desconto R$', dataIndex: 'desconto', key: 'Desconto', position: 11, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 150,
          },
          {
            title: 'Valor Pago R$', dataIndex: 'valorPago', key: 'ValorPago', position: 12, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 150,
          },
        ];
      }
    }

    async function verificarPermissaoPagarReceber() {
      const permissoesPagar = await servicoSistema.obterPermissoesDadosUsuario('PER_CONTAS_PAGAR');
      const permissoesReceber = await servicoSistema.obterPermissoesDadosUsuario('PER_CONTAS_RECEBER');
      const permissaoPagar = permissoesPagar.find((p) => p.codigoEmpresa === storeSistema.getters.codigoEmpresaOperacao());
      const permissaoReceber = permissoesReceber.find((p) => p.codigoEmpresa === storeSistema.getters.codigoEmpresaOperacao());

      if (permissaoPagar === undefined || permissaoPagar === null) {
        state.mostrarPagar = false;
      } else {
        state.mostrarPagar = permissaoPagar.imprimir;
      }

      if (permissaoReceber === undefined || permissaoReceber === null) {
        state.mostrarReceber = false;
      } else {
        state.mostrarReceber = permissaoReceber.imprimir;
      }
    }

    function obterIdentificadorCache(): string {
      return `RELATORIO_TITULOS_FINANCEIROS_${storeSistema.getters.codigoUsuarioAutenticado()}`;
    }

    function salvarCache() {
      const identificador = obterIdentificadorCache();
      state.cacheAutomatico.data = UtilitarioData.obterDataAtual();
      state.cacheAutomatico.codigoEmpresa = state.filtros.empresa;
      state.cacheAutomatico.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      state.cacheAutomatico.identificador = telaBase.identificadorRecurso;
      state.cacheAutomatico.dados = JSON.stringify(state.filtros);
      servicoSistema.salvamentoAutomaticoLocalStorage(`${identificador}`, state.cacheAutomatico);
    }

    async function recuperarCache() {
      const cacheRestaurado = await servicoSistema.obterSalvamentoAutomaticoLocalStorage(obterIdentificadorCache());
      if (UtilitarioGeral.valorValido(cacheRestaurado)) {
        const cache = JSON.parse(cacheRestaurado.dados);
        state.filtros = cache;
        state.filtros.pessoas = [];
        state.dataInicial = cache.dataInicial;
        state.dataFinal = cache.dataFinal;
        state.mostrarFiltros = 1;
        state.mostrarFiltrosInteligentes = 0;
      }
    }

    async function buscarTitulos() {
      telaBase.carregando = true;
      state.filtros.dataInicial = state.dataInicial;
      state.filtros.dataFinal = state.dataFinal;
      state.filtros.numeroPagina = state.paginacao.current;
      state.filtros.qtdRegistrosPagina = state.paginacao.pageSize;
      state.filtros.qtdTotalRegistros = state.paginacao.total;
      if (state.filtros.tipo === ETipoRelatorioTitulosFinanceiros.BaixadosContas) {
        state.filtros.status = [EStatusTituloFinanceiro.Baixado, EStatusTituloFinanceiro.BaixadoParcialmente];
      } else if (state.filtros.tipo === ETipoRelatorioTitulosFinanceiros.ProjecaoRecebiveis) {
        state.filtros.status = [EStatusTituloFinanceiro.Pendente, EStatusTituloFinanceiro.BaixadoParcialmente];
        state.filtros.tipoTitulo = ETipoTituloFinanceiro.Receber;
      }
      state.resultado = await servicoRelatorioFinanceiro.obterTitulosFinanceiros(state.filtros);
      state.filtrosUtilizados = UtilitarioGeral.clonarObjeto(state.filtros);
      salvarCache();
      if (state.filtros.status.length === 1 && state.filtros.status[0] === 2) {
        state.apenasBaixados = true;
      }
      preencherColunas();
      state.mostrarTitulos = true;
      state.titulos = state.resultado.titulos.dados;
      state.paginacaoApi = state.resultado.titulos.metaData;
      state.mostrarFiltros = 0;
      state.mostrarFiltrosInteligentes = 0;
      state.mostrarTotalizadores = 1;
      telaBase.carregando = false;
    }

    async function alterarQuantidadeRegistroTotal(valor: any) {
      clearTimeout(state.timerRegistros);
      let totalRegistros = 100;
      state.timerRegistros = setTimeout(async () => {
        if (UtilitarioGeral.valorValido(valor)) {
          const limiteRegistros = UtilitarioMascara.removerMascaraNumero(valor);
          if (limiteRegistros > 0) {
            totalRegistros = limiteRegistros;
          }
        }
        state.paginacao.total = totalRegistros;
        state.totalRegistrosComMascara = UtilitarioMascara.mascararNumeroInteiro(totalRegistros);
        await buscarTitulos();
      }, 600);
    }

    async function onPageChange(current: number, pageSize: number) {
      state.paginacao.current = current;
      state.paginacao.pageSize = pageSize;
      await buscarTitulos();
    }

    async function onShowSizeChange(current: number, pageSize: number) {
      state.paginacao.current = 1;
      state.paginacao.pageSize = pageSize;
      await buscarTitulos();
    }

    async function atualizarSelecaoEmpresas() {
      telaBase.empresasSelecionadas = [] as number[];
      telaBase.empresasSelecionadas.push(state.filtros.empresa);
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
      await buscarTitulos();
    }

    function usarFiltroInteligente(filtro: number) {
      switch (filtro) {
        case 1:
          state.filtros.tipoTitulo = ETipoTituloFinanceiro.Pagar;
          state.filtros.tipoData = ETipoDataTituloFinanceiro.DataVencimento;
          state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.filtros.status = [];
          break;
        case 2:
          state.filtros.tipoTitulo = ETipoTituloFinanceiro.Receber;
          state.filtros.tipoData = ETipoDataTituloFinanceiro.DataVencimento;
          state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.filtros.status = [];
          break;
        case 3:
          state.filtros.tipoTitulo = ETipoTituloFinanceiro.Pagar;
          state.filtros.tipoData = ETipoDataTituloFinanceiro.DataVencimento;
          state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.filtros.status = [];
          state.filtros.status.push(EStatusTituloFinanceiro.Pendente);
          state.filtros.status.push(EStatusTituloFinanceiro.BaixadoParcialmente);
          break;
        case 4:
          state.filtros.tipoTitulo = ETipoTituloFinanceiro.Receber;
          state.filtros.tipoData = ETipoDataTituloFinanceiro.DataVencimento;
          state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.filtros.status = [];
          state.filtros.status.push(EStatusTituloFinanceiro.Pendente);
          state.filtros.status.push(EStatusTituloFinanceiro.BaixadoParcialmente);
          break;
        case 5:
          state.filtros.tipoTitulo = ETipoTituloFinanceiro.Pagar;
          state.filtros.tipoData = ETipoDataTituloFinanceiro.DataBaixa;
          state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.filtros.status = [];
          state.filtros.status.push(EStatusTituloFinanceiro.Baixado);
          state.filtros.status.push(EStatusTituloFinanceiro.BaixadoCartao);
          state.filtros.status.push(EStatusTituloFinanceiro.BaixadoCredito);
          break;
        case 6:
          state.filtros.tipoTitulo = ETipoTituloFinanceiro.Receber;
          state.filtros.tipoData = ETipoDataTituloFinanceiro.DataBaixa;
          state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.filtros.status = [];
          state.filtros.status.push(EStatusTituloFinanceiro.Baixado);
          state.filtros.status.push(EStatusTituloFinanceiro.BaixadoCartao);
          state.filtros.status.push(EStatusTituloFinanceiro.BaixadoCredito);
          break;
        case 7:
          state.filtros.tipoTitulo = ETipoTituloFinanceiro.Pagar;
          state.filtros.tipoData = ETipoDataTituloFinanceiro.DataVencimento;
          state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.dataInicial = UtilitarioData.adicionarMesNaData(state.dataInicial, 1);
          state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.dataFinal = UtilitarioData.adicionarMesNaData(state.dataFinal, 1);
          state.filtros.status = [];
          state.filtros.status.push(EStatusTituloFinanceiro.Pendente);
          state.filtros.status.push(EStatusTituloFinanceiro.BaixadoParcialmente);
          break;
        case 9:
          state.filtros.tipoTitulo = ETipoTituloFinanceiro.Pagar;
          state.filtros.tipoData = ETipoDataTituloFinanceiro.DataCompetencia;
          state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.filtros.status = [];
          state.filtros.status.push(EStatusTituloFinanceiro.AprovacaoPendente);
          break;
        case 10:
          state.filtros.tipoTitulo = ETipoTituloFinanceiro.Pagar;
          state.filtros.tipoData = ETipoDataTituloFinanceiro.DataCompetencia;
          state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.filtros.status = [];
          state.filtros.status.push(EStatusTituloFinanceiro.Reprovado);
          break;
        default:
          state.filtros.tipoTitulo = ETipoTituloFinanceiro.Receber;
          state.filtros.tipoData = ETipoDataTituloFinanceiro.DataVencimento;
          state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.dataInicial = UtilitarioData.adicionarMesNaData(state.dataInicial, 1);
          state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.dataFinal = UtilitarioData.adicionarMesNaData(state.dataFinal, 1);
          state.filtros.status = [];
          state.filtros.status.push(EStatusTituloFinanceiro.Pendente);
          state.filtros.status.push(EStatusTituloFinanceiro.BaixadoParcialmente);
          break;
      }
      buscarTitulos();
    }

    async function imprimir(tipoArquivoRelatorio:ETipoArquivo) {
      telaBase.carregando = true;
      if (state.filtros.tipo === ETipoRelatorioTitulosFinanceiros.BaixadosContas) {
        state.filtros.status = [EStatusTituloFinanceiro.Baixado, EStatusTituloFinanceiro.BaixadoParcialmente];
      }
      if (!UtilitarioGeral.validaLista(state.titulos)) {
        await buscarTitulos();
      }
      const retornoRelatorio = await servicoRelatorioFinanceiro.obterArquivoTitulosFinanceiros(tipoArquivoRelatorio, state.filtrosUtilizados);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        if (tipoArquivoRelatorio === ETipoArquivo.PDF) {
          state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
        } else {
          UtilitarioGeral.downloadArquivo(retornoRelatorio.link);
        }
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function verificarControleAlcada() {
      state.filtros.empresa = storeSistema.getters.codigoEmpresaOperacao();
      const parametroValor = await servicoParametros.obterValor(state.filtros.empresa, 'PAR_FIN_ALCADA_DESPESAS');
      return parametroValor.valor === 'true';
    }

    function limparFiltros() {
      state.filtros = {} as IFiltrosRelatorioTitulosFinanceiros;
      state.filtros.empresa = storeSistema.getters.codigoEmpresaOperacao();
      state.filtros.tipo = ETipoRelatorioTitulosFinanceiros.Sintetico;
      if (state.mostrarPagar) {
        state.filtros.tipoTitulo = ETipoTituloFinanceiro.Pagar;
      } else if (state.mostrarReceber) {
        state.filtros.tipoTitulo = ETipoTituloFinanceiro.Receber;
      }
      state.filtros.tipoData = ETipoDataTituloFinanceiro.DataVencimento;
      state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
      state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
      state.filtros.status = [] as number[];
      state.filtros.status.push(1);
      state.filtros.status.push(2);
      state.filtros.status.push(3);
      state.filtros.status.push(4);
      state.filtros.status.push(5);
      state.filtros.status.push(6);
      if (state.controleAlcada) {
        state.filtros.status.push(8);
      }
      state.filtros.pessoas = [] as number[];
      state.filtros.contas = [] as number[];
      state.filtros.tiposDocumentos = [] as number[];
      state.filtros.categorias = [] as number[];
    }

    watch(() => storeSistema.state.layoutMobile, () => {
      preencherColunas();
    });

    onBeforeMount(async () => {
      preencherDadosRota();
      await obterPermissoes(ETipoPermissao.Dados);
      await preencherEmpresasDisponiveis();
      await defineEmpresasSelecionadasComPermissao();
      verificaConceitoParaApresentarEmpresas();
      await verificarPermissaoPagarReceber();
      limparFiltros();
      recuperarCache();
      preencherColunas();
      state.controleAlcada = await verificarControleAlcada();
      state.paginacao.total = 100;
      state.paginacao.showSizeChanger = true;
      state.paginacao.pageSize = 100;
      state.paginacao.current = 1;
      state.paginacao.pageSizeOptions = ['10', '50', '100', '500', '1000'];
    });

    return {
      state,
      props,
      window,
      ETipoTituloFinanceiro,
      EStatusTituloFinanceiro,
      EVinculoEmpresa,
      ETipoArquivo,
      ECustomRenderRow,
      ETipoRelatorioTitulosFinanceiros,
      UtilitarioMascara,
      UtilitarioData,
      UtilitarioDispositivo,
      storeSistema,
      telaBase,
      gradeBase,
      preencherColunas,
      onPageChange,
      onShowSizeChange,
      alterarQuantidadeRegistroTotal,
      adicionarAtalho,
      removerAtalho,
      atualizarSelecaoEmpresas,
      obterClasseStatus,
      buscarTitulos,
      usarFiltroInteligente,
      imprimir,
    };
  },
});
