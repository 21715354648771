import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-12 ant-col-xl-12" }
const _hoisted_3 = { class: "ant-col ant-col-xs-12 ant-col-xl-12" }
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selecionar_pessoa = _resolveComponent("selecionar-pessoa")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, { titulo: "DADOS DO REPASSE" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_form_item, {
            label: "Pessoa",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_selecionar_pessoa, {
                codigoSelecionado: _ctx.props.repasse.pessoa,
                empresas: [_ctx.props.empresa],
                disabled: true
              }, null, 8, ["codigoSelecionado", "empresas"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_form_item, {
            label: "Motivo",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createElementVNode("input", {
                type: "text",
                class: "ant-input",
                value: _ctx.props.repasse.motivo,
                disabled: true
              }, null, 8, _hoisted_4)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}