
import {
  defineComponent, onBeforeMount, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoBoletos from '@/servicos/Financeiro/ServicoBoleto';
import { IDTORemessa } from '@/models/DTO/Financeiro/Boletos/IDTORemessa';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import ServicoTituloFinanceiroReceber from '@/servicos/Financeiro/ServicoTituloFinanceiroReceber';
import { IParametrosConsultaTituloFinanceiro } from '@/models/ParametrosRequisicao/Financeiro/IParametrosConsultaTituloFinanceiro';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';

export default defineComponent({
  name: 'RemessaBoletosModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    titulo: {
      type: Number,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    SelecionarData,
    PreviewPdf,
  },
  emits: ['update:visivel'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta, apresentarMensagemErro } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const servicoTituloFinanceiro = new ServicoTituloFinanceiroReceber();
    const servicoBoleto = new ServicoBoletos();
    servicoTituloFinanceiro.requisicaoSistema();
    servicoBoleto.requisicaoSistema();

    const state = reactive({
      remessa: {} as IDTORemessa,
      titulos: [] as IDTOTituloFinanceiro[],
      colunasTitulos: [] as IColumn[],
      colunasRemessas: [] as IColumn[],
      totalVenda: 0,
      quantidadeSaldo: 0,
      telaMobile: false,
      carregandoProduto: false,
      arquivosPdf: [] as IArquivoPdf[],
    });

    function preencherColunas() {
      if (state.telaMobile) {
        state.colunasTitulos = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Detalhe', dataIndex: 'detalhe', key: 'TituloFinanceiro', position: 2, visible: true, ellipsis: true,
          },
        ];
        state.colunasRemessas = [
          {
            title: 'Sequencia', dataIndex: 'sequencia', key: 'Sequencia', position: 1, visible: false,
          },
          {
            title: 'Detalhe', dataIndex: 'detalhe', key: 'Remessa', position: 2, visible: true, ellipsis: true,
          },
        ];
      } else {
        state.colunasTitulos = [
          {
            title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 0, visible: true, width: 100, ordering: true, align: 'left', ellipsis: true,
          },
          {
            title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 1, visible: true, width: 100, ordering: true, align: 'left', ellipsis: true,
          },
          {
            title: 'Vencimento', dataIndex: 'vencimento', key: 'Vencimento', position: 2, visible: true, ordering: false, align: 'center', width: 80, ellipsis: true,
          },
          {
            title: 'Valor Total', dataIndex: 'valorTotal', key: 'ValorTotal', position: 3, visible: true, ordering: false, align: 'right', width: 100, ellipsis: true,
          },
          {
            title: 'Categoria', dataIndex: 'categoria', key: 'Categoria', position: 4, visible: true, ordering: false, align: 'left', width: 170, ellipsis: true,
          },
        ];
        state.colunasRemessas = [
          {
            title: 'Sequência', dataIndex: 'sequencia', key: 'Sequencia', position: 0, visible: true, width: 100, ordering: true, align: 'left', ellipsis: true,
          },
          {
            title: 'Dt de Geração', dataIndex: 'dataGeracao', key: 'DataGeracao', position: 1, visible: true, width: 100, ordering: true, align: 'left', ellipsis: true,
          },
          {
            title: 'Layout', dataIndex: 'layout', key: 'Layout', position: 2, visible: true, ordering: false, align: 'center', width: 80, ellipsis: true,
          },
          {
            title: 'Versão', dataIndex: 'versaoLayout', key: 'VersaoLayout', position: 3, visible: true, ordering: false, align: 'right', width: 100, ellipsis: true,
          },
          {
            title: 'Arquivo', dataIndex: 'arquivo', key: 'Arquivo', position: 4, visible: true, ordering: false, align: 'left', width: 170, ellipsis: true,
          },
        ];
      }
    }

    async function obterTitulos() {
      const parametrosConsulta = {} as IParametrosConsultaTituloFinanceiro;
      parametrosConsulta.empresas = [props.empresa];
      parametrosConsulta.codigosSelecionados = state.remessa.titulos;
      const listaPaginada = await servicoTituloFinanceiro.buscarTitulos(parametrosConsulta);
      state.titulos = listaPaginada.dados;
    }

    async function baixarArquivo() {
      UtilitarioGeral.downloadArquivo(state.remessa.arquivo);
    }

    async function imprimir() {
      const retornoRelatorio = await servicoBoleto.gerarRelatorioRemessa(props.empresa, props.titulo);
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    onBeforeMount(async () => {
      preencherColunas();
    });

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      state.remessa = {} as IDTORemessa;
      state.titulos = [] as IDTOTituloFinanceiro[];
      if (modalBase.computedVisivel) {
        state.remessa = await servicoBoleto.obterRemessa(props.empresa, props.titulo);
        await obterTitulos();
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      UtilitarioData,
      UtilitarioMascara,
      baixarArquivo,
      imprimir,
    };
  },
});
