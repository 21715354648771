
import {
  defineComponent, reactive, watch,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import SelecionarUsuario from '@/components/MeuSistema/Usuarios/SelecionarUsuario.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import ServicoControleCaixas from '@/servicos/Financeiro/ServicoControleCaixas';
import { IAberturaCaixa } from '@/models/Entidades/Financeiro/ControleCaixas/AberturaCaixa';
import SelecionarCaixa from '@/components/Cadastros/Financeiro/SelecionarCaixa.vue';
import ServicoCaixa from '@/servicos/Cadastros/Financeiro/ServicoCaixa';
import { ICaixa } from '@/models/Entidades/Cadastros/Financeiro/ICaixa';
import ServicoConta from '@/servicos/Cadastros/Financeiro/ServicoConta';

export default defineComponent({
  name: 'AberturaCaixaModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      required: true,
    },
    caixa: {
      type: Number,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    SelecionarUsuario,
    SelecionarCaixa,
    CampoNumerico,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemSucesso, apresentarMensagemAlerta } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    const servicoControleCaixas = new ServicoControleCaixas();
    const servicoCaixa = new ServicoCaixa();
    const servicoConta = new ServicoConta();
    servicoControleCaixas.requisicaoSistema();
    servicoCaixa.requisicaoSistema();
    servicoConta.requisicaoSistema();

    const state = reactive({
      aberturas: [] as IAberturaCaixa[],
      caixas: [] as ICaixa[],
      carregando: -1,
    });

    async function preencherOperadores(index: number) {
      state.caixas[index].operadores = [] as number[];
      if (state.caixas[index].conta.usuariosEspecificos) {
        state.caixas[index].conta.usuarios = await servicoConta.obterUsuarios(state.caixas[index].conta.codigo, state.caixas[index].conta.codigoEmpresa);
        state.caixas[index].conta.usuarios.forEach((u) => {
          state.caixas[index].operadores.push(u.codigoUsuario);
        });
      }
    }

    async function limparTela() {
      state.aberturas = [] as IAberturaCaixa[];
      state.caixas = [] as ICaixa[];
      state.carregando = 0;
      const abertura = {} as IAberturaCaixa;
      let caixa = {} as ICaixa;
      abertura.codigoCaixa = props.caixa;
      abertura.codigoResponsavel = storeSistema.getters.codigoUsuarioAutenticado();
      if (abertura.codigoCaixa > 0 && abertura.codigoCaixa !== undefined) {
        caixa = await servicoCaixa.obter(abertura.codigoCaixa, props.empresa);
        abertura.saldoInicial = caixa.saldoInicialPadrao;
      }
      state.aberturas.push(abertura);
      state.caixas.push(caixa);
      if (abertura.codigoCaixa > 0 && abertura.codigoCaixa !== undefined) {
        await preencherOperadores(0);
        if (state.caixas[0].operadores.includes(storeSistema.getters.codigoUsuarioAutenticado()) || state.caixas[0].operadores.length === 0) {
          state.aberturas[0].codigoOperador = storeSistema.getters.codigoUsuarioAutenticado();
        }
      }
      state.carregando = -1;
    }

    async function alterarCaixa(index: number) {
      state.carregando = index;
      if (state.aberturas[index].codigoCaixa !== state.caixas[index].codigo) {
        if (state.aberturas[index].codigoCaixa === 0 || state.aberturas[index].codigoCaixa === undefined) {
          state.aberturas[index].codigoOperador = 0;
          state.aberturas[index].saldoInicial = 0;
          state.caixas[index] = {} as ICaixa;
        } else {
          state.caixas[index] = await servicoCaixa.obter(state.aberturas[index].codigoCaixa, props.empresa);
          state.aberturas[index].saldoInicial = state.caixas[index].saldoInicialPadrao;
          await preencherOperadores(index);
        }
      }
      state.carregando = -1;
    }

    function adicionarAbertura() {
      const abertura = {} as IAberturaCaixa;
      const caixa = {} as ICaixa;
      abertura.codigoResponsavel = storeSistema.getters.codigoUsuarioAutenticado();
      abertura.codigoOperador = 0;
      state.aberturas.push(abertura);
      state.caixas.push(caixa);
    }

    function excluirAbertura(index: number) {
      state.aberturas.splice(index, 1);
      state.caixas.splice(index, 1);
    }

    function validarRepeticaoCaixa(caixa: number) {
      let vezes = 0;
      state.aberturas.forEach((a) => {
        if (a.codigoCaixa === caixa) {
          vezes += 1;
        }
      });
      return vezes === 1;
    }

    function validarAberturas() {
      let retorno = true;
      state.aberturas.forEach((a) => {
        if ((a.codigoCaixa === 0 || a.codigoCaixa === undefined) && !retorno) {
          apresentarMensagemAlerta('Todos os caixas devem ser informados!');
          retorno = false;
        }
        if ((a.codigoOperador === 0 || a.codigoOperador === undefined) && !retorno) {
          apresentarMensagemAlerta('Todos os operadores devem ser informados!');
          retorno = false;
        }
      });

      state.aberturas.forEach((a, index) => {
        const retornoRepeticaoCaixa = validarRepeticaoCaixa(a.codigoCaixa);
        if (!retornoRepeticaoCaixa && retorno) {
          apresentarMensagemAlerta(`O caixa ${state.aberturas[index].descricaoCaixa} está definido mais de uma vez!`);
          retorno = false;
        }
      });

      return retorno;
    }

    async function abrirCaixa() {
      apresentarBarraProgresso();
      let retornoRequisicao: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      retornoRequisicao = await servicoControleCaixas.abrirCaixas(props.empresa, state.aberturas);
      ocultarBarraProgresso();
      if (retornoRequisicao.status === EStatusRetornoRequisicao.Sucesso) {
        emit('confirmacao');
        if (state.aberturas.length === 1) {
          apresentarMensagemSucesso('Caixa aberto com sucesso!');
        } else {
          apresentarMensagemSucesso('Caixas abertos com sucesso!');
        }
        modalBase.computedVisivel = false;
      } else {
        if (retornoRequisicao.mensagem === '') {
          retornoRequisicao.mensagem = 'Não foi possível abrir o caixa.';
        }
        apresentarRetornoRequisicao(retornoRequisicao);
      }
    }

    function obterCodigosCaixas() {
      const codigos = [] as number[];

      state.caixas.forEach((c) => {
        codigos.push(c.codigo);
      });
      return codigos;
    }

    async function confirmaAberturaCaixa() {
      const retorno = validarAberturas();
      if (!retorno) {
        return;
      }

      const retornoValidacao = await servicoControleCaixas.validarAberturasPendentesAnteriores(props.empresa, obterCodigosCaixas());
      if (retornoValidacao.status === EStatusRetornoRequisicao.Sucesso) {
        Modal.confirm({
          title: 'Um dos caixas definidos para abertura já está aberto.',
          content: 'Deseja fazer a abertura mesmo assim?',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Não',
          autoFocusButton: null,
          onOk: async () => { abrirCaixa(); },
        });
      } else {
        Modal.confirm({
          title: 'Realmente quer realizar as aberturas agora?',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Não',
          autoFocusButton: null,
          onOk: async () => { abrirCaixa(); },
        });
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      if (modalBase.computedVisivel) {
        telaBase.carregando = true;
        telaBase.carregando = false;
      }
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      EPermissaoDados,
      storeSistema,
      UtilitarioGeral,
      apresentarRetornoRequisicao,
      alterarCaixa,
      adicionarAbertura,
      excluirAbertura,
      confirmaAberturaCaixa,
    };
  },
});
