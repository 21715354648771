import ApiERP from '@/core/conectores/ApiERP';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { IFiltrosRelatorioTitulosFinanceiros } from '@/models/Relatorios/Financeiro/TitulosFinanceiros/IFiltrosRelatorioTitulosFinanceiros';
import { IDadosRelatorioTitulosFinanceiros } from '@/models/Relatorios/Financeiro/TitulosFinanceiros/IDadosRelatorioTitulosFinanceiros';
import { IFiltrosRelatorioBalancete } from '@/models/Relatorios/Financeiro/Balancete/IFiltrosRelatorioBalancete';
import { IDadosRelatorioBalancete } from '@/models/Relatorios/Financeiro/Balancete/IDadosRelatorioBalancete';
import { IDadosRelatorioFluxoCaixa } from '@/models/Relatorios/Financeiro/FluxoCaixa/IDadosRelatorioFluxoCaixa';
import { IFiltrosRelatorioFluxoCaixa } from '@/models/Relatorios/Financeiro/FluxoCaixa/IFiltrosRelatorioFluxoCaixa';
import { IFiltrosRelatorioDespesasCentroCusto } from '@/models/Relatorios/Financeiro/DespesasCentroCusto/IFiltrosRelatorioDespesasCentroCusto';
import { IDadosRelatorioDespesasCentroCusto } from '@/models/Relatorios/Financeiro/DespesasCentroCusto/IDadosRelatorioDespesasCentroCusto';
import { IFiltrosRelatorioProjecaoFinanceira } from '@/models/Relatorios/Financeiro/ProjecaoFinanceira/IFiltrosRelatorioProjecaoFinanceira';
import { IDadosRelatorioProjecaoFinanceira } from '@/models/Relatorios/Financeiro/ProjecaoFinanceira/IDadosRelatorioProjecaoFinanceira';
import { IFiltrosRelatorioRentabilidadeEmpresa } from '@/models/Relatorios/Financeiro/RentabilidadeEmpresa/IFiltrosRelatorioRentabilidadeEmpresa';
import { IDadosRelatorioRentabilidadeEmpresa } from '@/models/Relatorios/Financeiro/RentabilidadeEmpresa/IDadosRelatorioRentabilidadeEmpresa';
import { ETipoRelatorioTitulosFinanceiros } from '@/models/Enumeradores/Relatorios/ETipoRelatorioTitulosFinanceiros';

/**
 * Serviço de Relatórios Financeiros
 * Fornece todas regras de negócios e lógicas relacionado a título financeiro.
 */

class ServicoRelatorioFinanceiro {
    endPoint = 'relatorios-financeiros';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterTitulosFinanceiros(filtros: IFiltrosRelatorioTitulosFinanceiros): Promise<IDadosRelatorioTitulosFinanceiros> {
      let result: any;

      if (filtros.tipo === ETipoRelatorioTitulosFinanceiros.ProjecaoRecebiveis) {
        result = await this.apiERP.post(`${this.endPoint}/titulos-financeiros/recebiveis`, filtros);
      } else {
        result = await this.apiERP.post(`${this.endPoint}/titulos-financeiros`, filtros);
      }
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data.titulos;
      result.data.titulos = listaPaginada;
      return result.data;
    }

    public async obterArquivoTitulosFinanceiros(tipoArquivo: ETipoArquivo, filtros: IFiltrosRelatorioTitulosFinanceiros): Promise<IRetornoRelatorio> {
      if (filtros.tipo === ETipoRelatorioTitulosFinanceiros.BaixadosContas) {
        const result: any = await this.apiERP.post(`${this.endPoint}/titulos-financeiros/baixados/arquivo/${tipoArquivo}`, filtros);
        return result.data;
      } if (filtros.tipo === ETipoRelatorioTitulosFinanceiros.ProjecaoRecebiveis) {
        const result: any = await this.apiERP.post(`${this.endPoint}/titulos-financeiros/recebiveis/arquivo/${tipoArquivo}`, filtros);
        return result.data;
      }
      const result: any = await this.apiERP.post(`${this.endPoint}/titulos-financeiros/arquivo/${tipoArquivo}`, filtros);
      return result.data;
    }

    public async obterBalancete(filtros: IFiltrosRelatorioBalancete): Promise<IDadosRelatorioBalancete> {
      const result: any = await this.apiERP.post(`${this.endPoint}/balancete`, filtros);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data.itens;
      result.data.itens = listaPaginada;
      return result.data;
    }

    public async obterArquivoBalancete(tipoArquivo: ETipoArquivo, filtros: IFiltrosRelatorioBalancete): Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.post(`${this.endPoint}/balancete/arquivo/${tipoArquivo}`, filtros);
      return result.data;
    }

    public async obterFluxoCaixa(filtros: IFiltrosRelatorioFluxoCaixa): Promise<IDadosRelatorioFluxoCaixa> {
      const result: any = await this.apiERP.post(`${this.endPoint}/fluxo-caixa`, filtros);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data.itens;
      result.data.itens = listaPaginada;
      return result.data;
    }

    public async obterArquivoFluxoCaixa(tipoArquivo: ETipoArquivo, filtros: IFiltrosRelatorioFluxoCaixa): Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.post(`${this.endPoint}/fluxo-caixa/arquivo/${tipoArquivo}`, filtros);
      return result.data;
    }

    public async obterDespesasCentroCusto(filtros: IFiltrosRelatorioDespesasCentroCusto): Promise<IDadosRelatorioDespesasCentroCusto> {
      const result: any = await this.apiERP.post(`${this.endPoint}/despesas-centro-custo`, filtros);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data.itens;
      result.data.itens = listaPaginada;
      return result.data;
    }

    public async obterArquivoDespesasCentroCusto(tipoArquivo: ETipoArquivo, filtros: IFiltrosRelatorioDespesasCentroCusto): Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.post(`${this.endPoint}/despesas-centro-custo/arquivo/${tipoArquivo}`, filtros);
      return result.data;
    }

    public async obterProjecaoFinanceira(filtros: IFiltrosRelatorioProjecaoFinanceira): Promise<IDadosRelatorioProjecaoFinanceira> {
      const result: any = await this.apiERP.post(`${this.endPoint}/projecao-financeira`, filtros);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data.itens;
      result.data.itens = listaPaginada;
      return result.data;
    }

    public async obterArquivoProjecaoFinanceira(tipoArquivo: ETipoArquivo, filtros: IFiltrosRelatorioProjecaoFinanceira): Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.post(`${this.endPoint}/projecao-financeira/arquivo/${tipoArquivo}`, filtros);
      return result.data;
    }

    public async obterRentabilidadeEmpresa(filtros: IFiltrosRelatorioRentabilidadeEmpresa): Promise<IDadosRelatorioRentabilidadeEmpresa> {
      const result: any = await this.apiERP.post(`${this.endPoint}/rentabilidade-empresa`, filtros);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data.itens;
      result.data.itens = listaPaginada;
      return result.data;
    }

    public async obterArquivoRentabilidadeEmpresa(tipoArquivo: ETipoArquivo, filtros: IFiltrosRelatorioRentabilidadeEmpresa): Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.post(`${this.endPoint}/rentabilidade-empresa/arquivo/${tipoArquivo}`, filtros);
      return result.data;
    }
}
export default ServicoRelatorioFinanceiro;
