import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ant-row"
}
const _hoisted_2 = { class: "ant-col ant-col-xs-5 ant-col-xl-5" }
const _hoisted_3 = { class: "ant-col ant-col-xs-5 ant-col-xl-5" }
const _hoisted_4 = { class: "ant-col ant-col-xs-5 ant-col-xl-5" }
const _hoisted_5 = { class: "ant-col ant-col-xs-5 ant-col-xl-5" }
const _hoisted_6 = { class: "ant-col ant-col-xs-4 ant-col-xl-4" }
const _hoisted_7 = {
  key: 1,
  class: "ant-row"
}
const _hoisted_8 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }
const _hoisted_9 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }
const _hoisted_10 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }
const _hoisted_11 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_totalizador_decorado = _resolveComponent("totalizador-decorado")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, { titulo: "RESUMO" }, {
    default: _withCtx(() => [
      (_ctx.props.totalTransferido > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_totalizador_decorado, {
                titulo: "SALDO INICIAL",
                cor: "#8ba2c7",
                iconePequeno: true,
                valor: _ctx.props.saldoInicial,
                monetario: true
              }, null, 8, ["valor"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_totalizador_decorado, {
                titulo: "TOTAL DE ENTRADAS",
                cor: "#33CC00",
                iconePequeno: true,
                valor: _ctx.props.totalEntradas,
                monetario: true
              }, null, 8, ["valor"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_totalizador_decorado, {
                titulo: "TOTAL DE SAÍDAS",
                cor: "#FF0000",
                iconePequeno: true,
                valor: _ctx.props.totalSaidas,
                monetario: true
              }, null, 8, ["valor"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_totalizador_decorado, {
                titulo: "SALDO FINAL",
                cor: "#25207d",
                iconePequeno: true,
                valor: _ctx.props.saldoFinal,
                monetario: true
              }, null, 8, ["valor"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_totalizador_decorado, {
                titulo: "TOTAL TRANSFERIDO",
                cor: "#FFA549",
                iconePequeno: true,
                valor: _ctx.props.totalTransferido,
                monetario: true
              }, null, 8, ["valor"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_totalizador_decorado, {
                titulo: "SALDO INICIAL",
                cor: "#8ba2c7",
                iconePequeno: true,
                valor: _ctx.props.saldoInicial,
                monetario: true
              }, null, 8, ["valor"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_totalizador_decorado, {
                titulo: "TOTAL DE ENTRADAS",
                cor: "#33CC00",
                iconePequeno: true,
                valor: _ctx.props.totalEntradas,
                monetario: true
              }, null, 8, ["valor"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_totalizador_decorado, {
                titulo: "TOTAL DE SAÍDAS",
                cor: "#FF0000",
                iconePequeno: true,
                valor: _ctx.props.totalSaidas,
                monetario: true
              }, null, 8, ["valor"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_totalizador_decorado, {
                titulo: "SALDO FINAL",
                cor: "#25207d",
                iconePequeno: true,
                valor: _ctx.props.saldoFinal,
                monetario: true
              }, null, 8, ["valor"])
            ])
          ]))
    ]),
    _: 1
  }))
}