
import {
  defineComponent, reactive, watch, onBeforeMount, onBeforeUnmount,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioFinanceiro from '@/core/utilitarios/UtilitarioFinanceiro';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import ServicoImportacaoOfx from '@/servicos/Financeiro/ServicoImportacaoOfx';
import ServicoParametros from '@/servicos/MeuSistema/ServicoParametros';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import storeSistema from '@/store/storeSistema';
import { ETipoPlanoConta } from '@/models/Enumeradores/Cadastros/PlanoContas/ETipoPlanoConta';
import { IDTORetornoImportacaoOfx, IDTOTransacaoOfx } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTORetornoImportacaoOfx';
import { ETipoMovimentoFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoMovimentoFinanceiro';
import { EProbabilidadeConciliacao } from '@/models/Enumeradores/Financeiro/EProbalidadeConciliacao';
import { EStatusConciliacaoOfx } from '@/models/Enumeradores/Financeiro/EStatusConciliacaoOfx';
import { IBaixaTitulos, IBaixaTitulosMovimentoFinanceiro, IBaixaTitulosTituloFinanceiroBaixado } from '@/models/Entidades/Financeiro/BaixasTitulosFinanceiros/IBaixaTitulosFinanceiros';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { IMovimentoFinanceiro } from '@/models/Entidades/Financeiro/MovimentosFinanceiros/IMovimentoFinanceiro';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import MovimentoFinanceiroModal from './MovimentoFinanceiroModal.vue';
import TransferenciaContasModal from './TransferenciaContasModal.vue';
import BuscarTitulosModal from '../../../components/Financeiro/TitulosFinanceiros/BuscarTitulosModal.vue';
import BuscarMovimentosFinanceirosModal from '../../../components/Financeiro/MovimentosFinanceiros/BuscarMovimentosFinanceirosModal.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import { IDTOConciliarImportacaoOfx } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOConciliarImportacaoOfx';
import { IDTOMovimentoFinanceiro } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiro';
import { ISalvamentoAutomatico } from '@/models/Entidades/MeuSistema/ISalvamentoAutomatico';
import { useFocusBase } from '@/core/composables/FocusBase';
import { IDTOConciliarImportacaoOfxVariosMovimentos } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOConciliarImportacaoOfxVariosMovimentos';
import { IDTODesconciliarImportacaoOfxVariosMovimentos } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTODesconciliarImportacaoOfxVariosMovimentos';
import { EMovimentoFinanceiroBaixaReferente } from '@/models/Enumeradores/Financeiro/EMovimentoFinanceiroBaixaReferente';
import ServicoTituloFinanceiroReceber from '@/servicos/Financeiro/ServicoTituloFinanceiroReceber';
import ServicoTituloFinanceiroPagar from '@/servicos/Financeiro/ServicoTituloFinanceiroPagar';
import { IDTOTituloFinanceiroDetalhamento } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroDetalhamento';
import { IDTOTituloFinanceiroObterDetalhamentoVariosTitulos } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroObterDetalhamentoVariosTitulos';
import { ETipoDetalhamentoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoDetalhamentoTituloFinanceiro';

export interface ITituloValoresBaixa {
  codigoTituloFinanceiro: number;
  valorJurosMulta: number;
  valorDesconto: number;
}
export default defineComponent({
  name: 'ImportacaoExtratoOfxModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      default: 0,
      required: true,
    },
    conta: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    Card,
    CampoNumerico,
    SelecionarConta,
    SelecionarCategoriaPlanoConta,
    MovimentoFinanceiroModal,
    TransferenciaContasModal,
    BuscarTitulosModal,
    BuscarMovimentosFinanceirosModal,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissao, verificaConceitoParaApresentarEmpresas, verificaAutorizacao,
      apresentarMensagemAlerta, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const {
      gradeBase,
    } = useGradeBase();

    const {
      focusBase, proximoElemento, elementoAnterior, elementoAcima, elementoAbaixo,
    } = useFocusBase();
    focusBase.classElementos = 'ss-importacao-ofx-valores-baixa';

    const servicoImportacaoOfx = new ServicoImportacaoOfx();
    const servicoParametros = new ServicoParametros();
    const servicoSistema = new ServicoSistema();
    const servicoTituloFinanceiroReceber = new ServicoTituloFinanceiroReceber();
    servicoTituloFinanceiroReceber.requisicaoSistema();
    const servicoTituloFinanceiroPagar = new ServicoTituloFinanceiroPagar();
    servicoTituloFinanceiroPagar.requisicaoSistema();

    let numeroMovimentoControle = 0;

    telaBase.identificadorRecurso = 'IMPORTACAO_EXTRATO_OFX';
    telaBase.identificadorPermissao = 'AUT_IMPORTACAO_EXTRATO_OFX';
    telaBase.apresentarEmpresas = false;
    const state = reactive({
      dataAtual: '',
      nomeArquivo: '',
      ultimoMovimentoSelecionado: '',
      codigoConta: 0,
      nomeConta: '',
      apresentarStatus: 0,
      codigoCategoriaJurosMulta: 0,
      valorJurosMulta: 0,
      codigoCategoriaDesconto: 0,
      valorDesconto: 0,
      retornoImportacaoOfx: {} as IDTORetornoImportacaoOfx,
      operacaoLancamento: {} as ITelaOperacao,
      movimentoPrePreechido: {} as IMovimentoFinanceiro,
      tituloFinanceiroBaixaJurosDesconto: {} as IDTOTituloFinanceiro,
      recuperarSalvamentoAutomatico: false,
      salvamentoAutomatico: {} as ISalvamentoAutomatico,
      indexTransacaoSelecionada: -1,
      enviandoArquivo: false,
      ocultarUpload: false,
      desabilitarSelecaoConta: false,
      exibirLancamento: false,
      exibirTransferencia: false,
      exibirConfirmacaoCategoriaBaixa: false,
      exibirBuscaTitulos: false,
      exibirBuscaMovimentos: false,
      recursoReferenciaBaixa: '',
      codigosTitulosBaixados: [] as number[],
      codigosMovimentosConciliados: [] as number[],
      tipoTituloFinanceiroBusca: {} as ETipoTituloFinanceiro,
      tituloModalBusca: '',
      valorTituloBusca: 0,
      titulosFinanceirosSelecionados: [] as IDTOTituloFinanceiro[],
      valoresTitulosSelecionados: [] as ITituloValoresBaixa[],
      totalBaixaTitulosSelecionados: 0,
      mensagemBaixaTitulosSelecionados: '',
      tituloModalBuscaMovimento: '',
      dataBuscaMovimento: '',
      tipoMovimentoFinanceiroBusca: {} as ETipoMovimentoFinanceiro,
      valorMovimentoBusca: 0,
      movimentosFinanceirosSelecionados: [] as IDTOMovimentoFinanceiro[],
      totalMovimentosSelecionados: 0,
      mensagemMovimentosSelecionados: '',
      movimentoBuscaOfx: {} as IMovimentoFinanceiro,
      detalhesPagamentos: [] as IDTOTituloFinanceiroDetalhamento[],
      movimentosInvalidos: false,
    });

    async function limparTela() {
      telaBase.carregando = false;
      numeroMovimentoControle = 0;
      state.indexTransacaoSelecionada = -1;
      state.nomeArquivo = '';
      state.ultimoMovimentoSelecionado = '';
      state.apresentarStatus = 0;
      state.recuperarSalvamentoAutomatico = false;
      state.salvamentoAutomatico = {} as ISalvamentoAutomatico;
      state.retornoImportacaoOfx = {} as IDTORetornoImportacaoOfx;
      state.retornoImportacaoOfx.transacoes = [];
      state.movimentoPrePreechido = {} as IMovimentoFinanceiro;
      state.tituloFinanceiroBaixaJurosDesconto = {} as IDTOTituloFinanceiro;
      state.recursoReferenciaBaixa = '';
      state.codigoConta = 0;
      state.nomeConta = '';
      state.codigoCategoriaJurosMulta = 0;
      state.codigoCategoriaDesconto = 0;
      state.valorJurosMulta = 0;
      state.valorDesconto = 0;
      state.ocultarUpload = false;
      state.exibirConfirmacaoCategoriaBaixa = false;
      state.exibirBuscaTitulos = false;
      state.tipoTituloFinanceiroBusca = {} as ETipoTituloFinanceiro;
      state.valorTituloBusca = 0;
      state.tituloModalBusca = '';
      state.mensagemBaixaTitulosSelecionados = '';
      state.totalBaixaTitulosSelecionados = 0;
      state.titulosFinanceirosSelecionados = [];
      state.valoresTitulosSelecionados = [];
      state.codigosTitulosBaixados = [];
      state.codigosMovimentosConciliados = [];
    }

    function obtemIdentificadorOfx(chave: string): string {
      return `${telaBase.identificadorRecurso}_${storeSistema.getters.codigoUsuarioAutenticado()}_${chave}`;
    }

    function obtemIdentificadorSalvamentoAutomatico(): string {
      return `${telaBase.identificadorRecurso}_${state.codigoConta}_${storeSistema.getters.codigoUsuarioAutenticado()}`;
    }

    function salvamentoAutomatico() {
      const identificador = obtemIdentificadorSalvamentoAutomatico();
      state.salvamentoAutomatico.data = state.dataAtual;
      state.salvamentoAutomatico.codigoEmpresa = props.empresa;
      state.salvamentoAutomatico.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      state.salvamentoAutomatico.identificador = telaBase.identificadorRecurso;
      state.salvamentoAutomatico.dados = JSON.stringify(state.retornoImportacaoOfx);
      // Salva informações - LocalStorage
      servicoSistema.salvamentoAutomaticoLocalStorage(`${identificador}`, state.salvamentoAutomatico);
      state.ultimoMovimentoSelecionado = state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentoConta.identificadorMovimento;
      servicoSistema.salvarLocalStorage(obtemIdentificadorOfx('ULTIMO_MOVIMENTO'), state.ultimoMovimentoSelecionado);
      servicoSistema.salvarLocalStorage(obtemIdentificadorOfx('APRESENTACAO_MOVIMENTOS'), state.apresentarStatus.toString());
      state.recuperarSalvamentoAutomatico = false;
    }

    function novoMovimento() {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = 0;
      state.operacaoLancamento.empresaSelecionada = props.empresa;
      state.movimentoPrePreechido = state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentoConta;
      state.movimentoPrePreechido.observacoes = UtilitarioGeral.valorValido(state.movimentoPrePreechido.observacoes) ? state.movimentoPrePreechido.observacoes : '';
      state.movimentoPrePreechido.numeroControle = state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].numeroControle;
      state.exibirLancamento = true;
    }

    function abrirTransferencia() {
      state.movimentoPrePreechido = state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentoConta;
      state.movimentoPrePreechido.observacoes = UtilitarioGeral.valorValido(state.movimentoPrePreechido.observacoes) ? state.movimentoPrePreechido.observacoes : '';
      state.movimentoPrePreechido.numeroControle = state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].numeroControle;
      state.exibirTransferencia = true;
    }

    function abrirConfirmacaoCategoriaBaixa(tituloFinanceiro: IDTOTituloFinanceiro, valorJurosMulta: number, valorDesconto: number) {
      state.valorJurosMulta = valorJurosMulta;
      state.valorDesconto = valorDesconto;
      state.tituloFinanceiroBaixaJurosDesconto = tituloFinanceiro;
      state.exibirConfirmacaoCategoriaBaixa = true;
    }

    function defineMovimentoOfxSelecionado(index: number) {
      state.indexTransacaoSelecionada = index;
      state.valorDesconto = 0;
      state.valorJurosMulta = 0;
      state.tituloFinanceiroBaixaJurosDesconto = {} as IDTOTituloFinanceiro;
      state.titulosFinanceirosSelecionados = [];
      state.valoresTitulosSelecionados = [];
      state.tipoTituloFinanceiroBusca = {} as ETipoTituloFinanceiro;
      salvamentoAutomatico();
    }

    function abrirBuscaTitulos(tipo: ETipoMovimentoFinanceiro) {
      state.tipoTituloFinanceiroBusca = tipo === ETipoMovimentoFinanceiro.Pagamento ? ETipoTituloFinanceiro.Pagar : ETipoTituloFinanceiro.Receber;
      state.valorTituloBusca = state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentoConta.valor;
      state.tituloModalBusca = `Selecione uma ou mais ${tipo === ETipoMovimentoFinanceiro.Pagamento ? 'Contas a Pagar' : 'Contas a Receber'}`;
      state.exibirBuscaTitulos = true;
    }

    function limparBuscaTitulos() {
      state.titulosFinanceirosSelecionados = [];
      state.valoresTitulosSelecionados = [];
    }

    function abrirBuscaMovimentosFinanceiros() {
      state.tipoMovimentoFinanceiroBusca = state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentoConta.tipoMovimento;
      state.dataBuscaMovimento = state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentoConta.dataMovimento;
      state.movimentoBuscaOfx = state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentoConta;
      state.tituloModalBuscaMovimento = `Selecione um ou mais movimentos na conta: ${state.nomeConta}`;
      state.exibirBuscaMovimentos = true;
    }

    function limparBuscaMovimentosFinanceiros() {
      state.movimentosFinanceirosSelecionados = [];
    }

    function localizaIndexMovimentoAcimaPorStatus(): number {
      let indexEncontrado = -1;
      // eslint-disable-next-line for-direction
      for (let i = state.indexTransacaoSelecionada - 1; i < state.retornoImportacaoOfx.transacoes.length; i -= 1) {
        if (state.retornoImportacaoOfx.transacoes[i].statusConciliacao === state.apresentarStatus) {
          indexEncontrado = i;
          break;
        }
      }
      return indexEncontrado;
    }

    function localizaIndexMovimentoAbaixoPorStatus(): number {
      let indexEncontrado = -1;
      // eslint-disable-next-line for-direction
      for (let i = state.indexTransacaoSelecionada + 1; i < state.retornoImportacaoOfx.transacoes.length; i += 1) {
        if (state.retornoImportacaoOfx.transacoes[i].statusConciliacao === state.apresentarStatus) {
          indexEncontrado = i;
          break;
        }
      }
      return indexEncontrado;
    }

    function movimentacaoAcima() {
      if (UtilitarioGeral.validaLista(state.retornoImportacaoOfx.transacoes)) {
        if (state.apresentarStatus === 0) {
          if (state.indexTransacaoSelecionada > 1) {
            defineMovimentoOfxSelecionado(state.indexTransacaoSelecionada - 1);
          } else if (state.indexTransacaoSelecionada === 1) {
            defineMovimentoOfxSelecionado(0);
          }
        } else {
          const index = localizaIndexMovimentoAcimaPorStatus();
          if (index >= 0) {
            defineMovimentoOfxSelecionado(index);
          }
        }
      }
    }

    function movimentacaoAbaixo() {
      if (UtilitarioGeral.validaLista(state.retornoImportacaoOfx.transacoes)) {
        if (state.apresentarStatus === 0) {
          if (state.indexTransacaoSelecionada < (state.retornoImportacaoOfx.transacoes.length - 1)) {
            defineMovimentoOfxSelecionado(state.indexTransacaoSelecionada + 1);
          } else {
            defineMovimentoOfxSelecionado(state.retornoImportacaoOfx.transacoes.length - 1);
          }
        } else {
          const index = localizaIndexMovimentoAbaixoPorStatus();
          if (index >= 0) {
            defineMovimentoOfxSelecionado(index);
          }
        }
      }
    }

    function onEscapeKeyUp(event: any) {
      if (event.which === 38) {
        movimentacaoAcima();
      } else if (event.which === 40) {
        movimentacaoAbaixo();
      }
    }
    onBeforeMount(() => {
      window.addEventListener('keyup', onEscapeKeyUp);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('keyup', onEscapeKeyUp);
    });

    async function localizarTransacaoOfx() {
      clearTimeout(telaBase.debounce);
      telaBase.debounce = setTimeout(async () => {
        const indexTransacaoOfx = state.retornoImportacaoOfx.transacoes.findIndex((c) => c.movimentoConta.identificadorMovimento === state.ultimoMovimentoSelecionado);
        if (indexTransacaoOfx >= 0) {
          const movimentoOfxElemento = document.getElementById(`T-OFX-${state.ultimoMovimentoSelecionado}`);
          if (movimentoOfxElemento !== null) {
            state.indexTransacaoSelecionada = indexTransacaoOfx;
            movimentoOfxElemento.scrollIntoView();
          }
        }
      }, 900);
    }

    async function verificaImportacaoAndamento() {
      const identificador = obtemIdentificadorSalvamentoAutomatico();
      const salvamentoAutomaticoLocalStorage = await servicoSistema.obterSalvamentoAutomaticoLocalStorage(identificador);
      if (UtilitarioGeral.objetoValido(salvamentoAutomaticoLocalStorage)) {
        state.salvamentoAutomatico = salvamentoAutomaticoLocalStorage;
        if (UtilitarioData.validaDataPreenchida(state.salvamentoAutomatico.data)) {
          const importacaoOfxSalvoAutomatico = JSON.parse(state.salvamentoAutomatico.dados);
          if (importacaoOfxSalvoAutomatico !== null) {
            state.nomeArquivo = importacaoOfxSalvoAutomatico.nomeArquivo;
          }
          state.recuperarSalvamentoAutomatico = true;
        }
      }

      const ultimoMovimento = servicoSistema.obterLocalStorage(obtemIdentificadorOfx('ULTIMO_MOVIMENTO'));
      if (UtilitarioGeral.valorValido(ultimoMovimento)) {
        state.ultimoMovimentoSelecionado = ultimoMovimento;
      }
      const apresentacao = servicoSistema.obterLocalStorage(obtemIdentificadorOfx('APRESENTACAO_MOVIMENTOS'));
      if (UtilitarioGeral.validaCodigo(apresentacao)) {
        state.apresentarStatus = Number(apresentacao);
      }
    }

    async function estrategiaAlteracaoConta() {
      await verificaImportacaoAndamento();
    }

    async function recuperarSalvamentoAutomatico() {
      if (UtilitarioGeral.valorValido(state.salvamentoAutomatico.dados)) {
        const importacaoOfxSalvoAutomatico = JSON.parse(state.salvamentoAutomatico.dados);
        if (UtilitarioGeral.objetoValido(importacaoOfxSalvoAutomatico)) {
          state.retornoImportacaoOfx = importacaoOfxSalvoAutomatico;
          state.recuperarSalvamentoAutomatico = false;
          state.ocultarUpload = true;
          await localizarTransacaoOfx();
          apresentarMensagemSucesso('Informações recuperadas com sucesso!');
        }
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        await obterPermissoes(ETipoPermissao.Autorizacoes);
        await preencherEmpresasComEstrategiaPermissao();
        verificaConceitoParaApresentarEmpresas();
        verificaAutorizacao(props.empresa, telaBase.identificadorPermissao, true);
        if (UtilitarioGeral.validaCodigo(props.conta)) {
          state.codigoConta = props.conta;
        }
        state.dataAtual = await servicoSistema.obterDataAtual();
        const categoriaPadraoJurosMulta = await servicoParametros.obterValor(props.empresa, 'PAR_FIN_RECEBER_CATEGORIA_PADRAO_JUROS_MULTA');
        if (categoriaPadraoJurosMulta !== undefined) {
          if (UtilitarioGeral.validaCodigo(categoriaPadraoJurosMulta.valor)) {
            state.codigoCategoriaJurosMulta = Number(categoriaPadraoJurosMulta.valor);
          }
        }

        const categoriaPadraoDescontos = await servicoParametros.obterValor(props.empresa, 'PAR_FIN_RECEBER_CATEGORIA_PADRAO_DESCONTO');
        if (categoriaPadraoDescontos !== undefined) {
          if (UtilitarioGeral.validaCodigo(categoriaPadraoDescontos.valor)) {
            state.codigoCategoriaDesconto = Number(categoriaPadraoDescontos.valor);
          }
        }

        await verificaImportacaoAndamento();
      }
      telaBase.carregando = false;
    });

    function selecionarArquivoUpload() {
      const elemento = document.getElementById('selecionarArquivoOfx');
      if (elemento !== null) {
        elemento.click();
      }
    }

    async function enviarArquivoParaLeitura(arquivo: any) {
      state.enviandoArquivo = true;
      const retornoImportacao = await servicoImportacaoOfx.enviarArquivoOfx(state.codigoConta, props.empresa, arquivo);
      if (retornoImportacao.sucesso) {
        state.retornoImportacaoOfx = retornoImportacao;
        if (state.retornoImportacaoOfx.transacoes.length > 0) {
          state.desabilitarSelecaoConta = true;
          state.ocultarUpload = true;
          state.recuperarSalvamentoAutomatico = false;
          if (UtilitarioGeral.valorValido(state.ultimoMovimentoSelecionado)) {
            await localizarTransacaoOfx();
          }
        }
      } else {
        apresentarMensagemAlerta(retornoImportacao.mensagem);
      }
      state.enviandoArquivo = false;
    }
    async function uploadArquivoSelecionado(event: any) {
      await enviarArquivoParaLeitura(event.target.files[0]);
    }

    async function uploadArquivoArrastado(event: any) {
      await enviarArquivoParaLeitura(event.dataTransfer.files[0]);
    }

    function obtemTextoContasPendentes(tipoMovimento: ETipoMovimentoFinanceiro): string {
      if (tipoMovimento === ETipoMovimentoFinanceiro.Pagamento) {
        return 'Contas a Pagar';
      } if (tipoMovimento === ETipoMovimentoFinanceiro.Recebimento) {
        return 'Contas a Receber';
      }
      return '';
    }
    function obtemTextoDetalheMovimentoTituloPendente(tipoMovimento: ETipoMovimentoFinanceiro, probabilidade: EProbabilidadeConciliacao, quantidadeTitulos: number): string {
      let titulo = 'Identificamos';

      if (quantidadeTitulos > 1) {
        titulo += ' várias ';
      } else {
        titulo += ' uma ';
      }

      titulo += obtemTextoContasPendentes(tipoMovimento);

      switch (probabilidade) {
        case EProbabilidadeConciliacao.Encontrado:
          if (quantidadeTitulos > 1) {
            titulo += ' muito parecidas.';
          } else {
            titulo += ' muito parecida.';
          }
          break;

        case EProbabilidadeConciliacao.ValorAproximado:
          if (quantidadeTitulos > 1) {
            titulo += ' com valores aproximados.';
          } else {
            titulo += ' com valor aproximado.';
          }
          break;

        case EProbabilidadeConciliacao.DataAproximada:
          if (quantidadeTitulos > 1) {
            titulo += ' com datas de vencimento aproximadas.';
          } else {
            titulo += ' com data de vencimento aproximada.';
          }
          break;
        case EProbabilidadeConciliacao.SomaVariosTitulos:
          titulo += ' que somadas totalizam este movimento.';
          break;

        default:
          break;
      }

      return titulo;
    }

    function obtemTextoDetalheMovimentoConta(tipoMovimento: ETipoMovimentoFinanceiro, probabilidade: EProbabilidadeConciliacao, quantidadeMovimentos: number): string {
      let titulo = 'Identificamos';

      if (probabilidade === EProbabilidadeConciliacao.Encontrado) {
        titulo = ' Movimento conciliado da seguinte forma:';
        return titulo;
      }

      if (quantidadeMovimentos > 1) {
        titulo += ' vários ';
      } else {
        titulo += ' um ';
      }

      if (tipoMovimento === ETipoMovimentoFinanceiro.Pagamento) {
        titulo += (quantidadeMovimentos > 1) ? 'Pagamentos' : 'Pagamento';
      } else if (tipoMovimento === ETipoMovimentoFinanceiro.Recebimento) {
        titulo += (quantidadeMovimentos > 1) ? 'Recebimentos' : 'Recebimento';
      }

      switch (probabilidade) {
        case EProbabilidadeConciliacao.Parecidos:
          if (quantidadeMovimentos > 1) {
            titulo += ' muito parecidos.';
          } else {
            titulo += ' muito parecido.';
          }
          break;

        case EProbabilidadeConciliacao.ValorAproximado:
          if (quantidadeMovimentos > 1) {
            titulo += ' com valores aproximados.';
          } else {
            titulo += ' com valor aproximado.';
          }
          break;

        case EProbabilidadeConciliacao.DataAproximada:
          if (quantidadeMovimentos > 1) {
            titulo += ' com datas de movimento aproximadas.';
          } else {
            titulo += ' com data de movimento aproximada.';
          }
          break;

        case EProbabilidadeConciliacao.SomaVariosMovimentos:
          titulo += ' que somados totalizam este movimento.';
          break;
        default:
          break;
      }

      return titulo;
    }

    function obtemTipoAlertaBaseadoNaProbabilidade(probabilidade: EProbabilidadeConciliacao): string {
      if (probabilidade === EProbabilidadeConciliacao.Encontrado) {
        return 'success';
      }
      return 'info';
    }

    function preparaTituloBaixa(codigoTituloFinanceiro: number, valorJurosMulta: number, valorDesconto: number, valorPago: number, valorAbatimentos: number): IBaixaTitulosTituloFinanceiroBaixado {
      const tituloBaixa = {} as IBaixaTitulosTituloFinanceiroBaixado;
      tituloBaixa.codigo = 0;
      tituloBaixa.codigoBaixaTitulosMovimentoFinanceiro = 0;
      tituloBaixa.codigoTituloFinanceiro = codigoTituloFinanceiro;
      tituloBaixa.valorJurosMulta = valorJurosMulta;
      tituloBaixa.valorDesconto = valorDesconto;
      tituloBaixa.valorAcrescimo = 0;
      tituloBaixa.valorTaxa = 0;
      tituloBaixa.valorAbatimento = valorAbatimentos;
      tituloBaixa.valorPago = valorPago;
      return tituloBaixa;
    }

    function preparaMovimentoFinanceiroBaixa(movimentoFinanceiro: IMovimentoFinanceiro, numeroControle: string, titulosFinanceiros: IDTOTituloFinanceiro[], valorJurosMulta: number, valorDesconto: number): IBaixaTitulosMovimentoFinanceiro[] {
      const movimentosFinanceiros: IBaixaTitulosMovimentoFinanceiro[] = [];

      let detalhamentosTitulo:IDTOTituloFinanceiroDetalhamento[] = [];
      let totalAbatimentosTitulo = 0;
      if (UtilitarioGeral.validaLista(state.detalhesPagamentos)) {
        detalhamentosTitulo = state.detalhesPagamentos.filter((c) => c.codigoTituloFinanceiro === titulosFinanceiros[0].codigo);
      }
      if (UtilitarioGeral.validaLista(detalhamentosTitulo)) {
        detalhamentosTitulo = detalhamentosTitulo.sort((a, b) => a.ordem - b.ordem);
        const detalhamentosInformativos = detalhamentosTitulo.filter((detalhamentosTitulo) => detalhamentosTitulo.tipo === ETipoDetalhamentoTituloFinanceiro.Informativo);
        if (!UtilitarioGeral.validaLista(detalhamentosInformativos)) {
          apresentarMensagemAlerta(`O título: ${titulosFinanceiros[0].numeroTitulo} não possuí detalhamentos informativos.`);
          state.movimentosInvalidos = true;
          return [];
        }
        const totalInformativosTitulo = UtilitarioFinanceiro.valorDecimal2Casas(detalhamentosInformativos.reduce((valorAcumulado, detalhamento) => valorAcumulado + detalhamento.valor, 0));

        const detalhamentosAbatimentos = detalhamentosTitulo.filter((detalhamentosTitulo) => detalhamentosTitulo.tipo === ETipoDetalhamentoTituloFinanceiro.Abatimento);
        if (UtilitarioGeral.validaLista(detalhamentosAbatimentos)) {
          totalAbatimentosTitulo = UtilitarioFinanceiro.valorDecimal2Casas(detalhamentosAbatimentos.reduce((valorAcumulado, detalhamento) => valorAcumulado + detalhamento.valor, 0));
        }

        const totalLiquidoDetalhamentos = UtilitarioFinanceiro.valorDecimal2Casas(totalInformativosTitulo - totalAbatimentosTitulo);
        const saldoTitulo = UtilitarioFinanceiro.valorDecimal2Casas(titulosFinanceiros[0].saldo);
        if (totalLiquidoDetalhamentos !== saldoTitulo) {
          apresentarMensagemAlerta(`O total dos detalhamentos não bate com o saldo do título: ${titulosFinanceiros[0].numeroTitulo}.`);
          state.movimentosInvalidos = true;
          return [];
        }

        const maiorDetalhamentoInformativo = detalhamentosInformativos.reduce((max, detalhamento) => (detalhamento.valor > max.valor ? detalhamento : max));
        const indexMaiorDetalhamentoInformativo = detalhamentosTitulo.findIndex((detalhamento) => detalhamento === maiorDetalhamentoInformativo);
        for (let indexDetalhamento = 0; indexDetalhamento < detalhamentosTitulo.length; indexDetalhamento += 1) {
          numeroMovimentoControle += 1;
          const movimento = {} as IBaixaTitulosMovimentoFinanceiro;
          movimento.movimentoFinanceiro = {} as IMovimentoFinanceiro;
          movimento.movimentoFinanceiro = UtilitarioGeral.instanciaObjetoLocal(movimentoFinanceiro);
          movimento.referente = EMovimentoFinanceiroBaixaReferente.BaixaTitulo;
          movimento.codigoTituloFinanceiroOrigem = titulosFinanceiros[0].codigo; // Considerando implementações até 26/09/2024 onde titulosFinanceiros esta sendo passado como um único titulo.
          movimento.movimentoFinanceiro.codigoConta = state.codigoConta;
          movimento.movimentoFinanceiro.codigoPlanoContaCategoria = detalhamentosTitulo[indexDetalhamento].codigoPlanoContaCategoria;
          movimento.movimentoFinanceiro.codigoEmpresa = props.empresa;
          movimento.movimentoFinanceiro.codigoPessoa = titulosFinanceiros[0].codigoPessoa;
          movimento.movimentoFinanceiro.codigoTipoDocumentoFinanceiro = titulosFinanceiros[0].codigoTipoDocumentoFinanceiro;
          movimento.movimentoFinanceiro.descricao = `BAIXA - ${detalhamentosTitulo[indexDetalhamento].descricao}`;
          movimento.movimentoFinanceiro.observacoes = 'Conciliado via extrato - OFX';
          movimento.movimentoFinanceiro.numeroControle = numeroControle;
          movimento.movimentoFinanceiro.conciliado = true;
          movimento.movimentoFinanceiro.influenciaSaldo = true;
          movimento.movimentoFinanceiro.identificadorMovimento = `${titulosFinanceiros[0].numeroTitulo}-${UtilitarioGeral.gerarIdentificadorTexto(detalhamentosTitulo[indexDetalhamento].descricao).toUpperCase()}-M${numeroMovimentoControle}`;
          movimento.movimentoFinanceiro.marcadores = [];
          movimento.movimentoFinanceiro.recursoOrigem = state.recursoReferenciaBaixa;
          movimento.movimentoFinanceiro.valor = detalhamentosTitulo[indexDetalhamento].valor;
          movimento.movimentoFinanceiro.anexos = [];

          if (detalhamentosTitulo[indexDetalhamento].tipo === ETipoDetalhamentoTituloFinanceiro.Informativo) {
            movimento.referente = EMovimentoFinanceiroBaixaReferente.BaixaTitulo;
            movimento.movimentoFinanceiro.tipoMovimento = titulosFinanceiros[0].tipo === ETipoTituloFinanceiro.Receber ? ETipoMovimentoFinanceiro.Recebimento : ETipoMovimentoFinanceiro.Pagamento;
          } else if (detalhamentosTitulo[indexDetalhamento].tipo === ETipoDetalhamentoTituloFinanceiro.Abatimento) {
            movimento.referente = EMovimentoFinanceiroBaixaReferente.Abatimento;
            movimento.movimentoFinanceiro.tipoMovimento = titulosFinanceiros[0].tipo === ETipoTituloFinanceiro.Receber ? ETipoMovimentoFinanceiro.Pagamento : ETipoMovimentoFinanceiro.Recebimento;
          }

          movimento.titulosBaixados = [] as IBaixaTitulosTituloFinanceiroBaixado[];
          if (indexDetalhamento === indexMaiorDetalhamentoInformativo) {
            titulosFinanceiros.forEach((tituloFinanceiro) => {
              movimento.titulosBaixados.push(preparaTituloBaixa(tituloFinanceiro.codigo, valorJurosMulta, valorDesconto, tituloFinanceiro.saldo, totalAbatimentosTitulo));
            });
          }
          movimentosFinanceiros.push(movimento);
        }
      } else {
        const movimento = {} as IBaixaTitulosMovimentoFinanceiro;
        movimento.movimentoFinanceiro = UtilitarioGeral.instanciaObjetoLocal(movimentoFinanceiro);
        movimento.referente = EMovimentoFinanceiroBaixaReferente.BaixaTitulo;
        movimento.codigoTituloFinanceiroOrigem = titulosFinanceiros[0].codigo; // Considerando implementações até 26/09/2024 onde titulosFinanceiros esta sendo passado como um único titulo.
        movimento.movimentoFinanceiro.codigoConta = state.codigoConta;
        movimento.movimentoFinanceiro.codigoPlanoContaCategoria = titulosFinanceiros[0].codigoPlanoContaCategoria;
        movimento.movimentoFinanceiro.codigoEmpresa = props.empresa;
        movimento.movimentoFinanceiro.codigoPessoa = titulosFinanceiros[0].codigoPessoa;
        movimento.movimentoFinanceiro.codigoTipoDocumentoFinanceiro = titulosFinanceiros[0].codigoTipoDocumentoFinanceiro;
        movimento.movimentoFinanceiro.descricao += ' BAIXA';
        movimento.movimentoFinanceiro.observacoes = 'Conciliado via extrato - OFX';
        movimento.movimentoFinanceiro.numeroControle = numeroControle;
        movimento.movimentoFinanceiro.conciliado = true;
        movimento.movimentoFinanceiro.influenciaSaldo = true;
        movimento.movimentoFinanceiro.marcadores = [];
        movimento.movimentoFinanceiro.recursoOrigem = state.recursoReferenciaBaixa;
        movimento.movimentoFinanceiro.anexos = [];
        movimento.movimentoFinanceiro.valor = 0;
        movimento.titulosBaixados = [] as IBaixaTitulosTituloFinanceiroBaixado[];
        titulosFinanceiros.forEach((tituloFinanceiro) => {
          movimento.titulosBaixados.push(preparaTituloBaixa(tituloFinanceiro.codigo, valorJurosMulta, valorDesconto, tituloFinanceiro.saldo, 0));
          movimento.movimentoFinanceiro.identificadorMovimento = `${tituloFinanceiro.numeroTitulo}-BAIXA-M${numeroMovimentoControle}`;
          movimento.movimentoFinanceiro.valor += tituloFinanceiro.saldo;
          numeroMovimentoControle += 1;
        });
        movimentosFinanceiros.push(movimento);
      }

      return movimentosFinanceiros;
    }

    function preparaMovimentoFinanceiroJurosOuDescontoBaixa(movimentoFinanceiro: IMovimentoFinanceiro, numeroControle: string, tituloFinanceiro: IDTOTituloFinanceiro, valorJurosMulta: number, valorDesconto: number, tipoMovimento: ETipoTituloFinanceiro): IBaixaTitulosMovimentoFinanceiro {
      const movimento = {} as IBaixaTitulosMovimentoFinanceiro;
      movimento.movimentoFinanceiro = UtilitarioGeral.instanciaObjetoLocal(movimentoFinanceiro);
      movimento.codigoTituloFinanceiroOrigem = tituloFinanceiro.codigo;
      movimento.movimentoFinanceiro.codigoConta = state.codigoConta;
      movimento.movimentoFinanceiro.codigoEmpresa = props.empresa;
      movimento.movimentoFinanceiro.codigoPessoa = tituloFinanceiro.codigoPessoa;
      movimento.movimentoFinanceiro.codigoTipoDocumentoFinanceiro = tituloFinanceiro.codigoTipoDocumentoFinanceiro;
      movimento.movimentoFinanceiro.conciliado = true;
      movimento.movimentoFinanceiro.influenciaSaldo = true;
      movimento.movimentoFinanceiro.marcadores = [];
      if (valorJurosMulta > 0) {
        movimento.referente = EMovimentoFinanceiroBaixaReferente.JurosMulta;
        movimento.movimentoFinanceiro.tipoMovimento = tipoMovimento === ETipoTituloFinanceiro.Receber ? ETipoMovimentoFinanceiro.Recebimento : ETipoMovimentoFinanceiro.Pagamento;
        movimento.movimentoFinanceiro.codigoPlanoContaCategoria = state.codigoCategoriaJurosMulta;
        movimento.movimentoFinanceiro.descricao += ' JUROS/MULTA';
        movimento.movimentoFinanceiro.valor = valorJurosMulta;
        movimento.movimentoFinanceiro.identificadorMovimento = `${tituloFinanceiro.numeroTitulo}-JUROS-MULTA-M${numeroMovimentoControle}`;
      } else if (valorDesconto > 0) {
        movimento.referente = EMovimentoFinanceiroBaixaReferente.Desconto;
        movimento.movimentoFinanceiro.tipoMovimento = tipoMovimento === ETipoTituloFinanceiro.Receber ? ETipoMovimentoFinanceiro.Pagamento : ETipoMovimentoFinanceiro.Recebimento;
        movimento.movimentoFinanceiro.codigoPlanoContaCategoria = state.codigoCategoriaDesconto;
        movimento.movimentoFinanceiro.descricao += ' DESCONTO';
        movimento.movimentoFinanceiro.identificadorMovimento = `${tituloFinanceiro.numeroTitulo}-DESCONTO-M${numeroMovimentoControle}`;
        movimento.movimentoFinanceiro.valor = valorDesconto;
      }
      movimento.movimentoFinanceiro.observacoes = 'Conciliado via extrato - OFX';
      movimento.movimentoFinanceiro.numeroControle = numeroControle;
      movimento.movimentoFinanceiro.recursoOrigem = state.recursoReferenciaBaixa;
      movimento.movimentoFinanceiro.anexos = [];
      movimento.titulosBaixados = [] as IBaixaTitulosTituloFinanceiroBaixado[];
      numeroMovimentoControle += 1;
      return movimento;
    }

    async function prepararBaixa(movimentoFinanceiro: IMovimentoFinanceiro, numeroControle: string, titulosFinanceiros: IDTOTituloFinanceiro[], valorJurosMulta: number, valorDesconto: number, baixaPorTitulosSelecionados: boolean): Promise<IBaixaTitulos> {
      const baixa = {} as IBaixaTitulos;
      baixa.codigoEmpresa = props.empresa;
      baixa.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      baixa.tipoTituloFinanceiro = titulosFinanceiros[0].tipo;
      baixa.dataBaixa = movimentoFinanceiro.dataMovimento;
      baixa.tipoAcrescimo = 2;
      baixa.tipoDesconto = 2;
      baixa.acrescimo = 0;
      baixa.descontoAdicional = 0;
      baixa.percentualJuros = 0;
      baixa.percentualMulta = 0;
      baixa.percentualDesconto = 0;
      baixa.totalAcrescimos = 0;
      baixa.totalAbatimentos = 0;
      baixa.totalTaxas = 0;
      baixa.totalJurosMulta = valorJurosMulta;
      baixa.totalDescontos = valorDesconto;
      baixa.totalAbatimentos = 0;
      baixa.totalTaxas = 0;
      baixa.totalSobraValores = 0;
      baixa.totalTitulosRepassados = 0;
      baixa.totalTitulos = 0;
      baixa.qtdTitulos = 0;
      baixa.titulosRepassados = [];
      baixa.titulosLancados = [];
      baixa.movimentosFinanceiros = [];
      state.recursoReferenciaBaixa = titulosFinanceiros[0].tipo === ETipoTituloFinanceiro.Pagar ? 'BAIXAS_CONTAS_PAGAR' : 'BAIXAS_CONTAS_RECEBER';
      baixa.totalBaixa = 0;

      const dados:IDTOTituloFinanceiroObterDetalhamentoVariosTitulos = {} as IDTOTituloFinanceiroObterDetalhamentoVariosTitulos;
      dados.empresas = titulosFinanceiros.map((c) => c.codigoEmpresa);
      dados.tipoTituloFinanceiro = baixa.tipoTituloFinanceiro;
      dados.codigosTituloFinanceiros = titulosFinanceiros.map((c) => c.codigo);

      if (baixa.tipoTituloFinanceiro === ETipoTituloFinanceiro.Receber) {
        state.detalhesPagamentos = await servicoTituloFinanceiroReceber.obterDetalhamentosVariosTitulos(dados);
      } else if (baixa.tipoTituloFinanceiro === ETipoTituloFinanceiro.Pagar) {
        state.detalhesPagamentos = await servicoTituloFinanceiroPagar.obterDetalhamentosVariosTitulos(dados);
      }

      if (UtilitarioGeral.validaLista(state.detalhesPagamentos)) {
        const detalhamentosAbatimentos = state.detalhesPagamentos.filter((detalhamentosTitulo) => detalhamentosTitulo.tipo === ETipoDetalhamentoTituloFinanceiro.Abatimento);
        if (UtilitarioGeral.validaLista(detalhamentosAbatimentos)) {
          baixa.totalAbatimentos = UtilitarioFinanceiro.valorDecimal2Casas(detalhamentosAbatimentos.reduce((valorAcumulado, detalhamento) => valorAcumulado + detalhamento.valor, 0));
        }
      }

      titulosFinanceiros.forEach((tituloFinanceiro) => {
        baixa.totalTitulos += tituloFinanceiro.saldo;
        baixa.qtdTitulos += 1;
        if (baixaPorTitulosSelecionados) {
          const valoresTituloBaixa = state.valoresTitulosSelecionados.find((c) => c.codigoTituloFinanceiro === tituloFinanceiro.codigo);
          if (valoresTituloBaixa !== undefined) {
            baixa.movimentosFinanceiros.push(...preparaMovimentoFinanceiroBaixa(movimentoFinanceiro, numeroControle, [tituloFinanceiro], valoresTituloBaixa.valorJurosMulta, valoresTituloBaixa.valorDesconto));
            if (state.movimentosInvalidos) {
              baixa.movimentosFinanceiros = [];
              return;
            }
            if (valoresTituloBaixa.valorJurosMulta > 0) {
              baixa.movimentosFinanceiros.push(preparaMovimentoFinanceiroJurosOuDescontoBaixa(movimentoFinanceiro, numeroControle, tituloFinanceiro, valoresTituloBaixa.valorJurosMulta, 0, baixa.tipoTituloFinanceiro));
            }
            if (valoresTituloBaixa.valorDesconto > 0) {
              baixa.movimentosFinanceiros.push(preparaMovimentoFinanceiroJurosOuDescontoBaixa(movimentoFinanceiro, numeroControle, tituloFinanceiro, 0, valoresTituloBaixa.valorDesconto, baixa.tipoTituloFinanceiro));
            }
          }
        } else {
          baixa.movimentosFinanceiros.push(...preparaMovimentoFinanceiroBaixa(movimentoFinanceiro, numeroControle, [tituloFinanceiro], valorJurosMulta, valorDesconto));
          if (state.movimentosInvalidos) {
            baixa.movimentosFinanceiros = [];
            return;
          }

          if (valorJurosMulta > 0) {
            baixa.movimentosFinanceiros.push(preparaMovimentoFinanceiroJurosOuDescontoBaixa(movimentoFinanceiro, numeroControle, tituloFinanceiro, valorJurosMulta, 0, baixa.tipoTituloFinanceiro));
          }
          if (valorDesconto > 0) {
            baixa.movimentosFinanceiros.push(preparaMovimentoFinanceiroJurosOuDescontoBaixa(movimentoFinanceiro, numeroControle, tituloFinanceiro, 0, valorDesconto, baixa.tipoTituloFinanceiro));
          }
        }
      });
      baixa.totalBaixa = ((baixa.totalTitulos + valorJurosMulta) - (valorDesconto));

      return baixa;
    }

    async function concluirConciliacao() {
      const movimentosConciliados = await servicoImportacaoOfx.obterMovimentosConciliados(state.codigoConta, props.empresa, state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].numeroControle);
      if (UtilitarioGeral.validaLista(movimentosConciliados)) {
        state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].provaveisMovimentosFinanceiros = [];
        state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].provaveisTitulosFinanceiros = [];
        state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentosConciliados = movimentosConciliados;
        state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].statusConciliacao = EStatusConciliacaoOfx.Conciliado;
        movimentacaoAbaixo();
      } else {
        apresentarMensagemAlerta('Desculpe-nós não conseguimos concluir a conciliação deste movimento.');
      }
    }

    function ignorarMovimento() {
      state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].statusConciliacao = EStatusConciliacaoOfx.Ignorado;
      movimentacaoAbaixo();
    }

    function calcularTotalBaixaTitulosSelecionados() {
      state.totalBaixaTitulosSelecionados = 0;

      let totalBaixaTitulosSelecionados = 0;
      let possuiTituloSelecionados = false;
      state.mensagemBaixaTitulosSelecionados = '';

      if (UtilitarioGeral.validaLista(state.titulosFinanceirosSelecionados)) {
        state.titulosFinanceirosSelecionados.forEach((tituloFinanceiro) => {
          totalBaixaTitulosSelecionados += tituloFinanceiro.saldo;
          possuiTituloSelecionados = true;
        });
      }

      if (UtilitarioGeral.validaLista(state.valoresTitulosSelecionados)) {
        state.valoresTitulosSelecionados.forEach((valoresTituloFinanceiro) => {
          totalBaixaTitulosSelecionados += valoresTituloFinanceiro.valorJurosMulta;
          totalBaixaTitulosSelecionados -= valoresTituloFinanceiro.valorDesconto;
        });
      }

      const valorMovimento = UtilitarioFinanceiro.valorDecimal2Casas(state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentoConta.valor);
      totalBaixaTitulosSelecionados = UtilitarioFinanceiro.valorDecimal2Casas(totalBaixaTitulosSelecionados);
      if (possuiTituloSelecionados && totalBaixaTitulosSelecionados < valorMovimento) {
        state.mensagemBaixaTitulosSelecionados = `Restam R$ ${UtilitarioMascara.mascararValor((valorMovimento - totalBaixaTitulosSelecionados), 2)} para você realizar a baixa.`;
      } else if (possuiTituloSelecionados && totalBaixaTitulosSelecionados > valorMovimento) {
        const diferenca = UtilitarioFinanceiro.valorDecimal2Casas((totalBaixaTitulosSelecionados - valorMovimento));
        if (diferenca > 0) {
          state.mensagemBaixaTitulosSelecionados = `O valor dos títulos selecionados excedeu R$ ${UtilitarioMascara.mascararValor(diferenca, 2)} em relação ao movimento a ser conciliado.`;
        }
      }

      state.totalBaixaTitulosSelecionados = totalBaixaTitulosSelecionados;
    }

    async function efetuarBaixaConciliar(tituloFinanceiros: IDTOTituloFinanceiro[], valorJurosMulta: number, valorDesconto: number, baixaPorTitulosSelecionados = false) {
      numeroMovimentoControle = 1;
      if (baixaPorTitulosSelecionados) {
        state.exibirConfirmacaoCategoriaBaixa = false;
        if (UtilitarioGeral.validaLista(state.valoresTitulosSelecionados)) {
          state.valoresTitulosSelecionados.forEach((valoresTituloFinanceiro) => {
            state.valorJurosMulta += valoresTituloFinanceiro.valorJurosMulta;
            state.valorDesconto += valoresTituloFinanceiro.valorDesconto;
          });
        }

        if (state.valorJurosMulta > 0 && !UtilitarioGeral.validaCodigo(state.codigoCategoriaJurosMulta)) {
          apresentarMensagemAlerta('É necessário definir a categoria para lançamento de Juros/Multa.');
          state.exibirConfirmacaoCategoriaBaixa = true;
          return;
        } if (state.valorDesconto > 0 && !UtilitarioGeral.validaCodigo(state.codigoCategoriaDesconto)) {
          apresentarMensagemAlerta('É necessário definir a categoria para lançamento de Desconto.');
          state.exibirConfirmacaoCategoriaBaixa = true;
          return;
        }
      }
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso();
      state.movimentosInvalidos = false;
      const baixa = await prepararBaixa(state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentoConta, state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].numeroControle, tituloFinanceiros, valorJurosMulta, valorDesconto, baixaPorTitulosSelecionados);
      if (state.movimentosInvalidos) {
        ocultarBarraProgresso();
        return;
      }
      retorno = await servicoImportacaoOfx.baixarTitulos(baixa);
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        tituloFinanceiros.forEach((tituloFinanceiro) => {
          state.codigosTitulosBaixados.push(tituloFinanceiro.codigo);
        });
        // Atualiza provaveis movimentos financeiros somente com a opção conciliada
        await concluirConciliacao();
        if (baixaPorTitulosSelecionados) {
          state.titulosFinanceirosSelecionados = [];
        }
        apresentarMensagemSucesso('Baixa concluída com sucesso!');
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function confirmarCategoriaBaixa() {
      if (state.valorJurosMulta > 0 && !UtilitarioGeral.validaCodigo(state.codigoCategoriaJurosMulta)) {
        apresentarMensagemAlerta('É necessário definir a categoria para lançamento de Juros/Multa.');
        return;
      } if (state.valorDesconto > 0 && !UtilitarioGeral.validaCodigo(state.codigoCategoriaDesconto)) {
        apresentarMensagemAlerta('É necessário definir a categoria para lançamento de Desconto.');
        return;
      }

      state.exibirConfirmacaoCategoriaBaixa = false;
      await efetuarBaixaConciliar([state.tituloFinanceiroBaixaJurosDesconto], state.valorJurosMulta, state.valorDesconto);
    }

    async function conciliar(movimentoFinanceiro: IDTOMovimentoFinanceiro) {
      const conciliarMovimento: IDTOConciliarImportacaoOfx = {} as IDTOConciliarImportacaoOfx;
      conciliarMovimento.codigoMovimentoFinanceiro = movimentoFinanceiro.codigo;
      conciliarMovimento.codigoEmpresa = movimentoFinanceiro.codigoEmpresa;
      conciliarMovimento.codigoConta = movimentoFinanceiro.codigoConta;
      conciliarMovimento.dataMovimento = movimentoFinanceiro.dataMovimento;
      conciliarMovimento.identificadorMovimento = movimentoFinanceiro.identificadorMovimento;
      conciliarMovimento.observacoes = UtilitarioGeral.valorValido(movimentoFinanceiro.observacoes) ? movimentoFinanceiro.observacoes : '';
      conciliarMovimento.observacoes += 'Conciliado via extrato - OFX';
      conciliarMovimento.numeroControle = state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].numeroControle;
      const retorno = await servicoImportacaoOfx.conciliarMovimento(conciliarMovimento);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        const movimentoConciliado = UtilitarioGeral.instanciaObjetoLocal(movimentoFinanceiro);
        state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].provaveisMovimentosFinanceiros = [];
        state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentosConciliados = [];
        state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentosConciliados.push(movimentoConciliado);
        state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].statusConciliacao = EStatusConciliacaoOfx.Conciliado;
        apresentarMensagemSucesso('Movimento conciliado com sucesso!');
        movimentacaoAbaixo();
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta('Não foi possível conciliar o movimento da conta!');
      }
    }

    async function conciliarVariosMovimentos(movimentosFinanceiros: IDTOMovimentoFinanceiro[]) {
      const conciliarMovimentos: IDTOConciliarImportacaoOfxVariosMovimentos = {} as IDTOConciliarImportacaoOfxVariosMovimentos;
      conciliarMovimentos.codigoEmpresa = movimentosFinanceiros[0].codigoEmpresa;
      conciliarMovimentos.codigoConta = movimentosFinanceiros[0].codigoConta;
      conciliarMovimentos.numeroControle = state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].numeroControle;
      conciliarMovimentos.codigosMovimentosFinanceiros = [];
      conciliarMovimentos.codigosMovimentosFinanceiros = movimentosFinanceiros.map((c) => c.codigo);
      const retorno = await servicoImportacaoOfx.conciliarVariosMovimentos(conciliarMovimentos);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].provaveisMovimentosFinanceiros = [];
        state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentosConciliados = [];
        state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentosConciliados = movimentosFinanceiros;
        state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].statusConciliacao = EStatusConciliacaoOfx.Conciliado;
        if (UtilitarioGeral.validaLista(state.movimentosFinanceirosSelecionados)) {
          state.movimentosFinanceirosSelecionados = [];
          state.totalMovimentosSelecionados = 0;
          state.mensagemMovimentosSelecionados = '';
          limparBuscaMovimentosFinanceiros();
        }
        apresentarMensagemSucesso('Movimentos conciliados com sucesso!');
        movimentacaoAbaixo();
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta('Não foi possível conciliar o movimento da conta!');
      }
    }

    async function desconciliarVariosMovimentos(movimentosFinanceiros: IDTOMovimentoFinanceiro[]) {
      const desconciliarMovimentos: IDTODesconciliarImportacaoOfxVariosMovimentos = {} as IDTODesconciliarImportacaoOfxVariosMovimentos;
      desconciliarMovimentos.codigoEmpresa = movimentosFinanceiros[0].codigoEmpresa;
      desconciliarMovimentos.codigoConta = movimentosFinanceiros[0].codigoConta;
      desconciliarMovimentos.codigosMovimentosFinanceiros = [];
      desconciliarMovimentos.codigosMovimentosFinanceiros = movimentosFinanceiros.map((c) => c.codigo);
      const retorno = await servicoImportacaoOfx.desconciliarVariosMovimentos(desconciliarMovimentos);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].provaveisMovimentosFinanceiros = [];
        state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentosConciliados = [];
        state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].statusConciliacao = EStatusConciliacaoOfx.NaoConciliado;
        apresentarMensagemSucesso('Movimentos desconciliados com sucesso!');
        movimentacaoAbaixo();
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta('Não foi possível desconciliar o movimento OFX na conta!');
      }
    }

    function verificaBaixaTitulo(codigoTituloFinanceiro: number): boolean {
      return state.codigosTitulosBaixados.some((c) => c === codigoTituloFinanceiro);
    }

    function verificaMovimentoConciliado(codigoTituloFinanceiro: number): boolean {
      return state.codigosTitulosBaixados.some((c) => c === codigoTituloFinanceiro);
    }

    function obterClassMovimentoOfx(index: number, transacao: IDTOTransacaoOfx): string {
      if (state.apresentarStatus === 0) {
        return state.indexTransacaoSelecionada === index ? 'ant-row ss-importacao-ofx-grade-personalizada-linha-ativa' : 'ant-row ss-importacao-ofx-grade-personalizada-linha';
      }
      if (state.apresentarStatus === transacao.statusConciliacao) {
        return state.indexTransacaoSelecionada === index ? 'ant-row ss-importacao-ofx-grade-personalizada-linha-ativa' : 'ant-row ss-importacao-ofx-grade-personalizada-linha';
      }

      return 'ant-row ss-importacao-ofx-grade-personalizada-linha ss-ocultar';
    }

    function omitirTitulosBusca(): number[] {
      const codigoTitulosFinanceiros: number[] = [];
      if (UtilitarioGeral.validaLista(state.codigosTitulosBaixados)) {
        state.codigosTitulosBaixados.forEach((codigo) => {
          codigoTitulosFinanceiros.push(codigo);
        });
      }

      if (UtilitarioGeral.validaLista(state.titulosFinanceirosSelecionados)) {
        state.titulosFinanceirosSelecionados.forEach((tituloFinanceiro) => {
          codigoTitulosFinanceiros.push(tituloFinanceiro.codigo);
        });
      }
      return codigoTitulosFinanceiros;
    }

    function removerTituloSelecionado(codigo: number) {
      const indexValorTituloSelecionado = state.valoresTitulosSelecionados.findIndex((c) => c.codigoTituloFinanceiro === codigo);
      if (indexValorTituloSelecionado >= 0) {
        state.valoresTitulosSelecionados.splice(indexValorTituloSelecionado, 1);
      }
      const indexTituloSelecionado = state.titulosFinanceirosSelecionados.findIndex((c) => c.codigo === codigo);
      if (indexTituloSelecionado >= 0) {
        state.titulosFinanceirosSelecionados.splice(indexTituloSelecionado, 1);
      }
      calcularTotalBaixaTitulosSelecionados();
    }

    function preencherTitulosSelecionados(titulosSelecionados: IDTOTituloFinanceiro[]) {
      titulosSelecionados.forEach((tituloFinanceiro) => {
        if (!state.titulosFinanceirosSelecionados.some((c) => c.codigo === tituloFinanceiro.codigo)) {
          const valorTitulosSelecionado: ITituloValoresBaixa = {} as ITituloValoresBaixa;
          valorTitulosSelecionado.codigoTituloFinanceiro = tituloFinanceiro.codigo;
          valorTitulosSelecionado.valorJurosMulta = 0;
          valorTitulosSelecionado.valorDesconto = 0;
          state.valoresTitulosSelecionados.push(valorTitulosSelecionado);
          state.titulosFinanceirosSelecionados.push(tituloFinanceiro);
        }
      });

      calcularTotalBaixaTitulosSelecionados();
    }

    function calcularTotalMovimentosSelecionados() {
      state.totalMovimentosSelecionados = 0;

      let totalMovimentosSelecionados = 0;
      let possuiMovimentosSelecionados = false;
      state.mensagemMovimentosSelecionados = '';

      if (UtilitarioGeral.validaLista(state.movimentosFinanceirosSelecionados)) {
        state.movimentosFinanceirosSelecionados.forEach((movimentoFinanceiro) => {
          if (state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentoConta.tipoMovimento === ETipoMovimentoFinanceiro.Recebimento) {
            if (movimentoFinanceiro.tipoMovimento === ETipoMovimentoFinanceiro.Recebimento) {
              totalMovimentosSelecionados += movimentoFinanceiro.valor;
            } else {
              totalMovimentosSelecionados -= movimentoFinanceiro.valor;
            }
          } else if (state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentoConta.tipoMovimento === ETipoMovimentoFinanceiro.Pagamento) {
            if (movimentoFinanceiro.tipoMovimento === ETipoMovimentoFinanceiro.Pagamento) {
              totalMovimentosSelecionados += movimentoFinanceiro.valor;
            } else {
              totalMovimentosSelecionados -= movimentoFinanceiro.valor;
            }
          }
          possuiMovimentosSelecionados = true;
        });
      }

      const valorMovimento = UtilitarioFinanceiro.valorDecimal2Casas(state.retornoImportacaoOfx.transacoes[state.indexTransacaoSelecionada].movimentoConta.valor);
      totalMovimentosSelecionados = UtilitarioFinanceiro.valorDecimal2Casas(totalMovimentosSelecionados);

      if (possuiMovimentosSelecionados && totalMovimentosSelecionados < valorMovimento) {
        state.mensagemMovimentosSelecionados = `Restam R$ ${UtilitarioMascara.mascararValor((valorMovimento - totalMovimentosSelecionados), 2)} para você conciliar essa movimentação do OFX.`;
      } else if (possuiMovimentosSelecionados && totalMovimentosSelecionados > valorMovimento) {
        const diferenca = UtilitarioFinanceiro.valorDecimal2Casas((totalMovimentosSelecionados - valorMovimento));
        if (diferenca > 0) {
          state.mensagemMovimentosSelecionados = `O valor dos movimentos selecionados excedeu R$ ${UtilitarioMascara.mascararValor(diferenca, 2)} em relação ao movimento a ser conciliado.`;
        }
      }

      state.totalMovimentosSelecionados = totalMovimentosSelecionados;
    }

    function preencherMovimentosFinanceirosSelecionados(movimentosFinanceiros: IDTOMovimentoFinanceiro[]) {
      movimentosFinanceiros.forEach((movimentoFinanceiro) => {
        if (!state.movimentosFinanceirosSelecionados.some((c) => c.codigo === movimentoFinanceiro.codigo)) {
          state.movimentosFinanceirosSelecionados.push(movimentoFinanceiro);
        }
      });

      calcularTotalMovimentosSelecionados();
    }

    function removerMovimentoFinanceiroSelecionado(codigo: number) {
      const indexMovimentoFinanceiroSelecionado = state.movimentosFinanceirosSelecionados.findIndex((c) => c.codigo === codigo);
      if (indexMovimentoFinanceiroSelecionado >= 0) {
        state.movimentosFinanceirosSelecionados.splice(indexMovimentoFinanceiroSelecionado, 1);
      }
      calcularTotalMovimentosSelecionados();
    }

    return {
      props,
      state,
      emit,
      telaBase,
      modalBase,
      gradeBase,
      storeSistema,
      EPermissaoDados,
      ETipoPlanoConta,
      ETipoMovimentoFinanceiro,
      EStatusConciliacaoOfx,
      EProbabilidadeConciliacao,
      UtilitarioGeral,
      apresentarRetornoRequisicao,
      UtilitarioMascara,
      UtilitarioData,
      selecionarArquivoUpload,
      uploadArquivoSelecionado,
      uploadArquivoArrastado,
      defineMovimentoOfxSelecionado,
      obtemTextoDetalheMovimentoTituloPendente,
      obtemTextoDetalheMovimentoConta,
      obtemTipoAlertaBaseadoNaProbabilidade,
      obtemTextoContasPendentes,
      movimentacaoAcima,
      movimentacaoAbaixo,
      efetuarBaixaConciliar,
      concluirConciliacao,
      novoMovimento,
      abrirTransferencia,
      ignorarMovimento,
      abrirConfirmacaoCategoriaBaixa,
      confirmarCategoriaBaixa,
      conciliar,
      conciliarVariosMovimentos,
      verificaBaixaTitulo,
      verificaMovimentoConciliado,
      abrirBuscaTitulos,
      preencherTitulosSelecionados,
      salvamentoAutomatico,
      estrategiaAlteracaoConta,
      recuperarSalvamentoAutomatico,
      obterClassMovimentoOfx,
      limparBuscaTitulos,
      calcularTotalBaixaTitulosSelecionados,
      omitirTitulosBusca,
      proximoElemento,
      elementoAnterior,
      elementoAcima,
      elementoAbaixo,
      removerTituloSelecionado,
      abrirBuscaMovimentosFinanceiros,
      limparBuscaMovimentosFinanceiros,
      preencherMovimentosFinanceirosSelecionados,
      removerMovimentoFinanceiroSelecionado,
      calcularTotalMovimentosSelecionados,
      desconciliarVariosMovimentos,
    };
  },
});
