
import { defineComponent, reactive, watch } from 'vue';
import { Modal } from 'ant-design-vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import SelecionarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiro.vue';
import SelecionarMarcadorMovimentoFinanceiro from '@/components/Financeiro/MovimentosFinanceiros/SelecionarMarcadorMovimentoFinanceiro.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IMovimentoFinanceiro, IMovimentoFinanceiroMarcador } from '@/models/Entidades/Financeiro/MovimentosFinanceiros/IMovimentoFinanceiro';
import { ETipoMovimentoFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoMovimentoFinanceiro';
import { ITransferenciaContas } from '@/models/Entidades/Financeiro/TransferenciasContas/ITransferenciaContas';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import ServicoMovimentoFinanceiro from '@/servicos/Financeiro/ServicoMovimentoFinanceiro';
import ServicoConta from '@/servicos/Cadastros/Financeiro/ServicoConta';
import ServicoTipoDocumentoFinanceiro from '@/servicos/Cadastros/Financeiro/ServicoTipoDocumentoFinanceiro';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import storeSistema from '@/store/storeSistema';
import { ETipoPlanoConta } from '@/models/Enumeradores/Cadastros/PlanoContas/ETipoPlanoConta';
import { ITipoDocumentoFinanceiro } from '@/models/Entidades/Cadastros/Financeiro/ITipoDocumentoFinanceiro';

export default defineComponent({
  name: 'TransferenciaContasModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      default: 0,
      required: true,
    },
    movimentoPrePreenchido: {
      type: Object as () => IMovimentoFinanceiro,
      default: {} as IMovimentoFinanceiro,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    Card,
    SelecionarEmpresa,
    SelecionarConta,
    SelecionarData,
    CampoNumerico,
    SelecionarTipoDocumentoFinanceiro,
    SelecionarCategoriaPlanoConta,
    SelecionarSimNao,
    SelecionarMarcadorMovimentoFinanceiro,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissao, verificaConceitoParaApresentarEmpresas, verificaAutorizacao,
      apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const servicoSistema = new ServicoSistema();
    const servicoMovimentoFinanceiro = new ServicoMovimentoFinanceiro();

    const servicoConta = new ServicoConta();
    servicoConta.requisicaoSistema();

    const servicoTipoDocumentoFinanceiro = new ServicoTipoDocumentoFinanceiro();
    servicoTipoDocumentoFinanceiro.requisicaoSistema();

    telaBase.identificadorRecurso = 'TRANSFERENCIA_ENTRE_CONTAS';
    telaBase.identificadorPermissao = 'AUT_TRANSFERENCIA_ENTRE_CONTAS';
    telaBase.apresentarEmpresas = false;
    const state = reactive({
      movimentoSaida: {} as IMovimentoFinanceiro,
      tipoDocumentoFinanceiroSaida: {} as ITipoDocumentoFinanceiro,
      movimentoEntrada: {} as IMovimentoFinanceiro,
      dataTransferencia: '',
      marcadores: [] as string[],
      saldoContaOrigem: 0,
      saldoContaDestino: 0,
      carregandoSaldoOrigem: false,
      carregandoSaldoDestino: false,
      definirTipoDocumentoEntrada: false,
    });

    async function limparTela() {
      state.definirTipoDocumentoEntrada = false;
      state.movimentoSaida = {} as IMovimentoFinanceiro;
      state.movimentoEntrada = {} as IMovimentoFinanceiro;
      state.movimentoSaida.codigoEmpresa = props.empresa;
      state.movimentoEntrada.codigoEmpresa = props.empresa;
      state.movimentoEntrada.codigoConta = 0;
      state.movimentoSaida.codigoConta = 0;
      state.movimentoSaida.tipoMovimento = ETipoMovimentoFinanceiro.Pagamento;
      state.movimentoEntrada.tipoMovimento = ETipoMovimentoFinanceiro.Recebimento;
      state.movimentoSaida.recursoOrigem = telaBase.identificadorRecurso;
      state.movimentoEntrada.recursoOrigem = telaBase.identificadorRecurso;
      state.movimentoSaida.conciliado = true;
      state.movimentoEntrada.influenciaSaldo = true;
      state.movimentoSaida.influenciaSaldo = true;
      state.movimentoSaida.valor = 0;
      state.saldoContaOrigem = 0;
      state.saldoContaDestino = 0;
    }

    function redefinirTipoDocumentoCategoriaOrigem() {
      state.movimentoSaida.codigoTipoDocumentoFinanceiro = 0;
      state.movimentoSaida.codigoPlanoContaCategoria = 0;
    }

    function redefinirTipoDocumentoCategoriaDestino() {
      state.movimentoEntrada.codigoTipoDocumentoFinanceiro = 0;
      state.movimentoEntrada.codigoPlanoContaCategoria = 0;
    }

    async function alterarContaOrigem() {
      state.carregandoSaldoOrigem = true;
      if (state.movimentoSaida.codigoConta !== undefined && state.movimentoSaida.codigoConta > 0) {
        const saldo = await servicoMovimentoFinanceiro.obterSaldoAtual(state.movimentoSaida.codigoConta, state.movimentoSaida.codigoEmpresa);
        state.saldoContaOrigem = saldo;
      }
      state.carregandoSaldoOrigem = false;
    }

    async function alterarContaDestino() {
      state.carregandoSaldoDestino = true;
      if (state.movimentoEntrada.codigoConta !== undefined && state.movimentoEntrada.codigoConta > 0) {
        const saldo = await servicoMovimentoFinanceiro.obterSaldoAtual(state.movimentoEntrada.codigoConta, state.movimentoEntrada.codigoEmpresa);
        state.saldoContaDestino = saldo;
      }
      state.carregandoSaldoDestino = false;
    }

    function alterarSaldoFinal() {
      state.movimentoEntrada.valor = state.movimentoSaida.valor;
    }

    function obterObjetoTransferencia() {
      const transferencia = {} as ITransferenciaContas;

      transferencia.codigoEmpresaOrigem = state.movimentoSaida.codigoEmpresa;
      transferencia.codigoEmpresaDestino = state.movimentoEntrada.codigoEmpresa;
      transferencia.codigoContaOrigem = state.movimentoSaida.codigoConta;
      transferencia.codigoContaDestino = state.movimentoEntrada.codigoConta;
      transferencia.codigoPlanoContaCategoriaSaida = state.movimentoSaida.codigoPlanoContaCategoria;
      transferencia.codigoPlanoContaCategoriaEntrada = state.movimentoEntrada.codigoPlanoContaCategoria;
      transferencia.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      transferencia.dataMovimento = state.dataTransferencia;
      transferencia.valor = state.movimentoSaida.valor;
      state.movimentoEntrada.valor = state.movimentoSaida.valor;
      state.movimentoEntrada.conciliado = state.movimentoSaida.conciliado;

      state.movimentoSaida.dataMovimento = transferencia.dataMovimento;
      state.movimentoEntrada.dataMovimento = transferencia.dataMovimento;

      state.movimentoEntrada.identificadorMovimento = state.movimentoSaida.identificadorMovimento;
      state.movimentoEntrada.descricao = state.movimentoSaida.descricao;

      state.movimentoSaida.marcadores = [];
      state.marcadores.forEach((marcador) => {
        const marcadorMovimentoFinanceiro = {} as IMovimentoFinanceiroMarcador;
        marcadorMovimentoFinanceiro.codigoMovimentoFinanceiro = 0;
        marcadorMovimentoFinanceiro.marcador = marcador;
        marcadorMovimentoFinanceiro.ordem = state.movimentoSaida.marcadores.length + 1;
        state.movimentoSaida.marcadores.push(marcadorMovimentoFinanceiro);
      });

      state.movimentoEntrada.marcadores = UtilitarioGeral.instanciaObjetoLocal(state.movimentoSaida.marcadores);

      transferencia.movimentoFinanceiroSaida = state.movimentoSaida;
      transferencia.movimentoFinanceiroEntrada = state.movimentoEntrada;
      return transferencia;
    }

    function validarValores() {
      if (!UtilitarioGeral.validaCodigo(state.movimentoSaida.codigoConta)) {
        apresentarMensagemAlerta('A Conta de origem foi não informada!');
        return false;
      }

      if (!UtilitarioGeral.validaCodigo(state.movimentoEntrada.codigoConta)) {
        apresentarMensagemAlerta('A Conta de Destino foi não informada!');
        return false;
      }

      if (state.movimentoSaida.codigoConta === state.movimentoEntrada.codigoConta) {
        apresentarMensagemAlerta('A Conta de origem não deve ser igual a Conta de destino!');
        return false;
      }
      return true;
    }

    async function transferir() {
      const transferencia = obterObjetoTransferencia();
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso();
      retorno = await servicoMovimentoFinanceiro.transferir(transferencia);
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        modalBase.computedVisivel = false;
        emit('confirmacao');
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function confirmarTransferência() {
      if (!validarValores()) {
        return;
      }

      if (state.movimentoSaida.valor > state.saldoContaOrigem) {
        let mensagemConfirmacao = 'Após a transferência o saldo da conta de origem ficará negativo.';
        if (state.saldoContaOrigem < 0) {
          mensagemConfirmacao = 'O saldo da conta de origem está negativo e o valor negativo aumentará após a transferência.';
        }

        Modal.confirm({
          title: mensagemConfirmacao,
          content: 'Tem certeza que deseja concluir essa transferência?',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Não',
          autoFocusButton: null,
          onOk: () => { transferir(); },
        });
      } else {
        transferir();
      }
    }

    async function verificaEstrategiaTipoDocumentoBaseSaida() {
      if (UtilitarioGeral.validaCodigo(state.movimentoSaida.codigoTipoDocumentoFinanceiro)) {
        state.tipoDocumentoFinanceiroSaida = await servicoTipoDocumentoFinanceiro.obter(state.movimentoSaida.codigoTipoDocumentoFinanceiro, state.movimentoSaida.codigoEmpresa);
        if (UtilitarioGeral.objetoValido(state.tipoDocumentoFinanceiroSaida)) {
          if (state.tipoDocumentoFinanceiroSaida.empresas.some((c) => c.codigoEmpresa === state.movimentoEntrada.codigoEmpresa) && state.tipoDocumentoFinanceiroSaida.movimentacaoEntrada) {
            state.movimentoEntrada.codigoTipoDocumentoFinanceiro = state.movimentoSaida.codigoTipoDocumentoFinanceiro;
          }
        }
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        if (props.movimentoPrePreenchido !== null && UtilitarioGeral.valorValido(props.movimentoPrePreenchido.identificadorMovimento)) {
          servicoMovimentoFinanceiro.requisicaoSistema();
          state.movimentoSaida.dataMovimento = props.movimentoPrePreenchido.dataMovimento;
          state.dataTransferencia = props.movimentoPrePreenchido.dataMovimento;
          state.movimentoSaida.identificadorMovimento = props.movimentoPrePreenchido.identificadorMovimento;
          state.movimentoSaida.descricao = props.movimentoPrePreenchido.descricao;
          state.movimentoSaida.valor = props.movimentoPrePreenchido.valor;
          state.movimentoSaida.observacoes = props.movimentoPrePreenchido.observacoes;
          state.movimentoSaida.conciliado = props.movimentoPrePreenchido.conciliado;
          state.movimentoSaida.numeroControle = props.movimentoPrePreenchido.numeroControle;
          state.movimentoSaida.influenciaSaldo = true;
          state.movimentoEntrada.influenciaSaldo = true;
          state.movimentoEntrada.conciliado = props.movimentoPrePreenchido.conciliado;
          state.movimentoEntrada.observacoes = props.movimentoPrePreenchido.observacoes;
          state.movimentoEntrada.numeroControle = props.movimentoPrePreenchido.numeroControle;
          state.movimentoEntrada.valor = props.movimentoPrePreenchido.valor;
          state.movimentoSaida.codigoEmpresa = props.empresa;
          state.movimentoEntrada.codigoEmpresa = props.empresa;
          if (props.movimentoPrePreenchido.tipoMovimento === ETipoMovimentoFinanceiro.Pagamento) {
            state.movimentoSaida.codigoConta = props.movimentoPrePreenchido.codigoConta;
            await alterarContaOrigem();
          } else if (props.movimentoPrePreenchido.tipoMovimento === ETipoMovimentoFinanceiro.Recebimento) {
            state.movimentoEntrada.codigoConta = props.movimentoPrePreenchido.codigoConta;
            await alterarContaDestino();
          }
        } else {
          await obterPermissoes(ETipoPermissao.Autorizacoes);
          await preencherEmpresasComEstrategiaPermissao();
          verificaConceitoParaApresentarEmpresas();
          verificaAutorizacao(props.empresa, telaBase.identificadorPermissao, true);
          state.dataTransferencia = await servicoSistema.obterDataAtual();
        }
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      emit,
      telaBase,
      modalBase,
      EPermissaoDados,
      ETipoPlanoConta,
      UtilitarioGeral,
      apresentarRetornoRequisicao,
      UtilitarioMascara,
      UtilitarioData,
      alterarContaOrigem,
      alterarContaDestino,
      alterarSaldoFinal,
      transferir,
      redefinirTipoDocumentoCategoriaOrigem,
      redefinirTipoDocumentoCategoriaDestino,
      verificaEstrategiaTipoDocumentoBaseSaida,
      confirmarTransferência,
    };
  },
});
