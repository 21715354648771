import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, {
      value: _ctx.computedValor,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
      onChange: _ctx.defineValorSelecionado,
      class: "ss-tamanho-completo",
      disabled: _ctx.props.disabled
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_select_option, { value: 16 }, {
          default: _withCtx(() => [
            _createTextVNode("DARF")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 18 }, {
          default: _withCtx(() => [
            _createTextVNode("DARF Simples")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 17 }, {
          default: _withCtx(() => [
            _createTextVNode("GPS")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 19 }, {
          default: _withCtx(() => [
            _createTextVNode("Tributos Municipais")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 25 }, {
          default: _withCtx(() => [
            _createTextVNode("IPVA")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 27 }, {
          default: _withCtx(() => [
            _createTextVNode("DPVAT")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 35 }, {
          default: _withCtx(() => [
            _createTextVNode("FGTS")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 91 }, {
          default: _withCtx(() => [
            _createTextVNode("GNRE")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "onChange", "disabled"])
  ]))
}