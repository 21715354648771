
import {
  defineComponent, onBeforeMount, reactive, watch,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { useTelaBase } from '@/core/composables/TelaBase';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { EFiltroPeriodoMovimentoConta } from '@/models/Enumeradores/Financeiro/EFiltroPeriodoMovimentoConta';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import ControleCaixasTotalizadores from '@/components/Financeiro/ControleCaixas/ControleCaixasTotalizadores.vue';
import { ETipoMovimentoFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoMovimentoFinanceiro';
import { ETipoConta } from '@/models/Enumeradores/Cadastros/Financeiro/ETipoConta';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import storeFinanceiro from '@/store/storeFinanceiro';
import { IAberturaCaixa } from '@/models/Entidades/Financeiro/ControleCaixas/AberturaCaixa';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import SelecionarCaixa from '@/components/Cadastros/Financeiro/SelecionarCaixa.vue';
import SelecionarUsuario from '@/components/MeuSistema/Usuarios/SelecionarUsuario.vue';
import SelecionarAberturaCaixa from '@/components/Financeiro/ControleCaixas/SelecionarAberturaCaixa.vue';
import { IMovimentoFinanceiro } from '@/models/Entidades/Financeiro/MovimentosFinanceiros/IMovimentoFinanceiro';
import AberturaCaixaModal from './AberturaCaixaModal.vue';
import ServicoControleCaixas from '@/servicos/Financeiro/ServicoControleCaixas';
import MovimentoCaixaModal from './MovimentoCaixaModal.vue';
import { EStatusAberturaCaixa } from '@/models/Enumeradores/Financeiro/EStatusAberturaCaixa';
import { ICaixa } from '@/models/Entidades/Cadastros/Financeiro/ICaixa';
import ServicoCaixa from '@/servicos/Cadastros/Financeiro/ServicoCaixa';
import ServicoMovimentoFinanceiro from '@/servicos/Financeiro/ServicoMovimentoFinanceiro';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOMovimentoFinanceiro } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiro';
import FechamentoCaixaModal from './FechamentoCaixaModal.vue';
import ReaberturaCaixaModal from './ReaberturaCaixaModal.vue';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';

export default defineComponent({
  name: 'ControleCaixas',
  components: {
    Icone,
    Card,
    PreviewPdf,
    MensagemSemDados,
    SelecionarEmpresa,
    SelecionarData,
    ControleCaixasTotalizadores,
    TituloPadrao,
    SelecionarUsuario,
    SelecionarCaixa,
    SelecionarAberturaCaixa,
    AberturaCaixaModal,
    FechamentoCaixaModal,
    MovimentoCaixaModal,
    ReaberturaCaixaModal,
  },
  setup(props) {
    const servicoControleCaixas = new ServicoControleCaixas();
    const servicoCaixa = new ServicoCaixa();
    const servicoMovimentoFinanceiro = new ServicoMovimentoFinanceiro();
    servicoCaixa.requisicaoSistema();
    servicoMovimentoFinanceiro.requisicaoSistema();
    let debounce = 0;
    const {
      telaBase, obterPermissoes, preencherDadosRota, verificaConceitoParaApresentarEmpresas,
      preencherEmpresasComEstrategiaPermissaoDados, filtrarPermissaoDadosUsuario, apresentarMensagemAlerta,
      adicionarAtalho, removerAtalho, apresentarMensagemSucesso, apresentarMensagemErro,
    } = useTelaBase();
    const {
      gradeBase, defineAlturaScroll,
    } = useGradeBase();
    const route = useRoute();

    const state = reactive({
      empresa: 0,
      codigoCaixa: 0,
      usuario: 0,
      data: '',
      codigoAberturaCaixa: 0,
      codigoMovimentacao: 0,
      motivoReabertura: '',
      usuarios: [] as number[],
      caixa: {} as ICaixa,
      aberturaCaixa: {} as IAberturaCaixa,
      buscaAvancada: {} as IBuscaAvancada,
      parametrosConsulta: {} as IParametrosConsulta,
      movimentos: [] as IMovimentoFinanceiro[],
      exibirAbertura: false,
      exibirFechamento: false,
      exibirReabertura: false,
      exibirLancamento: false,
      apresentarResultados: false,
      arquivosPdf: [] as IArquivoPdf[],
    });

    function limparTela() {
      state.empresa = storeSistema.getters.codigoEmpresaOperacao();
      state.codigoCaixa = 0;
      state.usuario = 0;
      state.codigoAberturaCaixa = 0;
      state.codigoMovimentacao = 0;
      state.motivoReabertura = '';
      state.data = UtilitarioData.obterDataAtual();
      state.usuarios = [] as number[];
      state.aberturaCaixa = {} as IAberturaCaixa;
      state.buscaAvancada = {} as IBuscaAvancada;
      state.parametrosConsulta = {} as IParametrosConsulta;
      state.parametrosConsulta.empresas = [] as number[];
      state.exibirAbertura = false;
      state.exibirFechamento = false;
      state.exibirReabertura = false;
      state.exibirLancamento = false;
      state.apresentarResultados = false;
    }

    function limparAberturaCaixa() {
      state.codigoAberturaCaixa = 0;
      state.aberturaCaixa = {} as IAberturaCaixa;
      state.codigoMovimentacao = 0;
      state.movimentos = [] as IMovimentoFinanceiro[];
      state.apresentarResultados = false;
    }

    async function buscarOperadores() {
      limparAberturaCaixa();
      if (state.codigoCaixa > 0 && state.codigoCaixa !== undefined && state.data !== '' && state.data !== undefined) {
        state.usuario = 0;
        state.usuarios = await servicoControleCaixas.obterOperadoresCaixa(state.codigoCaixa, state.empresa, state.data);
      }
    }

    async function buscarCaixa() {
      if (state.codigoCaixa > 0 && state.codigoCaixa !== undefined) {
        state.caixa = await servicoCaixa.obter(state.codigoCaixa, state.empresa);
        buscarOperadores();
      }
    }

    async function atualizarSaldo() {
      state.aberturaCaixa.totalEntradas = await servicoControleCaixas.obterTotalMovimentacoes(state.codigoAberturaCaixa, state.empresa, ETipoMovimentoFinanceiro.Recebimento);
      state.aberturaCaixa.totalSaidas = await servicoControleCaixas.obterTotalMovimentacoes(state.codigoAberturaCaixa, state.empresa, ETipoMovimentoFinanceiro.Pagamento);
      state.aberturaCaixa.saldoFinal = (state.aberturaCaixa.saldoInicial + state.aberturaCaixa.totalEntradas) - state.aberturaCaixa.totalSaidas;
    }

    function buscarMovimentos() {
      state.movimentos = [];
      state.apresentarResultados = false;

      if (state.codigoAberturaCaixa === 0 || state.aberturaCaixa === undefined) {
        return;
      }

      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        state.parametrosConsulta.empresas = [] as number[];
        state.parametrosConsulta.empresas.push(state.empresa);
        state.parametrosConsulta.ordenacao = Array<string>();

        gradeBase.ordenacaoSelecionada.forEach((item) => {
          state.parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
        });

        if (!UtilitarioGeral.validaLista(gradeBase.ordenacaoSelecionada)) {
          state.parametrosConsulta.ordenacao.push('DataMovimento|ASC');
        }

        telaBase.carregando = true;
        state.apresentarResultados = true;
        await atualizarSaldo();
        const resultado = await servicoControleCaixas.buscarMovimentos(state.codigoAberturaCaixa, state.parametrosConsulta, gradeBase.filtrosAplicados);
        state.movimentos = resultado.dados;
        telaBase.carregando = false;
      }, 600);
    }

    async function buscarAberturaCaixa() {
      if (state.codigoAberturaCaixa !== undefined && state.codigoAberturaCaixa > 0) {
        state.aberturaCaixa = await servicoControleCaixas.obterAberturaCaixa(state.codigoAberturaCaixa, state.empresa);
        if (state.aberturaCaixa.status === EStatusAberturaCaixa.Reaberta) {
          state.motivoReabertura = await servicoControleCaixas.obterMotivoReabertura(state.codigoAberturaCaixa, state.empresa);
        }
        buscarMovimentos();
      }
    }

    function preencherColunas() {
      if (storeSistema.state.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Movimento', dataIndex: 'movimento', key: 'Movimento', position: 2, visible: true, ordering: true, align: 'left',
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'N.Identificação', dataIndex: 'identificadorMovimento', key: 'IdentificadorMovimento', position: 2, visible: true, ellipsis: true, width: 150, customRenderRow: ECustomRenderRow.LinkAcao, fixed: 'left',
          },
          {
            title: 'Data', dataIndex: 'dataMovimento', key: 'DataMovimento', position: 3, visible: true, ordering: true, customRenderRow: ECustomRenderRow.MascararData, width: 100, align: 'center',
          },
          {
            title: 'Tipo Movimento', dataIndex: 'tipoMovimento', key: 'TipoMovimento', position: 4, visible: true, width: 120, align: 'left',
          },
          {
            title: 'Descrição', dataIndex: 'descricao', key: 'Descricao', position: 5, visible: true, align: 'left',
          },
          {
            title: 'Valor R$', dataIndex: 'valor', key: 'Valor', position: 6, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 100,
          },
          {
            title: 'Categoria', dataIndex: 'nomePlanoContaCategoria', key: 'NomePlanoContaCategoria', position: 7, visible: true, ellipsis: true, width: 200,
          },
          {
            title: 'Ações', key: 'acoes', position: 8, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
          },
        ];
      }
    }

    function validarSelecaoAberturaCaixa() {
      if (state.codigoAberturaCaixa === 0 || state.codigoAberturaCaixa === undefined) {
        apresentarMensagemAlerta('Nenhuma abertura selecionada para essa ação!');
        return false;
      }
      return true;
    }

    function abrirCaixas() {
      state.exibirAbertura = true;
    }

    function fecharCaixa() {
      if (!validarSelecaoAberturaCaixa()) return;

      if (state.aberturaCaixa === undefined || state.aberturaCaixa.status === EStatusAberturaCaixa.Fechada) {
        apresentarMensagemAlerta('O caixa deve está aberto para realizar o fechamento!');
        return;
      }
      state.exibirFechamento = true;
    }

    function reabrirCaixa() {
      if (!validarSelecaoAberturaCaixa()) return;

      if (state.aberturaCaixa === undefined || state.aberturaCaixa.status !== EStatusAberturaCaixa.Fechada) {
        apresentarMensagemAlerta('O caixa deve está fechado para realizar a reabertura!');
        return;
      }
      state.exibirReabertura = true;
    }

    function lancarMovimento() {
      if (!validarSelecaoAberturaCaixa()) return;

      if (state.aberturaCaixa === undefined || state.aberturaCaixa.status === EStatusAberturaCaixa.Fechada) {
        apresentarMensagemAlerta('O caixa deve está aberto para realizar o lançamento!');
        return;
      }
      state.codigoMovimentacao = 0;
      state.exibirLancamento = true;
    }

    async function validarSaldo(valor: number) {
      await atualizarSaldo();
      let saldo = state.aberturaCaixa.saldoFinal;
      saldo -= valor;
      if (saldo < 0) {
        apresentarMensagemAlerta('Saldo do caixa é insuficiente para a exclusão desse movimento!');
        return false;
      }
      return true;
    }

    async function excluir(codigo: number) {
      const retorno = await servicoMovimentoFinanceiro.excluirMovimento(codigo, state.empresa);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        buscarMovimentos();
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        Modal.warning({
          title: 'Não foi possível excluir o movimento da conta!',
          content: retorno.mensagem,
        });
      }
    }

    async function confirmarExclusao(objeto: IDTOMovimentoFinanceiro) {
      const retornoSaldo = await validarSaldo(objeto.valor);
      if (!retornoSaldo) return;
      Modal.confirm({
        title: 'Você confirma a exclusão do movimento:',
        content: `${objeto.identificadorMovimento} - ${objeto.descricao}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        centered: true,
        width: 600,
        autoFocusButton: null,
        onOk: async () => { await excluir(objeto.codigo); },
      });
    }

    function visualizarMovimento(codigo: number) {
      state.codigoMovimentacao = codigo;
      state.exibirLancamento = true;
    }

    async function imprimir(tipoArquivoRelatorio: ETipoArquivo) {
      state.parametrosConsulta.ordenacao = Array<string>();

      telaBase.carregando = true;
      const retornoRelatorio = await servicoControleCaixas.realtorioPadrao(state.codigoAberturaCaixa, state.empresa, tipoArquivoRelatorio);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        if (tipoArquivoRelatorio === ETipoArquivo.PDF) {
          state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
        } else {
          UtilitarioGeral.downloadArquivo(retornoRelatorio.link);
        }
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    const mudarSelecao = (selectedRowKeys: any) => {
      gradeBase.codigosSelecionados = selectedRowKeys;
    };

    watch(() => storeSistema.state.layoutMobile, () => {
      preencherColunas();
    });

    onBeforeMount(async () => {
      telaBase.carregando = true;
      limparTela();
      preencherDadosRota();
      await obterPermissoes(ETipoPermissao.Dados);
      await preencherEmpresasComEstrategiaPermissaoDados(EPermissaoDados.Visualizar, false);
      verificaConceitoParaApresentarEmpresas();
      const tokenStore = String(route.query.token);
      if (UtilitarioGeral.valorValido(tokenStore)) {
        const comunicacaoFinanceiro = storeFinanceiro.getters.obterComunicacao(tokenStore);
        if (UtilitarioGeral.objetoValido(comunicacaoFinanceiro) && UtilitarioGeral.validaCodigo(comunicacaoFinanceiro.empresa)) {
          storeFinanceiro.mutations.removerComunicacao(tokenStore);
        }
      }

      telaBase.empresasSelecionadas = [] as number[];
      telaBase.empresasSelecionadas.push(state.empresa);
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuario(state.empresa);
      if (telaBase.propriedadesConsulta.length > 0) {
        state.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        state.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
      state.buscaAvancada.filtrosAdicionados = [];
      state.buscaAvancada.chaveFiltrosAdicionados = 0;
      preencherColunas();
      telaBase.carregando = false;
    });

    return {
      state,
      props,
      window,
      ETipoTituloFinanceiro,
      EStatusTituloFinanceiro,
      ETipoMovimentoFinanceiro,
      EVinculoEmpresa,
      ETipoArquivo,
      ETipoConta,
      ECustomRenderRow,
      EFiltroPeriodoMovimentoConta,
      buscarOperadores,
      UtilitarioMascara,
      UtilitarioData,
      storeSistema,
      telaBase,
      gradeBase,
      adicionarAtalho,
      removerAtalho,
      preencherColunas,
      defineAlturaScroll,
      buscarMovimentos,
      abrirCaixas,
      fecharCaixa,
      reabrirCaixa,
      lancarMovimento,
      imprimir,
      limparAberturaCaixa,
      buscarAberturaCaixa,
      mudarSelecao,
      buscarCaixa,
      visualizarMovimento,
      confirmarExclusao,
    };
  },
});
