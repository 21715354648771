
import { defineComponent, reactive } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IDTOTituloFinanceiroRegistroAtividade } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroRegistroAtividade';

export default defineComponent({
  name: 'TituloFinanceiroHistorico',
  props: {
    visivel: {
      type: Boolean,
      required: true,
    },
    registros: {
      type: Array as () => IDTOTituloFinanceiroRegistroAtividade[],
      required: true,
    },
  },
  components: {
    Card,
    MensagemSemDados,
  },
  setup(props) {
    const state = reactive({});

    return {
      props,
      state,
      UtilitarioData,
    };
  },
});
