
import {
  defineComponent, reactive, watch,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import ServicoControleCaixas from '@/servicos/Financeiro/ServicoControleCaixas';
import { IAberturaCaixa, IReaberturaCaixa } from '@/models/Entidades/Financeiro/ControleCaixas/AberturaCaixa';
import DetalhesAberturaCaixa from '@/components/Financeiro/ControleCaixas/DetalhesAberturaCaixa.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import ServicoCaixa from '@/servicos/Cadastros/Financeiro/ServicoCaixa';

export default defineComponent({
  name: 'FechamentoCaixaModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      required: true,
    },
    abertura: {
      type: Object as () => IAberturaCaixa,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    DetalhesAberturaCaixa,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemSucesso, apresentarMensagemAlerta } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    const servicoControleCaixas = new ServicoControleCaixas();
    const servicoCaixa = new ServicoCaixa();
    servicoControleCaixas.requisicaoSistema();
    servicoCaixa.requisicaoSistema();

    const state = reactive({
      reabertura: {} as IReaberturaCaixa,
    });

    function limparTela() {
      state.reabertura = {} as IReaberturaCaixa;
      state.reabertura.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      state.reabertura.codigoAberturaCaixa = props.abertura.codigo;
    }

    async function reabrirCaixa() {
      if (state.reabertura.motivo === '') {
        apresentarMensagemAlerta('Você deve informar o motivo da reabertura!');
        return;
      }

      apresentarBarraProgresso();
      let retornoRequisicao: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      retornoRequisicao = await servicoControleCaixas.reabrir(props.empresa, state.reabertura);
      ocultarBarraProgresso();
      if (retornoRequisicao.status === EStatusRetornoRequisicao.Sucesso) {
        emit('confirmacao');
        apresentarMensagemSucesso('Caixa reaberto com sucesso!');
        modalBase.computedVisivel = false;
      } else {
        if (retornoRequisicao.mensagem === '') {
          retornoRequisicao.mensagem = 'Não foi possível reabrir o caixa.';
        }
        apresentarRetornoRequisicao(retornoRequisicao);
      }
    }

    function confirmarReabertura() {
      Modal.confirm({
        title: 'Realmente deseja realizar reabrir?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        centered: true,
        autoFocusButton: null,
        onOk: async () => { await reabrirCaixa(); },
      });
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      if (modalBase.computedVisivel) {
        telaBase.carregando = true;
        telaBase.carregando = false;
      }
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      EPermissaoDados,
      storeSistema,
      UtilitarioGeral,
      apresentarRetornoRequisicao,
      confirmarReabertura,
    };
  },
});
