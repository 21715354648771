
import {
  defineComponent, onBeforeMount, reactive, watch,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '@/core/components/Icone.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IListaPaginadaMetaData } from '@/core/models/Consulta/IListaPaginada';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IPagination } from '@/core/models/AntDesign/IPagination';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import Card from '@/core/components/Tela/Card.vue';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import SelecionarTipoRelatorioFluxoCaixa from '@/components/Relatorios/Financeiro/SelecionarTipoRelatorioFluxoCaixa.vue';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';
import ServicoRelatorioFinanceiro from '@/servicos/Relatorios/ServicoRelatoriosFinanceiros';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { useTituloFinanceiroBase } from '@/composables/Financeiro/TituloFinanceiroBase';
import TotalizadorDecorado from '@/core/components/Tela/TotalizadorDecorado.vue';
import { ETipoRelatorioFluxoCaixa } from '@/models/Enumeradores/Relatorios/ETipoRelatorioFluxoCaixa';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { ISalvamentoAutomatico } from '@/models/Entidades/MeuSistema/ISalvamentoAutomatico';
import { IFiltrosRelatorioFluxoCaixa } from '@/models/Relatorios/Financeiro/FluxoCaixa/IFiltrosRelatorioFluxoCaixa';
import { IDadosRelatorioFluxoCaixa, IDadosRelatorioFluxoCaixaItem } from '@/models/Relatorios/Financeiro/FluxoCaixa/IDadosRelatorioFluxoCaixa';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiro.vue';

export default defineComponent({
  name: 'RelatorioFluxoCaixa',
  components: {
    Icone,
    Card,
    MensagemSemDados,
    TituloPadrao,
    PreviewPdf,
    SelecionarTipoRelatorioFluxoCaixa,
    SelecionarDataInicialFinal,
    TotalizadorDecorado,
    SelecionarConta,
    SelecionarTipoDocumentoFinanceiro,
  },
  setup(props) {
    const servicoSistema = new ServicoSistema();
    const servicoRelatorioFinanceiro = new ServicoRelatorioFinanceiro();
    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis, verificaConceitoParaApresentarEmpresas, apresentarMensagemErro,
      defineEmpresasSelecionadasComPermissao, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemAlerta,
      adicionarAtalho, removerAtalho, preencherDadosRota,
    } = useTelaBase();

    const { gradeBase } = useGradeBase();
    const { obterClasseStatus } = useTituloFinanceiroBase(props);

    const state = reactive({
      dataInicial: '01/01/2001',
      dataFinal: '10/01/2001',
      filtros: {} as IFiltrosRelatorioFluxoCaixa,
      filtrosUtilizados: {} as IFiltrosRelatorioFluxoCaixa,
      resultado: {} as IDadosRelatorioFluxoCaixa,
      itens: [] as IDadosRelatorioFluxoCaixaItem[],
      paginacao: {} as IPagination,
      paginacaoApi: { totalRegistros: 0, paginaAtual: 1 } as IListaPaginadaMetaData,
      timerRegistros: 0,
      totalRegistrosComMascara: '100',
      arquivosPdf: [] as IArquivoPdf[],
      mostrarFiltros: 0,
      mostrarFiltrosInteligentes: 1,
      mostrarTotalizadores: 0,
      mostrarItens: false,
      cacheAutomatico: {} as ISalvamentoAutomatico,
    });

    telaBase.apresentarEmpresas = false;
    function preencherColunas() {
      if (storeSistema.state.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Item', dataIndex: 'item', key: 'Item', position: 2, visible: true,
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Data', dataIndex: 'dataFormatada', key: 'DataFormatada', position: 1, visible: true, align: 'left', width: 200,
          },
          {
            title: 'Conta', dataIndex: 'descricaoConta', key: 'DescricaoConta', position: 2, visible: true, width: 200,
          },
          {
            title: 'Tipo de Documento', dataIndex: 'descricaoTipoDocumento', key: 'DescricaoTipoDocumento', position: 3, visible: true, width: 200,
          },
          {
            title: 'Descrição', dataIndex: 'descricao', key: 'Descricao', position: 4, visible: true,
          },
          {
            title: 'R$ Valor', dataIndex: 'valor', key: 'Valor', position: 5, visible: true, align: 'right', width: 150, customRenderRow: ECustomRenderRow.MascararFracionado,
          },
          {
            title: 'R$ Saldo', dataIndex: 'saldo', key: 'Saldo', position: 6, visible: true, align: 'right', width: 150, customRenderRow: ECustomRenderRow.MascararFracionado,
          },
        ];
      }
    }

    function obterIdentificadorCache(): string {
      return `RELATORIO_FLUXO_CAIXA_${storeSistema.getters.codigoUsuarioAutenticado()}`;
    }

    function salvarCache() {
      const identificador = obterIdentificadorCache();
      state.cacheAutomatico.data = UtilitarioData.obterDataAtual();
      state.cacheAutomatico.codigoEmpresa = state.filtros.empresa;
      state.cacheAutomatico.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      state.cacheAutomatico.identificador = telaBase.identificadorRecurso;
      state.cacheAutomatico.dados = JSON.stringify(state.filtros);
      servicoSistema.salvamentoAutomaticoLocalStorage(`${identificador}`, state.cacheAutomatico);
    }

    async function recuperarCache() {
      const cacheRestaurado = await servicoSistema.obterSalvamentoAutomaticoLocalStorage(obterIdentificadorCache());
      if (UtilitarioGeral.valorValido(cacheRestaurado)) {
        const cache = JSON.parse(cacheRestaurado.dados);
        state.filtros = cache;
        state.dataInicial = cache.dataInicial;
        state.dataFinal = cache.dataFinal;
        state.mostrarFiltros = 1;
        state.mostrarFiltrosInteligentes = 0;
      }
    }

    async function buscarRelatorio() {
      telaBase.carregando = true;
      state.filtros.dataInicial = state.dataInicial;
      state.filtros.dataFinal = state.dataFinal;
      state.filtros.numeroPagina = state.paginacao.current;
      state.filtros.qtdRegistrosPagina = state.paginacao.pageSize;
      state.filtros.qtdTotalRegistros = state.paginacao.total;
      state.resultado = await servicoRelatorioFinanceiro.obterFluxoCaixa(state.filtros);
      state.filtrosUtilizados = UtilitarioGeral.clonarObjeto(state.filtros);
      salvarCache();
      preencherColunas();
      state.mostrarItens = true;
      state.itens = state.resultado.itens.dados;
      state.paginacaoApi = state.resultado.itens.metaData;
      state.mostrarFiltros = 0;
      state.mostrarFiltrosInteligentes = 0;
      state.mostrarTotalizadores = 1;
      telaBase.carregando = false;
    }

    async function alterarQuantidadeRegistroTotal(valor: any) {
      clearTimeout(state.timerRegistros);
      let totalRegistros = 100;
      state.timerRegistros = setTimeout(async () => {
        if (UtilitarioGeral.valorValido(valor)) {
          const limiteRegistros = UtilitarioMascara.removerMascaraNumero(valor);
          if (limiteRegistros > 0) {
            totalRegistros = limiteRegistros;
          }
        }
        state.paginacao.total = totalRegistros;
        state.totalRegistrosComMascara = UtilitarioMascara.mascararNumeroInteiro(totalRegistros);
        await buscarRelatorio();
      }, 600);
    }

    async function onPageChange(current: number, pageSize: number) {
      state.paginacao.current = current;
      state.paginacao.pageSize = pageSize;
      await buscarRelatorio();
    }

    async function onShowSizeChange(current: number, pageSize: number) {
      state.paginacao.current = 1;
      state.paginacao.pageSize = pageSize;
      await buscarRelatorio();
    }

    async function atualizarSelecaoEmpresas() {
      telaBase.empresasSelecionadas = [] as number[];
      telaBase.empresasSelecionadas.push(state.filtros.empresa);
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
      await buscarRelatorio();
    }

    function usarFiltroInteligente(filtro: number) {
      switch (filtro) {
        case 1:
          state.filtros.tipo = ETipoRelatorioFluxoCaixa.Geral;
          state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.filtros.contas = [] as number [];
          state.filtros.tiposDocumento = [] as number[];
          break;
        case 2:
          state.filtros.tipo = ETipoRelatorioFluxoCaixa.PorTipoDocumento;
          state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.filtros.contas = [] as number [];
          state.filtros.tiposDocumento = [] as number[];
          break;
        case 3:
          state.filtros.tipo = ETipoRelatorioFluxoCaixa.Geral;
          state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.dataInicial = UtilitarioData.adicionarMesNaData(state.dataInicial, 1);
          state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.dataFinal = UtilitarioData.adicionarMesNaData(state.dataFinal, 1);
          state.filtros.contas = [] as number [];
          state.filtros.tiposDocumento = [] as number[];
          break;
        default:
          state.filtros.tipo = ETipoRelatorioFluxoCaixa.PorTipoDocumento;
          state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
          state.dataInicial = UtilitarioData.adicionarMesNaData(state.dataInicial, 1);
          state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
          state.dataFinal = UtilitarioData.adicionarMesNaData(state.dataFinal, 1);
          state.filtros.contas = [] as number [];
          state.filtros.tiposDocumento = [] as number[];
          break;
      }
      buscarRelatorio();
    }

    async function imprimir(tipoArquivoRelatorio:ETipoArquivo) {
      telaBase.carregando = true;
      if (!UtilitarioGeral.validaLista(state.itens)) {
        await buscarRelatorio();
      }
      const retornoRelatorio = await servicoRelatorioFinanceiro.obterArquivoFluxoCaixa(tipoArquivoRelatorio, state.filtrosUtilizados);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        if (tipoArquivoRelatorio === ETipoArquivo.PDF) {
          state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
        } else {
          UtilitarioGeral.downloadArquivo(retornoRelatorio.link);
        }
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    function limparFiltros() {
      state.filtros = {} as IFiltrosRelatorioFluxoCaixa;
      state.filtros.empresa = storeSistema.getters.codigoEmpresaOperacao();
      state.filtros.tipo = ETipoRelatorioFluxoCaixa.Geral;
      state.dataInicial = UtilitarioData.obterPrimeiroDiaMesAtual();
      state.dataFinal = UtilitarioData.obterUltimoDiaMesAtual();
      state.filtros.contas = [] as number[];
      state.filtros.tiposDocumento = [] as number[];
    }

    watch(() => storeSistema.state.layoutMobile, () => {
      preencherColunas();
    });

    onBeforeMount(async () => {
      preencherDadosRota();
      await obterPermissoes(ETipoPermissao.Dados);
      await preencherEmpresasDisponiveis();
      await defineEmpresasSelecionadasComPermissao();
      verificaConceitoParaApresentarEmpresas();
      recuperarCache();
      preencherColunas();
      limparFiltros();
      state.paginacao.total = 100;
      state.paginacao.showSizeChanger = true;
      state.paginacao.pageSize = 100;
      state.paginacao.current = 1;
      state.paginacao.pageSizeOptions = ['10', '50', '100', '500', '1000'];
    });

    return {
      state,
      props,
      window,
      ETipoTituloFinanceiro,
      EStatusTituloFinanceiro,
      EVinculoEmpresa,
      ETipoArquivo,
      ECustomRenderRow,
      ETipoRelatorioFluxoCaixa,
      UtilitarioMascara,
      UtilitarioData,
      UtilitarioDispositivo,
      storeSistema,
      telaBase,
      gradeBase,
      preencherColunas,
      onPageChange,
      onShowSizeChange,
      alterarQuantidadeRegistroTotal,
      adicionarAtalho,
      removerAtalho,
      atualizarSelecaoEmpresas,
      obterClasseStatus,
      buscarRelatorio,
      usarFiltroInteligente,
      imprimir,
    };
  },
});
