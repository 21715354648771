
import {
  defineComponent, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import ServicoControleCaixas from '@/servicos/Financeiro/ServicoControleCaixas';
import { IAberturaCaixa } from '@/models/Entidades/Financeiro/ControleCaixas/AberturaCaixa';
import ServicoCaixa from '@/servicos/Cadastros/Financeiro/ServicoCaixa';
import ServicoConta from '@/servicos/Cadastros/Financeiro/ServicoConta';
import DetalhesAberturaCaixa from '@/components/Financeiro/ControleCaixas/DetalhesAberturaCaixa.vue';
import { IMovimentoFinanceiro, IMovimentoFinanceiroCentroCusto, IMovimentoFinanceiroMarcador } from '@/models/Entidades/Financeiro/MovimentosFinanceiros/IMovimentoFinanceiro';
import SelecionarGenerico from '@/core/components/Tela/SelecionarGenerico.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import SelecionarTipoMovimentoFinanceiro from '@/components/Financeiro/MovimentosFinanceiros/SelecionarTipoMovimentoFinanceiro.vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import Card from '@/core/components/Tela/Card.vue';
import { IDTOMovimentoFinanceiroCentroCusto } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiroCentroCusto';
import ServicoPlanoContas from '@/servicos/Cadastros/PlanosContas/ServicoPlanoContas';
import ServicoMovimentoFinanceiro from '@/servicos/Financeiro/ServicoMovimentoFinanceiro';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { EStatusAberturaCaixa } from '@/models/Enumeradores/Financeiro/EStatusAberturaCaixa';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { ICaixa } from '@/models/Entidades/Cadastros/Financeiro/ICaixa';
import { ETipoMovimentoFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoMovimentoFinanceiro';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import SelecionarMarcadorMovimentoFinanceiro from '@/components/Financeiro/MovimentosFinanceiros/SelecionarMarcadorMovimentoFinanceiro.vue';
import EditorHtml from '@/core/components/Tela/EditorHtml.vue';
import MovimentoFinanceiroCentroCusto from '@/components/Financeiro/MovimentosFinanceiros/MovimentoFinanceiroCentroCusto.vue';
import MovimentoFinanceiroAnexo from '@/components/Financeiro/MovimentosFinanceiros/MovimentoFinanceiroAnexo.vue';

export default defineComponent({
  name: 'MovimentoCaixaModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      required: true,
    },
    codigoMovimento: {
      type: Number,
      required: true,
    },
    caixa: {
      type: Object as () => ICaixa,
      required: true,
    },
    abertura: {
      type: Object as () => IAberturaCaixa,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    Card,
    DetalhesAberturaCaixa,
    CampoNumerico,
    SelecionarCategoriaPlanoConta,
    SelecionarTipoMovimentoFinanceiro,
    SelecionarGenerico,
    SelecionarPessoa,
    SelecionarMarcadorMovimentoFinanceiro,
    EditorHtml,
    MovimentoFinanceiroCentroCusto,
    MovimentoFinanceiroAnexo,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemSucesso, apresentarMensagemAlerta } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    const servicoControleCaixas = new ServicoControleCaixas();
    const servicoCaixa = new ServicoCaixa();
    const servicoConta = new ServicoConta();
    const servicoPlanoContas = new ServicoPlanoContas();
    const servicoMovimentoFinanceiro = new ServicoMovimentoFinanceiro();
    servicoControleCaixas.requisicaoSistema();
    servicoCaixa.requisicaoSistema();
    servicoConta.requisicaoSistema();
    servicoPlanoContas.requisicaoSistema();
    servicoMovimentoFinanceiro.requisicaoSistema();

    const state = reactive({
      movimento: {} as IMovimentoFinanceiro,
      tiposLancamento: [] as IOption[],
      tipoLancamento: 1,
      centrosCusto: [] as IDTOMovimentoFinanceiroCentroCusto[],
      marcadores: [] as string[],
      carregandoAnexos: false,
      carregandoCentrosCusto: false,
      editavel: true,
    });

    function preencherTiposLancamento() {
      state.tiposLancamento = [] as IOption[];
      const item1 = {} as IOption;
      item1.label = 'Sangria';
      item1.value = 1;
      state.tiposLancamento.push(item1);
      const item2 = {} as IOption;
      item2.label = 'Suprimento';
      item2.value = 2;
      state.tiposLancamento.push(item2);
      const item3 = {} as IOption;
      item3.label = 'Outro';
      item3.value = 3;
      state.tiposLancamento.push(item3);
      state.tipoLancamento = 1;
    }

    async function limparTela() {
      state.movimento = {} as IMovimentoFinanceiro;
      state.editavel = true;
      preencherTiposLancamento();
    }

    async function obterCentrosCustoContaContabil() {
      state.carregandoCentrosCusto = true;
      if (state.movimento.codigoPlanoContaCategoria > 0 && !UtilitarioGeral.validaLista(state.centrosCusto)) {
        const centrosCusto = await servicoPlanoContas.obterCentrosCustos(state.movimento.codigoPlanoContaCategoria, state.movimento.codigoEmpresa);
        centrosCusto.forEach((c) => {
          const centroCusto = {} as IDTOMovimentoFinanceiroCentroCusto;
          centroCusto.codigoCentroCusto = c.codigoCentroCusto;
          centroCusto.descricaoCentroCusto = c.descricaoCentroCusto;
          centroCusto.proporcao = c.proporcao;
          centroCusto.ordem = state.centrosCusto.length + 1;
          state.centrosCusto.push(centroCusto);
        });
      }
      state.carregandoCentrosCusto = false;
    }

    async function preencherMarcadores() {
      state.movimento.marcadores = await servicoMovimentoFinanceiro.obterMarcadores(props.codigoMovimento, props.empresa);
      state.movimento.marcadores.forEach((m) => {
        state.marcadores.push(m.marcador);
      });
    }

    async function preencherAnexos() {
      state.carregandoAnexos = true;
      state.movimento.anexos = await servicoMovimentoFinanceiro.obterAnexos(props.codigoMovimento, props.empresa);
      state.carregandoAnexos = false;
    }

    async function preencherCentrosCusto() {
      state.carregandoCentrosCusto = true;
      state.centrosCusto = await servicoMovimentoFinanceiro.obterCentrosCusto(props.codigoMovimento, props.empresa);
      state.carregandoCentrosCusto = false;
    }

    async function preencherMovimento() {
      state.movimento = await servicoMovimentoFinanceiro.obterMovimento(props.codigoMovimento, props.empresa);
      state.tipoLancamento = 3;
      preencherMarcadores();
      preencherAnexos();
      preencherCentrosCusto();
    }

    function obterCentrosCusto() {
      const centrosCusto = [] as IMovimentoFinanceiroCentroCusto[];

      state.centrosCusto.forEach((c) => {
        const centroCusto = {} as IMovimentoFinanceiroCentroCusto;
        centroCusto.codigo = c.codigo;
        centroCusto.codigoCentroCusto = c.codigoCentroCusto;
        centroCusto.codigoMovimentoFinanceiro = c.codigoMovimentoFinanceiro;
        centroCusto.proporcao = c.proporcao;
        centroCusto.ordem = c.ordem;
        centrosCusto.push(centroCusto);
      });
      return centrosCusto;
    }

    function obterMarcadores() {
      const marcadores = [] as IMovimentoFinanceiroMarcador[];

      state.marcadores.forEach((m) => {
        const marcador = {} as IMovimentoFinanceiroMarcador;
        marcador.codigoMovimentoFinanceiro = state.movimento.codigo;
        marcador.marcador = m;
        marcador.ordem = marcadores.length + 1;
        marcadores.push(marcador);
      });
      return marcadores;
    }

    function obterMovimento() {
      state.movimento.centrosCusto = obterCentrosCusto();
      state.movimento.marcadores = obterMarcadores();
      state.movimento.codigoConta = props.caixa.codigoConta;
      state.movimento.codigoAberturaCaixa = props.abertura.codigo;
      state.movimento.codigoEmpresa = props.empresa;
      state.movimento.dataMovimento = UtilitarioData.obterDataAtual();
      state.movimento.codigoTipoDocumentoFinanceiro = props.caixa.codigoTipoDocumentoFinanceiro;
      state.movimento.conciliado = true;
      state.movimento.influenciaSaldo = true;
      state.movimento.recursoOrigem = 'CONTROLE_CAIXA';
      if (state.tipoLancamento === 1) {
        state.movimento.tipoMovimento = ETipoMovimentoFinanceiro.Pagamento;
        state.movimento.identificadorMovimento = `${props.abertura.codigo}SAN${UtilitarioData.aplicaFormatoPersonalizadoData(state.movimento.dataMovimento, 'DDMMYYYYHHmmss')}`;
        state.movimento.descricao = 'SANGRIA DE CAIXA';
      } else if (state.tipoLancamento === 2) {
        state.movimento.tipoMovimento = ETipoMovimentoFinanceiro.Recebimento;
        state.movimento.identificadorMovimento = `${props.abertura.codigo}SUP${UtilitarioData.aplicaFormatoPersonalizadoData(state.movimento.dataMovimento, 'DDMMYYYYHHmmss')}`;
        state.movimento.descricao = 'SUPRIMENTO DE CAIXA';
      }
    }

    async function validarSaldo() {
      if (state.movimento.tipoMovimento === 2 || state.tipoLancamento === 1) {
        let saldo = props.abertura.saldoInicial;
        const totalEntradas = await servicoControleCaixas.obterTotalMovimentacoes(props.abertura.codigo, props.empresa, ETipoMovimentoFinanceiro.Recebimento);
        const totalSaidas = await servicoControleCaixas.obterTotalMovimentacoes(props.abertura.codigo, props.empresa, ETipoMovimentoFinanceiro.Pagamento);
        saldo = (saldo + totalEntradas) - totalSaidas;
        if (saldo < state.movimento.valor) {
          apresentarMensagemAlerta('Saldo do caixa é insuficiente para essa movimentação!');
          return false;
        }
      }
      return true;
    }

    async function salvar() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      const retornoSaldo = await validarSaldo();
      if (!retornoSaldo) return;

      apresentarBarraProgresso();
      obterMovimento();
      if (props.codigoMovimento === 0) {
        retorno = await servicoMovimentoFinanceiro.lancarMovimento(state.movimento);
      } else {
        retorno = await servicoMovimentoFinanceiro.atualizarMovimento(state.movimento);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        emit('confirmacao');
        apresentarMensagemSucesso(retorno.mensagem);
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      await limparTela();
      if (modalBase.computedVisivel) {
        telaBase.carregando = true;

        if (props.codigoMovimento > 0) {
          await preencherMovimento();
          state.editavel = props.abertura.status !== EStatusAberturaCaixa.Fechada;
        } else {
          state.editavel = true;
        }
        telaBase.carregando = false;
      }
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      storeSistema,
      apresentarRetornoRequisicao,
      obterCentrosCustoContaContabil,
      salvar,
    };
  },
});
