import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_4 = { class: "ant-row" }
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_6 = { class: "ant-row" }
const _hoisted_7 = { class: "ant-col ant-col-xs-19 ant-col-xl-19" }
const _hoisted_8 = { class: "ant-col ant-col-xs-5 ant-col-xl-5" }
const _hoisted_9 = { class: "ant-row" }
const _hoisted_10 = { class: "ant-col ant-col-xs-21 ant-col-xl-21" }
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "ant-col ant-col-xs-3 ant-col-xl-3" }
const _hoisted_13 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_icone = _resolveComponent("icone")!
  const _component_selecionar_empresa = _resolveComponent("selecionar-empresa")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_card = _resolveComponent("card")!
  const _component_selecionar_usuario = _resolveComponent("selecionar-usuario")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_requisicao_modal = _resolveComponent("requisicao-modal")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    keyboard: false,
    visible: _ctx.modalBase.computedVisivel,
    "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
    width: 600,
    centered: "",
    "wrap-class-name": "ss-modal-cadastros"
  }, {
    title: _withCtx(() => [
      _createTextVNode(" Cadastro de Equipe de Alçada de Despesas "),
      _createVNode(_component_a_switch, {
        checked: _ctx.state.equipe.ativo,
        "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.equipe.ativo) = $event)),
        style: {"float":"right"},
        "checked-children": "Ativa",
        "un-checked-children": "Inativa"
      }, null, 8, ["checked"])
    ]),
    closeIcon: _withCtx(() => [
      _createVNode(_component_icone, { nome: "voltar" })
    ]),
    footer: _withCtx(() => [
      ((_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Incluir && _ctx.telaBase.permissaoDados.incluir) || (_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Visualizar && _ctx.telaBase.permissaoDados.alterar))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("button", {
              type: "button",
              class: "ant-btn ant-btn-lg ant-btn-secondary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.salvar(true)))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvarNovo), 1)
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "ant-btn ant-btn-lg ant-btn-primary ss-btn-acao-destaque",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.salvar(false)))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvar), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_skeleton, {
        loading: _ctx.telaBase.carregando
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form, { layout: "vertical" }, {
            default: _withCtx(() => [
              _createVNode(_component_card, { titulo: "DADOS PRINCIPAIS" }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_a_form_item, {
                        label: "Empresa",
                        class: "ss-margin-campos",
                        rules: [{ required: true }]
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_selecionar_empresa, {
                            codigoSelecionado: _ctx.state.equipe.codigoEmpresa,
                            "onUpdate:codigoSelecionado": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.equipe.codigoEmpresa) = $event)),
                            empresasEspecificas: _ctx.telaBase.empresasDisponiveis
                          }, null, 8, ["codigoSelecionado", "empresasEspecificas"])
                        ]),
                        _: 1
                      })
                    ])
                  ], 512), [
                    [_vShow, _ctx.telaBase.apresentarEmpresas]
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_a_form_item, {
                        label: "Descrição",
                        class: "ss-margin-campos",
                        rules: [{ required: true }]
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            maxlength: "250",
                            class: "ant-input",
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.equipe.descricao) = $event))
                          }, null, 512), [
                            [_vModelText, _ctx.state.equipe.descricao]
                          ])
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_card, { titulo: "USUÁRIOS DA ALÇADA" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_a_form_item, {
                        label: "Usuário",
                        class: "ss-margin-campos"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_selecionar_usuario, {
                            empresas: [_ctx.props.operacao.empresaSelecionada],
                            "ignorar-usuarios": _ctx.state.equipe.usuarios,
                            codigoSelecionado: _ctx.state.usuario,
                            "onUpdate:codigoSelecionado": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.usuario) = $event)),
                            "descricao-selecionado": _ctx.state.nomeUsuario,
                            "onUpdate:descricaoSelecionado": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.nomeUsuario) = $event))
                          }, null, 8, ["empresas", "ignorar-usuarios", "codigoSelecionado", "descricao-selecionado"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_a_form_item, {
                        label: " ",
                        class: "ss-margin-campos"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("button", {
                            class: "ant-btn ant-btn-secondary",
                            style: {"width":"100%"},
                            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.adicionarUsuario && _ctx.adicionarUsuario(...args)))
                          }, "Adicionar")
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _createVNode(_component_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_draggable, {
                        modelValue: _ctx.state.equipe.usuarios,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.equipe.usuarios) = $event)),
                        "item-key": "codigoUsuario",
                        handle: ".ss-mover"
                      }, {
                        item: _withCtx(({ element, index }) => [
                          (_openBlock(), _createElementBlock("div", {
                            key: element.codigoUsuario,
                            class: "ss-mover"
                          }, [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("div", _hoisted_10, [
                                _createVNode(_component_a_form_item, {
                                  label: " ",
                                  class: "ss-margin-campos",
                                  style: {"cursor":"move"}
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("input", {
                                      style: {"cursor":"move"},
                                      type: "text",
                                      class: "ant-input",
                                      value: element.nomeUsuario,
                                      disabled: ""
                                    }, null, 8, _hoisted_11)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _createElementVNode("div", _hoisted_12, [
                                _createVNode(_component_a_form_item, {
                                  label: " ",
                                  class: "ss-margin-campos"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("button", {
                                      class: "ant-btn ant-btn-secondary",
                                      style: {"width":"100%"},
                                      onClick: ($event: any) => (_ctx.removerUsuario(index))
                                    }, [
                                      _createVNode(_component_icone, { nome: "excluir" })
                                    ], 8, _hoisted_13)
                                  ]),
                                  _: 2
                                }, 1024)
                              ])
                            ])
                          ]))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_requisicao_modal, {
        visivel: _ctx.modalBase.exibirRequisicaoModal,
        "onUpdate:visivel": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.modalBase.exibirRequisicaoModal) = $event)),
        retornoRequisicao: _ctx.modalBase.retornoRequisicao,
        apresentarBarraProgresso: _ctx.modalBase.apresentarBarraProgresso,
        fraseBarraProgresso: _ctx.modalBase.fraseBarraProgresso
      }, null, 8, ["visivel", "retornoRequisicao", "apresentarBarraProgresso", "fraseBarraProgresso"])
    ]),
    _: 1
  }, 8, ["visible"]))
}