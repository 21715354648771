
import { defineComponent, reactive, computed } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarTipoMovimentoComercial from '@/components/Compartilhados/SelecionarTipoMovimentoComercial.vue';
import SelecionarTipoDocumentoFiscal from '@/components/Compartilhados/SelecionarTipoDocumentoFiscal.vue';
import { ITituloFinanceiroDocumentoFiscal } from '@/models/Entidades/Financeiro/TitulosFinanceiros/ITituloFinanceiro';

export default defineComponent({
  name: 'TituloFinanceiroDocumentoFiscal',
  props: {
    codigoRegistro: {
      type: Number,
      required: true,
    },
    documentoFiscal: {
      type: Object as () => ITituloFinanceiroDocumentoFiscal,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Card,
    SelecionarData,
    SelecionarTipoMovimentoComercial,
    SelecionarTipoDocumentoFiscal,
  },
  emits: ['update:documentoFiscal'],
  setup(props, { emit }) {
    const state = reactive({
    });

    const computedDocumentoFiscal = computed({
      get: () => props.documentoFiscal,
      set: (documentoFiscal: ITituloFinanceiroDocumentoFiscal) => {
        emit('update:documentoFiscal', documentoFiscal);
      },
    });

    function obtemApresentacaoNumeroDocumento(tipoDocumentoFiscal:number):string {
      let tituloApresentacaoNumeroDocumento = '';

      switch (tipoDocumentoFiscal) {
        case 1:
          tituloApresentacaoNumeroDocumento = 'Número da NFe';
          break;

        case 2:
          tituloApresentacaoNumeroDocumento = 'Número da NFCe';
          break;

        case 3:
          tituloApresentacaoNumeroDocumento = 'Número da NFSe';
          break;

        case 4:
          tituloApresentacaoNumeroDocumento = 'Número da CCe';
          break;

        case 5:
          tituloApresentacaoNumeroDocumento = 'Número do MDFe';
          break;

        case 6:
          tituloApresentacaoNumeroDocumento = 'Número do CTe';
          break;

        default:
          tituloApresentacaoNumeroDocumento = 'Número da NFe';
          break;
      }

      return tituloApresentacaoNumeroDocumento;
    }

    return {
      props,
      state,
      computedDocumentoFiscal,
      obtemApresentacaoNumeroDocumento,
    };
  },
});
