import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_3 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_9 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_12 = { class: "ant-row" }
const _hoisted_13 = {
  class: "ant-col",
  style: {"flex":"auto 1 1"}
}
const _hoisted_14 = ["disabled"]
const _hoisted_15 = {
  key: 0,
  class: "ant-col ant-col-xs-4 ant-col-xl-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selecionar_data = _resolveComponent("selecionar-data")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_situacao_boleto = _resolveComponent("selecionar-situacao-boleto")!
  const _component_card = _resolveComponent("card")!
  const _component_remessa_boletos_modal = _resolveComponent("remessa-boletos-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_card, { titulo: "DADOS DO BOLETO" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_form_item, {
              label: "Data de Emissão",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_selecionar_data, {
                  dataSelecionada: _ctx.computedBoleto.dataEmissao,
                  "onUpdate:dataSelecionada": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedBoleto.dataEmissao) = $event)),
                  disabled: !_ctx.props.editavel || _ctx.computedBoleto.tipoEmissaoBoleto == _ctx.ETipoEmissaoBoleto.Propria
                }, null, 8, ["dataSelecionada", "disabled"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_a_form_item, {
              label: "Dt Processamento",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_selecionar_data, {
                  dataSelecionada: _ctx.computedBoleto.dataProcessamento,
                  "onUpdate:dataSelecionada": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedBoleto.dataProcessamento) = $event)),
                  disabled: !_ctx.props.editavel || _ctx.computedBoleto.tipoEmissaoBoleto == _ctx.ETipoEmissaoBoleto.Propria
                }, null, 8, ["dataSelecionada", "disabled"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_form_item, {
              label: "Nosso Número",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "ant-input",
                  style: {"text-align":"right"},
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedBoleto.nossoNumero) = $event)),
                  disabled: !_ctx.props.editavel || _ctx.computedBoleto.tipoEmissaoBoleto == _ctx.ETipoEmissaoBoleto.Propria
                }, null, 8, _hoisted_5), [
                  [_vModelText, _ctx.computedBoleto.nossoNumero]
                ])
              ]),
              _: 1
            })
          ]),
          (_ctx.props.tipoTitulo === _ctx.ETipoTituloFinanceiro.Receber)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_a_form_item, {
                    label: "Número do Documento",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "ant-input",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedBoleto.numeroDocumento) = $event)),
                        disabled: !_ctx.props.editavel || _ctx.computedBoleto.tipoEmissaoBoleto == _ctx.ETipoEmissaoBoleto.Propria
                      }, null, 8, _hoisted_7), [
                        [_vModelText, _ctx.computedBoleto.numeroDocumento]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_a_form_item, {
                    label: "Situação",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_situacao_boleto, {
                        valor: _ctx.computedBoleto.situacao,
                        "onUpdate:valor": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedBoleto.situacao) = $event)),
                        disabled: !_ctx.props.editavel
                      }, null, 8, ["valor", "disabled"])
                    ]),
                    _: 1
                  })
                ])
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_a_form_item, {
                    label: "Número do Documento",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "ant-input",
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedBoleto.numeroDocumento) = $event)),
                        disabled: !_ctx.props.editavel || _ctx.computedBoleto.tipoEmissaoBoleto == _ctx.ETipoEmissaoBoleto.Propria
                      }, null, 8, _hoisted_10), [
                        [_vModelText, _ctx.computedBoleto.numeroDocumento]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_a_form_item, {
                    label: "Situação",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_situacao_boleto, {
                        valor: _ctx.computedBoleto.situacao,
                        "onUpdate:valor": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedBoleto.situacao) = $event)),
                        disabled: !_ctx.props.editavel
                      }, null, 8, ["valor", "disabled"])
                    ]),
                    _: 1
                  })
                ])
              ], 64))
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_a_form_item, {
              label: "Linha Digitável",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "ant-input",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.computedBoleto.linhaDigitavel) = $event)),
                  disabled: !_ctx.props.editavel || _ctx.computedBoleto.tipoEmissaoBoleto == _ctx.ETipoEmissaoBoleto.Propria
                }, null, 8, _hoisted_14), [
                  [_vModelText, _ctx.computedBoleto.linhaDigitavel]
                ])
              ]),
              _: 1
            })
          ]),
          (_ctx.computedBoleto.situacao >= _ctx.ESituacaoBoleto.Registrado && _ctx.computedBoleto.tipoEmissaoBoleto == _ctx.ETipoEmissaoBoleto.Propria)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createVNode(_component_a_form_item, {
                  label: " ",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("button", {
                      class: "ant-btn ant-btn-secondary ss-acao-destaque-secundaria",
                      style: {"width":"100%"},
                      onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.abrirRemessa && _ctx.abrirRemessa(...args)))
                    }, "Remessa")
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_remessa_boletos_modal, {
      visivel: _ctx.state.exibirRemessa,
      "onUpdate:visivel": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.exibirRemessa) = $event)),
      empresa: _ctx.props.empresa,
      titulo: _ctx.props.boleto.codigoTituloFinanceiro
    }, null, 8, ["visivel", "empresa", "titulo"])
  ], 64))
}