
import {
  computed, defineComponent,
} from 'vue';

export default defineComponent({
  name: 'SelecionarSituacaoBoleto',
  components: {
  },
  props: {
    valor: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:valor', 'change'],
  setup(props, { emit }) {
    const computedValor = computed({
      get: () => props.valor,
      set: (valor: number) => {
        emit('update:valor', valor);
      },
    });

    function defineValorSelecionado(valor:number) {
      emit('update:valor', valor);
      emit('change', valor);
    }

    function defineValorMarcado(valor:number) {
      emit('change', valor);
    }

    return {
      props,
      computedValor,
      defineValorSelecionado,
      defineValorMarcado,
    };
  },
});
