
import { defineComponent } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import { IAberturaCaixa } from '@/models/Entidades/Financeiro/ControleCaixas/AberturaCaixa';
import SelecionarCaixa from '@/components/Cadastros/Financeiro/SelecionarCaixa.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarUsuario from '@/components/MeuSistema/Usuarios/SelecionarUsuario.vue';
import Icone from '@/core/components/Icone.vue';

export default defineComponent({
  name: 'DetalhesAberturaCaixa',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    abertura: {
      type: Object as () => IAberturaCaixa,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    SelecionarCaixa,
    SelecionarData,
    SelecionarUsuario,
  },
  setup(props) {
    return {
      props,
    };
  },
});
