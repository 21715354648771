
import {
  defineComponent, reactive, computed,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import Totalizador from '@/core/components/Tela/Totalizador.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { IDTOLancamentoBaixaConta } from '@/models/DTO/Financeiro/BaixasTitulosFinanceiros/IDTOLancamentoBaixaConta';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';

export default defineComponent({
  name: 'BaixaTitulosLancamento',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    tituloApresentacao: {
      type: String,
      default: '',
    },
    contaPadrao: {
      type: Number,
      default: 0,
    },
    dataBaixa: {
      type: String,
      required: true,
    },
    totalBaixa: {
      type: Number,
      required: true,
    },
    lancamentos: {
      type: Object as () => IDTOLancamentoBaixaConta[],
      required: true,
    },
    totalLancamentos: {
      type: Number,
      required: true,
    },
    valorRestante: {
      type: Number,
      default: 0,
    },
    baixaComDetalhamentos: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Card,
    MensagemSemDados,
    Totalizador,
    CampoNumerico,
    SelecionarData,
    SelecionarConta,
  },
  emits: ['update:lancamentos', 'update:totalLancamentos', 'atualizarTotalizadores'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();

    const state = reactive({
      computedLancamentos: computed({
        get: () => props.lancamentos,
        set: (val: IDTOLancamentoBaixaConta[]) => {
          emit('update:lancamentos', val);
        },
      }),
      totalLancamentos: 0,
      quantidadeLancamentos: 0,
    });

    function atualizarTotalizadores() {
      state.totalLancamentos = 0;
      state.quantidadeLancamentos = 0;
      state.computedLancamentos.forEach((t) => {
        if (t.codigoConta > 0) {
          state.totalLancamentos += t.valor;
        }
        state.quantidadeLancamentos += 1;
      });

      emit('update:totalLancamentos', state.totalLancamentos);
      emit('atualizarTotalizadores');
    }

    function excluirLancamento(index: number) {
      state.computedLancamentos.splice(index, 1);
      atualizarTotalizadores();
    }

    function adicionarLancamento() {
      const lancamento:IDTOLancamentoBaixaConta = {} as IDTOLancamentoBaixaConta;
      lancamento.codigoConta = 0;
      lancamento.dataBaixa = props.dataBaixa;
      lancamento.valor = 0;
      state.computedLancamentos.push(lancamento);
      atualizarTotalizadores();
    }

    function montaTituloCard(index:number): string {
      return `${index + 1}º ${props.tituloApresentacao.toLocaleUpperCase()}`;
    }

    function lancarValorRestante(index: number) {
      state.computedLancamentos[index].valor += props.valorRestante;
      atualizarTotalizadores();
    }
    return {
      props,
      state,
      telaBase,
      UtilitarioGeral,
      adicionarLancamento,
      excluirLancamento,
      atualizarTotalizadores,
      montaTituloCard,
      lancarValorRestante,
    };
  },
});
