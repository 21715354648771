export enum ETipoTributo {
    DARF = 16,
    DARFSimples = 18,
    GPS = 17,
    TributosMunicipais = 19,
    IPVA = 25,
    DPVAT = 27,
    FGTS = 35,
    GNRE = 91,
}
