

import { defineComponent, reactive, watch } from 'vue';
import { Modal } from 'ant-design-vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import SelecionarMarcadorMovimentoFinanceiro from '@/components/Financeiro/MovimentosFinanceiros/SelecionarMarcadorMovimentoFinanceiro.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import ServicoMovimentoFinanceiro from '@/servicos/Financeiro/ServicoMovimentoFinanceiro';
import SelecionarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiro.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOTransferenciaContas } from '@/models/DTO/Financeiro/TransferenciasContas/IDTOTransferenciaContas';
import { IDTOMovimentoFinanceiro } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiro';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';

export default defineComponent({
  name: 'TransferenciaContasVisualizacaoModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      default: 0,
      required: true,
    },
    movimento: {
      type: Number,
      required: true,
    },
    excluido: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    Card,
    SelecionarData,
    CampoNumerico,
    SelecionarSimNao,
    SelecionarEmpresa,
    SelecionarConta,
    SelecionarTipoDocumentoFinanceiro,
    SelecionarCategoriaPlanoConta,
    SelecionarMarcadorMovimentoFinanceiro,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissao, verificaConceitoParaApresentarEmpresas, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    const servicoMovimentoFinanceiro = new ServicoMovimentoFinanceiro();
    telaBase.identificadorRecurso = 'TRANSFERENCIA_ENTRE_CONTAS';
    telaBase.identificadorPermissao = 'AUT_TRANSFERENCIA_ENTRE_CONTAS';

    const state = reactive({
      transferencia: {} as IDTOTransferenciaContas,
      exibirEstorno: false,
      marcadores: [] as string[],
    });

    async function limparTela() {
      telaBase.empresasSelecionadas = [];
      telaBase.empresasSelecionadas.push(props.empresa);
      state.transferencia.movimentoFinanceiroEntrada = {} as IDTOMovimentoFinanceiro;
      state.transferencia.movimentoFinanceiroSaida = {} as IDTOMovimentoFinanceiro;
      state.transferencia.movimentoFinanceiroEntrada.codigoConta = 0;
      state.transferencia.movimentoFinanceiroSaida.codigoConta = 0;
      state.transferencia.movimentoFinanceiroEntrada.codigoEmpresa = 0;
      state.transferencia.movimentoFinanceiroSaida.codigoEmpresa = 0;
    }

    async function validarPermissaoEstorno() {
      state.exibirEstorno = await servicoMovimentoFinanceiro.validarPermissaoEstorno(props.empresa);
    }

    async function estornar() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso();
      retorno = await servicoMovimentoFinanceiro.estonarTransferencia(state.transferencia);
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        modalBase.computedVisivel = false;
        emit('confirmacao');
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function confirmarEstorno() {
      Modal.confirm({
        title: 'Você confirma o estorno desta transferência?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        centered: true,
        autoFocusButton: null,
        onOk: async () => { await estornar(); },
      });
    }

    async function obterTransferencia() {
      telaBase.carregando = true;
      state.transferencia = await servicoMovimentoFinanceiro.obterTransferencia(props.movimento, props.empresa);
      const marcadoresSaida = await servicoMovimentoFinanceiro.obterMarcadores(state.transferencia.movimentoFinanceiroSaida.codigo, state.transferencia.movimentoFinanceiroSaida.codigoEmpresa);

      marcadoresSaida.forEach((m) => {
        state.marcadores.push(m.marcador);
      });

      telaBase.carregando = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      if (modalBase.computedVisivel) {
        await obterPermissoes(ETipoPermissao.Autorizacoes);
        await preencherEmpresasComEstrategiaPermissao();
        verificaConceitoParaApresentarEmpresas();
        validarPermissaoEstorno();
        await obterTransferencia();
      }
    });

    return {
      props,
      state,
      emit,
      telaBase,
      modalBase,
      EPermissaoDados,
      UtilitarioGeral,
      UtilitarioMascara,
      UtilitarioData,
      apresentarRetornoRequisicao,
      confirmarEstorno,
      validarPermissaoEstorno,
    };
  },
});
