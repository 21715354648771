
import {
  defineComponent, reactive, computed, onBeforeMount,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Card from '@/core/components/Tela/Card.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { ETipoLancamentoBaixa } from '@/models/Enumeradores/Financeiro/ETipoLancamentoBaixa';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import Totalizador from '@/core/components/Tela/Totalizador.vue';
import { IDTOTituloFinanceiroPendenteBaixa } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroPendenteBaixa';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import ServicoTituloFinanceiroReceber from '@/servicos/Financeiro/ServicoTituloFinanceiroReceber';
import ServicoTituloFinanceiroPagar from '@/servicos/Financeiro/ServicoTituloFinanceiroPagar';
import { useGradeBase } from '@/core/composables/GradeBase';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { IDTOBaixaTitulosPessoaTotal } from '@/models/DTO/Financeiro/BaixasTitulosFinanceiros/IDTOBaixaTitulosPessoaTotal';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';

export default defineComponent({
  name: 'BaixaTitulosUtilizacaoCredito',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    tipoBaixaOrigem: {
      type: Number,
      required: true,
    },
    pessoasTitulos: {
      type: Object as () => IDTOBaixaTitulosPessoaTotal[],
      required: true,
    },
    utilizarCreditosApenasMesmaPessoa: {
      type: Boolean,
      required: true,
    },
    creditosUtilizados: {
      type: Object as () => IDTOTituloFinanceiroPendenteBaixa[],
      required: true,
    },
    totalCreditosUtilizados: {
      type: Number,
      required: true,
    },
    totalBaixa: {
      type: Number,
      required: true,
    },
    valorRestante: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Card,
    MensagemSemDados,
    Totalizador,
    CampoNumerico,
  },
  emits: ['update:creditosUtilizados', 'update:totalCreditosUtilizados', 'update:utilizarCreditosApenasMesmaPessoa', 'atualizarTotalizadores'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { gradeBase } = useGradeBase();

    const servicoTituloFinanceiroReceber = new ServicoTituloFinanceiroReceber();
    const servicoTituloFinanceiroPagar = new ServicoTituloFinanceiroPagar();

    const state = reactive({
      computedCreditosUtilizados: computed({
        get: () => props.creditosUtilizados,
        set: (val: IDTOTituloFinanceiroPendenteBaixa[]) => {
          emit('update:creditosUtilizados', val);
        },
      }),
      computedUtilizarCreditosApenasMesmaPessoa: computed({
        get: () => props.utilizarCreditosApenasMesmaPessoa,
        set: (val: boolean) => {
          emit('update:utilizarCreditosApenasMesmaPessoa', val);
        },
      }),
      totalCreditosUtilizados: 0,
      quantidadeCreditos: 0,
      totalCreditosDisponiveis: 0,
    });

    gradeBase.layoutMobile = UtilitarioDispositivo.larguraTelaMobile();

    function preencherColunas() {
      if (gradeBase.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Crédito', dataIndex: 'credito', key: 'Credito', position: 1, visible: true,
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'PGTO', dataIndex: 'descricaoTipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 1, visible: true, width: 40, ellipsis: true,
          },
          {
            title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 2, visible: true, ellipsis: true, width: 75,
          },
          {
            title: 'Vencimento', dataIndex: 'dataVencimento', key: 'DataVencimento', position: 3, visible: true, ordering: false, customRenderRow: ECustomRenderRow.MascararData, width: 60, align: 'center',
          },
          {
            title: 'Valor Disponível R$', dataIndex: 'saldo', key: 'Valor', position: 4, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 75,
          },
          {
            title: 'Valor Utilizado R$', dataIndex: 'valorUtilizado', key: 'ValorUtilizado', position: 5, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 75,
          },
          {
            title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 6, visible: true, ellipsis: true, width: 150,
          },
          {
            title: 'Conta', dataIndex: 'descricaoConta', key: 'Conta', position: 7, visible: true, ellipsis: true, width: 150,
          },

        ];
      }
    }

    preencherColunas();

    function atualizarTotalizadores(creditosDisponiveis:IDTOTituloFinanceiroPendenteBaixa[]) {
      state.quantidadeCreditos = 0;
      state.totalCreditosDisponiveis = 0;
      state.totalCreditosUtilizados = 0;
      creditosDisponiveis.forEach((t) => {
        state.totalCreditosDisponiveis += t.saldo;
        state.totalCreditosUtilizados += t.valorUtilizado;
        state.quantidadeCreditos += 1;
      });
      emit('update:totalCreditosUtilizados', state.totalCreditosUtilizados);
      emit('atualizarTotalizadores');
    }

    function adicionarTitulos(titulos: IDTOTituloFinanceiro[]) {
      const creditosDisponiveis = [] as IDTOTituloFinanceiroPendenteBaixa[];
      titulos.forEach((t) => {
        const tituloBaixa = {} as IDTOTituloFinanceiroPendenteBaixa;
        tituloBaixa.codigoTituloFinanceiro = t.codigo;
        tituloBaixa.codigoConta = t.codigoConta;
        tituloBaixa.codigoPlanoContaCategoria = t.codigoPlanoContaCategoria;
        tituloBaixa.codigoEmpresa = t.codigoEmpresa;
        tituloBaixa.codigoPessoa = t.codigoPessoa;
        tituloBaixa.codigoTipoDocumentoFinanceiro = t.codigoTipoDocumentoFinanceiro;
        tituloBaixa.dataCriacao = t.dataCriacao;
        tituloBaixa.dataVencimento = t.dataVencimento;
        tituloBaixa.descricaoConta = t.descricaoConta;
        tituloBaixa.descricaoTipo = t.descricaoTipo;
        tituloBaixa.descricaoTipoDocumentoFinanceiro = t.descricaoTipoDocumentoFinanceiro;
        tituloBaixa.siglaTipoDocumentoFinanceiro = t.siglaTipoDocumentoFinanceiro;
        tituloBaixa.nomePessoa = t.nomePessoa;
        tituloBaixa.nomePlanoContaCategoria = t.nomePlanoContaCategoria;
        tituloBaixa.numeroTitulo = t.numeroTitulo;
        tituloBaixa.observacoes = t.observacoes;
        tituloBaixa.saldo = t.saldo;
        tituloBaixa.status = t.status;
        tituloBaixa.tipo = t.tipo;
        tituloBaixa.valorPago = t.saldo;
        tituloBaixa.credito = true;
        if (UtilitarioGeral.validaLista(state.computedCreditosUtilizados)) {
          const index = state.computedCreditosUtilizados.findIndex((ts:IDTOTituloFinanceiroPendenteBaixa) => ts.codigoTituloFinanceiro === t.codigo);
          if (index >= 0) {
            tituloBaixa.valorUtilizado = state.computedCreditosUtilizados[index].valorUtilizado;
          }
        }

        creditosDisponiveis.push(tituloBaixa);
      });
      state.computedCreditosUtilizados = creditosDisponiveis;
      atualizarTotalizadores(creditosDisponiveis);
    }
    function estrategiaValorUtilizadoAlterado(index:number) {
      if (state.computedUtilizarCreditosApenasMesmaPessoa) {
        const totalTiulosPessoa = props.pessoasTitulos.find((c) => c.codigoPessoa === state.computedCreditosUtilizados[index].codigoPessoa);
        if (totalTiulosPessoa !== undefined) {
          let totalCreditoUtilizadoPessoa = 0;
          const creditosDisponiveisPessoa = state.computedCreditosUtilizados.filter((c) => c.codigoPessoa === totalTiulosPessoa.codigoPessoa);
          creditosDisponiveisPessoa.forEach((credito) => {
            if (credito.codigoPessoa === totalTiulosPessoa.codigoPessoa) {
              totalCreditoUtilizadoPessoa += credito.valorUtilizado;
            }
          });
          if (totalCreditoUtilizadoPessoa > totalTiulosPessoa.totalTitulos) {
            apresentarMensagemAlerta(`O total dos créditos utilizados da pessoa: ${state.computedCreditosUtilizados[index].nomePessoa} ultrapassaram o total dos seus títulos R$ ${UtilitarioMascara.mascararValor(totalTiulosPessoa.totalTitulos, 2)} .`);
            state.computedCreditosUtilizados[index].valorUtilizado = 0;
          }
        }
      }
      atualizarTotalizadores(state.computedCreditosUtilizados);
    }
    onBeforeMount(async () => {
      telaBase.carregando = true;
      state.computedCreditosUtilizados = props.creditosUtilizados;
      let creditos:IDTOTituloFinanceiro[] = [];
      if (props.tipoBaixaOrigem === ETipoTituloFinanceiro.Receber) {
        creditos = await servicoTituloFinanceiroPagar.obterCreditosPessoaAberto(props.empresa, props.pessoasTitulos.map((c) => c.codigoPessoa));
      } else if (props.tipoBaixaOrigem === ETipoTituloFinanceiro.Pagar) {
        creditos = await servicoTituloFinanceiroReceber.obterCreditosPessoaAberto(props.empresa, props.pessoasTitulos.map((c) => c.codigoPessoa));
      }

      if (UtilitarioGeral.validaLista(creditos)) {
        adicionarTitulos(creditos);
      } else {
        state.computedCreditosUtilizados = [];
        atualizarTotalizadores([]);
      }
      telaBase.carregando = false;
    });
    return {
      props,
      state,
      telaBase,
      gradeBase,
      ETipoLancamentoBaixa,
      UtilitarioGeral,
      UtilitarioData,
      UtilitarioMascara,
      adicionarTitulos,
      atualizarTotalizadores,
      estrategiaValorUtilizadoAlterado,
      ECustomRenderRow,
    };
  },
});
