
import { defineComponent, reactive } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IDTOTituloFinanceiroBaixado } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroBaixado';
import ContaReceberModal from '@/views/Financeiro/ContasReceber/ContaReceberModal.vue';
import ContaPagarModal from '@/views/Financeiro/ContasPagar/ContaPagarModal.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';

export default defineComponent({
  name: 'MovimentoFinanceiroTitulosBaixados',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    titulos: {
      type: Array as () => IDTOTituloFinanceiroBaixado[],
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    CampoNumerico,
    ContaReceberModal,
    ContaPagarModal,
  },
  emits: ['update:visivel', 'abrirBaixaTitulos'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();

    const state = reactive({
      exibirContaReceber: false,
      exibirContaPagar: false,
      operacaoLancamento: {} as ITelaOperacao,
    });

    function visualizar(codigoRegistro: number, tipo: ETipoTituloFinanceiro) {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Visualizar;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = codigoRegistro;
      state.operacaoLancamento.empresaSelecionada = props.empresa;
      if (tipo === ETipoTituloFinanceiro.Receber) {
        state.exibirContaReceber = true;
      } else {
        state.exibirContaPagar = true;
      }
    }

    return {
      props,
      state,
      UtilitarioData,
      visualizar,
    };
  },
});
