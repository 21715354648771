
import {
  defineComponent, onBeforeMount, reactive, watch,
} from 'vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IEventoCalendario } from '@/core/models/IEventoCalendario';
import Icone from '../Icone.vue';

export default defineComponent({
  name: 'CalendarioEventos',
  props: {
    eventos: {
      type: Array as () => IEventoCalendario[],
      default: [] as IEventoCalendario[],
    },
    dataCalendario: {
      type: String,
      default: '',
    },
  },
  components: {
    Icone,
  },
  emits: ['update:dataCalendario', 'buscarEventos', 'clickDia'],
  setup(props, { emit }) {
    const state = reactive({
      alturaDias: 'height: 35px;',
      classeDias: 'height: 35px; padding-left: 0px; padding-top: 0px; padding-right: 0px; margin: 0px;',
      semanas: [] as any[],
    });

    function obterNumeroDiaSemana(diaSemana: string) {
      switch (diaSemana.toLocaleLowerCase()) {
        case 'dom':
          return 1;
        case 'seg':
          return 2;
        case 'ter':
          return 3;
        case 'qua':
          return 4;
        case 'qui':
          return 5;
        case 'sex':
          return 6;
        default:
          return 7;
      }
    }

    function popularCalendario() {
      state.semanas = [];
      state.semanas.push([] as string[]);
      const dataCalendario = UtilitarioData.converterDataHoraMinutoSegundoParaDayJs(props.dataCalendario);
      const primeiroDia = UtilitarioData.defineDiaNaDataDayJs(dataCalendario, 1);
      const ultimoDia = UtilitarioData.defineUltimoDiaMesNaDataDayJs(dataCalendario);
      const primeiroDiaSemana = obterNumeroDiaSemana(UtilitarioData.obterDiaDaSemanaAbreviado(primeiroDia));
      const ultimoDiaSemana = obterNumeroDiaSemana(UtilitarioData.obterDiaDaSemanaAbreviado(ultimoDia));
      let dataCorrente = primeiroDia;

      for (let i = 0; i < primeiroDiaSemana - 1; i += 1) {
        state.semanas[0].push('');
      }
      for (let i = 0; i < UtilitarioData.obterQuantidadeDiasMesDayJs(dataCalendario); i += 1) {
        state.semanas[state.semanas.length - 1].push(dataCorrente.format('YYYY-MM-DD'));
        const diaSemana = obterNumeroDiaSemana(UtilitarioData.obterDiaDaSemanaAbreviado(dataCorrente));
        if (diaSemana === 7) {
          state.semanas.push([] as string[]);
        }
        dataCorrente = UtilitarioData.adicionarDiaNaDataDayJs(dataCorrente, 1);
      }
      for (let i = 0; i < 7 - ultimoDiaSemana; i += 1) {
        state.semanas[state.semanas.length - 1].push('');
      }
    }

    function clickDia(dia: any) {
      emit('clickDia', String(dia));
    }

    function obterMesAnoCalendario():string {
      return UtilitarioData.obterMesAno(props.dataCalendario).toLocaleUpperCase();
    }

    function calendarioMesAnterior() {
      const novaData = UtilitarioData.diminuirMesNaDataHoraMinutoSegundo(props.dataCalendario, 1);
      emit('update:dataCalendario', novaData);
      emit('buscarEventos');
    }

    function calendarioProximoMes() {
      const novaData = UtilitarioData.adicionarMesNaDataHoraMinutoSegundo(props.dataCalendario, 1);
      emit('update:dataCalendario', novaData);
      emit('buscarEventos');
    }

    onBeforeMount(async () => {
      popularCalendario();
    });

    watch(() => props.dataCalendario, () => {
      state.semanas = [];
      popularCalendario();
    });
    return {
      props,
      state,
      UtilitarioData,
      clickDia,
      obterMesAnoCalendario,
      calendarioMesAnterior,
      calendarioProximoMes,
    };
  },
});
