
import {
  defineComponent, reactive, computed,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import Totalizador from '@/core/components/Tela/Totalizador.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiro.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import UtilitarioFinanceiro from '@/core/utilitarios/UtilitarioFinanceiro';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import { EMeioPagamento } from '@/models/Enumeradores/Cadastros/Financeiro/EMeioPagamento';

export default defineComponent({
  name: 'BaixaTitulosLancamentoCreditos',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    recursoOrigem: {
      type: String,
      default: '',
    },
    tipoTituloFinanceiro: {
      type: Number,
      required: true,
    },
    contaPadrao: {
      type: Number,
      default: 0,
    },
    totalSobraValores: {
      type: Number,
      required: true,
    },
    creditosLancados: {
      type: Object as () => IDTOTituloFinanceiro[],
      required: true,
    },
    totalCreditosLancados: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    MensagemSemDados,
    Totalizador,
    CampoNumerico,
    SelecionarTipoDocumentoFinanceiro,
    SelecionarData,
    SelecionarConta,
    SelecionarPessoa,
    SelecionarCategoriaPlanoConta,
  },
  emits: ['update:creditosLancados', 'update:totalCreditosLancados', 'atualizarTotalizadores'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();

    const state = reactive({
      computedCreditosLancados: computed({
        get: () => props.creditosLancados,
        set: (val: IDTOTituloFinanceiro[]) => {
          emit('update:creditosLancados', val);
        },
      }),
      totalCreditosLancados: 0,
      quantidadeCreditosLancados: 0,
    });

    function atualizarTotalizadores(index:number) {
      state.totalCreditosLancados = 0;
      state.quantidadeCreditosLancados = 0;
      state.computedCreditosLancados.forEach((t) => {
        if (t.codigoConta > 0 && t.codigoTipoDocumentoFinanceiro > 0 && t.codigoPlanoContaCategoria > 0
            && UtilitarioGeral.valorValido(t.numeroTitulo) && UtilitarioGeral.valorValido(t.dataVencimento)) {
          state.totalCreditosLancados += t.valorTotal;

          if (state.totalCreditosLancados > props.totalSobraValores) {
            apresentarMensagemAlerta(`O total dos créditos lançados ultrapassaram o total da Sobra de Valores R$ ${UtilitarioMascara.mascararValor(props.totalSobraValores, 2)} .`);
            state.totalCreditosLancados -= t.valorTotal;
            state.computedCreditosLancados[index].valorTotal = 0;
          }
        }
        state.quantidadeCreditosLancados += 1;
      });

      emit('update:totalCreditosLancados', state.totalCreditosLancados);
      emit('atualizarTotalizadores');
    }

    function excluirLancamento(index: number) {
      state.computedCreditosLancados.splice(index, 1);
      atualizarTotalizadores(0);
    }

    function adicionarCredito() {
      const credito:IDTOTituloFinanceiro = {} as IDTOTituloFinanceiro;
      credito.codigoEmpresa = props.empresa;
      credito.codigoConta = props.contaPadrao;
      credito.codigoTipoDocumentoFinanceiro = 0;
      credito.codigoPlanoContaCategoria = 0;
      credito.valorTotal = 0;
      credito.saldo = 0;
      credito.tipo = props.tipoTituloFinanceiro;
      credito.parcela = 0;
      credito.status = EStatusTituloFinanceiro.Pendente;
      credito.observacoes = 'Crédito lançado a partir da baixa de títulos';
      state.computedCreditosLancados.push(credito);
      atualizarTotalizadores(0);
    }

    function montaTituloCard(index:number): string {
      return `${index + 1}º CRÉDITO`;
    }

    function obterValorRestanteLancamentoCreditos() {
      let valorRestante = 0;

      valorRestante = UtilitarioFinanceiro.valorDecimal2Casas(props.totalSobraValores - state.totalCreditosLancados);
      if (valorRestante < 0) { valorRestante = UtilitarioFinanceiro.valorDecimal2Casas(0); }
      return valorRestante;
    }

    return {
      props,
      state,
      telaBase,
      UtilitarioGeral,
      adicionarCredito,
      excluirLancamento,
      atualizarTotalizadores,
      montaTituloCard,
      obterValorRestanteLancamentoCreditos,
      ETipoTituloFinanceiro,
      EMeioPagamento,
    };
  },
});
