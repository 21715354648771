
import {
  defineComponent, onBeforeMount, reactive, watch, ref,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { useScroll } from '@vueuse/core';
import { useTelaBase } from '@/core/composables/TelaBase';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { EFiltroPeriodoMovimentoConta } from '@/models/Enumeradores/Financeiro/EFiltroPeriodoMovimentoConta';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { IFiltroGenericoAdicionado } from '@/core/models/BuscaAvancada/IFiltroGenericoAdicionado';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarFiltroPeriodoMovimentoConta from '@/components/Financeiro/MovimentosFinanceiros/SelecionarFiltroPeriodoMovimentoConta.vue';
import SelecionarFormaVisualizacaoMovimentoConta from '@/components/Financeiro/MovimentosFinanceiros/SelecionarFormaVisualizacaoMovimentoConta.vue';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';
import SelecionarTipoMovimentoFinanceiro from '@/components/Financeiro/MovimentosFinanceiros/SelecionarTipoMovimentoFinanceiro.vue';
import MovimentoFinanceiroTotalizadores from '@/components/Financeiro/MovimentosFinanceiros/MovimentoFinanceiroTotalizadores.vue';
import ServicoMovimentoFinanceiro from '@/servicos/Financeiro/ServicoMovimentoFinanceiro';
import { IDTOMovimentoFinanceiro } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiro';
import { ETipoMovimentoFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoMovimentoFinanceiro';
import MovimentoFinanceiroModal from './MovimentoFinanceiroModal.vue';
import ServicoConta from '@/servicos/Cadastros/Financeiro/ServicoConta';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { IConta } from '@/models/Entidades/Cadastros/Financeiro/IConta';
import { ETipoConta } from '@/models/Enumeradores/Cadastros/Financeiro/ETipoConta';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import SelecionarConciliado from '@/components/Financeiro/MovimentosFinanceiros/SelecionarConciliado.vue';
import BaixaTitulosModal from '@/components/Financeiro/BaixaTitulosFinanceiros/BaixaTitulosModal.vue';
import TransferenciaContasModal from './TransferenciaContasModal.vue';
import TransferenciaContasVisualizacaoModal from './TransferenciaContasVisualizacaoModal.vue';
import ControlePeriodoMovimentacaoContasModal from './ControlePeriodoMovimentacaoContasModal.vue';
import ImportacaoExtratoOfxModal from './ImportacaoExtratoOfxModal.vue';
import router from '@/router';
import { IParametrosConsultaMovimentoFinanceiro } from '@/models/ParametrosRequisicao/Financeiro/IParametrosConsultaMovimentoFinanceiro';
import { IDTOMovimentoFinanceiroExtrato } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiroExtrato';
import { IDTOUsuarioPermissoes } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoes';
import storeFinanceiro from '@/store/storeFinanceiro';
import ImportacaoRetornoBancarioModal from './ImportacaoRetornoBancarioModal.vue';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import { EFormaVisualizacaoMovimentoConta } from '@/models/Enumeradores/Financeiro/EFormaVisualizacaoMovimentoConta';

export default defineComponent({
  name: 'MovimentosFinanceiros',
  components: {
    Icone,
    Card,
    PreviewPdf,
    MensagemSemDados,
    MovimentoFinanceiroModal,
    SelecionarEmpresa,
    SelecionarConta,
    SelecionarFiltroPeriodoMovimentoConta,
    SelecionarDataInicialFinal,
    SelecionarTipoMovimentoFinanceiro,
    MovimentoFinanceiroTotalizadores,
    TituloPadrao,
    SelecionarConciliado,
    BaixaTitulosModal,
    TransferenciaContasModal,
    TransferenciaContasVisualizacaoModal,
    ControlePeriodoMovimentacaoContasModal,
    ImportacaoExtratoOfxModal,
    ImportacaoRetornoBancarioModal,
    SelecionarFormaVisualizacaoMovimentoConta,
  },
  setup(props) {
    const servicoMovimentoFinanceiro = new ServicoMovimentoFinanceiro();
    const servicoConta = new ServicoConta();
    const servicoSistema = new ServicoSistema();
    let debounce = 0;
    let debounceSelecaoConta = 0;
    const {
      telaBase, obterPermissoes, preencherDadosRota, verificaConceitoParaApresentarEmpresas,
      preencherEmpresasComEstrategiaPermissaoDados, filtrarPermissaoDadosUsuario,
      apresentarMensagemSucesso, apresentarMensagemAlerta, salvarPreferencias,
      adicionarAtalho, removerAtalho, apresentarMensagemErro,
    } = useTelaBase();
    const {
      gradeBase, defineAlturaScroll,
    } = useGradeBase();
    const route = useRoute();

    const carregandoMovimentosFinanceiros = ref(false);
    const scrollContainer = ref<HTMLElement | null>(null);
    const movimentosPorPagina = 20;
    let paginaAtual = 0;

    const state = reactive({
      buscaAvancada: {} as IBuscaAvancada,
      parametrosConsulta: {} as IParametrosConsultaMovimentoFinanceiro,
      codigoMovimentoSelecionado: 0,
      contaSelecionada: {} as IConta,
      dataAtual: '',
      extratoConta: {} as IDTOMovimentoFinanceiroExtrato,
      apresentarResultados: false,
      exibirLancamento: false,
      exibirControlePeriodoMovimentacao: false,
      exibirHistorico: false,
      exibirBaixa: false,
      exibirTransferencia: false,
      visualizarTransferencia: false,
      exibirImportacaoOfx: false,
      exibirImportacaoRetorno: false,
      operacaoLancamento: {} as ITelaOperacao,
      autorizacoes: [] as IDTOUsuarioPermissoes [],
      possuiPreferencias: false,
      movimentosConciliar: [] as IDTOMovimentoFinanceiro[],
      movimentosVisiveis: [] as IDTOMovimentoFinanceiro[],
      arquivosPdf: [] as IArquivoPdf[],
      chaveBuscaMovimentacoes: 1,
    });
    state.extratoConta.movimentos = [];
    state.movimentosVisiveis = [];
    state.movimentosConciliar = [];
    state.parametrosConsulta.influenciaSaldo = true;
    state.parametrosConsulta.excluidos = false;
    state.parametrosConsulta.periodo = EFiltroPeriodoMovimentoConta.Hoje;
    state.parametrosConsulta.formaVisualizacao = EFormaVisualizacaoMovimentoConta.Analitica;
    state.parametrosConsulta.conciliados = 0;
    state.parametrosConsulta.tipoMovimento = 0;

    function zerarTotalizadoresSaldo() {
      state.extratoConta.saldoAnterior = 0;
      state.extratoConta.saldo = 0;
      state.extratoConta.saldoConciliado = 0;
      state.extratoConta.pagamentos = 0;
      state.extratoConta.recebimentos = 0;
    }

    function preencherColunas() {
      let exibirSaldo = true;
      if (state.parametrosConsulta.excluidos || !state.parametrosConsulta.influenciaSaldo || UtilitarioGeral.valorValido(state.parametrosConsulta.buscaRapida)) {
        exibirSaldo = false;
      }
      if (storeSistema.state.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Movimento', dataIndex: 'movimento', key: 'Movimento', position: 2, visible: true, ordering: true, align: 'left',
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Conciliado', dataIndex: 'conciliado', key: 'Conciliado', position: 2, visible: true, align: 'center', width: 45, fixed: 'left',
          },
          {
            title: 'N.Identificação', dataIndex: 'identificadorMovimento', key: 'IdentificadorMovimento', position: 3, visible: true, ellipsis: true, width: 70, customRenderRow: ECustomRenderRow.LinkAcao, fixed: 'left',
          },
          {
            title: 'Data', dataIndex: 'dataMovimento', key: 'DataMovimento', position: 4, visible: true, ordering: true, customRenderRow: ECustomRenderRow.MascararData, width: 50, align: 'center',
          },
          {
            title: 'Tipo Movimento', dataIndex: 'tipoMovimento', key: 'TipoMovimento', position: 5, visible: true, width: 60, align: 'left',
          },
          {
            title: 'T.Documento', dataIndex: 'descricaoTipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 6, visible: true, width: 50, ellipsis: true,
          },
          {
            title: 'Valor R$', dataIndex: 'valor', key: 'Valor', position: 7, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 65,
          },
          {
            title: 'Saldo R$', dataIndex: 'saldo', key: 'Saldo', position: 8, visible: exibirSaldo, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 65,
          },
          {
            title: 'Categoria', dataIndex: 'nomePlanoContaCategoria', key: 'NomePlanoContaCategoria', position: 9, visible: true, ellipsis: true, width: 100,
          },
          {
            title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 10, visible: true, ellipsis: true, width: 150,
          },
          {
            title: 'Ações', key: 'acoes', position: 11, visible: true, fixed: 'right', width: 50, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
          },
        ];
      }
    }

    function obterFiltros() {
      switch (state.parametrosConsulta.periodo) {
        case EFiltroPeriodoMovimentoConta.Hoje:
          state.parametrosConsulta.dataInicial = state.dataAtual;
          state.parametrosConsulta.dataFinal = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoConta.MesAtual:
          state.parametrosConsulta.dataInicial = UtilitarioData.obterPrimeiroDiaMes(state.dataAtual);
          state.parametrosConsulta.dataFinal = UtilitarioData.obterUltimoDiaMes(state.dataAtual);
          break;

        case EFiltroPeriodoMovimentoConta.AnoAtual:
          state.parametrosConsulta.dataInicial = UtilitarioData.obterPrimeiroDiaAno(state.dataAtual);
          state.parametrosConsulta.dataFinal = UtilitarioData.obterUltimoDiaAno(state.dataAtual);
          break;

        case EFiltroPeriodoMovimentoConta.Ultimos7Dias:
          state.parametrosConsulta.dataInicial = UtilitarioData.diminuirDiaNaData(state.dataAtual, 7);
          state.parametrosConsulta.dataFinal = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoConta.Ultimos15Dias:
          state.parametrosConsulta.dataInicial = UtilitarioData.diminuirDiaNaData(state.dataAtual, 15);
          state.parametrosConsulta.dataFinal = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoConta.Ultimos30Dias:
          state.parametrosConsulta.dataInicial = UtilitarioData.diminuirDiaNaData(state.dataAtual, 30);
          state.parametrosConsulta.dataFinal = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoConta.Ultimos60Dias:
          state.parametrosConsulta.dataInicial = UtilitarioData.diminuirDiaNaData(state.dataAtual, 60);
          state.parametrosConsulta.dataFinal = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoConta.Ultimos90dias:
          state.parametrosConsulta.dataInicial = UtilitarioData.diminuirDiaNaData(state.dataAtual, 90);
          state.parametrosConsulta.dataFinal = state.dataAtual;
          break;

        default:
      }

      state.buscaAvancada.filtrosAdicionados = [] as IFiltroGenericoAdicionado[];

      gradeBase.filtrosAplicados = [];
      state.buscaAvancada.filtrosAdicionados.forEach((item) => {
        gradeBase.filtrosAplicados.push(item.filtro);
      });
    }

    async function atualizarSelecaoConta() {
      if (state.parametrosConsulta.conta > 0) {
        state.contaSelecionada = await servicoConta.obter(state.parametrosConsulta.conta);
      } else {
        state.contaSelecionada = {} as IConta;
      }
    }

    async function atualizarSelecaoEmpresas() {
      telaBase.empresasSelecionadas = [] as number[];
      telaBase.empresasSelecionadas.push(state.parametrosConsulta.empresa);
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuario(state.parametrosConsulta.empresa);
      state.parametrosConsulta.conta = await servicoConta.obterCodigoContaPadrao(state.parametrosConsulta.empresa);
      atualizarSelecaoConta();
    }

    async function salvarFiltrosPreferencias() {
      await salvarPreferencias('', 'filtros-movimentacoes-contas', JSON.stringify(state.parametrosConsulta), telaBase.empresasSelecionadas);
    }
    async function carregarPreferencias() {
      telaBase.preferencias = await servicoSistema.obterPreferenciaRecurso(telaBase.identificadorRecurso, [state.parametrosConsulta.empresa]);
      if (telaBase.preferencias !== null) {
        const preferenciaFiltros = telaBase.preferencias.find(((preferencia) => preferencia.chave === 'filtros-movimentacoes-contas'));
        if (preferenciaFiltros !== undefined && preferenciaFiltros !== null) {
          const filtrosSalvos = JSON.parse(preferenciaFiltros.valor);
          state.parametrosConsulta.periodo = filtrosSalvos.periodo;
          state.parametrosConsulta.dataInicial = filtrosSalvos.dataInicial;
          state.parametrosConsulta.dataFinal = filtrosSalvos.dataFinal;
          state.parametrosConsulta.conta = filtrosSalvos.conta;
          state.parametrosConsulta.tipoMovimento = filtrosSalvos.tipoMovimento;
          if (UtilitarioGeral.validaCodigo(filtrosSalvos.formaVisualizacao)) {
            state.parametrosConsulta.formaVisualizacao = filtrosSalvos.formaVisualizacao;
          }
          state.parametrosConsulta.conciliados = filtrosSalvos.conciliados;
          state.parametrosConsulta.influenciaSaldo = true;
          if (UtilitarioGeral.validaLista(filtrosSalvos.ordenacao)) {
            state.parametrosConsulta.ordenacao = filtrosSalvos.ordenacao;
          }
          state.possuiPreferencias = true;
        }
      }
    }

    const carregarMaisMovimentosFinanceiros = () => {
      const start = paginaAtual * movimentosPorPagina;
      const end = start + movimentosPorPagina;
      state.movimentosVisiveis.push(...state.extratoConta.movimentos.slice(start, end));
      paginaAtual += 1;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { y } = useScroll(scrollContainer);

    const onScroll = () => {
      if (scrollContainer.value) {
        const bottomReached = (scrollContainer.value.scrollTop + scrollContainer.value.clientHeight + 1) >= scrollContainer.value.scrollHeight;
        if (bottomReached && !carregandoMovimentosFinanceiros.value) {
          carregarMaisMovimentosFinanceiros();
        }
      }
    };

    async function buscarMovimentos(salvarFiltros = false) {
      state.chaveBuscaMovimentacoes += 1;
      state.extratoConta.movimentos = [];
      state.movimentosVisiveis = [];
      paginaAtual = 0;
      zerarTotalizadoresSaldo();
      state.apresentarResultados = false;

      if (!UtilitarioGeral.validaCodigo(state.parametrosConsulta.conta)) {
        apresentarMensagemAlerta('A conta deve ser informada antes de buscar as movimentações!');
        return;
      }

      if (state.parametrosConsulta.periodo === EFiltroPeriodoMovimentoConta.Personalizado && !UtilitarioGeral.valorValido(state.parametrosConsulta.dataInicial)) {
        apresentarMensagemAlerta('A data inicial deve ser informada antes de buscar as movimentações!');
        return;
      }

      if (state.parametrosConsulta.periodo === EFiltroPeriodoMovimentoConta.Personalizado && !UtilitarioGeral.valorValido(state.parametrosConsulta.dataFinal)) {
        apresentarMensagemAlerta('A data final deve ser informada antes de buscar as movimentações!');
        return;
      }

      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        state.parametrosConsulta.ordenacao = Array<string>();

        gradeBase.ordenacaoSelecionada.forEach((item) => {
          state.parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
        });

        if (!UtilitarioGeral.validaLista(gradeBase.ordenacaoSelecionada)) {
          state.parametrosConsulta.ordenacao.push('DataMovimento|ASC');
        }

        telaBase.carregando = true;
        state.extratoConta = await servicoMovimentoFinanceiro.buscarMovimentos(state.parametrosConsulta, gradeBase.filtrosAplicados);

        state.apresentarResultados = true;
        telaBase.carregando = false;
        if (salvarFiltros) { salvarFiltrosPreferencias(); }
        carregarMaisMovimentosFinanceiros();
      }, 600);
      obterFiltros();
    }

    async function verificarAlteracaoPeriodo() {
      if (state.parametrosConsulta.periodo !== EFiltroPeriodoMovimentoConta.Personalizado) {
        await buscarMovimentos(true);
      }
    }

    function verificaAcoesMovimentosSelecionados() {
      state.movimentosConciliar = [];

      if (UtilitarioGeral.validaLista(gradeBase.codigosSelecionados)) {
        const movimentosSelecionados = state.extratoConta.movimentos.filter((c) => gradeBase.codigosSelecionados.includes(c.codigo));

        const movimentosParaConciliar = movimentosSelecionados.filter((c) => c.excluido === false && c.conciliado === false);
        if (UtilitarioGeral.validaLista(movimentosParaConciliar)) {
          state.movimentosConciliar = movimentosParaConciliar;
        }
      }
    }

    const mudarSelecao = (selectedRowKeys: any) => {
      gradeBase.codigosSelecionados = selectedRowKeys;
      verificaAcoesMovimentosSelecionados();
    };

    function apresentarHistorico() {
      state.exibirHistorico = true;
    }

    function abrirControlePeriodoMovimentacao() {
      state.exibirControlePeriodoMovimentacao = true;
    }

    function incluir() {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = 0;
      state.operacaoLancamento.empresaSelecionada = state.parametrosConsulta.empresa;
      state.exibirLancamento = true;
    }

    function visualizar(codigo: number) {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Visualizar;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = codigo;
      state.operacaoLancamento.empresaSelecionada = state.parametrosConsulta.empresa;
      state.exibirLancamento = true;
    }

    async function excluir(codigo: number) {
      const retorno = await servicoMovimentoFinanceiro.excluirMovimento(codigo, state.parametrosConsulta.empresa);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        buscarMovimentos();
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        Modal.warning({
          title: 'Não foi possível excluir o movimento da conta!',
          content: retorno.mensagem,
        });
      }
    }

    async function conciliar(codigos: number[]) {
      const retorno = await servicoMovimentoFinanceiro.conciliarMovimentos(codigos, state.parametrosConsulta.empresa);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        gradeBase.codigosSelecionados = [];
        state.movimentosConciliar = [];
        apresentarMensagemSucesso(retorno.mensagem);
        buscarMovimentos();
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        Modal.warning({
          title: 'Não foi possível conciliar o movimento da conta!',
          content: retorno.mensagem,
        });
      }
    }

    function montaMensagemDadosMovimento(objeto: IDTOMovimentoFinanceiro): string {
      return `N.Identificação: ${objeto.identificadorMovimento} \n Descrição: ${objeto.descricao} \n Valor R$: ${UtilitarioMascara.mascararValor(objeto.valor)}`;
    }
    async function confirmarExclusao(objeto: IDTOMovimentoFinanceiro) {
      Modal.confirm({
        title: 'Você confirma a exclusão do movimento:',
        content: `${montaMensagemDadosMovimento(objeto)}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        centered: true,
        width: 600,
        autoFocusButton: null,
        onOk: async () => { await excluir(objeto.codigo); },
      });
    }

    async function confirmarConciliacao() {
      if (state.movimentosConciliar.length === 0) {
        apresentarMensagemAlerta('Nenhum movimento selecionado');
        return;
      }
      let mensagem = '';
      for (let contador = 0; contador < state.movimentosConciliar.length; contador += 1) {
        mensagem += `${montaMensagemDadosMovimento(state.movimentosConciliar[contador])}\n`;
        if (contador < gradeBase.codigosSelecionados.length - 1) {
          mensagem += ',';
        }
      }
      Modal.confirm({
        title: 'Você confirma a conciliação dos movimentos:',
        content: `${mensagem} ?`,
        okText: 'Sim',
        okType: 'primary',
        cancelText: 'Não',
        centered: true,
        width: 600,
        autoFocusButton: null,
        onOk: async () => { await conciliar(state.movimentosConciliar.map((c) => c.codigo)); },
      });
    }

    async function confirmarConciliacaoUnica(objeto: IDTOMovimentoFinanceiro) {
      Modal.confirm({
        title: 'Você confirma a conciliação do movimento:',
        content: `${montaMensagemDadosMovimento(objeto)} ?`,
        okText: 'Sim',
        okType: 'primary',
        cancelText: 'Não',
        centered: true,
        width: 600,
        autoFocusButton: null,
        onOk: async () => { await conciliar([objeto.codigo]); },
      });
    }

    function visualizarBaixa(codigo: number) {
      state.codigoMovimentoSelecionado = codigo;
      state.exibirBaixa = true;
    }

    function visualizarTransferencia(codigo: number) {
      state.codigoMovimentoSelecionado = codigo;
      state.visualizarTransferencia = true;
    }

    function redirecionarBaixaContasPagar() {
      const token = storeFinanceiro.mutations.adicionarComunicacao(state.parametrosConsulta.empresa, state.parametrosConsulta.conta, 'MovimentacoesContas');
      router.push({ name: 'BaixaContasPagar', query: { token } });
    }

    function redirecionarBaixaContasReceber() {
      const token = storeFinanceiro.mutations.adicionarComunicacao(state.parametrosConsulta.empresa, state.parametrosConsulta.conta, 'MovimentacoesContas');
      router.push({ name: 'BaixaContasReceber', query: { token } });
    }

    function abrirTransferencia() {
      state.exibirTransferencia = true;
    }

    function abrirImportacaoOfx() {
      state.exibirImportacaoOfx = true;
    }

    function abrirImportacaoRetorno() {
      state.exibirImportacaoRetorno = true;
    }

    async function verificacaoFiltrosBoleanos() {
      if (state.parametrosConsulta.excluidos) {
        state.parametrosConsulta.influenciaSaldo = true;
      }
      preencherColunas();
      await buscarMovimentos(true);
    }

    onBeforeMount(async () => {
      telaBase.carregando = true;
      state.possuiPreferencias = false;
      preencherDadosRota();
      await obterPermissoes(ETipoPermissao.Dados);
      await preencherEmpresasComEstrategiaPermissaoDados(EPermissaoDados.Visualizar, false);
      verificaConceitoParaApresentarEmpresas();

      if (!UtilitarioGeral.validaCodigo(state.parametrosConsulta.empresa)) {
        state.parametrosConsulta.empresa = storeSistema.getters.codigoEmpresaOperacao();
        await carregarPreferencias();
      }

      const tokenStore = String(route.query.token);
      if (UtilitarioGeral.valorValido(tokenStore)) {
        const comunicacaoFinanceiro = storeFinanceiro.getters.obterComunicacao(tokenStore);
        if (UtilitarioGeral.objetoValido(comunicacaoFinanceiro) && UtilitarioGeral.validaCodigo(comunicacaoFinanceiro.empresa)) {
          state.parametrosConsulta.empresa = comunicacaoFinanceiro.empresa;
          state.parametrosConsulta.conta = comunicacaoFinanceiro.conta;
          state.parametrosConsulta.influenciaSaldo = true;
          storeFinanceiro.mutations.removerComunicacao(tokenStore);
        }
      }

      if (!state.possuiPreferencias) {
        state.parametrosConsulta.conta = await servicoConta.obterCodigoContaPadrao(state.parametrosConsulta.empresa);
      }

      telaBase.empresasSelecionadas = [] as number[];
      telaBase.empresasSelecionadas.push(state.parametrosConsulta.empresa);

      telaBase.permissaoDados = await filtrarPermissaoDadosUsuario(state.parametrosConsulta.empresa);
      telaBase.propriedadesConsulta = await servicoMovimentoFinanceiro.obterPropriedadesConsulta();
      state.autorizacoes = await servicoSistema.obterVariasPermissoesAutorizacoesUsuario(['AUT_BAIXAS_CONTAS_RECEBER', 'AUT_BAIXAS_CONTAS_PAGAR', 'AUT_TRANSFERENCIA_ENTRE_CONTAS', 'AUT_MOVIMENTACOES_CONTAS_PERIODOS_FINANCEIROS', 'AUT_IMPORTACAO_EXTRATO_OFX']);
      if (telaBase.propriedadesConsulta.length > 0) {
        state.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        state.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
      state.buscaAvancada.filtrosAdicionados = [];
      state.buscaAvancada.chaveFiltrosAdicionados = 0;

      state.dataAtual = await servicoSistema.obterDataAtual();
      state.dataAtual = UtilitarioData.converterDataJson(state.dataAtual);
      zerarTotalizadoresSaldo();

      clearTimeout(debounceSelecaoConta);
      debounceSelecaoConta = setTimeout(async () => {
        await atualizarSelecaoConta();

        preencherColunas();

        if (state.parametrosConsulta.conta > 0) {
          await buscarMovimentos(false);
        }
        telaBase.carregando = false;
      }, 150);
    });

    function verificarAutorizacao(identificadorAutorizacao: string) {
      return servicoSistema.verificaAutorizacao(state.parametrosConsulta.empresa, identificadorAutorizacao, state.autorizacoes);
    }

    async function imprimir(tipoArquivoRelatorio:ETipoArquivo) {
      state.parametrosConsulta.ordenacao = Array<string>();

      gradeBase.ordenacaoSelecionada.forEach((item) => {
        state.parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
      });

      if (!UtilitarioGeral.validaLista(gradeBase.ordenacaoSelecionada)) {
        state.parametrosConsulta.ordenacao.push('DataMovimento|ASC');
      }

      telaBase.carregando = true;
      const retornoRelatorio = await servicoMovimentoFinanceiro.relatorioPadrao(tipoArquivoRelatorio, state.parametrosConsulta, gradeBase.filtrosAplicados);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        if (tipoArquivoRelatorio === ETipoArquivo.PDF) {
          state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
        } else {
          UtilitarioGeral.downloadArquivo(retornoRelatorio.link);
        }
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    watch(() => storeSistema.state.layoutMobile, () => {
      preencherColunas();
    });

    function textoCodigosSelecionados():string {
      if (gradeBase.codigosSelecionados.length === 1) { return '1 - Movimento selecionado'; }

      if (gradeBase.codigosSelecionados.length > 1) { return `${gradeBase.codigosSelecionados.length} - Movimentos selecionados`; }

      return '';
    }

    return {
      state,
      props,
      window,
      ETipoTituloFinanceiro,
      EStatusTituloFinanceiro,
      ETipoMovimentoFinanceiro,
      EVinculoEmpresa,
      ETipoArquivo,
      ETipoConta,
      ECustomRenderRow,
      EFiltroPeriodoMovimentoConta,
      servicoMovimentoFinanceiro,
      UtilitarioMascara,
      UtilitarioData,
      UtilitarioGeral,
      storeSistema,
      telaBase,
      gradeBase,
      incluir,
      visualizar,
      buscarMovimentos,
      mudarSelecao,
      atualizarSelecaoEmpresas,
      adicionarAtalho,
      removerAtalho,
      abrirControlePeriodoMovimentacao,
      atualizarSelecaoConta,
      confirmarExclusao,
      confirmarConciliacao,
      confirmarConciliacaoUnica,
      apresentarHistorico,
      redirecionarBaixaContasPagar,
      redirecionarBaixaContasReceber,
      visualizarBaixa,
      abrirTransferencia,
      visualizarTransferencia,
      abrirImportacaoOfx,
      preencherColunas,
      verificarAutorizacao,
      verificarAlteracaoPeriodo,
      verificacaoFiltrosBoleanos,
      salvarFiltrosPreferencias,
      defineAlturaScroll,
      textoCodigosSelecionados,
      abrirImportacaoRetorno,
      imprimir,
      onScroll,
      scrollContainer,
    };
  },
});
