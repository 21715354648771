import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-11" }
const _hoisted_4 = { class: "ant-col ant-col-xs-7 ant-col-xl-4" }
const _hoisted_5 = { class: "ant-col ant-col-xs-5 ant-col-xl-3" }
const _hoisted_6 = ["onUpdate:modelValue", "onChange"]
const _hoisted_7 = { class: "ant-col ant-col-xs-4 ant-col-xl-3" }
const _hoisted_8 = { class: "ant-col ant-col-xs-4 ant-col-xl-3" }
const _hoisted_9 = { class: "ant-row" }
const _hoisted_10 = { class: "ant-col ant-col-xs-12 ant-col-xl-11" }
const _hoisted_11 = { class: "ant-col ant-col-xs-12 ant-col-xl-13" }
const _hoisted_12 = {
  class: "ant-row",
  style: {"margin":"10px 0px"}
}
const _hoisted_13 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_14 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_15 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_16 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mensagem_sem_dados = _resolveComponent("mensagem-sem-dados")!
  const _component_selecionar_pessoa = _resolveComponent("selecionar-pessoa")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_tipo_documento_financeiro = _resolveComponent("selecionar-tipo-documento-financeiro")!
  const _component_selecionar_data = _resolveComponent("selecionar-data")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_selecionar_conta = _resolveComponent("selecionar-conta")!
  const _component_selecionar_categoria_plano_conta = _resolveComponent("selecionar-categoria-plano-conta")!
  const _component_card = _resolveComponent("card")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_totalizador = _resolveComponent("totalizador")!

  return (_openBlock(), _createBlock(_component_card, {
    titulo: "CRÉDITOS LANÇADOS",
    class: "ss-alinhar-texto-esquerda"
  }, {
    AcoesExtra: _withCtx(() => [
      _createElementVNode("a", {
        class: "ss-fonte-negrito ss-card-botao-acao-extra",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.adicionarCredito()))
      }, "ADICIONAR CRÉDITO")
    ]),
    default: _withCtx(() => [
      (_ctx.state.computedCreditosLancados.length <= 0)
        ? (_openBlock(), _createBlock(_component_mensagem_sem_dados, {
            key: 0,
            mensagem: "Nenhum crédito foi adicionado ainda"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_a_form, { layout: "vertical" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.computedCreditosLancados, (creditosLancado, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              style: {"padding":"5px 0px"}
            }, [
              _createVNode(_component_card, {
                titulo: _ctx.montaTituloCard(index),
                class: "ss-alinhar-texto-esquerda"
              }, {
                AcoesExtra: _withCtx(() => [
                  _createElementVNode("a", {
                    class: "ss-fonte-negrito ss-card-botao-acao-extra-secundaria",
                    title: "Remover",
                    onClick: ($event: any) => (_ctx.excluirLancamento(index))
                  }, "Remover ", 8, _hoisted_1)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_a_form_item, {
                        label: "Pessoa",
                        class: "ss-margin-campos"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_selecionar_pessoa, {
                            codigoSelecionado: creditosLancado.codigoPessoa,
                            "onUpdate:codigoSelecionado": ($event: any) => ((creditosLancado.codigoPessoa) = $event),
                            empresas: [_ctx.props.empresa],
                            recursoOrigem: _ctx.props.recursoOrigem,
                            onChange: ($event: any) => (_ctx.atualizarTotalizadores(index))
                          }, null, 8, ["codigoSelecionado", "onUpdate:codigoSelecionado", "empresas", "recursoOrigem", "onChange"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_a_form_item, {
                        label: "Tipo de Documento",
                        class: "ss-margin-campos"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_selecionar_tipo_documento_financeiro, {
                            codigoSelecionado: creditosLancado.codigoTipoDocumentoFinanceiro,
                            "onUpdate:codigoSelecionado": ($event: any) => ((creditosLancado.codigoTipoDocumentoFinanceiro) = $event),
                            empresas: [_ctx.props.empresa],
                            onChange: ($event: any) => (_ctx.atualizarTotalizadores(index)),
                            meioPagamento: _ctx.EMeioPagamento.CreditoLoja,
                            contasPagar: (_ctx.props.tipoTituloFinanceiro === _ctx.ETipoTituloFinanceiro.Pagar),
                            contasReceber: (_ctx.props.tipoTituloFinanceiro === _ctx.ETipoTituloFinanceiro.Receber)
                          }, null, 8, ["codigoSelecionado", "onUpdate:codigoSelecionado", "empresas", "onChange", "meioPagamento", "contasPagar", "contasReceber"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_a_form_item, {
                        label: "Identificação",
                        class: "ss-margin-campos"
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            class: "ant-input",
                            "onUpdate:modelValue": ($event: any) => ((creditosLancado.numeroTitulo) = $event),
                            onChange: ($event: any) => (_ctx.atualizarTotalizadores(index))
                          }, null, 40, _hoisted_6), [
                            [_vModelText, creditosLancado.numeroTitulo]
                          ])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_a_form_item, {
                        label: "Vencimento",
                        class: "ss-margin-campos"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_selecionar_data, {
                            dataSelecionada: creditosLancado.dataVencimento,
                            "onUpdate:dataSelecionada": ($event: any) => ((creditosLancado.dataVencimento) = $event),
                            onChange: ($event: any) => (_ctx.atualizarTotalizadores(index))
                          }, null, 8, ["dataSelecionada", "onUpdate:dataSelecionada", "onChange"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_a_form_item, {
                        label: "Valor",
                        class: "ss-margin-campos"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_campo_numerico, {
                            monetario: true,
                            quantidadeCasasDecimais: 2,
                            valor: creditosLancado.valorTotal,
                            "onUpdate:valor": ($event: any) => ((creditosLancado.valorTotal) = $event),
                            onChange: ($event: any) => (_ctx.atualizarTotalizadores(index))
                          }, null, 8, ["valor", "onUpdate:valor", "onChange"])
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_selecionar_conta, {
                        codigoSelecionado: creditosLancado.codigoConta,
                        "onUpdate:codigoSelecionado": ($event: any) => ((creditosLancado.codigoConta) = $event),
                        empresas: [_ctx.props.empresa],
                        onChange: ($event: any) => (_ctx.atualizarTotalizadores(index))
                      }, null, 8, ["codigoSelecionado", "onUpdate:codigoSelecionado", "empresas", "onChange"])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_a_form_item, {
                        label: "Categoria",
                        class: "ss-margin-campos"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_selecionar_categoria_plano_conta, {
                            codigoSelecionado: creditosLancado.codigoPlanoContaCategoria,
                            "onUpdate:codigoSelecionado": ($event: any) => ((creditosLancado.codigoPlanoContaCategoria) = $event),
                            empresas: [_ctx.props.empresa],
                            onChange: ($event: any) => (_ctx.atualizarTotalizadores(index))
                          }, null, 8, ["codigoSelecionado", "onUpdate:codigoSelecionado", "empresas", "onChange"])
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["titulo"])
            ]))
          }), 128))
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_totalizador, {
            titulo: "QTDE CRÉDITOS",
            monetario: false,
            valor: _ctx.state.quantidadeCreditosLancados,
            casasDecimais: 0
          }, null, 8, ["valor"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_totalizador, {
            titulo: "TOTAL CRÉDITOS",
            monetario: true,
            valor: _ctx.state.totalCreditosLancados
          }, null, 8, ["valor"])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_totalizador, {
            titulo: "TOTAL SOBRA VALORES",
            monetario: true,
            valor: _ctx.props.totalSobraValores
          }, null, 8, ["valor"])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_totalizador, {
            titulo: "VALOR RESTANTE",
            monetario: true,
            valor: _ctx.obterValorRestanteLancamentoCreditos()
          }, null, 8, ["valor"])
        ])
      ])
    ]),
    _: 1
  }))
}