
import { defineComponent } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import TotalizadorDecorado from '@/core/components/Tela/TotalizadorDecorado.vue';

export default defineComponent({
  name: 'MovimentoFinanceiroTotalizadores',
  props: {
    saldoAnterior: {
      type: Number,
      required: true,
    },
    totalRecebimentos: {
      type: Number,
      required: true,
    },
    totalPagamentos: {
      type: Number,
      required: true,
    },
    totalConciliados: {
      type: Number,
      required: true,
    },
    saldo: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    TotalizadorDecorado,
  },
  setup(props) {
    return {
      props,
    };
  },
});
