
import {
  defineComponent, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import EditorHtml from '@/core/components/Tela/EditorHtml.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiro.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import SelecionarMarcadorMovimentoFinanceiro from '@/components/Financeiro/MovimentosFinanceiros/SelecionarMarcadorMovimentoFinanceiro.vue';
import { ITipoDocumentoFinanceiro } from '@/models/Entidades/Cadastros/Financeiro/ITipoDocumentoFinanceiro';
import ServicoTipoDocumentoFinanceiro from '@/servicos/Cadastros/Financeiro/ServicoTipoDocumentoFinanceiro';
import { EMeioPagamento } from '@/models/Enumeradores/Cadastros/Financeiro/EMeioPagamento';
import ServicoPlanoContas from '@/servicos/Cadastros/PlanosContas/ServicoPlanoContas';
import { IDTOMovimentoFinanceiroCentroCusto } from '@/models/DTO/Financeiro/MovimentosFinanceiros/IDTOMovimentoFinanceiroCentroCusto';
import {
  IMovimentoFinanceiro, IMovimentoFinanceiroAnexo, IMovimentoFinanceiroCentroCusto, IMovimentoFinanceiroMarcador,
} from '@/models/Entidades/Financeiro/MovimentosFinanceiros/IMovimentoFinanceiro';
import { ETipoMovimentoFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoMovimentoFinanceiro';
import ServicoMovimentoFinanceiro from '@/servicos/Financeiro/ServicoMovimentoFinanceiro';
import SelecionarTipoMovimentoFinanceiro from '@/components/Financeiro/MovimentosFinanceiros/SelecionarTipoMovimentoFinanceiro.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import MovimentoFinanceiroCentroCusto from '@/components/Financeiro/MovimentosFinanceiros/MovimentoFinanceiroCentroCusto.vue';
import MovimentoFinanceiroAnexo from '@/components/Financeiro/MovimentosFinanceiros/MovimentoFinanceiroAnexo.vue';
import { IDTOTituloFinanceiroBaixado } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroBaixado';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import MovimentoFinanceiroTitulosBaixados from '@/components/Financeiro/MovimentosFinanceiros/MovimentoFinanceiroTitulosBaixados.vue';
import storeSistema from '@/store/storeSistema';

export default defineComponent({
  name: 'MovimentoFinanceiroModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
    conta: {
      type: Number,
      default: 0,
      required: true,
    },
    dataVencimento: {
      type: String,
      default: '',
    },
    dataCompetencia: {
      type: String,
      default: '',
    },
    movimentoPrePreenchido: {
      type: Object as () => IMovimentoFinanceiro,
      default: {} as IMovimentoFinanceiro,
    },
  },
  components: {
    RequisicaoModal,
    Card,
    Icone,
    EditorHtml,
    SelecionarTipoDocumentoFinanceiro,
    CampoNumerico,
    SelecionarEmpresa,
    SelecionarConta,
    SelecionarMarcadorMovimentoFinanceiro,
    SelecionarTipoMovimentoFinanceiro,
    SelecionarData,
    SelecionarCategoriaPlanoConta,
    SelecionarSimNao,
    MovimentoFinanceiroCentroCusto,
    MovimentoFinanceiroAnexo,
    SelecionarPessoa,
    MovimentoFinanceiroTitulosBaixados,
  },
  emits: ['update:operacao', 'update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas,
      preencherPermissoesDados, filtrarPermissaoDadosUsuario, apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    const servicoMovimentoFinanceiro = new ServicoMovimentoFinanceiro();
    const servicoTipoDocumentoFinanceiro = new ServicoTipoDocumentoFinanceiro();
    servicoTipoDocumentoFinanceiro.requisicaoSistema();

    const servicoPlanoContas = new ServicoPlanoContas();
    servicoPlanoContas.requisicaoSistema();

    telaBase.identificadorRecurso = 'MOVIMENTACOES_CONTAS';
    telaBase.identificadorPermissao = 'PER_MOVIMENTACOES_CONTAS';

    const state = reactive({
      tituloModal: 'Novo movimento na Conta',
      movimento: {} as IMovimentoFinanceiro,
      tipoDocumentoFinanceiro: {} as ITipoDocumentoFinanceiro,
      centrosCusto: [] as IDTOMovimentoFinanceiroCentroCusto[],
      marcadores: [] as string[],
      titulosBaixados: [] as IDTOTituloFinanceiroBaixado[],
      carregandoAnexos: false,
      carregandoCentrosCusto: false,
      carregandoTitulosBaixados: false,
      editavel: true,
      exibirBaixaTitulos: false,
      modoPrePreenchido: false,
    });

    modalBase.textoBotaoSalvar = 'Concluir';
    modalBase.textoBotaoSalvarNovo = 'Concluir e novo';

    async function limparTela() {
      telaBase.carregando = true;
      state.movimento = {} as IMovimentoFinanceiro;
      state.movimento.codigo = 0;
      state.movimento.codigoConta = props.conta;
      state.movimento.codigoEmpresa = props.operacao.empresaSelecionada;
      state.movimento.codigoPessoa = 0;
      state.movimento.codigoTipoDocumentoFinanceiro = 0;
      state.movimento.codigoPlanoContaCategoria = 0;
      state.movimento.centrosCusto = [] as IMovimentoFinanceiroCentroCusto[];
      state.movimento.anexos = [] as IMovimentoFinanceiroAnexo[];
      state.movimento.dataMovimento = UtilitarioData.obterDataAtual();
      state.movimento.dataCompetencia = state.movimento.dataMovimento;
      state.movimento.conciliado = true;
      state.movimento.influenciaSaldo = true;
      state.movimento.recursoOrigem = 'MOVIMENTACOES_CONTAS';
      state.movimento.tipoMovimento = ETipoMovimentoFinanceiro.Recebimento;
      state.centrosCusto = [] as IDTOMovimentoFinanceiroCentroCusto[];
      state.marcadores = [] as string[];
      state.titulosBaixados = [] as IDTOTituloFinanceiroBaixado[];
      telaBase.carregando = false;
      state.modoPrePreenchido = false;
    }

    async function atualizarTipoDocumentoFinanceiroSelecionado() {
      if (UtilitarioGeral.valorValido(state.movimento.codigoTipoDocumentoFinanceiro)) {
        state.tipoDocumentoFinanceiro = await servicoTipoDocumentoFinanceiro.obter(state.movimento.codigoTipoDocumentoFinanceiro, state.movimento.codigoEmpresa);
      } else {
        state.tipoDocumentoFinanceiro = {} as ITipoDocumentoFinanceiro;
      }
    }

    async function obterCentrosCustoContaContabil() {
      state.carregandoCentrosCusto = true;
      if (state.movimento.codigoPlanoContaCategoria > 0 && !UtilitarioGeral.validaLista(state.centrosCusto)) {
        const centrosCusto = await servicoPlanoContas.obterCentrosCustos(state.movimento.codigoPlanoContaCategoria, state.movimento.codigoEmpresa);
        centrosCusto.forEach((c) => {
          const centroCusto = {} as IDTOMovimentoFinanceiroCentroCusto;
          centroCusto.codigoCentroCusto = c.codigoCentroCusto;
          centroCusto.descricaoCentroCusto = c.descricaoCentroCusto;
          centroCusto.proporcao = c.proporcao;
          centroCusto.ordem = state.centrosCusto.length + 1;
          state.centrosCusto.push(centroCusto);
        });
      }
      state.carregandoCentrosCusto = false;
    }

    async function preencherMarcadores() {
      state.movimento.marcadores = await servicoMovimentoFinanceiro.obterMarcadores(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      state.movimento.marcadores.forEach((m) => {
        state.marcadores.push(m.marcador);
      });
    }

    async function preencherAnexos() {
      state.carregandoAnexos = true;
      state.movimento.anexos = await servicoMovimentoFinanceiro.obterAnexos(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      state.carregandoAnexos = false;
    }

    async function preencherCentrosCusto() {
      state.carregandoCentrosCusto = true;
      state.centrosCusto = await servicoMovimentoFinanceiro.obterCentrosCusto(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      state.carregandoCentrosCusto = false;
    }

    async function preencherTitulosBaixados() {
      state.carregandoTitulosBaixados = true;
      state.titulosBaixados = await servicoMovimentoFinanceiro.obterTitulosBaixados(state.movimento.codigo, state.movimento.codigoEmpresa);
      state.carregandoTitulosBaixados = false;
    }

    async function preencherMovimento() {
      state.movimento = await servicoMovimentoFinanceiro.obterMovimento(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      await atualizarTipoDocumentoFinanceiroSelecionado();
      preencherMarcadores();
      preencherAnexos();
      preencherCentrosCusto();
      preencherTitulosBaixados();
    }

    function obterCentrosCusto() {
      const centrosCusto = [] as IMovimentoFinanceiroCentroCusto[];

      state.centrosCusto.forEach((c) => {
        const centroCusto = {} as IMovimentoFinanceiroCentroCusto;
        centroCusto.codigo = c.codigo;
        centroCusto.codigoCentroCusto = c.codigoCentroCusto;
        centroCusto.codigoMovimentoFinanceiro = c.codigoMovimentoFinanceiro;
        centroCusto.proporcao = c.proporcao;
        centroCusto.ordem = c.ordem;
        centrosCusto.push(centroCusto);
      });
      return centrosCusto;
    }

    function obterMarcadores() {
      const marcadores = [] as IMovimentoFinanceiroMarcador[];

      state.marcadores.forEach((m) => {
        const marcador = {} as IMovimentoFinanceiroMarcador;
        marcador.codigoMovimentoFinanceiro = state.movimento.codigo;
        marcador.marcador = m;
        marcador.ordem = marcadores.length + 1;
        marcadores.push(marcador);
      });
      return marcadores;
    }

    function validarCampos() {
      if (!UtilitarioData.validaDataPreenchida(state.movimento.dataMovimento)) {
        apresentarMensagemAlerta('A data do movimento deve ser informada');
        return false;
      }

      if (!UtilitarioData.validaDataPreenchida(state.movimento.dataCompetencia) && !UtilitarioGeral.validaCodigo(state.movimento.codigo)) {
        apresentarMensagemAlerta('A data de competência deve ser informada!');
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.movimento.codigoTipoDocumentoFinanceiro)) {
        apresentarMensagemAlerta('O tipo de documento deve ser informado!');
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.movimento.identificadorMovimento)) {
        apresentarMensagemAlerta('O nº de identificação do movimento deve ser informado!');
        return false;
      }

      if (state.movimento.valor <= 0) {
        apresentarMensagemAlerta('O valor do movimento deve ser maior que 0,00!');
        return false;
      }

      if (!UtilitarioGeral.valorValido(state.movimento.descricao)) {
        apresentarMensagemAlerta('A descrição do movimento deve ser informada!');
        return false;
      }

      if (!UtilitarioGeral.validaCodigo(state.movimento.codigoPessoa)) {
        apresentarMensagemAlerta('A pessoa deve ser informada!');
        return false;
      }

      if (!UtilitarioGeral.validaCodigo(state.movimento.codigoPlanoContaCategoria)) {
        apresentarMensagemAlerta('A categoria deve ser informada!');
        return false;
      }

      return true;
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      const retornoValidacao = validarCampos();
      if (!retornoValidacao) return;

      apresentarBarraProgresso();
      state.movimento.centrosCusto = obterCentrosCusto();
      state.movimento.marcadores = obterMarcadores();
      if (props.dataVencimento !== '' && props.dataVencimento !== undefined) {
        state.movimento.dataVencimento = props.dataVencimento;
      }

      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        state.movimento.codigoConta = props.conta;
        retorno = await servicoMovimentoFinanceiro.lancarMovimento(state.movimento);
      } else {
        retorno = await servicoMovimentoFinanceiro.atualizarMovimento(state.movimento);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        emit('confirmacao', retorno.codigoRegistro);
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          limparTela();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      await limparTela();
      if (modalBase.computedVisivel) {
        telaBase.carregando = true;

        if (props.movimentoPrePreenchido !== null && UtilitarioGeral.valorValido(props.movimentoPrePreenchido.identificadorMovimento)) {
          servicoMovimentoFinanceiro.requisicaoSistema();
          telaBase.apresentarEmpresas = false;
          telaBase.permissaoDados.incluir = true;
          telaBase.permissaoDados.visualizar = true;
          state.movimento.dataMovimento = props.movimentoPrePreenchido.dataMovimento;
          state.movimento.dataCompetencia = state.movimento.dataMovimento;
          state.movimento.tipoMovimento = props.movimentoPrePreenchido.tipoMovimento;
          state.movimento.identificadorMovimento = props.movimentoPrePreenchido.identificadorMovimento;
          state.movimento.codigoImportado = props.movimentoPrePreenchido.codigoImportado;
          state.movimento.descricao = props.movimentoPrePreenchido.descricao;
          state.movimento.valor = props.movimentoPrePreenchido.valor;
          state.movimento.observacoes = props.movimentoPrePreenchido.observacoes;
          state.movimento.numeroControle = props.movimentoPrePreenchido.numeroControle;
          if (props.movimentoPrePreenchido.recursoOrigem !== '' && props.movimentoPrePreenchido.recursoOrigem !== undefined) {
            state.movimento.recursoOrigem = props.movimentoPrePreenchido.recursoOrigem;
          }
          state.modoPrePreenchido = true;
        } else {
          if (props.operacao.listaPermissoesDados.length > 0) {
            await preencherPermissoesDados(props.operacao.listaPermissoesDados);
          } else {
            await obterPermissoes(ETipoPermissao.Dados);
          }
          await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, false);
          verificaConceitoParaApresentarEmpresas();
          telaBase.permissaoDados = await filtrarPermissaoDadosUsuario(state.movimento.codigoEmpresa);
        }

        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.tituloModal = 'Movimento da conta';
          await preencherMovimento();
          state.editavel = (state.movimento.recursoOrigem === 'MOVIMENTACOES_CONTAS');
          if (state.editavel) {
            modalBase.textoBotaoSalvar = 'Salvar';
            modalBase.textoBotaoSalvarNovo = 'Salvar e novo';
          }
        } else {
          state.editavel = true;
          state.tituloModal = 'Novo movimento na Conta';
          modalBase.textoBotaoSalvar = 'Concluir';
          modalBase.textoBotaoSalvarNovo = 'Concluir e novo';
        }
        telaBase.carregando = false;
      }
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      EPermissaoDados,
      UtilitarioGeral,
      apresentarRetornoRequisicao,
      salvar,
      atualizarTipoDocumentoFinanceiroSelecionado,
      EMeioPagamento,
      obterCentrosCustoContaContabil,
      servicoMovimentoFinanceiro,
      storeSistema,
    };
  },
});
