
import { defineComponent, computed, reactive } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { ETipoLancamentoBaixa } from '@/models/Enumeradores/Financeiro/ETipoLancamentoBaixa';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import Totalizador from '@/core/components/Tela/Totalizador.vue';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import BuscarTitulosModal from '../TitulosFinanceiros/BuscarTitulosModal.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';

export default defineComponent({
  name: 'BaixaTitulosRepasse',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    recursoOrigem: {
      type: String,
      default: '',
    },
    tipoTituloFinanceiro: {
      type: Number,
      required: true,
    },
    titulosRepassados: {
      type: Object as () => IDTOTituloFinanceiro[],
      required: true,
    },
    totalTitulosRepassados: {
      type: Number,
      required: true,
    },
    totalBaixa: {
      type: Number,
      required: true,
    },
    valorRestante: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Card,
    Icone,
    MensagemSemDados,
    Totalizador,
    SelecionarPessoa,
    BuscarTitulosModal,
  },
  emits: ['update:titulosRepassados', 'update:totalTitulosRepassados', 'atualizarTotalizadores'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const { gradeBase } = useGradeBase();

    const state = reactive({
      pessoaRepasse: 0,
      motivoRepasse: '',
      exibirBuscaTitulos: false,
      computedTitulosRepassados: computed({
        get: () => props.titulosRepassados,
        set: (val: IDTOTituloFinanceiro[]) => {
          emit('update:titulosRepassados', val);
        },
      }),
      totalTitulosRepassados: 0,
      tituloSelecionado: {} as IDTOTituloFinanceiro,
      quantidadeTitulos: 0,
    });

    gradeBase.layoutMobile = UtilitarioDispositivo.larguraTelaMobile();

    function preencherColunas() {
      if (gradeBase.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Titulo', dataIndex: 'titulo', key: 'Titulo', position: 1, visible: true,
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Ações', dataIndex: 'acoes', key: 'Acoes', position: 1, visible: true, width: 40, ellipsis: true,
          },
          {
            title: 'Tipo', dataIndex: 'descricaoTipo', key: 'Tipo', position: 2, visible: true, width: 40, ellipsis: true,
          },
          {
            title: 'PGTO', dataIndex: 'descricaoTipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 3, visible: true, width: 40, ellipsis: true,
          },
          {
            title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 4, visible: true, ellipsis: true, width: 75,
          },
          {
            title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 5, visible: true, ellipsis: true, width: 150,
          },
          {
            title: 'Vencimento', dataIndex: 'dataVencimento', key: 'DataVencimento', position: 6, visible: true, ordering: false, customRenderRow: ECustomRenderRow.MascararData, width: 60, align: 'center',
          },
          {
            title: 'Valor R$', dataIndex: 'saldo', key: 'Valor', position: 7, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 75,
          },
          {
            title: 'Conta', dataIndex: 'descricaoConta', key: 'Conta', position: 8, visible: true, ellipsis: true, width: 150,
          },
        ];
      }
    }

    preencherColunas();

    function abrirSelecaoTitulos() {
      state.exibirBuscaTitulos = true;
    }

    function atualizarTotalizadores() {
      state.totalTitulosRepassados = 0;
      state.quantidadeTitulos = 0;
      state.computedTitulosRepassados.forEach((t) => {
        if (t.tipo === props.tipoTituloFinanceiro) {
          state.totalTitulosRepassados += t.saldo;
        }

        state.quantidadeTitulos += 1;
      });

      emit('update:totalTitulosRepassados', state.totalTitulosRepassados);
      emit('atualizarTotalizadores');
    }

    function atualizarInformacoesRepasse() {
      if (UtilitarioGeral.validaLista(state.computedTitulosRepassados)) {
        for (let index = 0; index < state.computedTitulosRepassados.length; index += 1) {
          state.computedTitulosRepassados[index].pessoaRepasse = UtilitarioGeral.validaCodigo(state.pessoaRepasse) ? state.pessoaRepasse : 0;
          state.computedTitulosRepassados[index].motivoRepasse = UtilitarioGeral.valorValido(state.motivoRepasse) ? state.motivoRepasse : '';
        }
      }
    }

    function adicionarTitulos(titulos: IDTOTituloFinanceiro[]) {
      if (state.tituloSelecionado.numeroTitulo !== undefined && state.tituloSelecionado.numeroTitulo !== '') {
        const index = state.computedTitulosRepassados.findIndex((t) => t.numeroTitulo === state.tituloSelecionado.numeroTitulo);
        const titulo = titulos[0];
        titulo.pessoaRepasse = 0;
        titulo.motivoRepasse = '';
        state.computedTitulosRepassados[index] = titulo;
        state.tituloSelecionado = {} as IDTOTituloFinanceiro;
      } else {
        for (let index = 0; index < titulos.length; index += 1) {
          const titulo = titulos[index];
          titulo.pessoaRepasse = 0;
          titulo.motivoRepasse = '';
          state.computedTitulosRepassados.push(titulo);
        }
      }
      atualizarTotalizadores();
    }

    function excluirTitulo(index: number) {
      state.computedTitulosRepassados.splice(index, 1);
      atualizarTotalizadores();
    }

    function obterDescricaoTipoTitulos() {
      if (props.tipoTituloFinanceiro === ETipoTituloFinanceiro.Receber) { return 'Recebimentos'; }
      if (props.tipoTituloFinanceiro === ETipoTituloFinanceiro.Pagar) { return 'Pagamentos'; }

      return '';
    }

    return {
      props,
      state,
      telaBase,
      gradeBase,
      ETipoLancamentoBaixa,
      UtilitarioGeral,
      UtilitarioData,
      UtilitarioMascara,
      abrirSelecaoTitulos,
      adicionarTitulos,
      excluirTitulo,
      obterDescricaoTipoTitulos,
      ECustomRenderRow,
      atualizarInformacoesRepasse,
    };
  },
});
