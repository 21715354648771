import ApiERP from '@/core/conectores/ApiERP';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IAberturaCaixa, IFechamentoCaixa, IReaberturaCaixa } from '@/models/Entidades/Financeiro/ControleCaixas/AberturaCaixa';
import { ETipoMovimentoFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoMovimentoFinanceiro';

/**
 * Serviço de Controle de Caixas
 * Fornece todas regras de negócios e lógicas relacionado a controle de caixa
 */
class ServicoControleCaixas {
    endPoint = 'controle-caixas';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async validarAberturasPendentesAnteriores(empresa: number, caixas: number[]): Promise<IRetornoRequisicao> {
      let parametrosAdicionais = '';
      for (let contador = 0; contador < caixas.length; contador += 1) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Caixas=${caixas[contador]}`;
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/aberturas-anteriores-pendentes/empresa/${empresa}${parametrosAdicionais}`);
      return result.data;
    }

    public async consultaRapida(codigo: number, usuario: number, data: string): Promise<IItemConsultaRapida[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida/${codigo}/usuario/${usuario}/data/${data}`);
      return result.data;
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscarMovimentos(abertura: number, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      let parametrosAdicionais = '';
      parametrosAdicionais = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);

      if (filtros !== undefined) {
        const jsonFiltros = JSON.stringify(filtros);
        parametrosAdicionais += `&Filtros=${jsonFiltros}`;
      }
      if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
        parametrosAdicionais += `&NumeroPagina=${parametrosConsulta.numeroPagina}`;
      }
      if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
        parametrosAdicionais += `&QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
      }
      if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
        parametrosAdicionais += `&QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
      }
      if (parametrosConsulta.ordenacao !== undefined) {
        parametrosConsulta.ordenacao.forEach((item) => {
          parametrosAdicionais += `&Ordenacao=${item}`;
        });
      }
      if (parametrosConsulta.codigosSelecionados !== undefined) {
        parametrosConsulta.codigosSelecionados.forEach((codigo) => {
          parametrosAdicionais += `&CodigosSelecionados=${codigo}`;
        });
      }

      if (parametrosConsulta.valorBuscaRapida !== undefined) {
        parametrosAdicionais += `&valorBuscaRapida=${parametrosConsulta.valorBuscaRapida}`;
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/aberturas-caixa/${abertura}${parametrosAdicionais}`);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data;
      return listaPaginada;
    }

    public async obterAberturaCaixa(codigo: number, empresa: number): Promise<IAberturaCaixa> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterAberturasAnterioresPendentesCaixa(codigo: number, empresa: number): Promise<IAberturaCaixa[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/anteriores/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterOperadoresCaixa(codigo: number, empresa: number, data: string): Promise<number[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/operadores/${codigo}/empresa/${empresa}/data/${data}`);
      return result.data;
    }

    public async obterTotalMovimentacoes(codigo: number, empresa: number, tipoMovimento: ETipoMovimentoFinanceiro): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/saldo/${codigo}/empresa/${empresa}/tipo/${tipoMovimento}`);
      return result.data;
    }

    public async obterMotivoReabertura(codigo: number, empresa: number): Promise<string> {
      const result: any = await this.apiERP.get(`${this.endPoint}/motivo-reabertura/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async abrirCaixas(empresa: number, aberturas: IAberturaCaixa[]): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/abrir/empresa/${empresa}`, aberturas);
      return result.data;
    }

    public async fecharCaixa(empresa: number, fechamento: IFechamentoCaixa): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/fechar/empresa/${empresa}`, fechamento);
      return result.data;
    }

    public async reabrir(empresa: number, reabertura: IReaberturaCaixa): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/reabrir/empresa/${empresa}`, reabertura);
      return result.data;
    }

    public async realtorioPadrao(codigo: number, empresa: number, tipoArquivo: ETipoArquivo): Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.get(`${this.endPoint}/relatorio-padrao/${codigo}/empresa/${empresa}/tipo-arquivo/${tipoArquivo}`);
      return result.data;
    }
}
export default ServicoControleCaixas;
