
import { defineComponent, reactive, watch } from 'vue';
import storeSistema from '@/store/storeSistema';
import Card from '@/core/components/Tela/Card.vue';
import BuscaGeral from '@/core/components/BuscaAvancada/BuscaGeral.vue';
import Icone from '@/core/components/Icone.vue';
import Totalizador from '@/core/components/Tela/Totalizador.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IFiltroGenericoAdicionado } from '@/core/models/BuscaAvancada/IFiltroGenericoAdicionado';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { EOperadorLogico } from '@/core/models/Enumeradores/EOperadorLogico';
import { useGradeBase } from '@/core/composables/GradeBase';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import ServicoTituloFinanceiroPagar from '@/servicos/Financeiro/ServicoTituloFinanceiroPagar';
import ServicoTituloFinanceiroReceber from '@/servicos/Financeiro/ServicoTituloFinanceiroReceber';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { ECondicao } from '@/core/models/Enumeradores/ECondicao';
import { ETipoDado } from '@/core/models/Enumeradores/ETipoDado';
import { IParametrosConsultaTituloFinanceiro } from '@/models/ParametrosRequisicao/Financeiro/IParametrosConsultaTituloFinanceiro';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';

export default defineComponent({
  name: 'BuscarTitulosModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    tipoTitulo: {
      type: Number,
      required: true,
    },
    tituloTela: {
      type: String,
      default: 'Buscar Títulos',
    },
    apenasTitulosPendentes: {
      type: Boolean,
      default: true,
    },
    apenasUmTitulo: {
      type: Boolean,
      default: false,
    },
    filtros: {
      type: Object as () => IFiltroGenerico[],
      default: [] as IFiltroGenerico[],
    },
    filtroInicialDataVencimento: {
      type: Boolean,
      default: false,
    },
    omitirTitulos: {
      type: Object as () => number[],
      default: [] as number[],
    },
    omitirTitulosRepassados: {
      type: Boolean,
      default: false,
    },
    obrigarSelecaoValorEspecifico: {
      type: Number,
      default: 0,
    },
    valorTotalTituloEspecifico: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Icone,
    Card,
    Totalizador,
    MensagemSemDados,
    BuscaGeral,
  },
  emits: ['confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, preencherEmpresasDisponiveis,
      filtrarPermissaoDadosUsuarioMultiEmpresas,
    } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const {
      gradeBase, defineAlturaScroll,
    } = useGradeBase();
    const servicoTituloFinanceiroPagar = new ServicoTituloFinanceiroPagar();
    const servicoTituloFinanceiroReceber = new ServicoTituloFinanceiroReceber();

    const state = reactive({
      titulos: [] as IDTOTituloFinanceiro[],
      buscaAvancada: {} as IBuscaAvancada,
      buscaRapida: '',
      quantidadeTitulos: 0,
      totalTitulos: 0,
      quantidadeTitulosSelecionados: 0,
      totalTitulosSelecionados: 0,
    });
    telaBase.ordenacaoSelecionada = [];
    telaBase.ordenacaoSelecionada.push({ identificador: 'DataVencimento', descricao: 'Data de Vencimento', ordem: 'ASC' } as IOrdenacao);
    function preencherColunas() {
      if (storeSistema.state.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Título', dataIndex: 'titulo', key: 'TituloFinanceiro', position: 2, visible: true,
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false, ordering: true,
          },
          {
            title: 'T.Documento', dataIndex: 'tipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 2, visible: true, ordering: true, width: 40, ellipsis: true,
          },
          {
            title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 3, visible: true, ordering: true, ellipsis: true, width: 75,
          },
          {
            title: 'Documento Fiscal', dataIndex: 'numeroDocumentoFiscal', key: 'NumeroDocumentoFiscal', position: 4, visible: true, ordering: true, width: 75, align: 'right',
          },
          {
            title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 5, visible: true, ordering: true, width: 150, ellipsis: true,
          },
          {
            title: 'Vencimento', dataIndex: 'dataVencimento', key: 'DataVencimento', position: 6, visible: true, ordering: true, customRenderRow: ECustomRenderRow.MascararData, width: 60, align: 'center',
          },
          {
            title: 'Valor R$', dataIndex: 'saldo', key: 'Saldo', position: 7, visible: true, ordering: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 65,
          },
          {
            title: 'Conta', dataIndex: 'descricaoConta', key: 'DescricaoConta', position: 8, visible: true, ordering: true, width: 150, ellipsis: true,
          },
        ];
      }
    }
    function atualizarTotalizadores() {
      state.quantidadeTitulos = 0;
      state.totalTitulos = 0;
      state.quantidadeTitulosSelecionados = 0;
      state.totalTitulosSelecionados = 0;

      state.titulos.forEach((tituloFinanceiro) => {
        state.quantidadeTitulos += 1;
        state.totalTitulos += tituloFinanceiro.saldo;

        const index = gradeBase.codigosSelecionados.findIndex((c) => c === tituloFinanceiro.codigo);
        if (index >= 0) {
          state.totalTitulosSelecionados += tituloFinanceiro.saldo;
          state.quantidadeTitulosSelecionados += 1;
        }
      });
    }
    const mudarSelecao = (selectedRowKeys: any) => {
      if (props.apenasUmTitulo) {
        gradeBase.codigosSelecionados = [selectedRowKeys[selectedRowKeys.length - 1]];
      } else {
        gradeBase.codigosSelecionados = selectedRowKeys;
      }
      atualizarTotalizadores();
    };

    function adicionarFiltrosIniciais() {
      if (props.filtroInicialDataVencimento) {
        const primeiroDia = UtilitarioData.definePrimeiroDiaMesNaDataDayJs(UtilitarioData.obterDataAtualDayJs());
        const ultimoDia = UtilitarioData.defineUltimoDiaMesNaDataDayJs(primeiroDia);
        state.buscaAvancada.chaveFiltrosAdicionados += 1;
        const filtroVencimento = {} as IFiltroGenericoAdicionado;
        filtroVencimento.codigo = state.buscaAvancada.chaveFiltrosAdicionados;
        filtroVencimento.filtro = {} as IFiltroGenerico;
        filtroVencimento.filtro.identificador = 'DataVencimento';
        filtroVencimento.apresentacao = 'Data de Vencimento';
        filtroVencimento.filtro.tipoDado = 6;
        filtroVencimento.filtro.operador = EOperadorLogico.E;
        filtroVencimento.filtro.apresentacao = 'Data de Vencimento';
        filtroVencimento.filtro.condicao = 10;
        filtroVencimento.filtro.valores = [UtilitarioData.aplicaFormatoData(primeiroDia), UtilitarioData.aplicaFormatoData(ultimoDia)];
        filtroVencimento.detalhes = `Entre: ${UtilitarioData.aplicaFormatoData(primeiroDia)} e ${UtilitarioData.aplicaFormatoData(ultimoDia)}`;
        state.buscaAvancada.filtrosAdicionados.push(filtroVencimento);
      }
    }

    async function buscarTitulos() {
      state.titulos = [];
      gradeBase.filtrosAplicados = [];
      state.quantidadeTitulos = 0;
      state.totalTitulos = 0;
      state.quantidadeTitulosSelecionados = 0;
      state.totalTitulosSelecionados = 0;

      state.buscaAvancada.filtrosAdicionados.forEach((item) => {
        gradeBase.filtrosAplicados.push(item.filtro);
      });
      props.filtros.forEach((f) => {
        gradeBase.filtrosAplicados.push(f);
      });

      if (UtilitarioGeral.validaLista(props.omitirTitulos)) {
        const filtroCodigoTitulo = {} as IFiltroGenerico;
        filtroCodigoTitulo.identificador = 'Codigo';
        filtroCodigoTitulo.tipoDado = ETipoDado.Longo;
        filtroCodigoTitulo.operador = EOperadorLogico.E;
        filtroCodigoTitulo.condicao = ECondicao.NaoEstaEntre;
        filtroCodigoTitulo.valores = [];
        props.omitirTitulos.forEach((codigoTitulo) => {
          filtroCodigoTitulo.valores.push(String(codigoTitulo));
        });

        gradeBase.filtrosAplicados.push(filtroCodigoTitulo);
      }
      const parametrosConsulta = {} as IParametrosConsultaTituloFinanceiro;
      parametrosConsulta.empresas = [props.empresa];
      parametrosConsulta.omitirTitulosRepassados = props.omitirTitulosRepassados;
      parametrosConsulta.apenasTitulosPendentes = props.apenasTitulosPendentes;
      if (props.valorTotalTituloEspecifico > 0) {
        parametrosConsulta.valorTotalTituloEspecifico = props.valorTotalTituloEspecifico;
      }
      parametrosConsulta.valorBuscaRapida = state.buscaRapida;
      parametrosConsulta.ordenacao = Array<string>();
      telaBase.ordenacaoSelecionada.forEach((ordenacao) => {
        parametrosConsulta.ordenacao.push(`${ordenacao.identificador}|${ordenacao.ordem}`);
      });

      telaBase.carregando = true;
      let listaPaginada = {} as IListaPaginada;
      if (props.tipoTitulo === ETipoTituloFinanceiro.Pagar) {
        listaPaginada = await servicoTituloFinanceiroPagar.buscarTitulos(parametrosConsulta, gradeBase.filtrosAplicados);
      } else {
        listaPaginada = await servicoTituloFinanceiroReceber.buscarTitulos(parametrosConsulta, gradeBase.filtrosAplicados);
      }
      if (UtilitarioGeral.validaLista(listaPaginada.dados)) {
        state.titulos = listaPaginada.dados;
        atualizarTotalizadores();
      }

      telaBase.carregando = false;
    }

    function limparTela() {
      state.titulos = [];
      if (props.tipoTitulo === ETipoTituloFinanceiro.Pagar) {
        telaBase.identificadorRecurso = 'CONTAS_PAGAR';
        telaBase.identificadorPermissao = 'PER_CONTAS_PAGAR';
      } else {
        telaBase.identificadorRecurso = 'CONTAS_RECEBER';
        telaBase.identificadorPermissao = 'PER_CONTAS_RECEBER';
      }
      preencherColunas();
      state.buscaAvancada.filtrosAdicionados = [];
      state.buscaAvancada.chaveFiltrosAdicionados = 0;
      gradeBase.codigosSelecionados = [];
    }

    function confirmar() {
      state.buscaRapida = '';
      const selecionados = state.titulos.filter((t) => gradeBase.codigosSelecionados.includes(t.codigo));
      emit('confirmacao', selecionados);
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        await preencherEmpresasDisponiveis();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas([props.empresa]);
        if (props.tipoTitulo === ETipoTituloFinanceiro.Pagar) {
          telaBase.propriedadesConsulta = await servicoTituloFinanceiroPagar.obterPropriedadesConsulta(props.empresa);
        } else {
          telaBase.propriedadesConsulta = await servicoTituloFinanceiroReceber.obterPropriedadesConsulta(props.empresa);
        }
        if (telaBase.propriedadesConsulta.length > 0) {
          if (props.apenasTitulosPendentes) {
            // Remove opção de filtrar o Status evitando falhas de baixas repetidas
            telaBase.propriedadesConsulta = telaBase.propriedadesConsulta.filter((propriedade) => propriedade.identificador !== 'Status');
          }
          state.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
          state.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
        }
        adicionarFiltrosIniciais();

        if (UtilitarioGeral.validaLista(props.filtros) || props.filtroInicialDataVencimento) {
          await buscarTitulos();
        }
      }
      telaBase.carregando = false;
    });

    watch(() => storeSistema.state.layoutMobile, () => {
      preencherColunas();
    });

    return {
      props,
      storeSistema,
      state,
      telaBase,
      modalBase,
      gradeBase,
      ECustomRenderRow,
      window,
      UtilitarioGeral,
      UtilitarioMascara,
      UtilitarioData,
      buscarTitulos,
      mudarSelecao,
      confirmar,
      defineAlturaScroll,
    };
  },
});
