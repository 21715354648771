
import { defineComponent, reactive, watch } from 'vue';
import storeSistema from '@/store/storeSistema';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Totalizador from '@/core/components/Tela/Totalizador.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { useGradeBase } from '@/core/composables/GradeBase';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import SelecionarServico from '@/components/Cadastros/PrestacaoServicos/SelecionarServico.vue';
import SelecionarNaturezaOperacao from '@/components/Cadastros/PrestacaoServicos/SelecionarNaturezaOperacao.vue';
import { IServico } from '@/models/Entidades/Cadastros/PrestacaoServicos/IServico';
import ServicoServico from '@/servicos/Cadastros/PrestacaoServicos/ServicoServico';
import ServicoNotaFiscalServico from '@/servicos/Fiscal/ServicoNotaFiscalServico';
import { IDTONotaFiscalServicoTituloFinanceiro } from '@/models/DTO/Fiscal/NotasFiscaisServico/IDTONotaFiscalServicoTituloFinanceiro';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { IDTOTituloFinanceiroNotaFiscalServico } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroNotaFiscalServico';
import UtilitarioFinanceiro from '@/core/utilitarios/UtilitarioFinanceiro';

export default defineComponent({
  name: 'EmissaoNFSeTituloFinanceiroModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    titulos: {
      type: Array as () => IDTOTituloFinanceiro[],
      required: true,
    },
  },
  components: {
    Icone,
    RequisicaoModal,
    Card,
    SelecionarServico,
    SelecionarNaturezaOperacao,
    Totalizador,
  },
  emits: ['confirmacao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemSucesso, apresentarMensagemAlerta } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    const { gradeBase } = useGradeBase();

    const servicoServico = new ServicoServico();
    servicoServico.requisicaoSistema();

    const servicoNotaFiscalServico = new ServicoNotaFiscalServico();
    servicoNotaFiscalServico.requisicaoSistema();

    const state = reactive({
      servico: {} as IServico,
      dados: {} as IDTONotaFiscalServicoTituloFinanceiro,
      descontoInCondicionado: true,
      apresentarAcaoDesconto: false,
      quantidadesTitulos: 0,
      totalServicos: 0,
      totalDescontos: 0,
      totalNfses: 0,
    });

    function preencherColunas() {
      if (storeSistema.state.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Pessoa', dataIndex: 'titulo', key: 'TituloFinanceiroMobile', position: 2, visible: true,
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false, ordering: true,
          },
          {
            title: 'Pessoa', dataIndex: 'pessoa', key: 'Pessoa', position: 2, visible: true, ordering: true, width: 125, ellipsis: true,
          },
          {
            title: 'Título', dataIndex: 'titulo', key: 'TituloFinanceiro', position: 3, visible: true, ordering: true, ellipsis: true, width: 100,
          },
          {
            title: 'Valor NFSe R$', dataIndex: 'valor', key: 'Valor', position: 4, visible: true, ordering: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 40,
          },
        ];
      }
    }

    function obtemValorTitulo(titulo:IDTOTituloFinanceiroNotaFiscalServico):number {
      if (titulo.status === EStatusTituloFinanceiro.Pendente || titulo.status === EStatusTituloFinanceiro.BaixadoParcialmente) {
        return titulo.saldo;
      }
      return titulo.valorTotal;
    }

    function obtemValorNfse(titulo:IDTOTituloFinanceiroNotaFiscalServico):number {
      if (state.dados.embutirIrValorServico) {
        return UtilitarioFinanceiro.valorDecimal2Casas(((titulo.valorLiquidoNfse * 100) / (100 - state.servico.aliquotaIr)));
      }
      return titulo.valorLiquidoNfse;
    }

    function atualizarTotalizadores() {
      state.quantidadesTitulos = 0;
      state.totalServicos = 0;
      state.totalDescontos = 0;
      state.totalNfses = 0;

      state.dados.titulosFinanceiros.forEach((tituloFinanceiro) => {
        state.totalDescontos += tituloFinanceiro.valorDesconto;
        if (state.dados.embutirIrValorServico) {
          const valorLiquidoComIr = UtilitarioFinanceiro.valorDecimal2Casas(((tituloFinanceiro.valorLiquidoNfse * 100) / (100 - state.servico.aliquotaIr)));
          state.totalNfses += valorLiquidoComIr;
          const valorServico = obtemValorTitulo(tituloFinanceiro);
          const valorServicoComIr = UtilitarioFinanceiro.valorDecimal2Casas(((valorServico * 100) / (100 - state.servico.aliquotaIr)));
          state.totalServicos += valorServicoComIr;
        } else {
          state.totalNfses += tituloFinanceiro.valorLiquidoNfse;
          state.totalServicos += obtemValorTitulo(tituloFinanceiro);
        }

        state.quantidadesTitulos += 1;
      });
    }

    function montaApresentacaoTitulo(titulo: IDTOTituloFinanceiroNotaFiscalServico): string {
      let apresentacao = ` Nº: ${titulo.numeroTitulo} T.DOC: ${titulo.siglaTipoDocumentoFinanceiro} Venc: ${UtilitarioData.aplicaFormatoData(titulo.dataVencimento)}`;

      if (titulo.valorDesconto > 0) {
        apresentacao += ` Valor: ${UtilitarioMascara.mascararValor(obtemValorTitulo(titulo))} Valor Desconto: ${UtilitarioMascara.mascararValor(titulo.valorDesconto)} `;
      } else if (state.dados.embutirIrValorServico) {
        apresentacao += ` Valor: ${UtilitarioMascara.mascararValor(obtemValorTitulo(titulo))} `;
      }

      return apresentacao;
    }
    function limparTela() {
      state.dados.codigoEmpresa = props.empresa;
      state.dados.codigoServico = 0;
      state.dados.serie = 'UNICA';
      state.dados.embutirIrValorServico = false;
      state.dados.naturezaOperacao = '1';
      state.dados.titulosFinanceiros = [];
      state.descontoInCondicionado = true;
      state.apresentarAcaoDesconto = false;
      state.quantidadesTitulos = 0;
      state.totalServicos = 0;
      state.totalDescontos = 0;
      state.totalNfses = 0;
      preencherColunas();
    }

    function aplicarDescontoEmTodosTitulos() {
      for (let index = 0; index < state.dados.titulosFinanceiros.length; index += 1) {
        state.dados.titulosFinanceiros[index].descontoInCondicionado = state.descontoInCondicionado;
      }
      apresentarMensagemSucesso('Títulos atualizados com sucesso!');
    }
    async function obterServico() {
      state.servico = await servicoServico.obter(state.dados.codigoServico, props.empresa);
    }

    async function confirmarEmissao() {
      apresentarBarraProgresso('Aguarde por favor... Estamos criando as suas Notas Fiscais de Serviço.');
      const retorno = await servicoNotaFiscalServico.criarPorTitulosFinanceiros(state.dados);
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();

      state.dados.serie = await servicoNotaFiscalServico.obterSerie(props.empresa);
      if (modalBase.computedVisivel) {
        const titulosFinanceirosNfse = await servicoNotaFiscalServico.prepararTitulosFinanceirosParaEmissao(props.empresa, props.titulos);
        if (UtilitarioGeral.validaLista(titulosFinanceirosNfse)) {
          state.dados.titulosFinanceiros = titulosFinanceirosNfse;
          atualizarTotalizadores();
          state.apresentarAcaoDesconto = titulosFinanceirosNfse.some((c) => c.valorDesconto > 0);
        } else {
          apresentarMensagemAlerta('Não foi possível obter as informações dos títulos, tente novamente mais tarde.');
          modalBase.computedVisivel = false;
        }
      }
      telaBase.carregando = false;
    });

    watch(() => storeSistema.state.layoutMobile, () => {
      preencherColunas();
    });

    return {
      props,
      storeSistema,
      state,
      telaBase,
      modalBase,
      gradeBase,
      ECustomRenderRow,
      window,
      UtilitarioGeral,
      UtilitarioMascara,
      UtilitarioData,
      confirmarEmissao,
      montaApresentacaoTitulo,
      obterServico,
      servicoServico,
      obtemValorTitulo,
      obtemValorNfse,
      aplicarDescontoEmTodosTitulos,
      atualizarTotalizadores,
    };
  },
});
