
import { defineComponent, reactive, watch } from 'vue';
import draggable from 'vuedraggable';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import { ETipoConta } from '@/models/Enumeradores/Cadastros/Financeiro/ETipoConta';
import SelecionarUsuario from '@/components/MeuSistema/Usuarios/SelecionarUsuario.vue';
import storeSistema from '@/store/storeSistema';
import { IEquipeAlcadaDespesas, IEquipeAlcadaDespesasUsuario } from '@/models/Entidades/Cadastros/Financeiro/IEquipeAlcadaDespesas';
import ServicoEquipeAlcadaDespesas from '@/servicos/Cadastros/Financeiro/ServicoEquipeAlcadaDespesas';

export default defineComponent({
  name: 'EquipeAlcadaDespesasModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    draggable,
    RequisicaoModal,
    Icone,
    Card,
    SelecionarEmpresa,
    SelecionarUsuario,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas,
      preencherPermissoesDados, filtrarPermissaoDadosUsuario, apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoEquipeAlcadaDespesas = new ServicoEquipeAlcadaDespesas();

    telaBase.identificadorRecurso = 'CADASTRO_EQUIPES_ALCADA_DESPESAS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_EQUIPES_ALCADA_DESPESAS';

    const state = reactive({
      equipe: {} as IEquipeAlcadaDespesas,
      usuario: 0,
      nomeUsuario: '',
      usuariosSelecionados: [] as number[],
    });

    function iniciarTela() {
      const equipe = {} as IEquipeAlcadaDespesas;
      equipe.codigo = 0;
      equipe.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
      equipe.ativo = true;
      equipe.usuarios = [] as IEquipeAlcadaDespesasUsuario[];
      state.equipe = equipe;
      state.nomeUsuario = '';
      state.usuario = 0;
      state.usuariosSelecionados = [] as number[];
    }

    function adicionarUsuario() {
      if (state.usuario === 0) {
        apresentarMensagemAlerta('Nenhum usuário selecionado!');
        return;
      }
      if (state.usuariosSelecionados.includes(state.usuario)) {
        apresentarMensagemAlerta('O usuário selecionado já foi adicionado!');
        state.usuario = 0;
        state.nomeUsuario = '';
        return;
      }
      const usuario = {} as IEquipeAlcadaDespesasUsuario;
      usuario.codigo = 0;
      usuario.codigoEquipeAlcadaDespesas = state.equipe.codigo;
      usuario.codigoUsuario = state.usuario;
      usuario.nomeUsuario = state.nomeUsuario;
      usuario.ordem = state.equipe.usuarios.length + 1;
      state.equipe.usuarios.push(usuario);
      state.usuariosSelecionados.push(state.usuario);
      state.usuario = 0;
      state.nomeUsuario = '';
    }

    function removerUsuario(index: number) {
      const indexSelecionado = state.usuariosSelecionados.findIndex((u) => u === state.equipe.usuarios[index].codigoUsuario);
      state.usuariosSelecionados.splice(indexSelecionado, 1);
      state.equipe.usuarios.splice(index, 1);
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      state.equipe.usuarios.forEach((u, index) => {
        state.equipe.usuarios[index].ordem = index;
      });

      apresentarBarraProgresso();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoEquipeAlcadaDespesas.incluir(state.equipe);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoEquipeAlcadaDespesas.alterar(state.equipe);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          iniciarTela();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      iniciarTela();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, false);
        verificaConceitoParaApresentarEmpresas();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuario(state.equipe.codigoEmpresa);

        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          modalBase.textoBotaoSalvar = 'Salvar alterações';
          modalBase.textoBotaoSalvarNovo = 'Salvar e novo';
          state.equipe = await servicoEquipeAlcadaDespesas.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          state.equipe.usuarios = await servicoEquipeAlcadaDespesas.obterUsuarios(state.equipe.codigo, state.equipe.codigoEmpresa);
          telaBase.empresasSelecionadas = [];
        } else {
          modalBase.textoBotaoSalvar = 'Concluir cadastro';
          modalBase.textoBotaoSalvarNovo = 'Concluir e novo';
        }
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      state,
      modalBase,
      EPermissaoDados,
      ETipoConta,
      UtilitarioGeral,
      apresentarRetornoRequisicao,
      iniciarTela,
      adicionarUsuario,
      removerUsuario,
      salvar,
    };
  },
});
