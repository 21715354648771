
import {
  defineComponent, reactive, watch,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import ServicoControleCaixas from '@/servicos/Financeiro/ServicoControleCaixas';
import { IAberturaCaixa, IFechamentoCaixa, IFechamentoCaixaTransferencia } from '@/models/Entidades/Financeiro/ControleCaixas/AberturaCaixa';
import DetalhesAberturaCaixa from '@/components/Financeiro/ControleCaixas/DetalhesAberturaCaixa.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoMovimentoFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoMovimentoFinanceiro';
import ServicoCaixa from '@/servicos/Cadastros/Financeiro/ServicoCaixa';
import { ICaixa, ICaixaContaFechamento } from '@/models/Entidades/Cadastros/Financeiro/ICaixa';

export default defineComponent({
  name: 'FechamentoCaixaModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      required: true,
    },
    abertura: {
      type: Object as () => IAberturaCaixa,
      required: true,
    },
    caixa: {
      type: Object as () => ICaixa,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    DetalhesAberturaCaixa,
    SelecionarData,
    CampoNumerico,
    SelecionarConta,
    SelecionarCategoriaPlanoConta,
    MensagemSemDados,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemSucesso, apresentarMensagemAlerta } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    const servicoControleCaixas = new ServicoControleCaixas();
    const servicoCaixa = new ServicoCaixa();
    servicoControleCaixas.requisicaoSistema();
    servicoCaixa.requisicaoSistema();

    const state = reactive({
      aberturaCaixa: {} as IAberturaCaixa,
      contasFechamento: [] as ICaixaContaFechamento[],
      fechamento: {} as IFechamentoCaixa,
      totalTransferencias: 0,
    });

    function limparTela() {
      const fechamento = {} as IFechamentoCaixa;
      fechamento.codigoAberturaCaixa = props.abertura.codigo;
      fechamento.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      fechamento.transferencias = [] as IFechamentoCaixaTransferencia[];
      fechamento.aberturasAnterioresPendentes = [] as IAberturaCaixa[];
      state.fechamento = fechamento;
      state.totalTransferencias = 0;
    }

    function calcularTotalTransferencias() {
      state.totalTransferencias = 0;
      state.fechamento.transferencias.forEach((t) => {
        state.totalTransferencias += t.valor;
      });
    }

    async function adicionarTransferencia() {
      const transferencia = {} as IFechamentoCaixaTransferencia;
      state.fechamento.transferencias.push(transferencia);
    }

    function excluirTransferencia(index: number) {
      state.fechamento.transferencias.splice(index, 1);
      calcularTotalTransferencias();
    }

    function validarTransferencias() {
      let retorno = false;
      calcularTotalTransferencias();
      state.fechamento.transferencias.forEach((t) => {
        if ((t.codigoConta === 0 || t.codigoConta === undefined) && !retorno) {
          apresentarMensagemAlerta('Todos as contas devem ser informadas!');
          retorno = true;
        }
        if ((t.codigoPlanoContaCategoriaOrigem === 0 || t.codigoPlanoContaCategoriaOrigem === undefined) && !retorno) {
          apresentarMensagemAlerta('Todas as categorias de origem devem ser informadas!');
          retorno = true;
        }
        if ((t.codigoPlanoContaCategoriaDestino === 0 || t.codigoPlanoContaCategoriaDestino === undefined) && !retorno) {
          apresentarMensagemAlerta('Todas as categorias de destino devem ser informadas!');
          retorno = true;
        }
        if (t.valor === 0 || t.valor < 0) {
          apresentarMensagemAlerta('Todos os valores de transferências devem ser informado!');
          retorno = true;
        }
      });

      if ((state.aberturaCaixa.saldoFinal - state.totalTransferencias) < props.caixa.fundoCaixaMinimo) {
        apresentarMensagemAlerta('O fundo de caixa é menor que o fundo mínimo definido no cadastro do caixa!');
        retorno = true;
      }
      return retorno;
    }

    async function fecharCaixa() {
      const retornoValidacao = validarTransferencias();
      if (retornoValidacao) return;

      apresentarBarraProgresso();
      let retornoRequisicao: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      retornoRequisicao = await servicoControleCaixas.fecharCaixa(props.empresa, state.fechamento);
      ocultarBarraProgresso();
      if (retornoRequisicao.status === EStatusRetornoRequisicao.Sucesso) {
        emit('confirmacao');
        apresentarMensagemSucesso('Caixa fechado com sucesso!');
        modalBase.computedVisivel = false;
      } else {
        if (retornoRequisicao.mensagem === '') {
          retornoRequisicao.mensagem = 'Não foi possível fechar o caixa.';
        }
        apresentarRetornoRequisicao(retornoRequisicao);
      }
    }

    function confirmarFechamento() {
      Modal.confirm({
        title: 'Realmente deseja realizar o fechamento?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        centered: true,
        autoFocusButton: null,
        onOk: async () => { await fecharCaixa(); },
      });
    }

    async function obterSaldoAtualizado() {
      state.aberturaCaixa.totalEntradas = await servicoControleCaixas.obterTotalMovimentacoes(state.aberturaCaixa.codigo, props.empresa, ETipoMovimentoFinanceiro.Recebimento);
      state.aberturaCaixa.totalSaidas = await servicoControleCaixas.obterTotalMovimentacoes(state.aberturaCaixa.codigo, props.empresa, ETipoMovimentoFinanceiro.Pagamento);
      if (state.fechamento.aberturasAnterioresPendentes.length > 0) {
        let saldoFinalAnterior = 0;
        state.fechamento.aberturasAnterioresPendentes.forEach((a, index) => {
          let saldoAbertura = 0;
          if (index === 0) {
            saldoAbertura = a.saldoInicial + a.totalMovimentacoes;
          } else {
            saldoAbertura = saldoFinalAnterior + a.totalMovimentacoes;
          }
          saldoFinalAnterior = saldoAbertura;
        });
        state.aberturaCaixa.saldoInicial = saldoFinalAnterior;
      }
      state.aberturaCaixa.saldoFinal = (state.aberturaCaixa.saldoInicial + state.aberturaCaixa.totalEntradas) - state.aberturaCaixa.totalSaidas;
    }

    async function preencherContasPredefinidas() {
      let restante = state.aberturaCaixa.saldoFinal;
      state.contasFechamento = await servicoCaixa.obterContasFechamento(state.aberturaCaixa.codigoCaixa, props.empresa);
      state.contasFechamento.forEach((c) => {
        if (restante > 0 && props.caixa.fundoCaixaMinimo < restante) {
          const transferencia = {} as IFechamentoCaixaTransferencia;
          transferencia.codigoConta = c.codigoConta;
          transferencia.codigoPlanoContaCategoriaOrigem = c.codigoPlanoContaCategoriaOrigem;
          transferencia.codigoPlanoContaCategoriaDestino = c.codigoPlanoContaCategoriaDestino;
          if (props.caixa.fundoCaixaMinimo < c.valor) {
            transferencia.valor = c.valor - props.caixa.fundoCaixaMinimo;
          } else if (restante < c.valor) {
            transferencia.valor = restante;
          } else {
            transferencia.valor = c.valor;
          }
          restante -= transferencia.valor;
          state.fechamento.transferencias.push(transferencia);
        }
      });
      calcularTotalTransferencias();
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      if (modalBase.computedVisivel) {
        telaBase.carregando = true;
        const aberturasAnteriores = await servicoControleCaixas.obterAberturasAnterioresPendentesCaixa(props.abertura.codigo, props.empresa);
        if (UtilitarioGeral.validaLista(aberturasAnteriores)) {
          state.fechamento.aberturasAnterioresPendentes = aberturasAnteriores;
        }
        state.aberturaCaixa = props.abertura;
        await obterSaldoAtualizado();
        await preencherContasPredefinidas();
        telaBase.carregando = false;
      }
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      EPermissaoDados,
      storeSistema,
      UtilitarioGeral,
      apresentarRetornoRequisicao,
      calcularTotalTransferencias,
      adicionarTransferencia,
      excluirTransferencia,
      confirmarFechamento,
    };
  },
});
